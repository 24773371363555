import { Flex, Text } from '@chakra-ui/react'
import { Loading } from 'components/ui'
import { Container, Layout } from 'components/ui'
import { useCustomer } from 'contexts/customerContext'
import { useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'

import { CustomizedClientsTable } from './customizedClientsTable'

const CustomizedClientsTemplate = () => {
  const { user } = useProfile()
  if (!user) return <Loading />

  const { removeStorageItem } = useLocalStorage()
  const removeCachedDocument = () => removeStorageItem('cachedDocument')

  return (
    <Layout isShowBackButton onPageBack={removeCachedDocument}>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default CustomizedClientsTemplate

const Main = () => {
  const { getCustomizedClients } = useCustomer()

  useEffect(() => {
    getCustomizedClients()
  }, [])

  return (
    <Flex flexDir="column" bg="standard.white" w="100%" p="40px" gap="32px">
      <Flex gap="16px" flexDir="column">
        <Heading />
        <SubHeading />
      </Flex>

      <CustomizedClientsTable />
    </Flex>
  )
}

const Heading = () => (
  <Text
    color="pluxee.text.primary"
    fontWeight="700"
    fontSize="24px"
    letterSpacing="-0.8px"
  >
    Configurações por cliente
  </Text>
)

const SubHeading = () => (
  <Flex
    flexDir="column"
    color="#221C46"
    fontSize="16px"
    fontWeight="700"
    letterSpacing="-0.8px"
  >
    <Text>Carteiras customizadas</Text>
    <Text fontSize="14px" fontWeight="500">
      Esta área contém a lista de clientes que tiveram dados de carteira
      customizados. Para customizar uma
      <br /> carteira ou retornar aos valores catálogo, acesse a página do
      cliente.
    </Text>
  </Flex>
)
