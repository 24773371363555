import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    _disabled: {
      background: '#EFEFEF',
      borderColor: '#EFEFEF',
      _placeholder: {
        color: '#221C46'
      }
    }
  }
})

export const inputThemePlx = defineMultiStyleConfig({ baseStyle })
