import { Pagination } from 'models/Pagination'
import { GetAllProps } from 'modules/users/application/queries/GetAllProps'
import { User } from 'modules/users/domain/entities/User'
import { UserRole } from 'modules/users/domain/entities/UserRole'
import {
  useMutationCreateUser,
  useMutationDeleteUser,
  useMutationUpdateUser,
  useQueryFetchUsers,
  useQueryGetRoles
} from 'modules/users/presentation/hooks/useUserQueries'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren
} from 'react'

export type CreateAndUpdateUserProps = {
  login: string
  name: string
  id_role: number
}

type ErrorCreateUser = {
  response: {
    data: {
      message: string
    }
  }
}

type RequestStatus = 'error' | 'idle' | 'loading' | 'success'

export type ContextType = {
  users: User[]
  roles: UserRole[]

  selectedUser?: User
  setSelectedUser: (value: undefined | User) => void

  isSuccessGetAll: boolean
  isSuccessCreate: boolean
  isSuccessUpdate: boolean
  isSuccessRemove: boolean

  isFetching: boolean

  isLoading: boolean
  isLoadingGetAll: boolean
  isLoadingRoles: boolean
  isLoadingCreate: boolean
  isLoadingUpdate: boolean
  isLoadingRemove: boolean

  isErrorGetAll: boolean
  isErrorCreate: boolean
  isErrorRemove: boolean

  errorCreateUser: ErrorCreateUser

  statusGetAll: RequestStatus
  statusCreate: RequestStatus
  statusUpdate: RequestStatus

  usersPagination?: Pagination

  currentFilters: GetAllProps
  setCurrentFilters: (props: GetAllProps) => void

  createUser: (user: CreateAndUpdateUserProps) => Promise<void>
  updateUser: (id: number, user: CreateAndUpdateUserProps) => Promise<void>
  removeUser: (id: number) => Promise<void>
  updateUsersList: () => void

  searchInputValue: string
  setSearchInputValue: (value: string) => void

  currentRoleName: string
  setCurrentRoleName: (value: string) => void

  selectedRoleId?: string
  setSelectedRoleId: (value: string) => void

  isSearchingByRole: boolean
  setIsSearchingByRole: (value: boolean) => void
}

export const UserContext = createContext<ContextType | undefined>(undefined)

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User | undefined>()

  const [usersPagination, setUsersPagination] = useState<
    Pagination | undefined
  >()

  const [roles, setRoles] = useState<UserRole[]>([])
  const [currentRoleName, setCurrentRoleName] = useState('')
  const [selectedRoleId, setSelectedRoleId] = useState<string>('ALL')
  const [isSearchingByRole, setIsSearchingByRole] = useState(false)

  const [currentFilters, setCurrentFilters] = useState<GetAllProps>({})
  const [searchInputValue, setSearchInputValue] = useState('')

  const {
    data: usersData,
    isLoading: isLoadingGetAll,
    refetch: refetchUsers,
    isFetching,
    isError: isErrorGetAll,
    isSuccess: isSuccessGetAll,
    status: statusGetAll
  } = useQueryFetchUsers(currentFilters)

  const { data: rolesData, isLoading: isLoadingRoles } = useQueryGetRoles()

  const createUserMutation = useMutationCreateUser()
  const updateUserMutation = useMutationUpdateUser()
  const deleteUserMutation = useMutationDeleteUser()

  const {
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
    status: statusCreate,
    error: errorCreateUser
  } = createUserMutation

  const {
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    status: statusUpdate
  } = updateUserMutation

  const {
    isLoading: isLoadingRemove,
    isSuccess: isSuccessRemove,
    isError: isErrorRemove
  } = deleteUserMutation

  const createUser = async (user: CreateAndUpdateUserProps) => {
    await createUserMutation.mutateAsync(user)
    refetchUsers()
  }

  const updateUser = async (id: number, user: CreateAndUpdateUserProps) => {
    await updateUserMutation.mutateAsync({ id, user })
    refetchUsers()
  }

  const removeUser = async (id: number) => {
    await deleteUserMutation.mutateAsync(id)
    refetchUsers()
  }

  const updateUsersList = refetchUsers

  useEffect(() => {
    if (usersData) {
      setUsers(usersData.users)
      setUsersPagination({
        currentPage: usersData?.page?.currentPage,
        totalPages: usersData?.page?.totalPages,
        totalResults: usersData?.page?.totalItems
      })
    }
  }, [usersData])

  useEffect(() => {
    if (rolesData) setRoles(rolesData)
  }, [rolesData])

  return (
    <UserContext.Provider
      value={{
        users,
        roles,
        selectedUser,
        setSelectedUser,
        isSuccessGetAll,
        isSuccessCreate,
        isSuccessUpdate,
        isSuccessRemove,
        isFetching,
        isLoading:
          isLoadingGetAll ||
          isLoadingRoles ||
          isLoadingCreate ||
          isLoadingUpdate ||
          isLoadingRemove,
        isLoadingGetAll,
        isLoadingRoles,
        isLoadingCreate,
        isLoadingUpdate,
        isLoadingRemove,
        isErrorGetAll,
        isErrorCreate,
        isErrorRemove,
        setCurrentFilters,
        errorCreateUser: errorCreateUser as ErrorCreateUser,
        statusGetAll,
        statusCreate,
        statusUpdate,
        usersPagination,
        currentFilters,
        createUser,
        updateUser,
        removeUser,
        updateUsersList,
        searchInputValue,
        setSearchInputValue,
        currentRoleName,
        setCurrentRoleName,
        selectedRoleId,
        setSelectedRoleId,
        isSearchingByRole,
        setIsSearchingByRole
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
