import { type WalletModel } from 'models/Wallet'

export const formatWalletModel = (model: WalletModel) => {
  const data = {
    A: 'PAT',
    M: 'MULTI'
  }

  return data[model]
}
