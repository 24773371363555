import {
  Menu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  Center,
  MenuList,
  Button,
  Text,
  Box
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import React from 'react'

export type MenuItemProps = {
  title: string
  onClick: () => void
  color?: string
  isDisabled?: boolean
  icon?: React.ReactNode
  hiddenBorder?: boolean
  width?: string
  height?: string
  fontWeight?: string
  fontSize?: string
  padding?: string
}

export type PluxeeMenuProps = {
  items: MenuItemProps[]
  menuButton?: React.ReactNode
  customMarginTop?: string
  customTransform?: string
  customizedMenuItem?: React.ReactNode
}

const PluxeeMenu = ({
  items,
  menuButton,
  customizedMenuItem,
  customMarginTop = 'auto',
  customTransform = 'auto'
}: PluxeeMenuProps) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const itemsIsGreatherThanOne = items.length > 1

  return (
    <Menu isLazy gutter={2}>
      {menuButton ? (
        menuButton
      ) : (
        <ChakraMenuButton
          w="40px"
          h="40px"
          as={Button}
          background="transparent"
          borderRadius="none"
          color="pluxee.interactive.primary"
          _hover={{ ...defaultStyles }}
          _focusVisible={{ ...defaultStyles, border: '3px solid #1B51DC' }}
          _active={{
            ...defaultStyles,
            background: 'pluxee.cta.secondaryPressed',
            color: 'standard.white'
          }}
          _expanded={{
            ...defaultStyles,
            background: 'transparent',
            boxShadow: 'none !important'
          }}
        >
          <Center>
            <Icon
              name="keyboard_arrow_down"
              color="inherit"
              size="lg"
              cursor="pointer"
            />
          </Center>
        </ChakraMenuButton>
      )}

      <MenuList
        marginTop={customMarginTop}
        transform={customTransform}
        p="0"
        borderRadius="0"
        border={itemsIsGreatherThanOne ? '1px solid' : 'none'}
        borderColor={
          itemsIsGreatherThanOne ? 'pluxee.border.secondary' : 'transparent'
        }
        boxShadow={'4px 4px 0px 0px #A9A7B6'}
        _hover={{
          background: 'transparent',
          boxShadow: 'none'
        }}
      >
        {!!customizedMenuItem && customizedMenuItem}
        {items.map(item => {
          return (
            <MenuItem
              key={`Menu Option - ${item.title}`}
              width={item.width}
              height={item.height}
              title={item.title}
              isDisabled={item.isDisabled}
              onClick={item.onClick}
              icon={item.icon}
              color={item.color}
              fontSize={item.fontSize}
              fontWeight={item.fontWeight}
              hiddenBorder={itemsIsGreatherThanOne}
              padding={item.padding}
            />
          )
        })}
      </MenuList>
    </Menu>
  )
}

export const MenuItem = ({
  title,
  onClick,
  icon,
  hiddenBorder,
  isDisabled = false,
  color = 'pluxee.text.primary',
  width = '188px',
  height = '44px',
  fontWeight = '600',
  fontSize = 'xxs',
  padding = '12px 8px'
}: MenuItemProps) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: isDisabled ? '#EFEFEF' : '#DEF3FB',
    color: !isDisabled && color
  }

  return (
    <ChakraMenuItem
      display={isDisabled ? 'none' : 'flex'}
      gap="8px"
      w={width}
      h={height}
      p={padding}
      fontWeight={fontWeight}
      fontSize={fontSize}
      outline="none"
      boxShadow={'4px 4px 0px 0px #CBCAD2'}
      border={hiddenBorder ? 'none' : '1px solid'}
      borderColor="pluxee.border.secondary"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      background={isDisabled ? '#EFEFEF' : 'standard.white'}
      color={isDisabled ? '#B5B2BC' : color}
      onClick={isDisabled ? () => undefined : onClick}
      _hover={{ ...defaultStyles }}
      _focusVisible={{
        ...defaultStyles,
        border: !isDisabled && '3px solid #1B51DC'
      }}
      _active={{
        ...defaultStyles,
        color: !isDisabled && 'standard.white',
        background: !isDisabled && 'pluxee.cta.secondaryPressed'
      }}
    >
      <Box color={isDisabled ? '#B5B2BC' : 'inherit'}>{icon}</Box>
      <Text whiteSpace="nowrap" letterSpacing="-0.8px">
        {title}
      </Text>
    </ChakraMenuItem>
  )
}

export default PluxeeMenu
