import { Flex, Center, Text } from '@chakra-ui/react'
import { CustomerWallet } from 'models/CustomerWallet'
import React from 'react'
import {
  WalletIcon,
  renderWalletBg,
  renderWalletIcon
} from 'templates/customerDetails/customerWallets/walletCard'
import { getFinalWalletStatus } from 'templates/customerDetails/customerWallets/walletDetails'
import {
  StatusEnum,
  WalletStatus
} from 'templates/customerDetails/customerWallets/walletStatus'
import { capitalizeSentence, limitString } from 'utils/stringUtils'

export const WalletInfo = ({ name, status, wallet }: CustomerWallet) => {
  return (
    <Flex alignItems="center" gap="24px">
      <Center
        w="40px"
        h="40px"
        borderRadius="4px"
        bg={renderWalletBg(
          capitalizeSentence(limitString(name, 18)) as WalletIcon
        )}
      >
        {renderWalletIcon(
          capitalizeSentence(limitString(name, 18)) as WalletIcon
        )}
      </Center>

      <Flex flexDir="column" gap="8px" letterSpacing="-0.8px">
        <Text fontSize="24px" fontWeight="700">
          {capitalizeSentence(name)}
        </Text>

        <Flex alignItems="center" gap="8px">
          <Text
            fontSize="16px"
            fontWeight="500"
            letterSpacing="-0.8px"
            color="#463F5F"
          >
            Status:
          </Text>
          <WalletStatus
            status={
              getFinalWalletStatus({
                customerWalletStatus: status,
                walletCatalogStatus: wallet.status
              }) as StatusEnum
            }
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
