import { Flex } from '@chakra-ui/react'
import { Box, Text } from 'components/atoms'
import React from 'react'
import { getFormattedStatus } from 'utils/transactionsUtils'

export type AcceptedStatus =
  | 'ACTIVE'
  | 'BLOCKED'
  | 'CANCELLED'
  | 'INACTIVE'
  | 'DRAFT'
  | 'CREATED'

export type StatusLabelProps = {
  statusType: AcceptedStatus
}

const StatusLabel = ({ statusType }: StatusLabelProps) => {
  const getStatusColor = (type: AcceptedStatus) => {
    const colors = {
      CANCELLED: 'pluxee.border.error',
      WAITING_CANCELLATION: 'pluxee.border.error',
      BLOCKED: 'pluxee.border.error',
      ACTIVE: 'pluxee.border.shiped',
      INACTIVE: 'pluxee.border.error',
      PRE_BLOCKED: '#878DFA',
      DRAFT: '#F8A05B',
      CREATED: '#878DFA'
    }

    return colors[type]
  }

  return (
    <Flex
      gap="8px"
      p="4px 8px"
      w="fit-content"
      h="24px"
      border="1px solid"
      alignItems="center"
      justifyContent="center"
      background="standard.white"
      borderColor={getStatusColor(statusType)}
    >
      <Box
        w="8px"
        h="8px"
        borderRadius="50%"
        bgColor={getStatusColor(statusType)}
      />

      <Text
        fontSize="xxs"
        fontWeight="500"
        color="pluxee.text.primary"
        letterSpacing="-0.7px"
      >
        {getFormattedStatus(statusType)}
      </Text>
    </Flex>
  )
}

export default StatusLabel
