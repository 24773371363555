import { Button, Flex, Spinner, Stack, Text } from '@chakra-ui/react'
import { Modal, Checkbox } from 'components/ui'
import { UserRole } from 'modules/users/domain/entities/UserRole'
import React from 'react'

interface FilterByRolePresentationProps {
  isOpen: boolean
  onClose: () => void
  isLoadingRoles: boolean
  roles: UserRole[]
  selectedRoleId: string
  tempCurrentRoleName: string
  handleFilterUsersByRole: () => void
  setSelectedRoleId: (id: string) => void
  setTempCurrentRoleName: (name: string) => void
}

export const FilterByRolePresentation = ({
  isOpen,
  onClose,
  isLoadingRoles,
  roles,
  selectedRoleId,
  handleFilterUsersByRole,
  setSelectedRoleId,
  setTempCurrentRoleName
}: FilterByRolePresentationProps) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const modalFooter = (
    <Flex gap="16px">
      <Button
        bg="transparent"
        w="97px"
        h="48px"
        color="#1B51DC"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          outline: '3px solid #1B51DC',
          boxShadow: 'none !important'
        }}
        onClick={onClose}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
          Cancelar
        </Text>
      </Button>

      <Button
        w="82px"
        h="48px"
        bg="#1B51DC"
        color="white"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles, border: 'none' }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          boxShadow: 'none !important'
        }}
        onClick={handleFilterUsersByRole}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
          Aplicar
        </Text>
      </Button>
    </Flex>
  )

  if (isLoadingRoles) return <Spinner />

  return (
    <Modal
      heading="Filtrar"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      decreaseMarginTop
    >
      <Stack spacing="nano">
        <Flex w="100%" mb="20px">
          <Checkbox
            isRounded
            title="Ver todos os perfis"
            checked={selectedRoleId === 'ALL'}
            onChange={() => {
              setSelectedRoleId('ALL')
              setTempCurrentRoleName('ALL')
            }}
          />
        </Flex>

        {roles?.map(({ id, name }) => (
          <Checkbox
            isRounded
            title={name}
            checked={String(id) === selectedRoleId}
            key={`Checkbox for Role: ${name}`}
            onChange={() => {
              setSelectedRoleId(String(id))
              setTempCurrentRoleName(name)
            }}
          />
        ))}
      </Stack>
    </Modal>
  )
}
