import { AxiosError } from 'axios'
import {
  useMutationBlockCard,
  useMutationUnblockCard,
  useQueryGetCards
} from 'hooks/queries/accountQueries'
import { Card, CardStatus } from 'models/Card'
import { BlockCard } from 'models/request/BlockCard'
import { UnblockCard } from 'models/request/UnblockCard'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

type FilterType = {
  status: CardStatus | 'ALL'
}

export type ContextType = {
  cards: Card[]
  filter: FilterType
  isLoading: boolean
  isError: boolean

  isBlockingCard: boolean
  isBlockSuccess: boolean
  isBlockError: boolean

  isUnblockingCard: boolean
  isUnblockSuccess: boolean
  isUnblockError: boolean

  unblockErrorMessage: AxiosError<{ message: string }>

  lockCard: ({ cardId, productId, reason }: BlockCard) => void
  unlockCard: ({ cardId, productId, reason }: UnblockCard) => Promise<void>

  setFilter: (filter: FilterType) => void

  refetch: () => void
  isRefetching: boolean
}

export const Context = createContext({} as ContextType)

type AccountCardsProviderProps = {
  accountId?: number
  children: ReactNode
}

export const AccountCardsProvider = ({
  children,
  accountId
}: AccountCardsProviderProps) => {
  const [cards, setCards] = useState<Card[]>([])
  const [filter, setFilter] = useState<FilterType>({ status: 'ALL' })
  const [unblockErrorMessage, setUnblockErrorMessage] =
    useState<AxiosError<{ message: string }>>()

  const queryAccount = useQueryGetCards(accountId)
  const { isLoading, isError, refetch, isRefetching } = queryAccount

  const mutateBlockCard = useMutationBlockCard()
  const {
    isLoading: isBlockingCard,
    isSuccess: isBlockSuccess,
    isError: isBlockError
  } = mutateBlockCard

  const mutateUnblockCard = useMutationUnblockCard()
  const {
    isLoading: isUnblockingCard,
    isSuccess: isUnblockSuccess,
    isError: isUnblockError,
    error: unblockError
  } = mutateUnblockCard

  const lockCard = useCallback(
    async (blockCardData: BlockCard) => {
      await mutateBlockCard.mutateAsync({ ...blockCardData })
    },
    [mutateBlockCard]
  )

  const unlockCard = useCallback(
    async (unblockCardData: UnblockCard) => {
      await mutateUnblockCard.mutateAsync({ ...unblockCardData })
    },
    [mutateUnblockCard]
  )

  useEffect(() => {
    const axiosError: AxiosError<{ message: string }> =
      unblockError as AxiosError<{ message: string }>

    setUnblockErrorMessage(axiosError)
  }, [unblockError])

  useEffect(() => {
    if (accountId && queryAccount.data) {
      const filteredCards = queryAccount.data.filter(
        (account: { status: CardStatus }) => {
          if (filter.status === 'ALL') {
            return true
          } else if (filter.status === 'BLOCKED') {
            return ['BLOCKED', 'PRE_BLOCKED'].includes(account.status)
          } else if (filter.status === 'CANCELLED') {
            return ['CANCELLED', 'WAITING_CANCELLATION'].includes(
              account.status
            )
          }

          return account.status === filter.status
        }
      )

      setCards(filteredCards)
    }
  }, [accountId, filter.status, queryAccount.data])

  return (
    <Context.Provider
      value={{
        cards,
        filter,
        isLoading,
        isError,
        isBlockingCard,
        isBlockSuccess,
        isBlockError,
        isUnblockingCard,
        isUnblockSuccess,
        isUnblockError,
        lockCard,
        unlockCard,
        setFilter,
        refetch,
        isRefetching,
        unblockErrorMessage: unblockErrorMessage as AxiosError<{
          message: string
        }>
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAccountCards = () => {
  const context = useContext(Context)

  return context
}
