import { CreateAndEditWallet } from 'models/Wallet'
import { WalletPriority } from 'models/WalletPriority'
import { useMutation } from 'react-query'
import { WalletService } from 'services/walletService'

export function useMutationGetAllWallets() {
  return useMutation(({ productId }: { productId: number }) =>
    getAll(productId)
  )
}

export function useMutationGetById() {
  return useMutation(({ walletId }: { walletId: number }) => getById(walletId))
}

export function useMutationUpdatePriorities() {
  return useMutation((newPriorities: WalletPriority[]) =>
    updatePriorities(newPriorities)
  )
}

export function useMutationCreate() {
  return useMutation(create)
}

export function useMutationUpdate() {
  return useMutation(update)
}

export function useMutationActive() {
  return useMutation(active)
}

export function useMutationInactive() {
  return useMutation(inactive)
}

export function useMutationRemove() {
  return useMutation(remove)
}

const getAll = async (productId?: number) => {
  if (!productId) return

  const response = await WalletService.getWalletsByProductId(productId)
  return response.data
}

const getById = async (walletId?: number) => {
  if (!walletId) return

  const response = await WalletService.getWalletById(walletId)
  return response.data
}

const create = async (wallet: CreateAndEditWallet) => {
  const response = await WalletService.create(wallet)
  return response.data
}

const update = async (wallet: CreateAndEditWallet) => {
  const response = await WalletService.update(wallet)
  return response.data
}

const active = async (walletId: number) => {
  return await WalletService.active(walletId)
}

const inactive = async (walletId: number) => {
  return await WalletService.inactive(walletId)
}

const remove = async (walletId: number) => {
  return await WalletService.delete(walletId)
}

const updatePriorities = async (newPriorities: WalletPriority[]) => {
  if (!newPriorities) return

  const response = await WalletService.updatePriorities(newPriorities)
  return response.data
}
