import { Box, Flex, Spinner } from '@chakra-ui/react'
import React from 'react'

const Loading = () => {
  return (
    <Flex
      fontFamily="TTTravels"
      bg="#FAF8FF"
      w="100%"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        w="48px"
        h="48px"
        bg="#FFF"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        filter="drop-shadow(2px 2px 0px rgba(34, 28, 70, 0.20))"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#00EB5E"
          size="xl"
          w="24px"
          h="24px"
        />
      </Box>
    </Flex>
  )
}

export default Loading
