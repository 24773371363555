import { GetOriginalTransaction } from 'modules/transaction/application/useCases/GetOriginalTransaction'
import { GetTransaction } from 'modules/transaction/application/useCases/GetTransaction'
import { TransactionRepository } from 'modules/transaction/infra/repositories/TransactionRepository'
import { useMutation, useQuery } from 'react-query'

const transactionRepository = new TransactionRepository()

const getTransaction = new GetTransaction(transactionRepository)
const getOriginalTransaction = new GetOriginalTransaction(transactionRepository)

export function useQueryGetTransactionByUUID(uuid?: string, options = {}) {
  return useQuery(
    ['getTransactionByUUID', uuid],
    () => getTransaction.execute(uuid),
    { enabled: !!uuid, retry: 0, ...options }
  )
}

export function useMutateGetOriginalTransaction() {
  return useMutation(
    (transactionUUID?: string) =>
      getOriginalTransaction.execute(transactionUUID),
    { retry: 0 }
  )
}
