import { Button, Text } from '@chakra-ui/react'
import React from 'react'

import { ButtonProps } from './ButtonProps'

const SecondaryButton = ({ title, onClick }: ButtonProps) => (
  <Button
    h="40px"
    p="10px 16px"
    color="#1B51DC"
    bg="white"
    border="3px solid"
    borderRadius="none"
    borderColor="#1B51DC"
    onClick={onClick}
    _hover={{
      boxShadow: '4px 4px 0px 0px #CBCAD2 !important',
      background: '#DEF3FB',
      borderColor: '#1B51DC',
      color: '#1B51DC'
    }}
    _active={{
      boxShadow: '2px 2px 0px 0px #A9A7B6 !important',
      color: 'standard.white',
      background: '#0F266D',
      borderColor: '#0F266D'
    }}
    _focusVisible={{
      color: '#1B51DC',
      background: '#DEF3FB',
      border: '3px solid #1B51DC',
      outline: '3px solid #1B51DC'
    }}
  >
    <Text fontSize="14px" fontWeight="700">
      {title}
    </Text>
  </Button>
)

export default SecondaryButton
