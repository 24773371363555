import {
  BoxProps,
  Flex,
  FormControl,
  FormLabel,
  Switch
} from '@chakra-ui/react'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import Tooltip from './Tooltip'

export type ToggleProps = {
  title: string
  titleSize?: string
  tooltip?: string
  isReverseLayout?: boolean
  register: UseFormRegister<any>
  id: string
  isDisabled?: boolean
  isChecked?: boolean
  onChange: () => void
} & BoxProps

const Toggle = ({
  id,
  titleSize = '16px',
  register,
  title,
  tooltip,
  isReverseLayout = false,
  isDisabled = false,
  isChecked,
  onChange,
  ...rest
}: ToggleProps) => {
  return (
    <FormControl
      display="flex"
      alignItems="center"
      gap={isReverseLayout ? '8px' : '0px'}
      {...rest}
    >
      <Flex
        alignItems="center"
        gap={isReverseLayout ? '0px' : '8px'}
        flexDir={isReverseLayout ? 'row-reverse' : 'row'}
      >
        <Switch
          id={id}
          {...register(id)}
          isChecked={isChecked}
          isDisabled={isDisabled}
          onChange={onChange}
          sx={{
            _disabled: {
              '& .chakra-switch__thumb': {
                background: '#B5B2BC'
              },
              '& .chakra-switch__track': {
                background: '#EFEFEF'
              }
            }
          }}
        />

        <FormLabel
          onClick={onChange}
          htmlFor={title}
          mb="0"
          color={isDisabled ? '#B5B2BC' : '#221C46'}
          fontSize={titleSize}
          fontWeight="500"
          letterSpacing="-0.8px"
          cursor="pointer"
        >
          {title}
        </FormLabel>
      </Flex>

      {!!tooltip && <Tooltip label={tooltip} iconColor="#1B51DC" />}
    </FormControl>
  )
}

export default Toggle
