import sanitizeHtml from 'sanitize-html'

const isSqlInjectionAttempt = (value: string): boolean => {
  const sqlKeywords = [
    'SELECT',
    'INSERT',
    'UPDATE',
    'DELETE',
    'DROP',
    '--',
    ';'
  ]
  const upperValue = value.toUpperCase()
  return sqlKeywords.some(keyword => upperValue.includes(keyword))
}

type SanitizedObject = {
  [key: string]: any
}

export const sanitizeObject = (input: any): any => {
  if (typeof input === 'object' && input !== null) {
    const sanitized: SanitizedObject = {}

    Object.keys(input).forEach(key => {
      const value = input[key]
      sanitized[key] = sanitizeObject(value)
    })

    return sanitized
  }

  if (typeof input === 'string') {
    let sanitizedValue = sanitizeHtml(input)

    if (isSqlInjectionAttempt(sanitizedValue)) {
      sanitizedValue = ''
    }

    return sanitizedValue
  }

  return input
}

export const sanitizeValue = (value: any) => {
  let sanitizedValue = sanitizeHtml(value)

  if (isSqlInjectionAttempt(sanitizedValue)) {
    sanitizedValue = ''
  }

  return sanitizedValue
}
