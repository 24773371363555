import { menuAnatomy } from '@chakra-ui/anatomy'
import { PartsStyleObject } from '@chakra-ui/theme-tools'

type MenuStyle = PartsStyleObject<typeof menuAnatomy>

type VariantStyles = {
  [key: string]: MenuStyle
}

type BaseStyle = MenuStyle

type MenuConfig = {
  baseStyle?: BaseStyle
  sizes?: VariantStyles
  variants?: VariantStyles
  defaultProps?: {
    size: string
    variant: string
  }
}

const variants: VariantStyles = {
  'brand-primary-default': {
    item: {
      bgColor: 'neutral.white'
    },
    list: {
      borderRadius: '8px',
      minWidth: 'fit-content'
    }
  },
  'brand-primary-solid': {
    item: {
      bgColor: 'neutral.white',
      fontWeight: 'bold',
      color: 'neutral.extradark'
    },
    list: {
      borderRadius: '8px',
      minWidth: 'fit-content'
    }
  }
}

const MenuTheme: MenuConfig = {
  baseStyle: {
    item: {
      _hover: {
        bgColor: 'standard.white'
      },
      _focus: {
        bgColor: 'standard.white'
      }
    },
    list: {
      boxShadow: '0px 0px 8px rgba(33, 37, 41, 0.16)'
    }
  },
  sizes: {
    sm: {
      item: {
        fontSize: 'xxs',
        px: 'xxxs',
        py: 'quarck',
        color: 'primaryColors.blue.80',
        _hover: {
          color: 'brand.primary-default'
        }
      }
    },
    md: {
      item: {
        fontSize: 'xs',
        px: 'xxxs',
        py: 'nano',
        lineHeight: '20px',
        color: 'primaryColors.blue.80',
        _hover: {
          color: 'brand.primary-default'
        }
      }
    },
    lg: {
      item: {
        fontSize: 'sm',
        px: 'xxxs',
        py: 'nano',
        color: 'primaryColors.blue.80',
        _hover: {
          color: 'brand.primary-default'
        }
      }
    }
  },
  variants,
  defaultProps: {
    variant: 'brand-primary-default',
    size: 'sm'
  }
}

export default MenuTheme
