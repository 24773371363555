import { Button, Flex, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { InputSearchUser } from 'modules/users/presentation/components'
import React from 'react'

interface FilterAreaPresentationProps {
  isLoadingGetAll: boolean
  currentRoleName: string
  isValidRole: boolean
  onOpenFilterByRole: () => void
  handleResetFilterByRole: () => void
  defaultStyles: Record<string, any>
}

export const FilterAreaPresentation = ({
  isLoadingGetAll,
  currentRoleName,
  isValidRole,
  onOpenFilterByRole,
  handleResetFilterByRole,
  defaultStyles
}: FilterAreaPresentationProps) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <InputSearchUser />

      <Flex alignItems="end" gap="16px" flexDir="column">
        <Button
          bg="transparent"
          w="169px"
          h="40px"
          isLoading={isLoadingGetAll}
          onClick={onOpenFilterByRole}
          color="#1B51DC"
          border="3px solid"
          borderRadius="none"
          borderColor="#1B51DC"
          rightIcon={<Icon name="filter_list" size="xs" fontWeight="700" />}
          _hover={{ ...defaultStyles }}
          _active={{
            ...defaultStyles,
            background: 'pluxee.cta.secondaryPressed',
            color: 'standard.white',
            borderColor: 'pluxee.cta.secondaryPressed',
            boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
          }}
          _focusVisible={{
            ...defaultStyles,
            outline: '3px solid #1B51DC',
            boxShadow: 'none !important'
          }}
        >
          <Text fontSize="14px" fontWeight="700" letterSpacing="-0.6px">
            Filtrar por perfil
          </Text>
        </Button>

        {isValidRole && (
          <Button
            h="32px"
            p="4px 8px"
            color="#221C46"
            bg="#E3E2FA"
            border="3px solid"
            borderRadius="none"
            borderColor="transparent"
            _hover={{ ...defaultStyles, bg: '#878DFA', color: 'white' }}
            _active={{
              ...defaultStyles,
              background: 'pluxee.cta.secondaryPressed',
              color: 'standard.white',
              borderColor: 'pluxee.cta.secondaryPressed',
              boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
            }}
            _focusVisible={{
              ...defaultStyles,
              outline: '3px solid #1B51DC',
              boxShadow: 'none !important',
              color: '#221C46',
              bg: '#E3E2FA'
            }}
            gap="6px"
            onClick={handleResetFilterByRole}
          >
            <Text fontSize="14px" fontWeight="700">
              {currentRoleName}
            </Text>

            <Icon name="close" size="md" color="#1B51DC" />
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
