import { Flex } from '@chakra-ui/react'
import { SearchInput, SubmitButton } from 'components/ui'
import React from 'react'

interface InputSearchPresentationalProps {
  isError: boolean
  searchInputValue: string
  setSearchInputValue: (value: string) => void
  handleFilterByUserName: (username: string) => void
  isLoadingGetAll: boolean
}

export function InputSearchPresentational({
  isError,
  searchInputValue,
  setSearchInputValue,
  handleFilterByUserName,
  isLoadingGetAll
}: InputSearchPresentationalProps) {
  return (
    <Flex minW="665px" alignItems="flex-end" gap="32px">
      <SearchInput
        isError={isError}
        isOptionalLabel
        customHeight="48px"
        placeholder="Informe o nome de usuário"
        value={searchInputValue}
        onChange={inputValue => setSearchInputValue(inputValue)}
        label="Buscar usuário"
        customIcon="search"
        imageIcon="/img/users/searchicon.svg"
        showClearButton={searchInputValue.length >= 1}
        errorMessage="Usuário não encontrado, verifique e tente novamente"
        onClear={() => {
          setSearchInputValue('')
          handleFilterByUserName('')
        }}
      />

      <SubmitButton
        onClick={() => handleFilterByUserName(searchInputValue)}
        isLoading={isLoadingGetAll}
        isDisabled={!searchInputValue.trim()}
        decreaseMarginBottom
        customMarginBottom={!isError ? '5px' : '26.5px'}
      />
    </Flex>
  )
}
