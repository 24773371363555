import { type Product } from 'models/Product'

type FormatCustomerModelProps = {
  products: Product[]
}

export const formatCustomerModel = ({ products }: FormatCustomerModelProps) => {
  const formattedCustomerModel = products
    ?.map(
      product => `${product.id} - ${product.patCustomer ? 'PAT' : 'Auxílio'}`
    )
    .join(' | ')

  return { formattedCustomerModel }
}

export const formatConsumerModel = ({
  isPatCustomer
}: {
  isPatCustomer: boolean
}) => {
  const formattedConsumerModel = isPatCustomer ? 'PAT' : 'Auxílio'
  return { formattedConsumerModel }
}

export const formatCustomerProduct = ({
  products
}: FormatCustomerModelProps) => {
  const formattedCustomerProduct = products
    ?.map(product => `${product.id} - ${product.name}`)
    .join(' | ')

  return { formattedCustomerProduct }
}

type ProductEnum = '34' | '39'

export const formatProductId = (productId: string) => {
  const data = {
    34: '3C',
    39: '4C'
  }

  return data[productId as ProductEnum] ?? productId
}

type ProductNameEnum = 'New 4C' | 'New 3C Gift'

export const formatProductName = (productName: string) => {
  const data = {
    'New 4C': '4C',
    'New 3C Gift': '3C'
  }

  return data[productName as ProductNameEnum] ?? productName
}
