import {
  useMutationCreditInTrust,
  useMutationCreditReversal,
  useMutationGetRefundableHistory,
  useMutationProvisionalCredit,
  useMutationReverseCredit,
  useMutationReverseTransaction,
  useQueryGetAllTransactions,
  useQueryGetTransactions,
  useQueryGetTransactionsByFilters
} from 'hooks/queries/accountWalletQueries'
import { AccountWallet } from 'models/AccountWallet'
import { Pagination } from 'models/Pagination'
import { GetRefundableHistory } from 'models/request/GetRefundableHistory'
import { RefundableHistory } from 'models/response/RefundableHistory'
import type {
  WalletTransaction,
  WalletTransationTypes
} from 'models/WalletTransaction'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  AllTransactions,
  TransactionsFilters
} from 'services/accountWalletService'

export type ReverseTransactionTypes = {
  value: number
  occurrence: string
  reason: string
  customerId: number
}

export type CreditInTrustTypes = {
  uuid?: string
  authorization_code?: string
  value: string
  occorence_code: string
  reason: string
}

export type CreditReversalTypes = {
  uuid?: string
  value: string
  occorence_code: string
  reason: string
}

export type ContextType = {
  allTransactions: WalletTransaction[]
  transactions: WalletTransaction[]

  selectedTransaction: WalletTransaction | null
  setSelectedTransaction: (transaction: WalletTransaction) => void

  isLoadingAllTransactions: boolean
  isErrorAllTransactions: boolean

  isLoading: boolean
  isLoadingWithFilters: boolean
  isError: boolean

  walletTransactionsPagination?: Pagination
  allTransactionPagination?: Pagination

  setWallet: (wallet: AccountWallet | null) => void
  setAccountId: (value: number) => void
  selectTransaction: (transaction: WalletTransaction) => void

  reverseCredit: (payload: CreditInTrustTypes) => Promise<void>
  isLoadingReverseCredit: boolean
  isErrorReverseCredit: boolean
  isSuccessReverseCredit: boolean

  addCreditInTrust: (payload: CreditInTrustTypes) => void
  isLoadingCreditInTrust: boolean
  isErrorCreditInTrust: boolean
  isSuccessCreditInTrust: boolean

  addProvisionalCredit: (payload: CreditInTrustTypes) => Promise<void>
  isLoadingProvisionalCredit: boolean
  isErrorProvisionalCredit: boolean
  isSuccessProvisionalCredit: boolean

  addCreditReversal: (payload: CreditReversalTypes) => Promise<void>
  isLoadingCreditReversal: boolean
  isErrorCreditReversal: boolean
  isSuccessCreditReversal: boolean

  reverseTransaction: (payload: ReverseTransactionTypes) => void
  isLoadingReverseTransaction: boolean
  isErrorReverseTransaction: boolean
  isSuccessReverseTransaction: boolean

  refundableHistory: RefundableHistory | undefined
  setRefundableHistory: (value: RefundableHistory | undefined) => void

  isLoadingGetRefundableHistory: boolean
  isErrorGetRefundableHistory: boolean
  getRefundableHistory: ({
    transactionUUID
  }: GetRefundableHistory) => Promise<void>

  getAllTransactions: (filters: AllTransactions) => void
  getTransactiosByFilter: (filters: TransactionsFilters) => void
  currentFilters: TransactionsFilters

  refreshTransactionsList: () => void
  isRefetching: boolean

  setWalletId: (value: number) => void

  originalTransaction?: WalletTransaction
  setOriginalTransaction: (transaction?: WalletTransaction) => void

  originalTransactionUUID?: string
  setOriginalTransactionUUID: (value?: string) => void

  originalTransactionNSU?: string
  setOriginalTransactionNSU: (value?: string) => void

  openModalViaTransaction?: WalletTransationTypes
  setOpenModalViaTransaction: (type: WalletTransationTypes) => void
}

export const Context = createContext({} as ContextType)

type WalletTransactionProviderProps = {
  children: ReactNode
}

export const WalletTransactionProvider = ({
  children
}: WalletTransactionProviderProps) => {
  const [allTransactions, setAllTransactions] = useState<WalletTransaction[]>(
    []
  )
  const [transactions, setWalletTransactions] = useState<WalletTransaction[]>(
    []
  )

  const [originalTransaction, setOriginalTransaction] =
    useState<WalletTransaction>()

  const [originalTransactionNSU, setOriginalTransactionNSU] = useState<string>()
  const [originalTransactionUUID, setOriginalTransactionUUID] =
    useState<string>()

  const [refundableHistory, setRefundableHistory] =
    useState<RefundableHistory>()

  const [selectedTransaction, setSelectedTransaction] =
    useState<WalletTransaction | null>(null)

  const [wallet, setWallet] = useState<AccountWallet | null>(null)
  const [accountId, setAccountId] = useState<number>()
  const [walletId, setWalletId] = useState<number>()

  const [currentFilters, setCurrentFilters] = useState<TransactionsFilters>({})

  const [walletTransactionsPagination, setWalletTransactionsPagination] =
    useState<Pagination>()

  const [allTransactionPagination, setAllTransactionPagination] =
    useState<Pagination>()

  const [openModalViaTransaction, setOpenModalViaTransaction] =
    useState<WalletTransationTypes>()

  const {
    isLoading,
    isError,
    refetch: refreshTransactionsList,
    isRefetching
  } = useQueryGetTransactions({
    walletId: wallet?.walletId,
    accountId
  })

  const mutateGetWithFilters = useQueryGetTransactionsByFilters()
  const { data: queryTransactionsByFilters, isLoading: isLoadingWithFilters } =
    mutateGetWithFilters

  const mutateGetAllTransactions = useQueryGetAllTransactions()
  const {
    data: queryAllTransactions,
    isLoading: isLoadingAllTransactions,
    isError: isErrorAllTransactions
  } = mutateGetAllTransactions

  const mutateProvisionalCredit = useMutationProvisionalCredit()
  const {
    isLoading: isLoadingProvisionalCredit,
    isError: isErrorProvisionalCredit,
    isSuccess: isSuccessProvisionalCredit
  } = mutateProvisionalCredit

  const mutateCreditInTrust = useMutationCreditInTrust()
  const {
    isLoading: isLoadingCreditInTrust,
    isError: isErrorCreditInTrust,
    isSuccess: isSuccessCreditInTrust
  } = mutateCreditInTrust

  const mutateCreditReversal = useMutationCreditReversal()
  const {
    isLoading: isLoadingCreditReversal,
    isError: isErrorCreditReversal,
    isSuccess: isSuccessCreditReversal
  } = mutateCreditReversal

  const mutateReverseCredit = useMutationReverseCredit()
  const {
    isLoading: isLoadingReverseCredit,
    isError: isErrorReverseCredit,
    isSuccess: isSuccessReverseCredit
  } = mutateReverseCredit

  const mutateReverseTransaction = useMutationReverseTransaction()
  const {
    isLoading: isLoadingReverseTransaction,
    isError: isErrorReverseTransaction,
    isSuccess: isSuccessReverseTransaction
  } = mutateReverseTransaction

  const mutateGetRefundableHistory = useMutationGetRefundableHistory()
  const {
    data: queryGetRefundableHistory,
    isLoading: isLoadingGetRefundableHistory,
    isError: isErrorGetRefundableHistory
  } = mutateGetRefundableHistory

  const getRefundableHistory = async ({
    transactionUUID
  }: GetRefundableHistory) => {
    return await mutateGetRefundableHistory.mutateAsync({
      transactionUUID
    })
  }

  const getAllTransactions = async (filters: TransactionsFilters) => {
    if (!filters) return
    setCurrentFilters({ ...filters })

    return await mutateGetAllTransactions.mutateAsync({
      accountId,
      ...filters
    })
  }

  const getTransactiosByFilter = async (filters: TransactionsFilters) => {
    if (!filters) return

    setCurrentFilters({ ...filters })

    return await mutateGetWithFilters.mutateAsync({
      walletId: walletId,
      accountId,
      ...filters
    })
  }

  const selectTransaction = (wallet: WalletTransaction) => {
    setSelectedTransaction(wallet)
  }

  const reverseCredit = async ({
    occorence_code,
    reason,
    value,
    authorization_code,
    uuid
  }: CreditInTrustTypes) => {
    await mutateReverseCredit.mutateAsync({
      authorization_code,
      uuid,
      occorence_code,
      reason,
      value,
      walletId: Number(walletId),
      accountId: Number(accountId)
    })
  }

  const addProvisionalCredit = async ({
    occorence_code,
    reason,
    value,
    authorization_code,
    uuid
  }: CreditInTrustTypes) => {
    await mutateProvisionalCredit.mutateAsync({
      authorization_code,
      uuid,
      occorence_code,
      reason,
      value,
      walletId: Number(walletId),
      accountId: Number(accountId)
    })
  }

  const addCreditInTrust = async ({
    occorence_code,
    reason,
    value,
    authorization_code,
    uuid
  }: CreditInTrustTypes) => {
    await mutateCreditInTrust.mutateAsync({
      authorization_code,
      uuid,
      occorence_code,
      reason,
      value,
      walletId: Number(walletId),
      accountId: Number(accountId)
    })
  }

  const addCreditReversal = async ({
    occorence_code,
    reason,
    value,
    uuid
  }: CreditReversalTypes) => {
    await mutateCreditReversal.mutateAsync({
      uuid,
      occorence_code,
      reason,
      value,
      walletId: Number(walletId),
      accountId: Number(accountId)
    })
  }

  const reverseTransaction = async ({
    occurrence,
    reason,
    value,
    customerId
  }: ReverseTransactionTypes) => {
    await mutateReverseTransaction.mutateAsync({
      occurrence,
      reason,
      value,
      walletId: Number(walletId),
      accountId: Number(accountId),
      customerId: customerId
    })
  }

  useEffect(() => {
    if (isError) {
      setWalletTransactions([])
      setWalletTransactionsPagination(undefined)
    }
  }, [isError])

  useEffect(() => {
    if (queryTransactionsByFilters) {
      setWalletTransactions(queryTransactionsByFilters.transactions)
      setWalletTransactionsPagination({
        currentPage: queryTransactionsByFilters?.page?.currentPage - 1,
        totalPages: queryTransactionsByFilters?.page?.totalPages,
        totalResults: queryTransactionsByFilters?.page?.totalItems,
        lastPage: !!queryTransactionsByFilters.page?.lastPage
      })
    }
  }, [queryTransactionsByFilters])

  useEffect(() => {
    if (isErrorAllTransactions) {
      setAllTransactions([])
      setAllTransactionPagination(undefined)
    }
  }, [isErrorAllTransactions])

  useEffect(() => {
    if (queryAllTransactions) {
      setAllTransactions(queryAllTransactions.transactions)

      setAllTransactionPagination({
        currentPage: queryAllTransactions?.page?.currentPage - 1,
        totalPages: queryAllTransactions?.page?.totalPages,
        totalResults: queryAllTransactions?.page?.totalItems,
        lastPage: !!queryAllTransactions.page?.lastPage
      })
    }
  }, [queryAllTransactions])

  useEffect(() => {
    if (queryGetRefundableHistory) {
      const reversedRefundHistory = [
        ...queryGetRefundableHistory.refundHistory
      ].reverse()

      const updatedRefundableHistory = {
        ...queryGetRefundableHistory,
        refundHistory: reversedRefundHistory
      }

      setRefundableHistory(updatedRefundableHistory)
    }
  }, [queryGetRefundableHistory])

  return (
    <Context.Provider
      value={{
        allTransactions,
        transactions,
        selectedTransaction,
        isLoadingAllTransactions,
        isErrorAllTransactions,
        isLoading,
        isLoadingWithFilters,
        isError,
        selectTransaction,
        setWallet,
        setAccountId,
        addCreditInTrust,
        isLoadingCreditInTrust,
        isErrorCreditInTrust,
        isSuccessCreditInTrust,
        addCreditReversal,
        isLoadingCreditReversal,
        isErrorCreditReversal,
        isSuccessCreditReversal,
        isErrorReverseCredit,
        isLoadingReverseCredit,
        isSuccessReverseCredit,
        reverseTransaction,
        reverseCredit,
        isLoadingReverseTransaction,
        isErrorReverseTransaction,
        isSuccessReverseTransaction,
        currentFilters,
        getTransactiosByFilter,
        walletTransactionsPagination,
        getAllTransactions,
        refreshTransactionsList,
        isRefetching,
        allTransactionPagination,
        setWalletId,
        isErrorProvisionalCredit,
        isLoadingProvisionalCredit,
        isSuccessProvisionalCredit,
        addProvisionalCredit,
        setSelectedTransaction,
        isErrorGetRefundableHistory,
        isLoadingGetRefundableHistory,
        getRefundableHistory,
        refundableHistory,
        setOriginalTransactionUUID,
        originalTransactionUUID,
        setOriginalTransactionNSU,
        originalTransactionNSU,
        setRefundableHistory,
        originalTransaction,
        setOriginalTransaction,
        setOpenModalViaTransaction,
        openModalViaTransaction
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useWalletTransaction = () => {
  return useContext(Context)
}
