import { Flex } from '@chakra-ui/react'
import { Table } from 'components/ui'
import { WalletTransaction } from 'models/WalletTransaction'
import { GetTransactionResponse } from 'modules/transaction/infra/dto/GetTransactionResponse'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { TransactionDetailsModal } from 'templates/consumerDetails/transactions/modals/transactionDetails'
import { CreditReversal } from 'templates/consumerDetails/transactions/operations/creditReversal'
import { ProvisionalCredit } from 'templates/consumerDetails/transactions/operations/provisionalCredit'
import { ReverseTransaction } from 'templates/consumerDetails/transactions/operations/reverseTransaction'

import { TransactionAccordionContainer } from '../../TransactionAccordion/container/TransactionAccordionContainer'

type TransactionTablePresentationalProps = {
  columns: any[]
  transaction?: GetTransactionResponse
  isLoading: boolean
  isEmptyTable: boolean
  onRowClick: (data: any) => void
  isOpenDetails: boolean
  onCloseDetails: () => void
  selectedTransaction: WalletTransaction | null
  isOpenOutboundTransaction: boolean
  onCloseOutboundTransaction: () => void
  isOpenCreditInTrust: boolean
  onCloseCreditInTrust: () => void
  isOpenCreditReversal: boolean
  onCloseCreditReversal: () => void
  isOpenReverseTransaction: boolean
  onCloseReverseTransaction: () => void
  handleSaveCreditReversal: () => void
}

const TransactionTablePresentational = ({
  columns,
  transaction,
  isLoading,
  isEmptyTable,
  onRowClick,
  isOpenDetails,
  onCloseDetails,
  selectedTransaction,
  isOpenOutboundTransaction,
  onCloseOutboundTransaction,
  isOpenCreditInTrust,
  onCloseCreditInTrust,
  isOpenCreditReversal,
  onCloseCreditReversal,
  isOpenReverseTransaction,
  onCloseReverseTransaction,
  handleSaveCreditReversal
}: TransactionTablePresentationalProps) => {
  if (isLoading) return <Skeleton />

  if (isEmptyTable) return <></>

  return (
    <>
      <Flex flexDir="column" mt="-25px">
        <Table
          minWidth="100%"
          size="sm"
          textType="initial"
          columns={columns}
          list={[transaction!]}
          isPaginated={false}
          onClickRow={onRowClick}
        />
        {transaction && <TransactionAccordionContainer {...transaction} />}
      </Flex>

      <TransactionDetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <TransactionDetailsModal
        isOpen={isOpenOutboundTransaction}
        onClose={onCloseOutboundTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        isOutboundTransaction
      />

      <ProvisionalCredit
        isOpen={isOpenCreditInTrust}
        onClose={onCloseCreditInTrust}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <CreditReversal
        isOpen={isOpenCreditReversal}
        onClose={onCloseCreditReversal}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        onSave={handleSaveCreditReversal}
      />

      <ReverseTransaction
        isOpen={isOpenReverseTransaction}
        onClose={onCloseReverseTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />
    </>
  )
}

const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)

export default TransactionTablePresentational
