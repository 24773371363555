import React from 'react'

import { ButtonPresentation } from '../presentational/ButtonPresentation'

interface ButtonContainerProps {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const ButtonContainer = ({
  title,
  isLoading,
  isDisabled,
  onClick
}: ButtonContainerProps) => {
  return (
    <ButtonPresentation
      title={title}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  )
}
