import { VStack, Text } from 'components/atoms'
import { translate } from 'internationalization'
import React from 'react'

const NoDataFound = () => (
  <VStack alignItems="center" justifyContent="center" pt={10}>
    <Text color="brand.primary-dark" fontSize="24px" fontWeight="semibold">
      {translate('commons.table.dataNotFound')}
    </Text>
    <Text color="neutral.dark" fontSize="16px">
      {translate('commons.table.dataNotFoundDesc')}
    </Text>
  </VStack>
)

export default NoDataFound
