import { usersApi } from 'services/instances/usersApi'

const APIM_KEY = process.env.REACT_APP_APIM_KEY as string

export const ProfileService = {
  checkProfile: async ({ token }: { token: string }) => {
    return usersApi.get('/token/profile', {
      data: null,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': APIM_KEY
      }
    })
  }
}
