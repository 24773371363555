import { Flex } from 'components/atoms/Flex'
import { Container, Layout } from 'components/ui'
import { useSchedules } from 'contexts/scheduleContext'
import React, { useEffect, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ConsumersList from './consumersList/consumersList'
import { OrderDetails } from './orderDetails'

const SchedulesDetails = () => {
  return (
    <Layout isShowBackButton>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default SchedulesDetails

const Main = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    getScheduleDetails,
    schedulesDetails,
    isErrorDetails,
    isSuccessCancelOrder,
    isSuccessAntecipateOrder
  } = useSchedules()

  useEffect(() => {
    const isRedirect = (isErrorDetails && !schedulesDetails) || !id
    if (isRedirect) navigate('/schedules')

    getScheduleDetails(String(id))
  }, [id, isErrorDetails])

  useLayoutEffect(() => {
    if (isSuccessCancelOrder) getScheduleDetails(String(id))
  }, [isSuccessCancelOrder])

  useLayoutEffect(() => {
    if (isSuccessAntecipateOrder) getScheduleDetails(String(id))
  }, [isSuccessAntecipateOrder])

  return (
    <Flex flexDir="column" bg="white" width="100%" p="20px 40px" gap="32px">
      <OrderDetails />
      <ConsumersList />
    </Flex>
  )
}
