import { Container as ChakraContainer } from 'components/atoms'
import React, { type PropsWithChildren } from 'react'

const Container = ({ children }: PropsWithChildren) => {
  return (
    <ChakraContainer maxW={{ xl: '90rem', '2xl': '112.5rem' }} margin="0 auto">
      {children}
    </ChakraContainer>
  )
}

export default Container
