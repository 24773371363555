import {
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'
import { Button, Icon } from 'components/atoms'
import pt from 'date-fns/locale/pt-BR'
import React, { useLayoutEffect } from 'react'
import { useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'

type Props = {
  title?: string
  onApply: (range?: DateRange) => void
  resetRange?: boolean
}

export const ButtonCalendar = ({
  onApply,
  title = 'Período',
  resetRange
}: Props) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [range, setRange] = useState<DateRange | undefined>()

  useLayoutEffect(() => {
    if (resetRange) setRange(undefined)
  }, [resetRange])

  return (
    <>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            variant="default-outline"
            size="sm"
            leftIcon={<Icon name="calendar_month" variant="outlined" />}
            color="brand.primary-default"
            borderColor="brand.primary-default"
          >
            {title}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <DayPicker
              mode="range"
              locale={pt}
              defaultMonth={new Date()}
              selected={range}
              onSelect={setRange}
              showOutsideDays
              disabled={{ after: new Date() }}
              style={{ margin: 0 }}
              styles={{
                caption: {
                  background: '#2A295C',
                  color: 'white',
                  borderRadius: 10
                },
                cell: { color: '#283897' },
                head: { color: 'rgba(0, 0, 0, 0.54)' }
              }}
            />
          </PopoverBody>
          <PopoverFooter border="0">
            <HStack justify="end" spacing="xxs">
              <Button size="sm" variant="ghost" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                size="sm"
                variant="brand-primary-solid"
                onClick={() => {
                  onApply(range)
                  onClose()
                }}
              >
                Aplicar
              </Button>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default ButtonCalendar
