import React from 'react'

const outlined = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M20.8143 7.49713C20.815 7.4881 20.815 7.47902 20.8143 7.46999C20.8143 7.46999 20.8043 7.45856 20.8 7.45141C20.7957 7.44427 20.7914 7.42855 20.7843 7.41712C20.6432 7.19587 20.4461 7.01593 20.2129 6.89561L13.1692 3.27645C12.8062 3.09466 12.4059 3 12 3C11.5941 3 11.1938 3.09466 10.8308 3.27645L3.79708 6.89561C3.60371 7.00052 3.43591 7.1469 3.30569 7.32425C3.29569 7.33425 3.28855 7.34711 3.27855 7.35854C3.26855 7.36997 3.23712 7.39997 3.22141 7.42426C3.2057 7.44855 3.22141 7.43427 3.22141 7.43855C3.20175 7.46063 3.18362 7.48403 3.16713 7.50856C3.15619 7.54163 3.14806 7.57557 3.14285 7.61001C3.05562 7.78836 3.00694 7.9831 3 8.18153V15.3255C3.001 15.7491 3.12291 16.1635 3.3514 16.52C3.58027 16.8766 3.8997 17.1661 4.27704 17.3587L10.8308 20.7178C11.1927 20.9033 11.5934 21 12 21C12.4066 21 12.8073 20.9033 13.1692 20.7178L19.723 17.3444C20.1008 17.1512 20.4206 16.8614 20.65 16.5043C20.8775 16.1474 20.9989 15.7331 21 15.3098V8.16581C20.9952 7.93086 20.9313 7.70091 20.8143 7.49713ZM11.3522 4.31662C11.5529 4.21448 11.7748 4.16125 12 4.16125C12.2252 4.16125 12.4471 4.21448 12.6478 4.31662L19.1573 7.6643L16.2147 9.17883L9.05809 5.49681L11.3522 4.31662ZM12.0007 11.3463L4.80271 7.68574L7.78819 6.14406L14.9433 9.8318L12.0007 11.3463ZM4.79843 16.3086C4.60321 16.2109 4.43754 16.0629 4.31847 15.8799C4.20752 15.7124 4.14703 15.5165 4.14419 15.3155V8.65732L11.4293 12.3579V19.7091C11.405 19.6991 11.3779 19.6948 11.3551 19.6834L4.79843 16.3086ZM19.6844 15.8799C19.5643 16.0631 19.3977 16.2111 19.2016 16.3086L12.6492 19.682C12.6264 19.6934 12.5992 19.6977 12.575 19.7077V12.3565L15.639 10.7848V12.8694C15.639 13.021 15.6992 13.1664 15.8064 13.2736C15.9135 13.3807 16.0588 13.441 16.2104 13.441C16.3619 13.441 16.5073 13.3807 16.6144 13.2736C16.7216 13.1664 16.7818 13.021 16.7818 12.8694V10.1904L19.8572 8.61589V15.3141C19.8542 15.5144 19.7942 15.7096 19.6844 15.8771V15.8799Z"
      />
      <path
        fill="currentColor"
        d="M9.45449 13.2145L6.64471 11.7656C6.57744 11.7313 6.50404 11.7106 6.42873 11.7048C6.35342 11.699 6.27772 11.7082 6.20598 11.7319C6.13425 11.7555 6.06792 11.7931 6.01082 11.8426C5.95373 11.8921 5.907 11.9523 5.87334 12.02C5.80404 12.1574 5.79156 12.3165 5.8386 12.463C5.88564 12.6095 5.98843 12.7316 6.12475 12.803L8.9331 14.2532C9.01357 14.2944 9.10267 14.316 9.19308 14.3161C9.29917 14.3167 9.40334 14.2877 9.49392 14.2324C9.58449 14.1772 9.65789 14.0978 9.7059 14.0031C9.777 13.8652 9.79046 13.7047 9.74332 13.5568C9.69618 13.4089 9.5923 13.2858 9.45449 13.2145Z"
      />
    </>
  )
}

export default outlined
