import { Button, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useAuth } from 'contexts/authContext'
import {
  WalletTransaction,
  WalletTransationTypes
} from 'models/WalletTransaction'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useParams } from 'react-router-dom'
import { consumerApi } from 'services/instances/consumerApi'
import { generateCSVFilename } from 'utils/csvUtils'
import { formatToBrl } from 'utils/currencyUtils'
import { formatDateToFilter, formatToDateAndTime } from 'utils/dateUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

type CSVFields = {
  'Data e hora': string
  'ID transação': string
  Descrição: string
  Valor: string
  Status: string
  'Código de resposta': string
  Cartão: string
  Estabelecimento: string
  'Código do estabelecimento': string
  Cidade?: string
  País?: string
  MCC: string | number
  type?: string
}

type ExportButtonProps = {
  transactionType?: WalletTransationTypes
  operationType?: '%2B' | '-'
  type: 'transacoes' | 'entradas' | 'saidas'
  dtStart: string
  dtEnd: string
  isDisabled?: boolean
}

const ExportButton = ({
  dtStart,
  dtEnd,
  type,
  operationType,
  transactionType
}: ExportButtonProps) => {
  const { userLevel } = useAuth()

  const [transactionsToCsv, setTransactionsToCsv] = useState<
    WalletTransaction[]
  >([])

  const { selectedWallet } = useAccountWallet()
  const { id: accountId } = useParams()

  async function getTransactionsForCsv() {
    try {
      const walletId = selectedWallet?.walletId

      const initialDate =
        dtStart?.length > 0 ? dtStart : formatDateToFilter(new Date())

      const finalDate =
        dtEnd?.length > 0 ? dtEnd : formatDateToFilter(new Date())

      const transactionTypeFilter = transactionType
        ? `&type=${transactionType}`
        : ''

      const operationTypeFilter = operationType
        ? `&operation=${operationType}`
        : ''

      const endpoint = `/accounts/${accountId}/wallets/${walletId}/transactions?dtStart=${initialDate}&dtEnd=${finalDate}&size=300${transactionTypeFilter}${operationTypeFilter}`

      const { data } = await consumerApi.get(endpoint, { data: null })

      setTransactionsToCsv(data.transactions)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getTransactionsForCsv()
  }, [selectedWallet, dtStart, dtEnd])

  const data: CSVFields[] = transactionsToCsv?.map(item => {
    return {
      'Data e hora': formatToDateAndTime(String(item.transactionDate)),
      'ID transação': item.transactionUUID ?? '',
      Descrição: item.description ?? '',
      Valor: formatToBrl(Number(item.value)),
      Status:
        TransactionUtils.translateStatusToBrazilian({ value: item.status }) ??
        '',
      Cartão: item.cardNumber ?? '',
      Estabelecimento: item.merchantName ?? '',
      'Código do estabelecimento': item.acquirerCode ?? '',
      MCC: item.mcc ?? '',
      'Código de resposta': item.authorizationCode ?? ''
    }
  })

  const dataToExitCsv: CSVFields[] = transactionsToCsv
    ?.filter(item => item.type !== 'PTX')
    .map(item => {
      return {
        'Data e hora': formatToDateAndTime(String(item.transactionDate)),
        'ID transação': item.transactionUUID ?? '',
        Descrição: item.description ?? '',
        Valor: formatToBrl(Number(item.value)),
        Status:
          TransactionUtils.translateStatusToBrazilian({ value: item.status }) ??
          '',
        Cartão: item.cardNumber ?? '',
        Estabelecimento: item.merchantName ?? '',
        'Código do estabelecimento': item.acquirerCode ?? '',
        MCC: item.mcc ?? '',
        'Código de resposta': item.authorizationCode ?? ''
      }
    })

  const isDisabledButton =
    transactionsToCsv.length === 0 || (!dtStart && !dtEnd)

  if (userLevel === 'Atendimento') return null

  return (
    <Button
      isDisabled={isDisabledButton}
      cursor={isDisabledButton ? 'not-allowed' : 'pointer'}
      w="146px"
      h="40px"
      padding="12px 24px 12px 16px"
      gap="12px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="0"
      border="3px solid #221C46"
      bg="white"
      color="#221C46"
      _hover={{
        bg: !isDisabledButton && '#C7FCCA',
        borderColor: !isDisabledButton && '#221C46',
        boxShadow: !isDisabledButton && '4px 4px 0px 0px rgba(34, 28, 70, 0.20)'
      }}
      _focusVisible={{
        bg: !isDisabledButton && '#C7FCCA',
        outline: !isDisabledButton && '3px solid #1B51DC'
      }}
      _active={{
        border: !isDisabledButton && '3px solid #00330E)',
        bg: !isDisabledButton && '#00330E',
        boxShadow:
          !isDisabledButton && '2px 2px 0px 0px rgba(34, 28, 70, 0.20)',
        color: !isDisabledButton && 'white'
      }}
    >
      <Icon name="download" />

      {isDisabledButton ? (
        <Text fontWeight="700" fontSize="16px" letterSpacing="-0.8px">
          Exportar
        </Text>
      ) : (
        <CSVLink
          filename={generateCSVFilename(new Date(), type)}
          data={
            !transactionsToCsv.length
              ? []
              : type === 'saidas'
              ? dataToExitCsv
              : data
          }
        >
          <Text fontWeight="700" fontSize="16px" letterSpacing="-0.8px">
            Exportar
          </Text>
        </CSVLink>
      )}
    </Button>
  )
}

export default ExportButton
