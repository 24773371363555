import { RestoreWalletOriginalValues } from 'models/request/RestoreWalletOriginalValues'
import { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'

import { customerApi } from './instances/customerApi'

const CONSUMERS_PER_PAGE = 20

export const CustomerService = {
  getByDocument: (document: string) => {
    return customerApi.get(`/customers/${document}`, {
      data: null,
      params: { size: CONSUMERS_PER_PAGE }
    })
  },
  getWalletsByCustomerId: (customerId: number) => {
    return customerApi.get(`/customers/${customerId}/wallets`, {
      data: null
    })
  },
  getCustomizedClients: () => {
    return customerApi.get(`/customers`, {
      data: null,
      params: { size: CONSUMERS_PER_PAGE, customValues: true }
    })
  },
  restoreWalletOriginalValues: (walletInfo: RestoreWalletOriginalValues) => {
    const body = [{ ...walletInfo }]

    return customerApi.put(
      `/customerWallets/${walletInfo.customerId}/wallets`,
      body,
      {
        data: null
      }
    )
  },
  updateWalletToCustomValues: (customValues: UpdateWalletToCustomValues) => {
    const body = [{ ...customValues }]

    return customerApi.put(
      `/customerWallets/${customValues.customerId}/wallets`,
      body,
      { data: null }
    )
  }
}
