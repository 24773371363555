import { Button, Flex, Text } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const Wrapper = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      position="absolute"
      left="0"
      right="0"
      top="0"
      bottom="0"
      bg="rgba(0,0,0, 25%)"
      zIndex={99}
      alignItems="flex-start"
      justifyContent="center"
      pt={200}
    >
      <Flex flexDir="column" w="100%" maxW="660px" bg="white">
        {children}
      </Flex>
    </Flex>
  )
}

export const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      bg="transparent"
      w="97px"
      h="48px"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        Cancelar
      </Text>
    </Button>
  )
}

export const SubmitButton = ({
  isLoading,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      onClick={onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      _hover={{
        boxShadow: '4px 4px 0px 0px #A9A7B6!important',
        borderColor: '#283897',
        background: '#DEF3FB',
        color: '#221C46',
        border: 'none'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}
