import { Center, Flex, Box, Text, Divider } from '@chakra-ui/react'
import { StatusLabel } from 'components/ui'
import { Wallet } from 'models/Wallet'
import React from 'react'
import {
  renderWalletIcon,
  renderWalletBg,
  WalletIcon
} from 'templates/consumerDetails/consumerWallets/walletCard'
import { capitalizeSentence, limitString } from 'utils/stringUtils'

const ViewWallet = ({ name, status }: Partial<Wallet>) => {
  const maxChar = 23
  const isHealthWallet = name === 'Saúde & Bem-estar'
  const capitalizedWalletName = isHealthWallet
    ? capitalizeSentence(String(name))
    : capitalizeSentence(limitString(String(name), maxChar))

  return (
    <Flex flexDir="column" gap="24px">
      <Flex gap="24px" alignItems="center">
        <Center
          w="40px"
          h="40px"
          borderRadius="4px"
          bg={renderWalletBg(capitalizedWalletName as WalletIcon)}
        >
          {renderWalletIcon(capitalizedWalletName as WalletIcon)}
        </Center>

        <Box>
          <Text
            color="#221C46"
            fontSize="24px"
            fontWeight="700"
            letterSpacing="-1.2px"
          >
            {name ? capitalizeSentence(name) : '-'}
          </Text>

          <Flex gap="8px">
            <Text
              color="#463F5F"
              fontSize="16px"
              fontWeight="500"
              lineHeight="24px"
              letterSpacing="-0.8px"
            >
              Status:
            </Text>

            {status && <StatusLabel statusType={status} />}
          </Flex>
        </Box>
      </Flex>

      <Divider />
    </Flex>
  )
}

export default ViewWallet
