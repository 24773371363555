import { modalAnatomy } from '@chakra-ui/anatomy'
import { PartsStyleObject } from '@chakra-ui/theme-tools'

type ModalStyle = PartsStyleObject<typeof modalAnatomy>

type VariantStyles = {
  [key: string]: ModalStyle
}

type BaseStyle = ModalStyle

type ModalConfig = {
  baseStyle?: BaseStyle
  sizes?: VariantStyles
  variants?: VariantStyles
  defaultProps?: {
    size: string
    variant: string
  }
}

const variants: VariantStyles = {
  'footer-no-box-shadow': {
    footer: {
      boxShadow: 'none'
    }
  },
  'footer-neutral-extralight': {
    footer: {
      bg: 'neutral.extralight'
    }
  },
  'align-bottom': {
    dialogContainer: {
      alignItems: 'flex-end'
    }
  }
}

const ModalTheme: ModalConfig = {
  baseStyle: {
    closeButton: {
      fontSize: 'xxxs',

      _focus: { boxShadow: 'none' },
      _hover: { bgColor: 'none' }
    },
    header: {
      bgColor: 'transparent',
      color: 'brand.primary-default',
      fontSize: 'xs'
    },
    body: {
      pb: 'xxs'
    },
    footer: {
      borderTop: '1px solid',
      borderColor: 'neutral.light'
    }
  },
  sizes: {},
  variants
}

export default ModalTheme
