import { Box, Button, Text } from '@chakra-ui/react'
import React from 'react'

export type CloseButtonProps = {
  onClick: () => void
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
  const defaultStyles = {
    bg: 'pluxee.brand.secondary-dimmed',
    color: 'pluxee.text.primary'
  }

  return (
    <Box p="1px" onClick={onClick}>
      <Button
        p="12px 24px"
        height="48px"
        bg="transparent"
        border="3px solid"
        borderColor="pluxee.text.primary"
        borderRadius="none"
        _hover={{
          ...defaultStyles,
          boxShadow: '4px 4px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _active={{
          borderColor: 'pluxee.cta.primaryPressed',
          background: 'pluxee.cta.primaryPressed',
          color: 'white',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          outline: '3px solid #1B51DC'
        }}
      >
        <Text fontWeight="700" fontSize="xs" letterSpacing="-0.8px">
          Fechar
        </Text>
      </Button>
    </Box>
  )
}

export default CloseButton
