import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react'
import React from 'react'

type LinkProps = ChakraLinkProps & {
  children: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  isDisabled?: boolean
}

export const Link = ({
  children,
  leftIcon,
  rightIcon,
  isDisabled,
  isExternal,
  href,
  ...styleProps
}: LinkProps) => {
  const customProps = isDisabled
    ? {
        opacity: 0.64,
        color: 'neutral.dark',
        cursor: 'not-allowed',
        borderColor: 'transparent',
        _hover: {
          borderColor: 'transparent'
        }
      }
    : {
        isExternal,
        href
      }

  return (
    <ChakraLink {...styleProps} {...customProps}>
      {leftIcon}
      {children}
      {rightIcon}
    </ChakraLink>
  )
}
