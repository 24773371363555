import { useQueryGetWalletsByAccount } from 'hooks/queries/accountWalletQueries'
import { Account } from 'models/Account'
import { AccountWallet } from 'models/AccountWallet'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

export type ContextType = {
  accountId?: number
  setAccountId: (value: number) => void
  setCustomerId: (value: number) => void
  account: Account | null
  wallets: AccountWallet[]
  selectedWallet: AccountWallet | null
  isLoading: boolean
  isRefetching: boolean
  setAccount: (account: Account | null) => void
  selectWallet: (wallet: AccountWallet) => void
  refreshWalletList: () => void
}

export const Context = createContext({} as ContextType)

type AccountWalletProviderProps = {
  children: ReactNode
}

export const AccountWalletProvider = ({
  children
}: AccountWalletProviderProps) => {
  const [wallets, setWallets] = useState<AccountWallet[]>([])

  const [accountId, setAccountId] = useState<number>()
  const [account, setAccount] = useState<Account | null>(null)
  const [customerId, setCustomerId] = useState<number>()

  const [selectedWallet, setSelectedWallet] = useState<AccountWallet | null>(
    null
  )

  const { data, isLoading, refetch, isRefetching } =
    useQueryGetWalletsByAccount({
      accountId: accountId,
      customerId: customerId
    })

  const selectWallet = (wallet: AccountWallet) => setSelectedWallet(wallet)

  useEffect(() => {
    if (accountId && customerId && data) setWallets(data)
  }, [accountId, customerId, data])

  return (
    <Context.Provider
      value={{
        account,
        accountId,
        setAccountId,
        wallets,
        selectedWallet,
        isLoading,
        isRefetching,
        selectWallet,
        setAccount,
        setCustomerId,
        refreshWalletList: refetch
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAccountWallet = () => {
  const context = useContext(Context)

  return context
}
