import { Flex } from '@chakra-ui/react'
import { Loading } from 'components/ui'
import { Container, Layout } from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'

import { CustomerProfile } from './customerProfile/customerProfile'
import { CustomerWallets } from './customerWallets/customerWallets'

const SearchCustomerPluxee = () => {
  const { user } = useProfile()
  const { removeStorageItem } = useLocalStorage()

  const removeCachedDocument = () => removeStorageItem('cachedDocument')
  const removeCachedCustomer = () => removeStorageItem('customer')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDocument)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDocument)
    }
  }, [removeCachedDocument])

  if (!user) return <Loading />

  return (
    <Layout isShowBackButton onPageBack={removeCachedCustomer}>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default SearchCustomerPluxee

const Main = () => {
  return (
    <Flex
      flexDir="column"
      bg="standard.white"
      w="100%"
      p="40px"
      gap="32px"
      mb="40px"
    >
      <CustomerProfile />
      <CustomerWallets />
    </Flex>
  )
}
