import { Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { IconSizes } from 'components/atoms/Icon'
import React from 'react'

export type TooltipProps = {
  label: string
  children?: React.ReactNode
  iconColor?: string
  minW?: string
  minH?: string
  iconSize?: IconSizes
  textAlign?: 'center' | 'right' | 'left'
  fontWeight?: '500' | '700'
}

const Tooltip = ({
  label,
  children,
  iconColor = '#221C46',
  minW = '256px',
  minH = '48px',
  iconSize = 'md',
  textAlign = 'center',
  fontWeight = '500'
}: TooltipProps) => (
  <ChakraTooltip
    aria-label={label}
    label={label}
    hasArrow
    arrowSize={16}
    arrowShadowColor="#CBCAD2"
    minW={minW}
    minH={minH}
    textAlign={textAlign}
    bg="#221C46"
    placement="auto"
    border="0"
    p="16px"
    borderRadius="none"
    fontFamily="TTTravels"
    fontWeight={fontWeight}
    fontSize="12px"
    color="white"
    boxShadow="4px 4px 0px 0px #CBCAD2"
    letterSpacing="-0.8px"
  >
    {children ? (
      children
    ) : (
      <Icon name="info_outline" color={iconColor} size={iconSize} />
    )}
  </ChakraTooltip>
)

export default Tooltip
