import { useMutation } from 'react-query'
import { ProfileService } from 'services/profileService'

export function useMutationCheckProfile() {
  return useMutation(({ token }: { token: string }) => checkLogin({ token }), {
    retry: 0
  })
}

const checkLogin = async ({ token }: { token: string }) => {
  if (!token) return

  const response = await ProfileService.checkProfile({ token })
  return response.data
}
