import { Button, Flex, Tab as ChakraTab, Text } from '@chakra-ui/react'
import React from 'react'

export type TabProps = {
  title: string
  icon: React.ReactNode
  width?: string
}

const Tab = ({ title, icon, width = '185px' }: TabProps) => {
  return (
    <Flex
      as={Button}
      h="64px"
      m="0"
      p="0"
      bg="transparent"
      borderRadius="0"
      _focusVisible={{ border: '3px solid #1B51DC', bg: '#DEF3FB' }}
    >
      <ChakraTab
        w={width}
        h="64px"
        m="0"
        borderBottomWidth="1px"
        _selected={{ borderColor: '#1B51DC', borderBottomWidth: '6px' }}
        _hover={{ bg: '#DEF3FB' }}
      >
        <Flex gap="8px" alignItems="center" mb="0px">
          {icon}
          <Text
            fontSize="16px"
            fontWeight="600"
            color="#221C46"
            letterSpacing="-0.8px"
          >
            {title}
          </Text>
        </Flex>
      </ChakraTab>
    </Flex>
  )
}

export default Tab
