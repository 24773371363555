import { Flex, Text, Box, Button, Image } from '@chakra-ui/react'
import { useOkta } from 'contexts/oktaContext'
import React from 'react'

const PageNotFound = () => {
  const { login } = useOkta()

  return (
    <Flex
      fontFamily="TTTravels"
      bg="#FAF8FF"
      w="100%"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        bgImage="/img/plx-icon-404.png"
        bgSize="contain"
        bgPosition="center"
        bgRepeat="no-repeat"
        w="645px"
        h="231px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image maxW="300px" h="300px" src="img/plx-icon-page-not-found.png" />
      </Box>
      <Box pt="42px">
        <Text
          color="#221C46"
          fontSize="24px"
          letterSpacing="-1.2px"
          fontWeight="700"
          lineHeight="31.2px"
          textAlign="center"
        >
          Página não encontrada
        </Text>
        <Text
          color="#221C46"
          fontSize="20px"
          letterSpacing="-1px"
          lineHeight="25px"
          fontWeight="500"
          textAlign="center"
          pt="8px"
        >
          Realize uma nova consulta ou tente novamente mais tarde.
        </Text>
        <Flex justifyContent="center">
          <Button
            border="3px solid #221C46"
            borderRadius="0"
            bg="transparent"
            color="#221C46"
            fontSize="16px"
            fontWeight="700"
            letterSpacing="-0.8px"
            lineHeight="24px"
            p="12px 24px"
            mt="24px"
            h="48px"
            _hover={{
              bg: '#C7FCCA',
              boxShadow: '4px 4px 0px 0px rgba(34, 28, 70, 0.20)'
            }}
            _active={{
              boxShadow: '2px 2px 0px 0px rgba(34, 28, 70, 0.20)',
              bg: '#00330E',
              color: 'white',
              borderColor: '#00330E',
              outline: 'none'
            }}
            _focusVisible={{
              outline: '3px solid #1B51DC',
              bg: '#C7FCCA',
              color: '#221C46'
            }}
            onClick={login}
          >
            Voltar para o login
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

export default PageNotFound
