import {
  Box,
  Flex,
  Image,
  InputGroup,
  InputRightElement,
  Text
} from '@chakra-ui/react'
import { Input } from 'components/atoms'
import { Icon, IconSizes, IconsName } from 'components/atoms/Icon'
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useEffect,
  useState
} from 'react'

type SearchInputProps = {
  value: string
  onChange?: (value: string) => void
  placeholder?: string
  variant?: string
  size?: IconSizes | string
  customHeight?: string
  type?: HTMLInputTypeAttribute
  maxLength?: number
  customIcon?: IconsName
  customFontSize?: string
  isError?: boolean
  errorMessage?: string
  label: string
  isOptionalLabel?: boolean
  onClear?: () => void
  showClearButton?: boolean
  hiddenErrorIcon?: boolean
  imageIcon?: string
}

const SearchInput = ({
  label,
  value,
  placeholder,
  onChange,
  type = 'text',
  size = 'md',
  customHeight = '48px',
  customIcon,
  maxLength,
  errorMessage,
  customFontSize = '16px',
  imageIcon,
  isError = false,
  isOptionalLabel = false,
  showClearButton = false,
  onClear
}: SearchInputProps) => {
  const [labelColor, setLabelColor] = useState('pluxee.text.primary')
  const [isFocused, setIsFocused] = useState(false)
  const [error, setError] = useState(false)

  const isShowErrorMessage = !!errorMessage && isError
  const isShowClearButton = showClearButton && !error
  const isShowRightElementSlot = isError && error

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.currentTarget.value)
  }

  const handleOnMouseEnter = () => setLabelColor('pluxee.text.link')
  const handleOnMouseLeave = () => {
    !isFocused && setLabelColor('pluxee.text.primary')
  }

  const handleOnBlur = () => {
    setIsFocused(false)
    setLabelColor('pluxee.text.primary')
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setLabelColor('pluxee.text.link')
  }

  useEffect(() => {
    setError(isError)
  }, [isError])

  return (
    <Box w="100%">
      <InputLabel
        title={label}
        isOptionalLabel={isOptionalLabel}
        textColor={isError ? 'pluxee.text.primary' : labelColor}
      />

      <Flex w="100%" flexDir="column" gap="4px">
        <InputGroup>
          <Input
            width="100%"
            size={size}
            type={type}
            value={value}
            fontWeight="600"
            border="1px solid"
            borderRadius="none"
            letterSpacing="-0.8px"
            height={customHeight}
            leftIcon={!imageIcon ? customIcon : undefined}
            leftElementSlot={
              imageIcon ? <Image src={imageIcon} alt="icon" /> : undefined
            }
            maxLength={maxLength}
            onChange={handleOnChange}
            placeholder={placeholder}
            fontSize={customFontSize}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            iconColor="pluxee.icon.secondary"
            borderColor={isError ? '#B30000' : '#E9E8EF'}
            bg={isError ? '#FCF1EE' : 'brand.primary-light'}
            color={'pluxee.text.primary'}
            _placeholder={{
              color: 'pluxee.icon.secondary'
            }}
            _focusVisible={{
              border: '3px solid #1B51DC',
              _placeholder: {
                color: '#1B51DC'
              }
            }}
            _hover={{
              border: !isError && '1px solid',
              borderColor: !isError && 'pluxee.interactive.primary',
              background: !isError && '#DEF3FB',
              _placeholder: {
                color: !isError && '#1B51DC'
              }
            }}
            rightElementSlot={
              isShowRightElementSlot ? (
                <Flex onMouseEnter={() => setError(false)}>
                  <ErrorIcon />
                </Flex>
              ) : undefined
            }
          />

          {isShowClearButton && (
            <InputRightElement
              h="100%"
              cursor="pointer"
              onClick={onClear ?? undefined}
              onMouseLeave={isError ? () => setError(true) : undefined}
              data-testid="clearButton"
            >
              <Icon name="close" color="#221C46" />
            </InputRightElement>
          )}
        </InputGroup>

        {isShowErrorMessage && (
          <Text
            fontSize="12px"
            fontWeight="600"
            color="#B30000"
            letterSpacing="-0.6px"
          >
            {errorMessage}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default SearchInput

const ErrorIcon = () => (
  <Box bg="#B30000" w="24px" h="24px" borderRadius="50%" mt={2} mr={3}>
    <Icon name="error" color="#B30000" bg="#FCF1EE" />
  </Box>
)

const InputLabel = ({
  title,
  textColor,
  isOptionalLabel = false
}: {
  title: string
  textColor: string
  isOptionalLabel?: boolean
}) => (
  <Flex
    mb="4px"
    gap="2px"
    fontSize="xxs"
    fontWeight="600"
    color={textColor}
    letterSpacing="-0.7px"
  >
    <Text>{title}</Text>
    {!isOptionalLabel && <Text color="#CC1480">*</Text>}
  </Flex>
)
