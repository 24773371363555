import type { Consumer } from 'models/Consumer'
import { PageResult } from 'models/PageResult'
import { GetBlockReasons } from 'models/request/GetBlockReasons'

import { consumerApi } from './instances/consumerApi'

export type ConsumersPage = {
  consumers: Consumer[]
  page: PageResult
}

export type GetByCpfFilters = {
  page?: string
  status?: 'A' | 'B' | 'C'
}

export type GetWalletDetails = {
  accountId?: string
  walletId?: string
}

// ACTIVE("A"),
// BLOCKED("B"),
// CANCELLED('C')

const CONSUMERS_PER_PAGE = 20

export const ConsumerService = {
  create: (payload: Consumer) => {
    return consumerApi.post('/consumers', payload)
  },
  update: (payload: Consumer) => {
    return consumerApi.put('/consumers', payload)
  },
  getAll: () => {
    return consumerApi.get('/consumers')
  },
  getById: (id: string) => {
    return consumerApi.get(`/consumer/${id}`)
  },
  getByCPF: (cpf: string) => {
    return consumerApi.get(`/accounts/by-cpf/${cpf}`, {
      data: null,
      params: { size: CONSUMERS_PER_PAGE }
    })
  },
  getByCpfWithFilters: (cpf: string, filters?: GetByCpfFilters) => {
    return consumerApi.get(`/accounts/by-cpf/${cpf}`, {
      data: null,
      params: { ...filters, size: CONSUMERS_PER_PAGE }
    })
  },
  getBlockReasons: ({ profileName }: GetBlockReasons) => {
    return consumerApi.get(`/cards/${profileName}/reasons`, {
      data: null
    })
  },
  getByTransactionUUID: (transactionUUID: string) => {
    return consumerApi.get(`/accounts/transaction/${transactionUUID}`, {
      data: null
    })
  },
  getTransactionsDetailsByUUID: (transactionUUID: string) => {
    return consumerApi.get(`/transactions/${transactionUUID}/details`, {
      data: null
    })
  },
  getByAccountDetails: (accountId: string) => {
    return consumerApi.get(`/accounts/${accountId}`, {
      data: null
    })
  },
  getWalletDetails: ({ accountId, walletId }: GetWalletDetails) => {
    return consumerApi.get(`wallets/${accountId}?walletId=${walletId}`, {
      data: null
    })
  }
}
