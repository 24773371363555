import axios from 'axios'
import { AuthUtils } from 'utils/authUtils'

const customerApiEndpoint = process.env
  .REACT_APP_SCHEDULING_API_ENDPOINT as string

const APIM_KEY = process.env.REACT_APP_APIM_KEY as string

export const customerApi = axios.create({
  baseURL: customerApiEndpoint,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': APIM_KEY,
    Authorization: `Bearer ${AuthUtils().idToken}`
  }
})
