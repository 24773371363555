import { Flex, Text } from 'components/atoms'
import React from 'react'
import { getCurrentYear } from 'utils/dateUtils'

const Footer = () => (
  <Flex
    bg="standard.white"
    position="absolute"
    bottom="0"
    left="0"
    right="0"
    h="41px"
    p="12px 32px"
    alignItems="center"
    borderTop="1px solid"
    borderColor="pluxee.border.secondary"
  >
    <Text fontSize="xxxs" color="pluxee.text.primary" fontWeight="600">
      © Copyright Pluxee {getCurrentYear()}
    </Text>
  </Flex>
)

export default Footer
