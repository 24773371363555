import { useDisclosure, Text } from '@chakra-ui/react'
import { ResponseCode, TransactionStatus } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useProfile } from 'contexts/profileContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import { GetTransactionResponse } from 'modules/transaction/infra/dto/GetTransactionResponse'
import { useTransaction } from 'modules/transaction/presentation/contexts/TransactionContext'
import React, { useState } from 'react'
import { AppetizerOptions } from 'templates/consumerDetails/transactions/options/appetizerOptions'
import { ExitsOptions } from 'templates/consumerDetails/transactions/options/exitsOptions'
import { TransactionOptions } from 'templates/consumerDetails/transactions/options/transactionOptions'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

import TransactionTablePresentational from '../presentation/TransactionsTablePresentation'

export const TransactionTableContainer = () => {
  const {
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    isBackoffice4c,
    isFraudAndPrevention,
    isManageTransactions,
    isOperation3c,
    isPayments
  } = useProfile()

  const { setWalletId, setAccountId } = useWalletTransaction()
  const { transaction, isLoading, isError, isEmptyTransaction } =
    useTransaction()

  const { refreshWalletList } = useAccountWallet()

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  const {
    isOpen: isOpenOutboundTransaction,
    onOpen: onOpenDetailsOutboundTransaction,
    onClose: onCloseOutboundTransaction
  } = useDisclosure()

  const {
    isOpen: isOpenCreditInTrust,
    onOpen: onOpenCreditInTrust,
    onClose: onCloseCreditInTrust
  } = useDisclosure()

  const {
    isOpen: isOpenCreditReversal,
    onOpen: onOpenCreditReversal,
    onClose: onCloseCreditReversal
  } = useDisclosure()

  const {
    isOpen: isOpenReverseTransaction,
    onOpen: onOpenReverseTransaction,
    onClose: onCloseReverseTransaction
  } = useDisclosure()

  const [selectedTransaction, setSelectedTransaction] =
    useState<WalletTransaction | null>(null)

  const columns: CellProps[] = [
    {
      title: 'Data e hora',
      width: '100px',
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color="pluxee.text.secondary"
            letterSpacing="-0.7px"
          >
            {formatToDateAndTime(data.transactionDate, true)}
          </Text>
        )
      }
    },
    {
      title: 'ID transação',
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color="pluxee.text.secondary"
            letterSpacing="-0.7px"
          >
            {TransactionUtils.formatTransactionId(data.transactionUUID)}
          </Text>
        )
      }
    },
    {
      title: 'Descrição',
      width: '250px',
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        const capitalizedDescription = capitalizeSentence(data.description)

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color="pluxee.text.secondary"
            letterSpacing="-0.7px"
          >
            {limitString(capitalizedDescription, 50)}
          </Text>
        )
      }
    },
    {
      title: translate('commons.value'),
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        return (
          <Text
            fontSize="14px"
            fontWeight="700"
            color="#0F2365"
            letterSpacing="-0.7px"
          >
            {formatToBrl(Number(data.value))}
          </Text>
        )
      }
    },
    {
      title: translate('commons.status'),
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        return <TransactionStatus letterSpacing="-0.7px" status={data.status} />
      }
    },
    {
      title: ' ',
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        const formattedResponseCode = data.responseCode
          ? TransactionUtils.formatResponseCode({ code: data.responseCode })
          : '-'

        return (
          <ResponseCode
            text={formattedResponseCode}
            maxLenght={25}
            letterSpacing="-0.7px"
          />
        )
      }
    },
    {
      title: '',
      width: '10px',
      render: ({
        transaction: data
      }: Record<keyof GetTransactionResponse, any>) => {
        transaction && setAccountId(transaction.account?.id)

        const isShowCreditReversal =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E' &&
          data.type === 'CTR'

        const isShowCreditInTrust =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E'

        const isShowProvisionalCreditForExit =
          (isShowCreditInTrust && data.type === 'WTX') ||
          (isShowCreditInTrust && data.type === 'WFT')

        const isShowReverseTransaction =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isFraudAndPrevention &&
          !isOperation3c &&
          !isManageTransactions &&
          !isPayments &&
          data.status === 'E'

        const isShowReverseTransactionForExit =
          (isShowReverseTransaction && data.type === 'WTX') ||
          (isShowReverseTransaction && data.type === 'WFT')

        const isExit = data.type !== 'PTX' && data.operationType === '-'
        const isAppetizer = data.operationType === '+'

        if (isExit) {
          return (
            <ExitsOptions
              data={data}
              setWalletId={() => setWalletId(data?.walletId)}
              onOpenDetails={onOpenDetailsOutboundTransaction}
              setSelectedTransaction={setSelectedTransaction}
              onOpenProvisionalCredit={onOpenCreditInTrust}
              isShowProvisionalCredit={isShowProvisionalCreditForExit}
              isShowReverseTransaction={isShowReverseTransactionForExit}
              onOpenReverseTransaction={onOpenReverseTransaction}
            />
          )
        }

        if (isAppetizer) {
          return (
            <AppetizerOptions
              data={data}
              setWalletId={() => setWalletId(data?.walletId)}
              isShowCreditReversal={isShowCreditReversal}
              onOpenDetails={onOpenDetailsOutboundTransaction}
              onOpenCreditReversal={onOpenCreditReversal}
              setSelectedTransaction={setSelectedTransaction}
            />
          )
        }

        return (
          <TransactionOptions
            data={data}
            setWalletId={() => setWalletId(data?.walletId)}
            isShowProvisionalCredit={isShowCreditInTrust}
            isShowReverseTransaction={isShowReverseTransaction}
            onOpenProvisionalCredit={onOpenCreditInTrust}
            onOpenDetails={onOpenDetails}
            onOpenReverseTransaction={onOpenReverseTransaction}
            setSelectedTransaction={setSelectedTransaction}
          />
        )
      }
    }
  ]

  const isEmptyTable = (isEmptyTransaction && !isLoading) || isError

  const handleRowClick = (data: any) => {
    setSelectedTransaction(data.transaction)
    data.type === 'PTX' ? onOpenDetails() : onOpenDetailsOutboundTransaction()
  }

  return (
    <TransactionTablePresentational
      columns={columns}
      transaction={transaction}
      isLoading={isLoading}
      isEmptyTable={isEmptyTable}
      onRowClick={handleRowClick}
      isOpenDetails={isOpenDetails}
      onCloseDetails={onCloseDetails}
      selectedTransaction={selectedTransaction}
      isOpenOutboundTransaction={isOpenOutboundTransaction}
      onCloseOutboundTransaction={onCloseOutboundTransaction}
      isOpenCreditInTrust={isOpenCreditInTrust}
      onCloseCreditInTrust={onCloseCreditInTrust}
      isOpenCreditReversal={isOpenCreditReversal}
      onCloseCreditReversal={onCloseCreditReversal}
      isOpenReverseTransaction={isOpenReverseTransaction}
      onCloseReverseTransaction={onCloseReverseTransaction}
      handleSaveCreditReversal={refreshWalletList}
    />
  )
}
