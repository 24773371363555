import { Flex, Image, Text } from 'components/atoms'
import { formatCNPJ, formatCPF } from 'internationalization'
import type { Customer } from 'models/Customer'
import React from 'react'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { formatCustomerModel, formatCustomerProduct } from 'utils/productUtils'

const CustomerInfo = ({
  tradeName,
  document,
  products,
  createDate
}: Customer) => {
  const isCPF = document?.length === 11

  const formattedClosureDate = '-'
  const formattedCreationDate = formatDateToBrazilianFormat(createDate)

  const { formattedCustomerModel } = formatCustomerModel({ products })
  const { formattedCustomerProduct } = formatCustomerProduct({ products })

  return (
    <Flex flexDir="column" gap="16px">
      <Text
        color="pluxee.text.primary"
        fontSize="md"
        fontWeight="700"
        lineHeight="31.2px"
        letterSpacing="-0.8px"
      >
        Detalhes do cliente:
      </Text>

      <Flex gap="32px" alignItems="flex-start">
        <Flex id="consumer-avatar" w="84px" h="84px">
          <Image
            aria-label="Default Avatar"
            src="/img/pluxee/defaultAvatar.png"
            alt="Icone de uma pessoa"
            w="100%"
            h="100%"
          />
        </Flex>
        <Flex gap="74px">
          <Flex id="consumer-info" flexDir="column" gap="8px">
            <Flex gap="10px">
              <Details title="Empresa:" description={tradeName} />
            </Flex>

            {document && (
              <Flex gap="10px">
                <Details
                  title="CNPJ/CPF:"
                  description={
                    isCPF ? formatCPF(document) : formatCNPJ(document)
                  }
                />
              </Flex>
            )}

            <Flex gap="10px">
              <Details
                title="Produto:"
                description={formattedCustomerProduct}
              />
            </Flex>
          </Flex>

          <Flex
            id="consumer-info"
            flexDir="column"
            justifyContent="center"
            gap="8px"
            pt={1}
          >
            <Flex gap="10px">
              <Details
                minW="115px"
                title="Criado em:"
                description={formattedCreationDate}
              />
            </Flex>

            <Flex gap="10px">
              <Details
                minW="115px"
                title="Inativado em:"
                description={formattedClosureDate}
              />
            </Flex>

            <Flex gap="10px">
              <Details
                minW="115px"
                title="Modelo:"
                description={formattedCustomerModel ?? '-'}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CustomerInfo

const Details = ({
  title,
  description,
  minW = '110px'
}: {
  title: string
  description: string
  minW?: string
}) => {
  return (
    <>
      <Text
        fontWeight="700"
        color="pluxee.text.primary"
        minW={minW}
        fontSize="xxs"
        letterSpacing="-0.8px"
      >
        {title}
      </Text>
      <Text
        color="pluxee.text.primary"
        fontWeight="500"
        fontSize="xxs"
        letterSpacing="-0.8px"
      >
        {description}
      </Text>
    </>
  )
}
