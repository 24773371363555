import { ErrorBoundary } from 'components/organisms'
import {
  useMutationLogin,
  useMutationLogoutUser,
  useMutationVerifyProfile
} from 'hooks/queries/authQueries'
import { useLocalStorage } from 'hooks/useLocalStorage'
import type { User, UserRoles } from 'models/User'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { LoginProps } from 'services/authService'

export type ContextType = {
  user: User | null
  userLevel: UserRoles | undefined
  logged?: boolean

  setLogged: (isLogged: boolean) => void

  isAttendant: boolean
  isAdmin: boolean
  isBackoffice4c: boolean
  isFraudAndPrevention: boolean

  isLoadingLogin: boolean
  isErrorLogin: boolean
  errorLogin: any

  isLoadingProfile: boolean
  isSuccessLogin: boolean
  isSuccessProfile: boolean

  isLoadingLogout: boolean
  isErrorLogout: boolean
  errorProfile: any
  isSuccessLogout: boolean

  isErrorProfile: boolean

  login: ({ code, client_request_id }: LoginProps) => void
  logoutUser: ({ id_token_hint }: { id_token_hint: string }) => void

  verifyProfile: ({ token }: { token: string }) => void
}

export const Context = createContext<ContextType>({} as ContextType)

type AuthProviderProps = {
  children: React.ReactNode
}

const baseUrl = process.env.REACT_APP_APPLICATION_BASE_URL as string

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useLocalStorage()

  const [user, setUser] = useState<User | null>(null)
  const [userLevel, setUserLevel] = useState<UserRoles>()
  const [logged, setLogged] = useState<boolean>()

  const [isAttendant, setIsAttendant] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isBackoffice4c, setIsBackoffice4c] = useState(false)
  const [isFraudAndPrevention, setIsFraudAndPrevention] = useState(false)

  const mutateLogoutUser = useMutationLogoutUser()
  const {
    isLoading: isLoadingLogout,
    isError: isErrorLogout,
    isSuccess: isSuccessLogout
  } = mutateLogoutUser

  const mutateVerifyProfile = useMutationVerifyProfile()
  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
    isSuccess: isSuccessProfile
  } = mutateVerifyProfile

  const mutateLogin = useMutationLogin()
  const {
    data: loginData,
    isLoading: isLoadingLogin,
    isError: isErrorLogin,
    isSuccess: isSuccessLogin,
    error: errorLogin
  } = mutateLogin

  const login = async ({ code, client_request_id }: LoginProps) => {
    if (!code) return
    return await mutateLogin.mutateAsync({ code, client_request_id })
  }

  const logoutUser = async ({ id_token_hint }: { id_token_hint: string }) => {
    if (!id_token_hint) return
    return await mutateLogoutUser.mutateAsync({ id_token_hint })
  }

  const verifyProfile = async ({ token }: { token: string }) => {
    if (!token) return
    return await mutateVerifyProfile.mutateAsync({ token })
  }

  useEffect(() => {
    if (loginData) {
      setStorageItem('access_token', loginData.access_token)
      setStorageItem('refresh_token', loginData.refresh_token)
      setStorageItem('id_token', loginData.id_token)
    }
  }, [loginData])

  useEffect(() => {
    const token = getStorageItem('id_token')
    if (token) verifyProfile({ token })
  }, [])

  useEffect(() => {
    if (profileData) {
      setUser(profileData)
      setUserLevel(profileData.role?.name)

      setIsAttendant(userLevel === 'Atendimento')
      setIsAdmin(userLevel === 'Administrador de Perfis')
      setIsBackoffice4c(userLevel === 'Backoffice 4C')
      setIsFraudAndPrevention(userLevel === 'Prevenção a Fraude')
    }

    if (isErrorProfile) {
      const tokenForInvalidate = getStorageItem('id_token') as string

      setStorageItem('tokenForInvalidate', tokenForInvalidate)
      removeStorageItem('access_token')
      removeStorageItem('refresh_token')
      removeStorageItem('id_token')
      setLogged(false)
      // window.location.href = unauthorizedUrl
    }
  }, [profileData, isErrorProfile, isLoadingProfile])

  useEffect(() => {
    if (user) setLogged(!!user)
  }, [user])

  useEffect(() => {
    if (isSuccessLogout) {
      removeStorageItem('access_token')
      removeStorageItem('refresh_token')
      removeStorageItem('id_token')
      setLogged(false)
      // window.location.href = gatesLoginEndpoint
    }
  }, [isSuccessLogout])

  if (isErrorLogin) return <ErrorBoundary error={errorLogin} linkTo={baseUrl} />

  return (
    <Context.Provider
      value={{
        user,
        userLevel,
        logged,
        setLogged,
        login,
        logoutUser,
        isErrorLogout,
        isLoadingLogout,
        isSuccessLogout,
        isLoadingLogin,
        isAttendant,
        isFraudAndPrevention,
        isAdmin,
        isBackoffice4c,
        isErrorLogin,
        verifyProfile,
        isLoadingProfile,
        isSuccessLogin,
        isSuccessProfile,
        isErrorProfile,
        errorLogin,
        errorProfile
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAuth = () => {
  return useContext(Context)
}
