import { Box } from '@chakra-ui/react'
import { Flex } from 'components/atoms'
import React from 'react'

import { Header, Footer } from '.'

export type LayoutProps = {
  children: React.ReactNode
  isShowBackButton?: boolean
  pageBackTitle?: string
  onPageBack?: () => void
  colorScheme?: 'primary' | 'secondary'
  maxWidth?: string
}

const Layout = ({
  children,
  onPageBack,
  isShowBackButton,
  pageBackTitle = 'Voltar',
  colorScheme = 'primary',
  maxWidth = '100%'
}: LayoutProps) => {
  return (
    <Flex
      minH="100vh"
      flexDir="column"
      fontFamily="TTTravels"
      bg={colorScheme === 'primary' ? 'standard.white' : '#F9FAFF'}
    >
      <Header
        isShowBackButton={isShowBackButton}
        pageBackTitle={pageBackTitle}
        onPageBack={onPageBack}
        maxWidth={maxWidth}
      />
      <Box mb="100px">{children}</Box>
      <Footer />
    </Flex>
  )
}

export default Layout
