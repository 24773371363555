import { Flex, Spinner } from '@chakra-ui/react'
import { WalletCatalog } from 'components/ui'
import { useWallet } from 'contexts/walletContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'

export type WalletListProps = {
  header: React.ReactNode
}

export const WalletsList = ({ header }: WalletListProps) => {
  const { removeStorageItem } = useLocalStorage()
  const removeCachedProduct = () => removeStorageItem('selectedProduct')
  const { selectedProduct, getWalletsByProductId, isLoading, wallets } =
    useWallet()

  const productId = selectedProduct?.productId

  useEffect(() => {
    if (!productId) return
    getWalletsByProductId({ productId: Number(productId) })
  }, [productId])

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedProduct)
    return () => {
      window.removeEventListener('beforeunload', removeCachedProduct)
    }
  }, [removeCachedProduct])

  if (isLoading) return <Spinner />

  return (
    <Flex flexDir="column" gap="32px">
      {header}
      <Flex gap="32px" flexWrap="wrap">
        {wallets?.map(wallet => (
          <WalletCatalog key={wallet.id} {...wallet} />
        ))}
      </Flex>
    </Flex>
  )
}
