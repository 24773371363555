import { Flex, Image, Text, Box, Link } from '@chakra-ui/react'
import { useOkta } from 'contexts/oktaContext'
import React, { useEffect } from 'react'

const NotAuthorized = () => {
  const { login } = useOkta()

  useEffect(() => {
    login()
  }, [])

  return (
    <Flex
      fontFamily="TTTravels"
      bg="#FAF8FF"
      w="100%"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src="/img/plx-icon-not-authorized.png" />
      <Text
        color="#221C46"
        letterSpacing="-1.2px"
        fontWeight="700"
        fontSize="24px"
        lineHeight="31.2px"
        pt="66px"
      >
        Você não possui acesso a esta área.
      </Text>
      <Box pt="16px" maxW="602px">
        <Text
          color="#221C46"
          fontSize="20px"
          fontWeight="500"
          lineHeight="25px"
          letterSpacing="-1px"
          textAlign="center"
        >
          Entre em contato com o gestor responsável e solicite seu acesso.
        </Text>
        <Text
          color="#221C46"
          fontSize="20px"
          fontWeight="500"
          lineHeight="25px"
          letterSpacing="-1px"
          textAlign="center"
        >
          Você será redirecionado para a página de login.
        </Text>
        <Text
          color="#221C46"
          fontSize="20px"
          fontWeight="500"
          lineHeight="25px"
          letterSpacing="-1px"
          textAlign="center"
        >
          Ou
          <Link
            onClick={login}
            display="inline-block"
            px="3px"
            color="#1B51DC"
            fontSize="20px"
            letterSpacing="-1px"
            fontWeight="500"
            lineHeight="25px"
            textDecoration="underline"
            textUnderlineOffset="1px"
            _hover={{
              color: '#526CF8'
            }}
            _active={{
              color: '#0F266D'
            }}
          >
            clique aqui
          </Link>
          para ser redirecionado agora.
        </Text>
      </Box>
    </Flex>
  )
}

export default NotAuthorized
