import {
  Button,
  Center,
  MenuButton as ChakraMenuButton
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { PluxeeMenu } from 'components/ui'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './scheduleOption.module.css'

export const ScheduleOptions = ({
  orderId,
  isShowCancelOrder,
  onOpenCancelOrder,
  setSelectedOrderId
}: {
  orderId: string
  onOpenCancelOrder: () => void
  isShowCancelOrder: boolean
  setSelectedOrderId: () => void
}) => {
  const navigate = useNavigate()

  return (
    <PluxeeMenu
      menuButton={<CustomMenuButton />}
      items={[
        {
          title: 'Ver Detalhes',
          onClick: () => navigate(`/schedules/${orderId}`),
          isDisabled: false,
          icon: <Icon name="search" />
        },
        {
          title: 'Cancelar Pedido',
          onClick: () => {
            onOpenCancelOrder()
            setSelectedOrderId()
          },
          isDisabled: !isShowCancelOrder,
          icon: (
            <Center className={styles.button}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.96386 14.6625L9.41152 11.2149L5.96477 7.76813L7.37898 6.35392L10.8257 9.80067L14.4491 6.17725L15.8634 7.59147L12.2399 11.2149L15.8643 14.8392L14.45 16.2534L10.8257 12.6291L7.37808 16.0767L5.96386 14.6625Z"
                  fill="#221C46"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.998 0.499756C16.797 0.499756 21.498 5.20077 21.498 10.9998C21.498 16.7987 16.797 21.4998 10.998 21.4998C5.19906 21.4998 0.498047 16.7987 0.498047 10.9998C0.498047 5.20077 5.19906 0.499756 10.998 0.499756ZM10.998 2.49976C15.6925 2.49976 19.498 6.30534 19.498 10.9998C19.498 15.6942 15.6925 19.4998 10.998 19.4998C6.30363 19.4998 2.49805 15.6942 2.49805 10.9998C2.49805 6.30534 6.30363 2.49976 10.998 2.49976Z"
                  fill="#221C46"
                />
              </svg>
            </Center>
          )
        }
      ]}
    />
  )
}

const CustomMenuButton = () => (
  <ChakraMenuButton
    w="40px"
    h="40px"
    as={Button}
    color="#1B51DC"
    borderRadius="0"
    background="transparent"
    _hover={{
      color: '#0F266D'
    }}
    _focusVisible={{
      border: '3px solid #1B51DC',
      color: '#0F266D'
    }}
    _active={{
      background: 'transparent'
    }}
  >
    <Center>
      <Icon
        name="more_horiz"
        variant="outlined"
        _hover={{ color: '#0F266D' }}
        _active={{ color: '#0F266D' }}
      />
    </Center>
  </ChakraMenuButton>
)
