import { useAlert } from 'hooks/useAlert'
import { useUser } from 'modules/users/presentation/contexts/UserContext'
import React, { useEffect } from 'react'

import { DeleteUserPresentational } from '../presentational/DeleteUserPresentational'

type DeleteUserProps = {
  userId: number
  userName: string
  onClose: () => void
  onComplete: () => void
  isOpen: boolean
}

export const DeleteUserContainer = ({
  userId,
  userName,
  onClose,
  onComplete,
  isOpen
}: DeleteUserProps) => {
  const { alert } = useAlert()
  const { removeUser, isLoadingRemove } = useUser()

  const handleDeleteUser = () => {
    removeUser(userId)
      .then(() => {
        onClose()
        onComplete()

        alert({
          id: 'successDeleteUser',
          status: 'success',
          title: 'Usuário excluído com sucesso'
        })
      })
      .catch(error => {
        console.error(error)
        return alert({
          id: 'errorDeleteUser',
          status: 'error',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
        })
      })
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose()
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  if (!isOpen) return null

  return (
    <DeleteUserPresentational
      userName={userName}
      onClose={onClose}
      handleDeleteUser={handleDeleteUser}
      isLoadingRemove={isLoadingRemove}
    />
  )
}
