import { Flex } from '@chakra-ui/react'
import { Text } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { Calendar, Table } from 'components/ui'
import { DateRange } from 'components/ui/Calendar'
import { CellProps } from 'components/ui/Table'
import { useAccountEvents } from 'contexts/accountEventsContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { usePagination } from 'hooks/usePagination'
import { AccountEvents } from 'models/AccountEvents'
import React, { useEffect, useState } from 'react'
import {
  formatDateToBrazilianFormat,
  formatDateToFilter
} from 'utils/dateUtils'
import { formatEventName } from 'utils/stringUtils'

export const HistoryTable = () => {
  const { events, eventsPagination } = useAccountEvents()
  const { currentPage, handleNextPage, handleOnChangePage, handlePrevPage } =
    usePagination({ initialPage: eventsPagination?.currentPage ?? 0 })

  const isEmptyEvents = events?.length === 0 || !events
  const minimumItemsPerPage = 10

  const columns: CellProps[] = [
    {
      title: 'Descrição',
      width: '60%',
      render: (data: Record<keyof AccountEvents, any>) => (
        <Text textTransform="capitalize">{formatEventName(data.event)}</Text>
      )
    },
    {
      title: 'Data e Hora',
      render: (data: Record<keyof AccountEvents, any>) => {
        return <Text>{formatDateToBrazilianFormat(data.eventDate)}</Text>
      }
    }
  ]

  return (
    <Flex flexDir="column" gap="14px" w="100%">
      <Header />

      {!isEmptyEvents ? (
        <Table
          textType="initial"
          minWidth="100%"
          size="sm"
          columns={columns}
          list={events}
          isPaginated={events.length > minimumItemsPerPage}
          currentPage={currentPage}
          totalPages={eventsPagination?.totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
        />
      ) : (
        <NoDataFound />
      )}
    </Flex>
  )
}

const Header = () => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null])
  const [startDate, endDate] = dateRange

  const { getEventsByFilter } = useAccountEvents()
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useLocalStorage()

  const calendarInputValue = getStorageItem('historyFilterDate')

  const handleOnChangeDate = () => {
    const formattedStartDate = formatDateToFilter(startDate)
    const formattedEndDate = formatDateToFilter(endDate)

    const hasValidValues = !!formattedStartDate && !!formattedEndDate
    if (hasValidValues) {
      const startDateBrazil = formatDateToBrazilianFormat(
        startDate?.toISOString()
      )
      const endDateBrazil = formatDateToBrazilianFormat(endDate?.toISOString())

      setStorageItem(
        'historyFilterDate',
        `${startDateBrazil}                  ${endDateBrazil}`
      )

      getEventsByFilter({
        dateStart: formattedStartDate,
        dateEnd: formattedEndDate
      })
    }
  }

  const removeCachedDate = () => removeStorageItem('historyFilterDate')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDate)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDate)
    }
  }, [removeCachedDate])

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text
        color="#221C46"
        fontWeight="700"
        letterSpacing="-0.8px"
        fontSize="16px"
      >
        Histórico
      </Text>

      <Flex justifyContent="flex-end">
        <Calendar
          calendarWidth="350px"
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          inputValue={calendarInputValue}
          onChangeDate={handleOnChangeDate}
          onClear={() => {
            removeStorageItem('historyFilterDate')
            getEventsByFilter({
              dateStart: '',
              dateEnd: ''
            })
          }}
        />
      </Flex>
    </Flex>
  )
}
