import { Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { Loading } from 'components/ui'
import { useSchedules } from 'contexts/scheduleContext'
import React, { useEffect, useState } from 'react'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'

import { CustomTh } from './table'

export const ScheduleHistory = () => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null)

  const {
    getScheduleHistory,
    schedulesDetails,
    scheduleHistory,
    isLoadingHistory,
    isErrorHistory,
    setOriginalScheduleDate,
    setScheduleDateForCalendar,
    originalScheduleDate
  } = useSchedules()

  const scheduleId = schedulesDetails?.schedulingDetails?.id

  useEffect(() => {
    getScheduleHistory(String(scheduleId))
  }, [])

  if (isLoadingHistory) return <Loading />
  if (isErrorHistory) return <NoDataFound />

  const toggleRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index)
  }

  return (
    <TableContainer w="100%" bgColor="#221C46">
      <Table variant="simple">
        <Thead bg="#221C46">
          <Tr>
            <CustomTh w="435px" title="Histórico" isBolder />
            <CustomTh title="Data" isBolder textTransform="initial" />
          </Tr>
        </Thead>
        <Tbody>
          {scheduleHistory?.map(
            ({ description, date, name, event, scheduleDate }, index) => {
              setScheduleDateForCalendar(scheduleHistory[0].scheduleDate)
              if (event === 'CREATED') setOriginalScheduleDate(scheduleDate)

              const isAnticipated = event === 'ANTICIPATED'
              const isSelected = expandedRowIndex === index

              return (
                <React.Fragment key={`Schedule Info = ${name}`}>
                  <Tr
                    w="100%"
                    color="brand.primary-dark"
                    _odd={{ bg: '#F3F3FC' }}
                    bgColor={isSelected ? '#c3ebfb' : '#fff'}
                    onClick={isAnticipated ? () => toggleRow(index) : undefined}
                    borderTop={
                      isSelected && isAnticipated ? '3px solid #1b51dc' : 'none'
                    }
                  >
                    <Td
                      color="#221C46"
                      fontSize="14px"
                      fontWeight="500"
                      letterSpacing="-0.7px"
                      textTransform="capitalize"
                    >
                      {description ?? 'Descrição não disponível'}
                    </Td>
                    <Td
                      color="#221C46"
                      fontSize="14px"
                      fontWeight="500"
                      letterSpacing="-0.7px"
                      textTransform="capitalize"
                    >
                      {formatDateToBrazilianFormat(date)}
                    </Td>
                    {isAnticipated ? (
                      <Td textAlign="right">
                        <Icon
                          cursor="pointer"
                          color="#1b51dc"
                          name={
                            isSelected
                              ? 'keyboard_arrow_up'
                              : 'keyboard_arrow_down'
                          }
                        />
                      </Td>
                    ) : (
                      <Td></Td>
                    )}
                  </Tr>

                  {isSelected && (
                    <>
                      <Tr w="100%" bgColor="#E3F7FF" color="#221C46">
                        <Td
                          colSpan={1}
                          color="#221C46"
                          fontSize="14px"
                          fontWeight="700"
                          letterSpacing="-0.7px"
                          textTransform="capitalize"
                          p="10px 72px !important"
                        >
                          Agendamento original
                        </Td>
                        <Td
                          colSpan={2}
                          color="#221C46"
                          fontSize="14px"
                          fontWeight="700"
                          letterSpacing="-0.7px"
                          textTransform="capitalize"
                          p="10px 72px !important"
                        >
                          Agendamento antecipado
                        </Td>
                      </Tr>
                      <Tr
                        w="100%"
                        borderTop="none"
                        bgColor="#E3F7FF"
                        color="#221C46"
                        borderBottom="3px solid #d1cfd7"
                      >
                        <Td
                          colSpan={1}
                          color="#221C46"
                          fontSize="14px"
                          fontWeight="500"
                          letterSpacing="-0.7px"
                          textTransform="capitalize"
                          p="10px 72px !important"
                        >
                          {formatDateToBrazilianFormat(originalScheduleDate) ??
                            'Carregando...'}
                        </Td>
                        <Td
                          colSpan={2}
                          color="#221C46"
                          fontSize="14px"
                          fontWeight="500"
                          letterSpacing="-0.7px"
                          textTransform="capitalize"
                          p="10px 72px !important"
                        >
                          {formatDateToBrazilianFormat(scheduleDate) ?? ''}
                        </Td>
                      </Tr>
                    </>
                  )}
                </React.Fragment>
              )
            }
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
