import { Divider, useDisclosure } from '@chakra-ui/react'
import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Calendar, Modal } from 'components/ui'
import { DateRange } from 'components/ui/Calendar'
import { useProfile } from 'contexts/profileContext'
import { useSchedules } from 'contexts/scheduleContext'
import { format, parseISO, isToday } from 'date-fns'
import { useAlert } from 'hooks/useAlert'
import React, { useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'

type AntecipateOrderProps = {
  isOpen: boolean
  onClose: () => void
  orderId: string
  scheduleDate: string
}

export const AntecipateOrder = ({
  isOpen,
  onClose,
  orderId,
  scheduleDate
}: AntecipateOrderProps) => {
  const { user } = useProfile()
  const { alert } = useAlert()

  const {
    isOpen: isOpenConfirmationAntecipate,
    onOpen: onOpenConfirmationAntecipate,
    onClose: onCloseConfirmationAntecipate
  } = useDisclosure()

  const {
    getSchedulesByOrderId,
    originalScheduleDate,
    antecipateOrder,
    isSuccessAntecipateOrder,
    isLoadingAntecipateOrder
  } = useSchedules()

  const [dateRange, setDateRange] = useState<DateRange>([null, null])
  const [startDate] = dateRange
  const [antecipateDate, setAntecipateDate] = useState<Date | null>()

  const isTodaySelected = antecipateDate ? isToday(antecipateDate) : false
  const [isConfirmationShown, setIsConfirmationShown] = useState(false)

  const { reset, handleSubmit } = useForm()

  const onSubmit = () => {
    if (!isConfirmationShown && isTodaySelected) {
      onOpenConfirmationAntecipate()
      setIsConfirmationShown(true)
      onClose()

      return
    }

    const formattedAntecipatedDate = format(
      antecipateDate ?? new Date(),
      'yyyy-MM-dd'
    )

    antecipateOrder({
      orderId: String(orderId),
      scheduledDate: formattedAntecipatedDate,
      userName: `${user?.name} - ${user?.login}` || '',
      sourceIp: '127.0.0.1'
    })
      .then(() => {
        alert({
          id: 'successAntecipateOrder',
          status: 'success',
          title: 'Agendamento antecipado com sucesso'
        })

        getSchedulesByOrderId(orderId)

        reset()
        onClose()
        setIsConfirmationShown(false)
      })
      .catch(err => {
        console.log('err: ', err)
        alert({
          id: 'errorAntecipateOrder',
          status: 'error',
          title:
            err?.response?.data?.message ??
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
        })
      })
  }

  useLayoutEffect(() => {
    if (isSuccessAntecipateOrder) {
      reset()
      onClose()
    }
  }, [isSuccessAntecipateOrder])

  const handleCancelOperation = () => {
    onClose()
    onCloseConfirmationAntecipate()
    setIsConfirmationShown(false)
    setAntecipateDate(null)
    reset()
  }

  const modalFooter = (
    <Flex alignItems="center" gap="16px" mt="20px">
      <CancelButton onClick={handleCancelOperation} />

      <SubmitButton
        isDisabled={!antecipateDate}
        title="Antecipar pedido"
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingAntecipateOrder}
      />
    </Flex>
  )

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        decreaseMarginTop
        footer={modalFooter}
        heading="Antecipar pedido"
        closeOnOverlayClick={false}
        onClose={() => {
          onClose()
          handleCancelOperation()
        }}
      >
        <VStack spacing="xxs">
          <Flex
            fontSize="16px"
            fontWeight="600"
            w="100%"
            letterSpacing="-0.8px"
            gap="4px"
          >
            <Text color="#221C46">Você deseja antecipar o agendamento</Text>
            <Text color="#1B51DC">{orderId}?</Text>
          </Flex>

          <WarningMessage />

          <Flex flexDir="column" gap="16px" w="100%">
            <Flex
              letterSpacing="-0.8px"
              color="#221C46"
              fontSize="16px"
              fontWeight="500"
              gap="8px"
            >
              <Text>Data atual da disponibilização de crédito:</Text>
              <Text fontWeight="600">
                {formatDateToBrazilianFormat(scheduleDate)}
              </Text>
            </Flex>

            {originalScheduleDate && (
              <Calendar
                oneDateStyle
                calendarWidth="458px"
                height="48px"
                startDate={startDate}
                endDate={startDate}
                minDate={new Date()}
                maxDate={new Date(parseISO(originalScheduleDate))}
                setDateRange={setDateRange}
                inputValue={
                  antecipateDate
                    ? formatDateToBrazilianFormat(antecipateDate?.toISOString())
                    : undefined
                }
                onSelectDate={dates => {
                  const firstDateSelected: Date = dates[0] ?? new Date()
                  setAntecipateDate(firstDateSelected)
                }}
                onChangeDate={() => undefined}
                onClear={() => setAntecipateDate(null)}
              />
            )}
          </Flex>
        </VStack>
      </Modal>

      <ConfirmAntecipationForToday
        footer={modalFooter}
        isOpen={isOpenConfirmationAntecipate}
        onClose={() => {
          onCloseConfirmationAntecipate()
          handleCancelOperation()
        }}
      />
    </>
  )
}

const WarningMessage = () => (
  <Flex
    bg="#FBF2EC"
    h="100%"
    p="16px 12px"
    gap="12px"
    alignItems="center"
    mb="24px"
  >
    <Icon name="warning" color="#221C46" size="xs" />
    <Flex
      fontSize="16px"
      color="#874810"
      fontWeight="600"
      letterSpacing="-0.8px"
    >
      <Text>
        Atenção ao preencher as informações. Antecipar o pedido é uma ação que
        não pode ser desfeita.
      </Text>
    </Flex>
  </Flex>
)

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="#1B51DC"
      bg="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      onClick={onClick}
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
        Cancelar
      </Text>
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  return (
    <Button
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _hover={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && 'pluxee.interactive.primary',
        border: !isDisabled && 'none'
      }}
      _active={{
        background: !isDisabled && 'pluxee.cta.secondaryPressed',
        color: !isDisabled && 'standard.white',
        borderColor: !isDisabled && 'pluxee.cta.secondaryPressed',
        boxShadow:
          !isDisabled && '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && 'pluxee.interactive.primary',
        boxShadow: !isDisabled && 'none !important'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
        {title}
      </Text>
    </Button>
  )
}

const ConfirmAntecipationForToday = ({
  isOpen,
  onClose,
  footer
}: {
  isOpen: boolean
  onClose: () => void
  footer: React.ReactNode
}) => {
  return (
    <Modal
      heading="Antecipação para hoje"
      footer={footer}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
      hideLine
      titleSpacingToBottom="0px"
    >
      <VStack spacing="xxs">
        <Flex
          fontSize="24px"
          fontWeight="700"
          w="100%"
          letterSpacing="-0.8px"
          gap="4px"
        >
          <Text color="#1B51DC">{format(new Date(), 'dd/MM/yyyy')}</Text>
        </Flex>

        <Flex
          bg="#FBF2EC"
          w="100%"
          h="100%"
          p="16px 12px"
          gap="12px"
          alignItems="center"
          mb="24px"
        >
          <Icon name="warning" color="#221C46" size="xs" />
          <Flex
            fontSize="16px"
            color="#874810"
            fontWeight="600"
            letterSpacing="-0.8px"
          >
            <Text>Não será possível alterar esta antecipação.</Text>
          </Flex>
        </Flex>

        <Divider />

        <Text
          letterSpacing="-0.8px"
          color="#221C46"
          fontSize="16px"
          fontWeight="700"
        >
          Após confirmada a antecipação, o crédito será disponibilizado nos
          próximos minutos.
        </Text>
      </VStack>
    </Modal>
  )
}
