/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider } from '@chakra-ui/react'
import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Checkbox, Modal, NoData } from 'components/ui'
import { useAccountCards } from 'contexts/accountCardsContext'
import { useConsumer } from 'contexts/consumerContext'
import { useProfile } from 'contexts/profileContext'
import { useAlert } from 'hooks/useAlert'
import { BlockReason } from 'models/BlockReason'
import { Card } from 'models/Card'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ErrorMessage, formatErrorMessage } from 'utils/errorUtils'
import { capitalizeSentence } from 'utils/stringUtils'

import {
  AcceptedCodes,
  getButtonTitleByBlockCode,
  getTextByBlockCode
} from './helpers'

type Inputs = {
  reason: string
}

export type BlockAndUnblockCardProps = {
  isOpen: boolean
  onClose: () => void
  card: Card
  isFraudLevel: boolean
}

export const BlockAndUnblockCard = ({
  isOpen,
  onClose,
  card
}: BlockAndUnblockCardProps) => {
  const { alert, hideAllAlerts } = useAlert()
  const [radioValue, setRadioValue] = useState<any>('')

  const [isShowSecurityActionModal, setIsShowSecurityActionModal] =
    useState(false)

  const [reasonObject, setReasonObject] = useState<BlockReason>({
    id: 0,
    code: '',
    description: '',
    type: undefined
  })

  const { isAttendant } = useProfile()
  const { blockReasons, blockReasonsForPreBlocked, isErrorBlockReasons } =
    useConsumer()

  const isPreBlocked = ['PRE_BLOCKED'].includes(card.status)

  const formattedBlockReasons = isPreBlocked
    ? blockReasonsForPreBlocked
    : blockReasons

  const {
    lockCard,
    unlockCard,
    isBlockingCard,
    isBlockSuccess,
    isUnblockingCard,
    isUnblockSuccess
  } = useAccountCards()

  const { handleSubmit, resetField, reset } = useForm<Inputs>()

  const onContinue = () => {
    if (JSON.parse(radioValue)) {
      setReasonObject(JSON.parse(radioValue))
      setIsShowSecurityActionModal(true)
    }
  }

  const onSubmit: SubmitHandler<Inputs> = () => {
    if (isLock) {
      return lockCard({
        cardId: card.id,
        productId: card.productId,
        reason: reasonObject
      })
    }

    return unlockCard({
      cardId: card.id,
      productId: card.productId,
      reason: card.cardReason!
    })
      .then(data => {
        return data
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.message
        const formattedErrorMessage = errorMessage
          ? formatErrorMessage(errorMessage as ErrorMessage)
          : 'Ocorreu um erro, tente novamente!'

        alert({
          id: 'unblockErrorToast',
          status: 'error',
          title: formattedErrorMessage
        })
      })
  }

  const isLock = ['ACTIVE', 'PRE_BLOCKED'].includes(card.status)
  const operationType = isAttendant
    ? 'Bloquear'
    : isLock
    ? 'Bloquear'
    : 'Desbloquear'

  useEffect(() => {
    if ((isLock && isBlockSuccess) || (!isLock && isUnblockSuccess)) {
      reset()
      onClose()
      setRadioValue('')
    }
  }, [isBlockSuccess, isLock, isUnblockSuccess, onClose, resetField])

  useEffect(() => {
    if (!radioValue) return

    const checkboxGroupDiv =
      document.querySelectorAll<HTMLInputElement>('.pluxeeCheckbox')

    checkboxGroupDiv?.forEach(item => {
      const formattedInnerText = item.innerText?.toUpperCase()
      const formattedRadioValue = JSON.parse(radioValue)

      const checkbox = item?.children[1] as HTMLInputElement

      if (formattedInnerText !== formattedRadioValue.description) {
        checkbox.style.borderColor = '#908C99'
      } else {
        checkbox.style.borderColor = '#1B51DC'
        checkbox.removeAttribute('data-focus')
        checkbox.removeAttribute('data-focus-visible')
      }
    })
  }, [radioValue])

  const lockComponent = (
    <>
      <Text
        letterSpacing="-0.8px"
        color="#221C46"
        fontWeight="500"
        fontSize="16px"
        mb="16px"
      >
        Você selecionou o Cartão com final{' '}
        {card.cardNumber?.substring(card.cardNumber?.length, 12)}
        .<br /> Selecione um motivo abaixo para prosseguir com a tratativa de
        segurança:
      </Text>

      <Flex flexDir="column" gap="8px">
        {formattedBlockReasons?.map(item => {
          const formattedItem = JSON.stringify(item)
          const isChecked = radioValue === formattedItem

          return (
            <Checkbox
              key={`Checkbox - ${item.description}`}
              checked={isChecked}
              title={capitalizeSentence(item.description)}
              onChange={() => setRadioValue(formattedItem)}
            />
          )
        })}
      </Flex>
    </>
  )

  const unlockComponent = (
    <Text letterSpacing="-0.8px">
      O cartão final{' '}
      <strong>{card.cardNumber?.substring(card.cardNumber.length, 12)}</strong>{' '}
      encontra-se bloqueado, tem certeza que deseja desbloqueá-lo?
    </Text>
  )

  const confirmLockComponent = (
    <Flex flexDir="column">
      <Text
        letterSpacing="-0.8px"
        color="#1B51DC"
        fontSize="16px"
        fontWeight="800"
        mb="16px"
      >
        {capitalizeSentence(reasonObject?.description)}
      </Text>

      <Divider mb="24px" />

      {reasonObject.code !== '' && (
        <Text
          letterSpacing="-0.8px"
          mb="8px"
          fontWeight="500"
          fontSize="16px"
          dangerouslySetInnerHTML={{
            __html: getTextByBlockCode(reasonObject.code as AcceptedCodes)
          }}
        />
      )}

      <Flex
        bg="#FBF2EC"
        h="100%"
        p="16px 12px"
        gap="12px"
        alignItems="center"
        mb="24px"
      >
        <Icon name="warning" color="#221C46" />
        <Text
          letterSpacing="-0.8px"
          fontSize="16px"
          color="#874810"
          fontWeight="600"
        >
          Atenção ao confirmar a tratativa, verifique se ela possui ações que
          não podem ser desfeitas.
        </Text>
      </Flex>
    </Flex>
  )

  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          hideAllAlerts('unblockErrorToast')
          setRadioValue('')
          resetField('reason')
          onClose()
          setIsShowSecurityActionModal(false)
        }}
      />

      {isShowSecurityActionModal || !isLock ? (
        <SubmitButton
          onClick={handleSubmit(onSubmit)}
          isLoading={isLock ? isBlockingCard : isUnblockingCard}
          title={
            !isLock
              ? operationType
              : getButtonTitleByBlockCode(reasonObject.code as AcceptedCodes)
          }
        />
      ) : (
        <ContinueButton onClick={onContinue} />
      )}
    </Flex>
  )

  const isLocked = isLock && !isAttendant
  const isUnlocked = !isLock && !isAttendant

  const onCloseComplete = () => {
    hideAllAlerts('unblockErrorToast')
    setIsShowSecurityActionModal(false)
    setRadioValue('')
  }

  if (isErrorBlockReasons && !isPreBlocked)
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        footer={<></>}
        heading={'Ações de segurança'}
        onCloseComplete={onCloseComplete}
        size={isShowSecurityActionModal ? 'xl' : '2xl'}
      >
        <NoData />
      </Modal>
    )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={modalFooter}
      heading={'Ações de segurança'}
      onCloseComplete={onCloseComplete}
      size={isShowSecurityActionModal ? 'xl' : '2xl'}
      closeOnOverlayClick={false}
    >
      {isShowSecurityActionModal ? (
        <VStack spacing="nano" align="start">
          {confirmLockComponent}
        </VStack>
      ) : (
        <VStack spacing="nano" align="start">
          {isAttendant && lockComponent}
          {isLocked && lockComponent}
          {isUnlocked && unlockComponent}
        </VStack>
      )}
    </Modal>
  )
}

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      background="transparent"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        Cancelar
      </Text>
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      bg="#1B51DC"
      color="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles, borderColor: 'transparent' }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      isLoading={isLoading}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}

const ContinueButton = ({
  onClick
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      bg="#1B51DC"
      color="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles, borderColor: 'transparent' }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        Continuar
      </Text>
    </Button>
  )
}
