import { Flex, Text } from 'components/atoms'
import React from 'react'
import { limitString } from 'utils/stringUtils'

import { Tooltip } from '.'

export type ResponseCodeProps = {
  text: string
  maxLenght?: number
  fontSize?: string
  letterSpacing?: string
}

const ResponseCode = ({
  text,
  maxLenght = 26,
  fontSize = '14px',
  letterSpacing
}: ResponseCodeProps) => {
  return (
    <Flex w="100%" alignItems="center" justifyContent="space-between" gap="8px">
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <Text
          color="#221C46"
          fontSize={fontSize}
          fontWeight="700"
          letterSpacing={letterSpacing}
        >
          {limitString(text, maxLenght)}
        </Text>
        <Tooltip label={text} />
      </Flex>
    </Flex>
  )
}

export default ResponseCode
