import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {},
  thumb: {
    bg: 'white',

    backgroundImage: 'url("/icons/pluxee/closeIcon.svg")',
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    _disabled: {
      background: 'red'
    },

    _checked: {
      backgroundImage: 'url("/icons/pluxee/checkedIcon.svg")',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }
  },
  track: {
    bg: '#908C99',
    border: '2px solid transparent',

    _hover: { border: '2px solid #1B51DC', background: '#DEF3FB' },
    _focusVisible: {
      outline: '2px solid #1B51DC',
      outlineOffset: '1.5px',
      border: '2px solid #1B51DC',
      background: '#DEF3FB',
      boxShadow: 'none'
    },

    _active: { background: '#0F266D', borderColor: '#0F266D' },

    _checked: {
      bg: '#006245',
      _hover: {
        background: '#1B51DC',
        borderColor: '#1B51DC'
      },
      _focusVisible: {
        outline: '2px solid #1B51DC',
        outlineOffset: '1.5px',
        boxShadow: 'none',
        background: '#1B51DC',
        border: '2px solid #1B51DC'
      },
      _active: { background: '#0F266D', borderColor: '#0F266D' }
    }
  }
})

export const toggleThemePlx = defineMultiStyleConfig({ baseStyle })
