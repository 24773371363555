import { useOktaAuth } from '@okta/okta-react'
import ComposeProviders from 'components/ui/ComposeProviders'
import { AccountWalletProvider } from 'contexts/accountWalletContext'
import { ConsumerProvider } from 'contexts/consumerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { SchedulesProvider } from 'contexts/scheduleContext'
import { WalletTransactionProvider } from 'contexts/walletTransactionContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SchedulesDetailsTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const SchedulesDetails = () => {
  const navigate = useNavigate()
  const { getStorageItem } = useLocalStorage()
  const { authState, oktaAuth } = useOktaAuth()
  const {
    user,
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isOperation3c,
    isAttendant3c,
    isAttendantB2M,
    isPayments,
    isSupervisorB2b
  } = useProfile()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission =
        isAttendant ||
        isFraudAndPrevention ||
        isBackoffice4c ||
        isOperation3c ||
        isAttendant3c ||
        isAttendantB2M ||
        isPayments ||
        isSupervisorB2b

      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isOperation3c,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    navigate,
    user,
    authState
  ])

  const providers = [
    { Provider: ProfileProvider },
    { Provider: ConsumerProvider },
    { Provider: AccountWalletProvider },
    { Provider: WalletTransactionProvider },
    { Provider: SchedulesProvider }
  ]

  return (
    <ComposeProviders providers={providers}>
      <SchedulesDetailsTemplate />
    </ComposeProviders>
  )
}

export default SchedulesDetails
