export const fonts = {
  heading: `'SansaPro', sans-serif`,
  body: `'SansaPro', sans-serif`
}

export const fontSizes = {
  xxxs: '0.75rem', // 12px
  xxs: '0.875rem', // 14px
  xs: '1rem', // 16px
  sm: '1.25rem', // 20px
  md: '1.5rem', // 24px
  lg: '2rem', // 32px
  xl: '2.5rem', // 40px
  xxl: '3rem', // 48px
  xxxl: '4rem', // 64px
  display: '5rem' // 80px
}

export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900
}

export const lineHeights = {
  default: '100%',
  xs: '115%',
  sm: '120%',
  md: '133%',
  lg: '150%',
  xl: '170%',
  xxl: '200%'
}
