import { Button, FlexProps, useDisclosure } from '@chakra-ui/react'
import { Text, Flex, Box } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { ScheduleStatus, Tooltip } from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import { useSchedules } from 'contexts/scheduleContext'
import { isToday, parseISO } from 'date-fns'
import { translate } from 'internationalization'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { AntecipateOrder } from 'templates/schedules/scheduleTable/antecipateOrder'
import { CancelOrder } from 'templates/schedules/scheduleTable/cancelOrder'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'

export type SchedulesStatusEnum = 'SCHEDULED' | 'PROCESSED' | 'CANCELED'

export const OrderDetails = () => {
  const { schedulesDetails, isLoadingDetails, isErrorDetails } = useSchedules()
  const { isBackoffice4c, isSupervisorB2b } = useProfile()

  const id = schedulesDetails?.schedulingDetails?.id
  const status = schedulesDetails?.schedulingDetails?.status

  const isRenderAntecipateBtn = isSupervisorB2b && status !== 'CANCELED'

  const isAllowAntecipateOrder = status === 'SCHEDULED' && isSupervisorB2b
  const isAllowCancelOrder = status === 'SCHEDULED' && isBackoffice4c

  const isScheduledForToday = isToday(
    new Date(parseISO(schedulesDetails?.schedulingDetails?.scheduledDate ?? ''))
  )

  const isDisabledAntecipateOrder =
    !isAllowAntecipateOrder || isScheduledForToday

  const {
    isOpen: isOpenCancelOrder,
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder
  } = useDisclosure()

  const {
    isOpen: isOpenAntecipateOrder,
    onOpen: onOpenAntecipateOrder,
    onClose: onCloseAntecipateOrder
  } = useDisclosure()

  if (isLoadingDetails || !schedulesDetails)
    return (
      <Box width="100%">
        <SkeletonSchedules />
      </Box>
    )

  if (isErrorDetails) return <NoDataFound />

  return (
    <>
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap="16px" mt="30px">
          <Text
            fontSize="24px"
            fontWeight="700"
            color="#221C46"
            letterSpacing="-1.2px"
          >
            {translate('commons.order')} {id}
          </Text>

          <Text fontSize="20px">
            <ScheduleStatus
              status={
                schedulesDetails?.schedulingDetails
                  ?.status as SchedulesStatusEnum
              }
            />
          </Text>
        </Flex>

        <Flex gap="16px">
          {isRenderAntecipateBtn && (
            <ActionButton
              onClick={onOpenAntecipateOrder}
              isDisabled={isDisabledAntecipateOrder}
              title="Antecipar pedido"
              bgColor="#1B51DC"
              color="white"
              _disabled={{
                bgColor: '#EFEFEF',
                borderColor: '#EFEFEF',
                color: '#B5B2BC'
              }}
              tooltipMessage={formatTooltipMessage({
                status,
                isDisabled: isDisabledAntecipateOrder,
                scheduleDate: schedulesDetails?.schedulingDetails?.scheduledDate
              })}
            />
          )}

          {isAllowCancelOrder && (
            <ActionButton
              onClick={onOpenCancelOrder}
              isDisabled={!isAllowCancelOrder}
              title="Cancelar pedido"
            />
          )}
        </Flex>
      </Flex>

      <Flex w="100%" gap="130px">
        <Info title="Dados do pedido">
          <Flex flexDir="column" gap="8px">
            {schedulesDetails?.schedulingDetails?.scheduledDate && (
              <TextLabel
                title="Data do crédito:"
                value={formatDateToBrazilianFormat(
                  schedulesDetails?.schedulingDetails?.scheduledDate
                )}
              />
            )}

            {schedulesDetails?.schedulingDetails?.creationDate && (
              <TextLabel
                title={translate('commons.requestDate')}
                value={formatDateToBrazilianFormat(
                  schedulesDetails?.schedulingDetails?.creationDate
                )}
              />
            )}

            <TextLabel
              title={translate('commons.lastChangeDate')}
              value={
                schedulesDetails?.schedulingDetails?.lastUpdatedDate
                  ? formatDateToBrazilianFormat(
                      schedulesDetails?.schedulingDetails?.lastUpdatedDate
                    )
                  : '-'
              }
            />

            {schedulesDetails?.schedulingDetails?.productId && (
              <TextLabel
                title={`${translate('commons.product')}:`}
                value={
                  formatProductId(
                    schedulesDetails?.schedulingDetails?.productId
                  ) ?? '-'
                }
              />
            )}
          </Flex>
        </Info>

        <Info title={translate('commons.company')} hiddenTitle>
          <Flex flexDir="column" gap="8px">
            <TextLabel
              title={translate('commons.issuingCompany')}
              value={schedulesDetails?.customerDetails?.name ?? '-'}
            />

            <TextLabel
              title={translate('commons.clientCode')}
              value={
                schedulesDetails?.customerDetails?.id
                  ? String(schedulesDetails?.customerDetails?.id)
                  : '-'
              }
            />
          </Flex>
        </Info>
      </Flex>

      {id && (
        <>
          <AntecipateOrder
            orderId={id}
            isOpen={isOpenAntecipateOrder}
            onClose={onCloseAntecipateOrder}
            scheduleDate={schedulesDetails?.schedulingDetails?.scheduledDate}
          />

          <CancelOrder
            orderId={id}
            isOpen={isOpenCancelOrder}
            onClose={onCloseCancelOrder}
          />
        </>
      )}
    </>
  )
}

const Info = ({
  title,
  children,
  hiddenTitle = false
}: {
  title: string
  children: React.ReactNode
  hiddenTitle?: boolean
}) => (
  <Flex flexDir="column" alignItems="flex-start" justifyContent="flex-start">
    <Text
      color="#221C46"
      fontSize="24px"
      fontWeight="700"
      letterSpacing="-1.2px"
      mb="16px"
      opacity={hiddenTitle ? 0 : 1}
    >
      {title}
    </Text>

    {children}
  </Flex>
)

const TextLabel = ({ title, value }: { title: string; value: string }) => (
  <Flex fontSize="xs">
    <Text
      fontWeight="700"
      minW="230px"
      fontSize="14px"
      color="#221C46"
      letterSpacing="-0.7px"
    >
      {title}
    </Text>

    <Text
      fontSize="14px"
      color="#221C46"
      fontWeight="500"
      letterSpacing="-0.7px"
    >
      {value}
    </Text>
  </Flex>
)

const SkeletonSchedules = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="70" height="10" />

    <rect x="0" y="30" rx="3" ry="3" width="120" height="10" />
    <rect x="0" y="45" rx="3" ry="3" width="120" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="120" height="10" />

    <rect x="130" y="30" rx="3" ry="3" width="120" height="10" />
    <rect x="130" y="45" rx="3" ry="3" width="120" height="10" />
    <rect x="130" y="60" rx="3" ry="3" width="120" height="10" />

    <rect x="260" y="30" rx="3" ry="3" width="120" height="10" />
    <rect x="260" y="45" rx="3" ry="3" width="120" height="10" />
    <rect x="260" y="60" rx="3" ry="3" width="120" height="10" />
  </ContentLoader>
)

type ProductIdEnum = '34' | '39'

export const formatProductId = (productId: string) => {
  const data = {
    34: 'New 3C Gift',
    39: 'New 4C'
  }

  return data[productId as ProductIdEnum] ?? productId
}

type ActionButtonProps = {
  title: string
  isDisabled: boolean
  onClick: () => void
  tooltipMessage?: string
} & FlexProps

const ActionButton = ({
  title,
  isDisabled,
  onClick,
  tooltipMessage,
  ...rest
}: ActionButtonProps) => {
  return (
    <Tooltip
      label={tooltipMessage ?? ''}
      minW="380px"
      textAlign="left"
      fontWeight="700"
    >
      <Flex
        as={Button}
        w="130px"
        h="32px"
        border="3px solid"
        borderColor="#1B51DC"
        color="#1B51DC"
        borderRadius="0"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={onClick}
        isDisabled={isDisabled}
        bg="white"
        _hover={{
          bg: !isDisabled && '#DEF3FB',
          boxShadow: !isDisabled && '4px 4px 0px 0px #CBCAD2 !important',
          color: !isDisabled && '#1B51DC'
        }}
        _active={{
          boxShadow: !isDisabled && '2px 2px 0px 0px #CBCAD2 !important',
          bg: !isDisabled && '#0F266D',
          color: !isDisabled && 'white',
          borderColor: !isDisabled && '#0F266D'
        }}
        _focusVisible={{
          outline: !isDisabled && '3px solid #1B51DC',
          bg: !isDisabled && '#DEF3FB'
        }}
        _disabled={{
          color: '#B5B2BC',
          borderColor: '#B5B2BC',
          _hover: { bg: 'white' }
        }}
        {...rest}
      >
        <Text fontSize="12px" fontWeight="700" letterSpacing="-0.6px">
          {title}
        </Text>
      </Flex>
    </Tooltip>
  )
}

const formatTooltipMessage = ({
  isDisabled,
  status,
  scheduleDate
}: {
  isDisabled: boolean
  status?: string
  scheduleDate: string
}) => {
  let message = ''
  if (status !== 'CANCELED' && isDisabled) {
    const formattedDate = formatDateToBrazilianFormat(scheduleDate)

    message = `Este botão está desabilitado pois o crédito foi antecipado para o dia ${formattedDate}. Não é possível alterar a data do agendamento.`
  }

  return message
}
