import { WalletTransaction } from 'models/WalletTransaction'
import { ITransactionRepository } from 'modules/transaction/domain/repositories/ITransactionRepository'
import { transactionApi } from 'modules/transaction/infra/api/TransactionApi'
import { GetTransactionResponse } from 'modules/transaction/infra/dto/GetTransactionResponse'

export class TransactionRepository implements ITransactionRepository {
  async getTransactionByUUID(uuid: string): Promise<GetTransactionResponse> {
    const response = await transactionApi.get(`/transactions/${uuid}/details`, {
      data: null
    })

    return response.data
  }

  async getOriginalTransaction(
    transactionUUID?: string
  ): Promise<WalletTransaction> {
    const response = await transactionApi.get(
      `/accounts/transaction/${transactionUUID}`,
      {
        data: null
      }
    )

    return response.data
  }
}
