import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { Table } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { User } from 'modules/users/domain/entities/User'
import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './userIcons.module.css'

interface UsersTableProps {
  users: User[]
  currentPage: number
  totalPages?: number
  totalResults?: number
  handlePrevPage: () => void
  handleNextPage: () => void
  handleOnChangePage: (pageNumber: number) => void
  handleEditUser: (user: any) => void
  handleDeleteUser: (user: any) => void
  minimumUsersForPagination: number
  isLoading?: boolean
}

const UsersTablePresentational = ({
  users,
  currentPage,
  totalPages,
  totalResults,
  handlePrevPage,
  handleNextPage,
  handleOnChangePage,
  handleEditUser,
  handleDeleteUser,
  minimumUsersForPagination,
  isLoading
}: UsersTableProps) => {
  const columns: CellProps[] = [
    {
      title: 'Nome de usuário',
      width: '25%',
      render: (data: Record<keyof User, any>) => (
        <Text fontSize="xxs" lineHeight="150%" color="pluxee.text.secondary">
          {data.name}
        </Text>
      )
    },
    {
      title: 'Login',
      width: '230px',
      render: (data: Record<keyof User, any>) => (
        <Text color="pluxee.text.secondary" fontSize="xxs">
          {data.login}
        </Text>
      )
    },
    {
      title: 'Perfil',
      width: '450px',
      render: (data: Record<keyof User, any>) => (
        <Text color="pluxee.text.secondary" fontSize="xxs">
          {data.role?.name}
        </Text>
      )
    },
    {
      title: ' ',
      width: '5%',
      render: (data: Record<keyof User, any>) => (
        <Flex gap="24px">
          <Center
            w="40px"
            h="40px"
            as={Button}
            className={styles.penIcon}
            color="#1B51DC"
            borderRadius="0"
            background="transparent"
            p="0"
            _hover={{
              background: 'transparent'
            }}
            _focusVisible={{
              border: '3px solid #1B51DC',
              color: '#0F266D'
            }}
            onClick={() => handleEditUser(data)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 22L14 21L2 9L9 2L21 14L22 22ZM18.3787 14.2072L14.2072 18.3787L4.82843 9L9 4.82843L18.3787 14.2072ZM19.2544 16.1599L16.1599 19.2544L19.6965 19.6965L19.2544 16.1599Z"
                fill="#1B51DC"
              />
            </svg>
          </Center>

          <Center
            w="40px"
            h="40px"
            as={Button}
            className={styles.deleteIcon}
            onClick={() => handleDeleteUser(data)}
            color="#1B51DC"
            borderRadius="0"
            background="transparent"
            p="0"
            _hover={{
              background: 'transparent'
            }}
            _focusVisible={{
              border: '3px solid #1B51DC',
              color: '#0F266D'
            }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_672_147314)">
                <path d="M15 16V28H17V16H15Z" fill="#1B51DC" />
                <path d="M19 28V16H21V28H19Z" fill="#1B51DC" />
                <path d="M23 16V28H25V16H23Z" fill="#1B51DC" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25 8V12H32V14H29V29.5L26.75 32H13.25L11 29.5V14H8V12H15V8H25ZM17 10H23V12H17V10ZM27 14V28.7325L25.8593 30H14.1407L13 28.7325V14H27Z"
                  fill="#1B51DC"
                />
              </g>
              <defs>
                <clipPath id="clip0_672_147314">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(8 8)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Center>
        </Flex>
      )
    }
  ]

  if (isLoading) return <Skeleton />

  return (
    <Table
      textType="initial"
      minWidth="100%"
      size="sm"
      columns={columns}
      list={users}
      currentPage={currentPage}
      totalPages={totalPages}
      totalResults={totalResults}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
      isPaginated={users.length >= minimumUsersForPagination || currentPage > 0}
    />
  )
}

const Skeleton = () => (
  <ContentLoader viewBox="0 0 1000 550" height={550} width={1000}>
    <rect x="0" y="0" width="1000" height="50" />
    <rect x="0" y="70" width="1000" height="50" />
    <rect x="0" y="140" width="1000" height="50" />
    <rect x="0" y="210" width="1000" height="50" />
    <rect x="0" y="280" width="1000" height="50" />
    <rect x="0" y="350" width="1000" height="50" />
  </ContentLoader>
)

export default UsersTablePresentational
