import { Flex, Text, Center } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { useCustomer } from 'contexts/customerContext'
import React from 'react'
import ContentLoader from 'react-content-loader'

import { WalletCard } from './walletCard'

export const CustomerWallets = () => {
  const { wallets, isLoadingWallets } = useCustomer()

  if (isLoadingWallets) return <SkeletonWallet />

  return (
    <Flex flexDir="column" gap="16px">
      <Text
        color="pluxee.text.primary"
        fontSize="md"
        fontWeight="700"
        lineHeight="31.2px"
        letterSpacing="-0.8px"
      >
        Limites em carteira
      </Text>

      {!wallets?.length && (
        <Center w="100%">
          <NoDataFound />
        </Center>
      )}

      <Flex flexWrap="wrap" gap="32px" alignItems="center">
        {wallets?.map(wallet => (
          <WalletCard key={`customerWallet-${wallet.id}`} {...wallet} />
        ))}
      </Flex>
    </Flex>
  )
}

const SkeletonWallet = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="70" height="10" />
    <rect x="0" y="30" rx="3" ry="3" width="90" height="50" />
    <rect x="105" y="30" rx="3" ry="3" width="90" height="50" />
    <rect x="210" y="30" rx="3" ry="3" width="90" height="50" />
  </ContentLoader>
)
