import { Flex, useDisclosure } from '@chakra-ui/react'
import { useWallet } from 'contexts/walletContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { WalletStatus } from 'models/Wallet'
import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActiveWallet } from 'templates/wallet/operations/activeWallet'
import { DeleteWallet } from 'templates/wallet/operations/deleteWallet'
import { InactiveWallet } from 'templates/wallet/operations/inactiveWallet'

import PrimaryButton from './primaryButton'
import SecondaryButton from './secondaryButton'

export type RenderButtonsProps = {
  id: number
  name: string
  status: WalletStatus
}

const RenderButtons = ({ id, name, status }: RenderButtonsProps) => {
  const navigate = useNavigate()
  const { getWalletById } = useWallet()
  const { getStorageItem } = useLocalStorage()

  const selectedProduct = JSON.parse(
    getStorageItem('selectedProduct') as string
  )

  if (status === 'INACTIVE')
    return (
      <InactiveFlow
        walletId={id}
        walletName={name}
        onComplete={() => getWalletById({ walletId: id })}
        onClick={() =>
          navigate(`/wallet/edit/${id}`, { state: selectedProduct })
        }
      />
    )

  if (status === 'CREATED')
    return (
      <CreatedFlow
        onClick={() =>
          navigate(`/wallet/edit/${id}`, { state: selectedProduct })
        }
      />
    )

  if (status === 'DRAFT')
    return (
      <DraftFlow
        walletId={id}
        walletName={name}
        onDelete={() => navigate('/wallet')}
        onComplete={() => getWalletById({ walletId: id })}
        onClick={() =>
          navigate(`/wallet/edit/${id}`, { state: selectedProduct })
        }
      />
    )

  return (
    <ActiveFlow
      walletId={id}
      walletName={name}
      onComplete={() => getWalletById({ walletId: id })}
      onClick={() => navigate(`/wallet/edit/${id}`, { state: selectedProduct })}
    />
  )
}

export default RenderButtons

const Wrapper = ({ children }: PropsWithChildren) => (
  <Flex gap="8px">{children}</Flex>
)

type ButtonsFlow = {
  onClick: () => void
  walletName: string
  walletId: number
  onComplete: () => void
}

const ActiveFlow = ({
  walletName,
  walletId,
  onClick,
  onComplete
}: ButtonsFlow) => {
  const {
    isOpen: isOpenInactiveModal,
    onClose: onCloseInactiveModal,
    onOpen: onOpenInactiveModal
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <PrimaryButton title="Editar carteira" onClick={onClick} />

        <SecondaryButton
          title="Inativar carteira"
          onClick={onOpenInactiveModal}
        />
      </Wrapper>

      <InactiveWallet
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseInactiveModal}
        isOpen={isOpenInactiveModal}
        onComplete={onComplete}
      />
    </>
  )
}

const InactiveFlow = ({
  walletName,
  walletId,
  onClick,
  onComplete
}: ButtonsFlow) => {
  const {
    isOpen: isOpenActiveModal,
    onClose: onCloseActiveModal,
    onOpen: onOpenActiveModal
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <PrimaryButton title="Editar carteira" onClick={onClick} />
        <SecondaryButton title="Ativar carteira" onClick={onOpenActiveModal} />
      </Wrapper>

      <ActiveWallet
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseActiveModal}
        isOpen={isOpenActiveModal}
        onComplete={onComplete}
      />
    </>
  )
}

const CreatedFlow = ({ onClick }: Pick<ButtonsFlow, 'onClick'>) => {
  return (
    <Wrapper>
      <PrimaryButton title="Editar carteira" onClick={onClick} />
    </Wrapper>
  )
}

const DraftFlow = ({
  walletName,
  walletId,
  onClick,
  onComplete,
  onDelete
}: ButtonsFlow & { onDelete: () => void }) => {
  const {
    isOpen: isOpenActiveModal,
    onClose: onCloseActiveModal,
    onOpen: onOpenActiveModal
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteModal,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <PrimaryButton title="Editar carteira" onClick={onClick} />
        <SecondaryButton title="Ativar carteira" onClick={onOpenActiveModal} />
        <SecondaryButton title="Excluir carteira" onClick={onOpenDeleteModal} />
      </Wrapper>

      <ActiveWallet
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseActiveModal}
        isOpen={isOpenActiveModal}
        onComplete={onComplete}
      />

      <DeleteWallet
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseDeleteModal}
        isOpen={isOpenDeleteModal}
        onComplete={onDelete}
      />
    </>
  )
}
