import { Center, Spinner } from '@chakra-ui/react'
import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Modal, TextArea } from 'components/ui'
import { ControlledInput, UncontrolledInput } from 'components/ui/Input'
import { useAccountWallet } from 'contexts/accountWalletContext'
import {
  useWalletTransaction,
  CreditInTrustTypes
} from 'contexts/walletTransactionContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formatToBrl } from 'utils/currencyUtils'
import { ErrorMessage, formatErrorMessage } from 'utils/errorUtils'
import { goToSection } from 'utils/windowUtils'

import { BlockedAction } from '../modals/blockedAction'

type ReverseTransactionModal = {
  isOpen: boolean
  onClose: () => void
  transaction: WalletTransaction
}

export const ReverseTransaction = ({
  isOpen,
  onClose,
  transaction
}: ReverseTransactionModal) => {
  const [formattedValue, setFormattedValue] = useState('0')
  const { alert } = useAlert()
  const { refreshWalletList } = useAccountWallet()

  const isValidValue =
    Number(formattedValue) > Number(transaction.value) ||
    Number(formattedValue) === 0

  const [isValidOcorrenceCode, setIsValidOcorrenceCode] = useState(false)
  const [isValidReason, setIsValidReason] = useState(false)

  const isValidFields = isValidOcorrenceCode && isValidReason && !isValidValue
  const maxTextAreaLenght = 300

  const isValueGreatherThanOriginalValue =
    Number(formattedValue) > Number(transaction.value)

  const {
    reverseCredit,
    isSuccessReverseCredit,
    isLoadingReverseCredit,
    getAllTransactions,
    isLoadingGetRefundableHistory,
    refundableHistory
  } = useWalletTransaction()

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreditInTrustTypes>()

  const onSubmit: SubmitHandler<any> = async formData => {
    if (!formData || !transaction) {
      return alert({
        id: 'creditInTrustModal',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'warning'
      })
    }

    if (Number(formattedValue) > Number(transaction.value)) {
      return alert({
        status: 'warning',
        id: 'creditInTrust',
        title:
          'O valor do crédito não pode ser maior que o valor da própria transação.'
      })
    }

    reverseCredit({
      value: formattedValue,
      uuid: transaction.transactionUUID,
      authorization_code: transaction.authorizationCode,
      occorence_code: formData.occorence_code,
      reason: formData.reason
    })
      .then(() => {
        refreshWalletList()
        getAllTransactions({ page: 0 })
        goToSection('consumerWallets-section')

        alert({
          id: 'refundToastInTransactionPage',
          status: 'success',
          title: 'Estorno realizado com sucesso'
        })
      })
      .catch(err => {
        console.error(err)

        if (
          !err ||
          !err.response ||
          !err.response.data ||
          !err.response.data.code
        ) {
          return alert({
            id: 'reverseTransactionModal',
            title: 'Ocorreu um erro, tente novamente!',
            status: 'warning'
          })
        }

        const errorMessage = err.response.data.code
        const formattedErrorMessage = formatErrorMessage(
          errorMessage as ErrorMessage
        )

        return alert({
          id: 'reverseTransactionModal',
          status: 'error',
          title: formattedErrorMessage
        })
      })
  }

  useEffect(() => {
    if (isSuccessReverseCredit) {
      if (transaction) setFormattedValue(String(transaction.value))
      onClose()
      reset()
    }
  }, [isSuccessReverseCredit])

  useLayoutEffect(() => {
    if (transaction) setFormattedValue(String(transaction.value))
  }, [transaction])

  const refundValue = Number(refundableHistory?.refundableValue)
  const isTotalRefunded =
    !refundableHistory?.hasProvisionalCreditHistory && refundValue <= 0
  const valueOfRefundableAreEqualThanOriginal =
    Number(transaction.value) === refundValue

  const validFieldsForParcialRefund =
    isValidOcorrenceCode &&
    isValidReason &&
    Number(formattedValue) <= refundValue &&
    Number(formattedValue) > 0

  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          onClose()
          reset()
        }}
      />

      <SubmitButton
        title="Confirmar"
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingReverseCredit}
        isDisabled={
          !valueOfRefundableAreEqualThanOriginal
            ? !validFieldsForParcialRefund
            : !isValidFields
        }
      />
    </Flex>
  )

  useLayoutEffect(() => {
    if (!valueOfRefundableAreEqualThanOriginal)
      setFormattedValue(String(refundValue))
  }, [refundableHistory?.refundableValue])

  const onCloseModals = () => {
    onClose()
    setFormattedValue('')
    reset()
  }

  if (refundableHistory?.hasProvisionalCreditHistory && refundValue <= 0) {
    return (
      <BlockedAction
        isOpen={isOpen}
        onClose={onCloseModals}
        transaction={transaction}
        description="Não será possível realizar um estorno nesta transação pois um crédito provisório foi aplicado anteriormente. <br /> Para mais informações acesse os detalhes da transação."
      />
    )
  }

  if (isTotalRefunded)
    return (
      <BlockedAction
        isOpen={isOpen}
        onClose={onCloseModals}
        transaction={transaction}
        description="Não será possível realizar um estorno nesta transação pois um estorno total foi aplicado anteriormente. Para mais informações acesse os detalhes da transação."
      />
    )

  const isValueGreathenThanParcialValue = Number(formattedValue) > refundValue

  const isShowMsgGreatherThanOriginalValue =
    isValueGreatherThanOriginalValue && valueOfRefundableAreEqualThanOriginal

  return (
    <Modal
      heading={'Estornar transação'}
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      decreaseMarginTop
      onCloseComplete={() => {
        onClose()
        reset()
      }}
    >
      {isLoadingGetRefundableHistory ? (
        <LoadingModal />
      ) : (
        <VStack spacing="xxs">
          <Flex
            w="100%"
            flexDir="column"
            gap={!valueOfRefundableAreEqualThanOriginal ? '8px' : '0px'}
          >
            {!valueOfRefundableAreEqualThanOriginal && (
              <Text
                color="#221C46"
                fontSize="20px"
                fontWeight="700"
                letterSpacing="-0.8px"
              >
                Estorno parcial aplicado anteriormente
              </Text>
            )}

            <ModalDescription
              value={refundValue}
              valueOfRefundableAreEqualThanOriginal={
                valueOfRefundableAreEqualThanOriginal
              }
            />
          </Flex>

          <WarningMessage />

          <Flex w="100%" flexDir="column" gap="4px">
            <ControlledInput
              id="value"
              height="40px"
              isInvalid={
                !!errors.value ||
                isValueGreatherThanOriginalValue ||
                isValueGreathenThanParcialValue
              }
              isRequired
              placeholder="Valor"
              register={register}
              value={Mask.moneyInput(String(formattedValue))}
              onChange={event => {
                setFormattedValue(
                  Mask.removeMoneyInput(event.currentTarget.value)
                )
              }}
              title={'Valor do estorno'}
            />

            {!valueOfRefundableAreEqualThanOriginal && (
              <Text
                fontWeight="600"
                fontSize="12px"
                letterSpacing="-0.8px"
                color={isValueGreathenThanParcialValue ? '#B30000' : '#5A5469'}
              >
                O estorno não poderá ser maior que {formatToBrl(refundValue)}
              </Text>
            )}

            {isShowMsgGreatherThanOriginalValue && (
              <Text
                color="#B30000"
                fontWeight="600"
                fontSize="12px"
                letterSpacing="-0.8px"
              >
                O valor do estorno não pode ser maior que o valor da própria
                transação
              </Text>
            )}
          </Flex>

          <UncontrolledInput
            id="occorence_code"
            height="40px"
            title={translate('commons.occurrenceCode')}
            isInvalid={!!errors.occorence_code}
            isRequired
            requiredMessage="Código da ocorrência é obrigatório"
            placeholder="Informe o código"
            control={control}
            onChange={event => {
              const inputValue = event.currentTarget.value
              if (inputValue.length >= 1) setIsValidOcorrenceCode(true)
              else setIsValidOcorrenceCode(false)
            }}
          />

          <TextArea
            id="reason"
            maxLenght={maxTextAreaLenght}
            title={translate('commons.reason')}
            isInvalid={!!errors.reason}
            placeholder="Informe o motivo do estorno"
            isRequired
            control={control}
            onChange={event => {
              const value = event.target.value

              if (
                Number(value.length) <= maxTextAreaLenght &&
                Number(value.length) >= 1
              ) {
                setIsValidReason(true)
              } else {
                setIsValidReason(false)
              }
            }}
          />
        </VStack>
      )}
    </Modal>
  )
}

const LoadingModal = () => (
  <Center p="20px">
    <Spinner />
  </Center>
)

const WarningMessage = () => (
  <Flex
    bg="#FBF2EC"
    h="100%"
    p="16px 12px"
    gap="12px"
    alignItems="center"
    mb="24px"
  >
    <Icon name="warning" color="#221C46" size="xs" />
    <Text
      fontSize="16px"
      color="#874810"
      fontWeight="600"
      letterSpacing="-0.8px"
    >
      Atenção ao preencher as informações. O estorno de valores não pode ser
      desfeito.
    </Text>
  </Flex>
)

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      bg="transparent"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700">
        Cancelar
      </Text>
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      bg="#1B51DC"
      color="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles, border: 'none' }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}

const ModalDescription = ({
  valueOfRefundableAreEqualThanOriginal,
  value
}: {
  valueOfRefundableAreEqualThanOriginal: boolean
  value: number
}) => {
  if (valueOfRefundableAreEqualThanOriginal) {
    return (
      <Text
        color="#221C46"
        fontSize="16px"
        fontWeight="500"
        letterSpacing="-0.8px"
      >
        Deseja solicitar o estorno da transação? Informe o motivo, código de
        ocorrência e o valor:
      </Text>
    )
  }

  return (
    <Text
      color="#221C46"
      fontSize="16px"
      fontWeight="500"
      letterSpacing="-0.8px"
    >
      Foi aplicado anteriormente um estorno parcial nesta transação. Se um novo
      estorno for aplicado, ele deverá possuir valor menor ou igual a{' '}
      <Text display="inline-block" fontWeight="700">
        {formatToBrl(value)}.
      </Text>{' '}
      Se deseja prosseguir preencha os campos abaixo.
    </Text>
  )
}
