import {
  Center,
  Flex,
  useDisclosure,
  Text,
  MenuButton as ChakraMenuButton
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { useProfile } from 'contexts/profileContext'
import { useWallet } from 'contexts/walletContext'
import { Wallet } from 'models/Wallet'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  renderWalletIcon,
  renderWalletBg,
  WalletIcon
} from 'templates/consumerDetails/consumerWallets/walletCard'
import { ActiveWallet } from 'templates/wallet/operations/activeWallet'
import { DeleteWallet } from 'templates/wallet/operations/deleteWallet'
import { InactiveWallet } from 'templates/wallet/operations/inactiveWallet'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import { backToTop } from 'utils/windowUtils'

import PluxeeMenu from './PluxeeMenu'
import StatusLabel from './StatusLabel'

const WalletCard = (wallet: Wallet) => {
  const { isPayments } = useProfile()
  const { selectedProduct, getWalletsByProductId } = useWallet()
  const navigate = useNavigate()

  const isDraft = wallet.status === 'DRAFT'
  const isActive = wallet.status === 'ACTIVE'
  const isCreated = wallet.status === 'CREATED'
  const isInactive = wallet.status === 'INACTIVE'

  const {
    isOpen: isOpenActiveModal,
    onClose: onCloseActiveModal,
    onOpen: onOpenActiveModal
  } = useDisclosure()

  const {
    isOpen: isOpenInactiveModal,
    onClose: onCloseInactiveModal,
    onOpen: onOpenInactiveModal
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteModal,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal
  } = useDisclosure()

  const maxChar = 23
  const isHealthWallet = wallet.name === 'Saúde & Bem-estar'
  const capitalizedWalletName = isHealthWallet
    ? capitalizeSentence(String(wallet.name))
    : capitalizeSentence(limitString(String(wallet.name), maxChar))

  const formattedWalletName =
    capitalizedWalletName.length >= maxChar
      ? limitString(capitalizedWalletName, maxChar)
      : capitalizedWalletName

  const isAllowTransfer = wallet.allowTransferAux || wallet.allowTransferPat

  return (
    <>
      <Flex
        p="16px"
        w="300px"
        minH="176px"
        h="fit-content"
        gap="8px"
        bg="#FAF8FF"
        id="walletCard"
        flexDir="column"
        borderRadius="4px"
        border="1px solid #E5E3EA"
        justifyContent="center"
        alignSelf="end"
      >
        <Flex w="100%" justifyContent="space-between">
          <Flex gap="8px" alignItems="center">
            <Center
              w="32px"
              h="32px"
              borderRadius="8px"
              bg={renderWalletBg(capitalizedWalletName as WalletIcon)}
            >
              {renderWalletIcon(capitalizedWalletName as WalletIcon)}
            </Center>

            <Text
              color="#221C46"
              fontSize="16px"
              fontWeight="600"
              letterSpacing="-0.8px"
            >
              {formattedWalletName}
            </Text>
          </Flex>

          <PluxeeMenu
            menuButton={<CustomMenuButton />}
            items={[
              {
                title: 'Detalhes da carteira',
                onClick: () => navigate(`/wallet/details/${wallet.id}`)
              },
              {
                title: 'Editar',
                onClick: () =>
                  navigate(`/wallet/edit/${wallet.id}`, {
                    state: selectedProduct
                  }),
                isDisabled: isPayments
              },
              {
                title: 'Ativar carteira',
                onClick: () => {
                  onOpenActiveModal()
                  backToTop()
                },
                color: '#006825',
                isDisabled: isCreated || isActive || isPayments
              },
              {
                title: 'Inativar carteira',
                onClick: () => {
                  onOpenInactiveModal()
                  backToTop()
                },
                color: '#B30000',
                isDisabled: isInactive || isCreated || isDraft || isPayments
              },
              {
                title: 'Excluir carteira',
                onClick: () => {
                  onOpenDeleteModal()
                  backToTop()
                },
                color: '#B30000',
                isDisabled: isCreated || isActive || isInactive || isPayments
              }
            ]}
          />
        </Flex>

        {wallet.status && (
          <Flex mb="8px">
            <StatusLabel statusType={wallet.status} />
          </Flex>
        )}

        <Flex
          alignItems="center"
          fontSize="14px"
          letterSpacing="-0.7px"
          gap="4px"
        >
          <Text fontWeight="500">Prioridade:</Text>
          <Text fontWeight="700">{wallet.priority}</Text>
        </Flex>

        <Flex gap="8px" flexWrap="wrap" h="fit-content" minH="30px">
          {isAllowTransfer && <WalletAllow value="Transferências" />}
          {wallet.allowWithdraw && <WalletAllow value="Saques" />}
          {!wallet.openWallet && <WalletAllow value="MCCs" />}
        </Flex>
      </Flex>

      <ActiveWallet
        walletId={wallet.id!}
        walletName={capitalizedWalletName}
        onClose={onCloseActiveModal}
        isOpen={isOpenActiveModal}
        onComplete={() =>
          getWalletsByProductId({
            productId: Number(selectedProduct?.productId)
          })
        }
      />

      <InactiveWallet
        walletId={wallet.id!}
        walletName={capitalizedWalletName}
        onClose={onCloseInactiveModal}
        isOpen={isOpenInactiveModal}
        onComplete={() =>
          getWalletsByProductId({
            productId: Number(selectedProduct?.productId)
          })
        }
      />

      <DeleteWallet
        walletId={wallet.id!}
        walletName={capitalizedWalletName}
        onClose={onCloseDeleteModal}
        isOpen={isOpenDeleteModal}
        onComplete={() =>
          getWalletsByProductId({
            productId: Number(selectedProduct?.productId)
          })
        }
      />
    </>
  )
}

export default WalletCard

const WalletAllow = ({ value }: { value: string }) => (
  <Flex p="5px 8px" bg="#E3E2FA" w="fit-content">
    <Text
      color="#221C46"
      fontSize="14px"
      fontWeight="700"
      letterSpacing="-0.8px"
    >
      {value}
    </Text>
  </Flex>
)

const CustomMenuButton = () => (
  <ChakraMenuButton
    w="40px"
    h="40px"
    borderRadius="0"
    background="transparent"
    color="#1B51DC"
    _active={{
      border: '2px solid #1B51DC'
    }}
    _focusVisible={{
      border: '3px solid #1B51DC',
      color: '#0F266D'
    }}
    _hover={{
      background: 'transparent',
      color: '#0F266D'
    }}
  >
    <Center>
      <Icon name="more_vert" color="inherit" />
    </Center>
  </ChakraMenuButton>
)
