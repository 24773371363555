import { IUserRepository } from 'modules/users/domain/repositories/IUserRepository'

import { GetAllProps } from '../queries/GetAllProps'
export class GetAllUsers {
  constructor(private userRepository: IUserRepository) {}

  async execute(filters?: GetAllProps) {
    return await this.userRepository.getAll(filters)
  }
}
