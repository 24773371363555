import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    width: '24px',
    height: '24px',
    border: '2px solid',
    borderRadius: 0,
    borderColor: 'pluxee.icon.secondary',
    _hover: {
      background: '#DEF3FB',
      borderColor: 'pluxee.text.link'
    },
    _focusVisible: {
      outline: '3px solid',
      outlineColor: 'pluxee.text.link',
      outlineOffset: '1px',
      borderColor: 'pluxee.text.link',
      boxShadow: 'none'
    },
    _checked: {
      background: 'transparent',
      borderColor: 'pluxee.text.link',
      _hover: {
        background: '#DEF3FB',
        borderColor: 'pluxee.text.link'
      }
    }
  }
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })
