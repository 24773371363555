import { OktaAuth } from '@okta/okta-auth-js'

const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER
const OKTA_CLIENTID = process.env.REACT_APP_OKTA_CLIENTID
const OKTA_AUTHORIZE_URL = process.env.REACT_APP_OKTA_AUTHORIZE_URL
const OKTA_TOKEN_URL = process.env.REACT_APP_OKTA_TOKEN_URL
const OKTA_CALLBACK_URL = process.env.REACT_APP_OKTA_CALLBACK_URL

export const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER || '',
  clientId: OKTA_CLIENTID || '',
  authorizeUrl: OKTA_AUTHORIZE_URL || '',
  redirectUri: OKTA_CALLBACK_URL || '',
  tokenUrl: OKTA_TOKEN_URL,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  tokenManager: {
    storage: 'sessionStorage'
  }
})
