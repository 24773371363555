import { Flex, Box, Text } from '@chakra-ui/react'
import { Tooltip } from 'components/ui'
import React from 'react'

export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'BLOCKED'

export const WalletStatus = ({
  status,
  hideTooltip = false,
  fontSize = '12px',
  padding = '0px 4px'
}: {
  status: StatusEnum
  hideTooltip?: boolean
  fontSize?: string
  padding?: string
}) => {
  const isActive = status === 'ACTIVE'

  const formatWalletStatus = (status: StatusEnum) => {
    const data = {
      ACTIVE: 'Ativa',
      INACTIVE: 'Inativa',
      BLOCKED: 'Desligada'
    }

    return data[status]
  }

  const formatWalletTooltip = (status: StatusEnum) => {
    const data = {
      ACTIVE: '',
      INACTIVE:
        'Inativada pela Pluxee, somente a Pluxee poderá desfazer esta ação.',
      BLOCKED:
        'Desligada pelo Cliente RH, somente ele poderá desfazer esta ação'
    }

    return data[status]
  }

  return (
    <Tooltip label={hideTooltip ? '' : formatWalletTooltip(status)}>
      <Flex
        bg="standard.white"
        w="fit-content"
        minH="18px"
        p={padding}
        alignItems="center"
        justifyContent="center"
        gap="4px"
        border="1px solid"
        borderColor={isActive ? '#01D253' : '#B30000'}
      >
        <Box
          w="8px"
          h="8px"
          borderRadius="50%"
          bg={isActive ? '#01D253' : '#B30000'}
        />
        <Text
          letterSpacing="-0.6px"
          color="#221C46"
          fontWeight="500"
          fontSize={fontSize}
          lineHeight="16px"
        >
          {formatWalletStatus(status) ?? 'Não disponivel'}
        </Text>
      </Flex>
    </Tooltip>
  )
}
