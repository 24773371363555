import axios from 'axios'
import { AuthUtils } from 'utils/authUtils'

const scheduleApiEndpoint = process.env
  .REACT_APP_SCHEDULING_API_ENDPOINT as string

const APIM_KEY = process.env.REACT_APP_APIM_KEY as string

export const scheduleApi = axios.create({
  baseURL: scheduleApiEndpoint,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': APIM_KEY,
    Authorization: `Bearer ${AuthUtils().idToken}`
  }
})
