import { useDisclosure } from '@chakra-ui/react'
import { useUser } from 'modules/users/presentation/contexts/UserContext'
import React from 'react'

import { FilterAreaPresentation } from '../presentational/FilterAreaPresentation'

import { FilterByRoleContainer } from './FilterByRoleContainer'

export const FilterAreaContainer = () => {
  const {
    isLoadingGetAll,
    currentRoleName,
    setCurrentRoleName,
    currentFilters,
    setSearchInputValue,
    setCurrentFilters,
    setSelectedRoleId,
    setIsSearchingByRole
  } = useUser()

  const {
    isOpen: isOpenFilterByRole,
    onClose: onCloseFilterByRole,
    onOpen: onOpenFilterByRole
  } = useDisclosure()

  const isValidRole = !!currentRoleName && currentRoleName !== 'ALL'

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const handleResetFilterByRole = () => {
    delete currentFilters.name
    setSearchInputValue('')
    setCurrentRoleName('')
    setSelectedRoleId('ALL')
    setIsSearchingByRole(false)

    setCurrentFilters({
      ...currentFilters,
      name: '',
      roleId: '',
      page: '0'
    })
  }

  return (
    <>
      <FilterAreaPresentation
        isLoadingGetAll={isLoadingGetAll}
        currentRoleName={currentRoleName}
        isValidRole={isValidRole}
        onOpenFilterByRole={onOpenFilterByRole}
        handleResetFilterByRole={handleResetFilterByRole}
        defaultStyles={defaultStyles}
      />

      <FilterByRoleContainer
        isOpen={isOpenFilterByRole}
        onClose={onCloseFilterByRole}
      />
    </>
  )
}
