import { CancelOrderProps } from 'contexts/scheduleContext'
import { AntecipateOrderProps } from 'models/request/AntecipateOrder'
import { useMutation } from 'react-query'
import { SchedulesService } from 'services/scheduleService'

export function useQueryGetScheduleHistory() {
  return useMutation((orderId: string) => getHistoryByOrderId(orderId))
}

export function useQueryGetSchedulesByOrder() {
  return useMutation((orderId: string) => getByOrderId(orderId))
}

export function useQueryGetSchedulesByCustomer() {
  return useMutation(
    ({
      customerId,
      filterByScheduleds,
      pageNumber
    }: {
      customerId: string
      filterByScheduleds?: boolean
      pageNumber?: string
    }) => getByCustomerId({ customerId, filterByScheduleds, pageNumber })
  )
}

export function useQueryGetDetails() {
  return useMutation((orderId: string) => getSchedulesDetails(orderId))
}

export function useQueryGetByCpf() {
  return useMutation(({ orderId, cpf }: { orderId: string; cpf: string }) =>
    getConsumersByCpf({ cpf, orderId })
  )
}

export function useQueryCancelOrder() {
  return useMutation(
    ({ orderId, occurencyId, userName, sourceIp }: CancelOrderProps) =>
      cancelOrder({ orderId, occurencyId, userName, sourceIp })
  )
}

export function useQueryAntecipateOrder() {
  return useMutation((props: AntecipateOrderProps) =>
    antecipateOrder({ ...props })
  )
}

const getHistoryByOrderId = async (orderId?: string) => {
  if (!orderId) return

  const response = await SchedulesService.getHistory(orderId)
  return response.data
}

const getByOrderId = async (orderId?: string) => {
  if (!orderId) return

  const response = await SchedulesService.getSchedulesByOrderId(orderId)
  return response.data
}

const getByCustomerId = async ({
  customerId,
  filterByScheduleds,
  pageNumber
}: {
  customerId: string
  filterByScheduleds?: boolean
  pageNumber?: string
}) => {
  if (!customerId) return

  const response = await SchedulesService.getSchedulesByCustomerId({
    customerId,
    filterByScheduleds,
    pageNumber
  })

  return response.data
}

const getSchedulesDetails = async (orderId?: string) => {
  if (!orderId) return

  const response = await SchedulesService.getDetails(orderId)
  return response.data
}

const getConsumersByCpf = async ({
  orderId,
  cpf
}: {
  orderId: string
  cpf: string
}) => {
  if (!cpf || !orderId) return

  const response = await SchedulesService.getConsumersByCpf({ cpf, orderId })
  return response.data
}

const antecipateOrder = async ({
  orderId,
  scheduledDate,
  sourceIp,
  userName
}: AntecipateOrderProps) => {
  if (!orderId || !scheduledDate) return

  const response = await SchedulesService.antecipateOrder({
    orderId,
    scheduledDate,
    userName,
    sourceIp
  })

  return response.data
}

const cancelOrder = async ({
  orderId,
  occurencyId,
  userName,
  sourceIp
}: CancelOrderProps) => {
  if (!orderId || !occurencyId) return

  const response = await SchedulesService.cancelOrder({
    orderId,
    occurencyId,
    userName,
    sourceIp
  })

  return response.data
}
