import { useMutationCheckProfile } from 'hooks/queries/profileQueries'
import type { User, UserRoles } from 'models/User'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthUtils } from 'utils/authUtils'

export type ProfileContextType = {
  user?: User
  userLevel?: UserRoles

  isLogged?: boolean
  setIsLogged: (isLogged: boolean) => void

  isAttendant: boolean
  isProfileAdmin: boolean
  isBackoffice4c: boolean
  isFraudAndPrevention: boolean
  isOperation3c: boolean
  isAttendant3c: boolean
  isAttendantB2M: boolean
  isManageTransactions: boolean
  isPayments: boolean
  isSupervisorB2b: boolean

  isLoadingProfile: boolean
  isSuccessProfile: boolean
  isErrorProfile: boolean

  checkProfile: ({ token }: { token: string }) => Promise<void>
}

export const Context = createContext<ProfileContextType>(
  {} as ProfileContextType
)

type ProfileProviderProps = {
  children: React.ReactNode
}

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const navigate = useNavigate()

  const [user, setUser] = useState<User>()
  const [userLevel, setUserLevel] = useState<UserRoles>()
  const [isLogged, setIsLogged] = useState<boolean>()

  const [isAttendant, setIsAttendant] = useState(false)
  const [isProfileAdmin, setIsProfileAdmin] = useState(false)
  const [isBackoffice4c, setIsBackoffice4c] = useState(false)
  const [isFraudAndPrevention, setIsFraudAndPrevention] = useState(false)
  const [isOperation3c, setIsOperation3c] = useState(false)
  const [isAttendant3c, setIsAttendant3c] = useState(false)
  const [isAttendantB2M, setIsAttendantB2M] = useState(false)
  const [isManageTransactions, setIsManageTransactions] = useState(false)
  const [isPayments, setIsPayments] = useState(false)
  const [isSupervisorB2b, setIsSupervisorB2b] = useState(false)

  const mutateVerifyProfile = useMutationCheckProfile()
  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    isSuccess: isSuccessProfile,
    error: errorProfile
  } = mutateVerifyProfile

  const checkProfile = async ({ token }: { token: string }) => {
    if (!token) return
    return await mutateVerifyProfile.mutateAsync({ token })
  }

  useEffect(() => {
    const token = AuthUtils().idToken
    if (token) checkProfile({ token })
  }, [])

  useEffect(() => {
    if (isSuccessProfile && profileData) {
      setIsLogged(true)
      setUser(profileData)
      setUserLevel(profileData.role?.name)

      const userRole = profileData.role?.name

      setIsLogged(!!profileData)
      setIsPayments(userRole === 'Payments')
      setIsAttendant(userRole === 'Atendimento')
      setIsOperation3c(userRole === 'CSC Operações-3C')
      setIsAttendant3c(userRole === 'Atendimento-3C')
      setIsBackoffice4c(userRole === 'Backoffice 4C')
      setIsAttendantB2M(userRole === 'Atendimento B2M')
      setIsProfileAdmin(userRole === 'Administrador de Perfis')
      setIsFraudAndPrevention(userRole === 'Prevenção a Fraude')
      setIsManageTransactions(userRole === 'Operações Gestão de Transações-3C')
      setIsSupervisorB2b(userRole === 'Supervisor B2B')
    }

    if (isErrorProfile) {
      setIsLogged(false)
      setUser(undefined)
      setUserLevel(undefined)

      setIsAttendant(false)
      setIsOperation3c(false)
      setIsAttendant3c(false)
      setIsBackoffice4c(false)
      setIsAttendantB2M(false)
      setIsProfileAdmin(false)
      setIsFraudAndPrevention(false)
      setIsManageTransactions(false)
    }
  }, [profileData, isErrorProfile, isSuccessProfile])

  useEffect(() => {
    if (isErrorProfile && errorProfile) {
      const originalError = errorProfile as any

      const formattedErrStatus = originalError?.response?.data?.statusCode
      const formattedErrMsg = originalError?.response?.data?.message

      if (formattedErrStatus === 403) return navigate('/vpn-off')

      if (formattedErrMsg === 'login not exist') {
        return navigate('/login-not-found')
      }

      return navigate('/not-allowed')
    }
  }, [isErrorProfile, errorProfile])

  return (
    <Context.Provider
      value={{
        user,
        checkProfile,
        setIsLogged,
        isLogged,
        isSuccessProfile,
        isErrorProfile,
        isLoadingProfile,
        isBackoffice4c,
        isFraudAndPrevention,
        isProfileAdmin,
        isAttendant,
        isAttendant3c,
        isAttendantB2M,
        isManageTransactions,
        isOperation3c,
        isPayments,
        isSupervisorB2b,
        userLevel
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useProfile = () => useContext(Context)
