import { Flex, Text } from '@chakra-ui/react'
import { Tooltip } from 'components/ui'
import React, { PropsWithChildren } from 'react'

export type StatusInfoProps = {
  walletCatalogStatus: string
  customerWalletStatus: string
}

export const StatusInfo = ({
  walletCatalogStatus,
  customerWalletStatus
}: StatusInfoProps) => {
  const isActive =
    walletCatalogStatus === 'ACTIVE' && customerWalletStatus === 'ACTIVE'
  const isInactive = walletCatalogStatus === 'INACTIVE'

  if (isInactive)
    return (
      <Wrapper>
        <Label
          title="Inativa pela Pluxee"
          tooltipLabel="A carteira foi inativada pela Pluxee. Ações do Cliente ou Colaborador não são possíveis."
        />
      </Wrapper>
    )

  return (
    <Wrapper>
      <Text>Ativa pela Pluxee</Text>

      {isActive ? (
        <Label title="Ligada pelo Cliente" />
      ) : (
        <Label
          title="Desligada pelo Cliente"
          tooltipLabel={`Somente o Cliente poderá ligar esta carteira. Neste status a carteira permite transferências (somente envio) para outra carteira com status ”Ativa” ou ”Pausada". Transações de compra e saque podem ser realizadas até finalizar o saldo da carteira. A carteira com este status (sem saldo) não aparece para o Colaborador  no APP ou Portal.`}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = ({ children }: PropsWithChildren) => (
  <Flex
    flexDir="column"
    alignItems="flex-end"
    fontSize="12px"
    fontWeight="500"
    color="#221C46"
    letterSpacing="-0.7px"
  >
    {children}
  </Flex>
)

const Label = ({
  title,
  tooltipLabel
}: {
  tooltipLabel?: string
  title: string
}) => (
  <Flex gap="4px">
    {!!tooltipLabel && <Tooltip iconSize="xs" label={tooltipLabel} />}
    <Text>{title}</Text>
  </Flex>
)
