import { Flex } from '@chakra-ui/react'
import { Container, Layout } from 'components/ui'
import React, { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

import { AccountDetails } from './accountDetails/accountDetails'
import { ConsumerWallets } from './consumerWallets/consumerWallets'
import { Transactions } from './transactions/transactions'

const ConsumerDetailsPluxee = () => {
  const { state } = useLocation()
  const pageBackTitle = state?.isFromTransactionPage
    ? 'Voltar para transação'
    : 'Voltar'

  return (
    <Layout isShowBackButton pageBackTitle={pageBackTitle}>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default ConsumerDetailsPluxee

const Main = () => (
  <Wrapper>
    <AccountDetails />
    <ConsumerWallets />
    <Transactions />
  </Wrapper>
)

const Wrapper = ({ children }: PropsWithChildren) => (
  <Flex
    flexDir="column"
    bg="standard.white"
    w="100%"
    p="40px"
    gap="32px"
    mb="40px"
  >
    {children}
  </Flex>
)
