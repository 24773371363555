import { Center, Spinner } from '@chakra-ui/react'
import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Modal, TextArea } from 'components/ui'
import { UncontrolledInput } from 'components/ui/Input'
import { useAccountWallet } from 'contexts/accountWalletContext'
import {
  CreditInTrustTypes,
  useWalletTransaction
} from 'contexts/walletTransactionContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ErrorMessage, formatErrorMessage } from 'utils/errorUtils'
import { goToSection } from 'utils/windowUtils'

import { BlockedAction } from '../modals/blockedAction'

type ProvisionalCreditProps = {
  isOpen: boolean
  onClose: () => void
  transaction: WalletTransaction
}

export const ProvisionalCredit = ({
  isOpen,
  onClose,
  transaction
}: ProvisionalCreditProps) => {
  const [formattedValue, setFormattedValue] = useState('0')
  const { alert } = useAlert()
  const { refreshWalletList } = useAccountWallet()

  const isValidValue =
    Number(formattedValue) > Number(transaction.value) ||
    Number(formattedValue) === 0

  const [isValidOcorrenceCode, setIsValidOcorrenceCode] = useState(false)
  const [isValidReason, setIsValidReason] = useState(false)

  const isValidFields = isValidOcorrenceCode && isValidReason && !isValidValue
  const maxTextAreaLenght = 300

  const {
    addProvisionalCredit,
    isLoadingProvisionalCredit,
    isSuccessProvisionalCredit,
    getAllTransactions,
    isLoadingGetRefundableHistory,
    refundableHistory
  } = useWalletTransaction()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreditInTrustTypes>()

  const onSubmit: SubmitHandler<CreditInTrustTypes> = async formData => {
    if (!formData || !transaction) {
      return alert({
        id: 'creditInTrustModal',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'warning'
      })
    }

    if (Number(formattedValue) > Number(transaction.value)) {
      return alert({
        status: 'warning',
        id: 'creditInTrust',
        title:
          'O valor do crédito não pode ser maior que o valor da própria transação.'
      })
    }

    addProvisionalCredit({
      value: String(refundableHistory?.refundableValue),
      uuid: transaction.transactionUUID,
      authorization_code: transaction.authorizationCode,
      occorence_code: formData.occorence_code,
      reason: formData.reason
    })
      .then(() => {
        refreshWalletList()
        getAllTransactions({ page: 0 })
        goToSection('consumerWallets-section')

        alert({
          id: 'provisionalCreditToast',
          title: 'Crédito provisório realizado com sucesso!',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)

        if (
          !err ||
          !err.response ||
          !err.response.data ||
          !err.response.data.code
        ) {
          return alert({
            id: 'creditProvisionalModal',
            title: 'Ocorreu um erro, tente novamente!',
            status: 'warning'
          })
        }

        const errorMessage = err.response.data.code
        const formattedErrorMessage = formatErrorMessage(
          errorMessage as ErrorMessage
        )

        return alert({
          id: 'creditProvisionalModal',
          status: 'error',
          title: formattedErrorMessage
        })
      })
  }

  useEffect(() => {
    if (isSuccessProvisionalCredit) {
      if (transaction) setFormattedValue(String(transaction.value))

      reset()
      onClose()
    }
  }, [isSuccessProvisionalCredit])

  useEffect(() => {
    if (transaction) setFormattedValue(String(transaction.value))
  }, [transaction])

  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          onClose()
          reset()
        }}
      />

      <SubmitButton
        title="Confirmar"
        isDisabled={!isValidFields}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingProvisionalCredit}
      />
    </Flex>
  )

  if (
    refundableHistory?.hasProvisionalCreditHistory &&
    Number(refundableHistory?.refundableValue) <= 0
  ) {
    return (
      <BlockedAction
        isOpen={isOpen}
        onClose={onClose}
        transaction={transaction}
      />
    )
  }

  const isTotalRefunded =
    !refundableHistory?.hasProvisionalCreditHistory &&
    Number(refundableHistory?.refundableValue) <= 0

  const valueOfRefundableAreEqualThanOriginal =
    Number(transaction.value) === Number(refundableHistory?.refundableValue)

  if (isTotalRefunded)
    return (
      <BlockedAction
        isOpen={isOpen}
        onClose={onClose}
        transaction={transaction}
        description="Não será possível realizar um crédito provisório nesta transação, pois um estorno total foi aplicado anteriormente. Para mais informações acesse os detalhes da transação."
      />
    )

  return (
    <Modal
      heading={'Crédito provisório'}
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      decreaseMarginTop
    >
      {isLoadingGetRefundableHistory ? (
        <LoadingModal />
      ) : (
        <VStack spacing="xxs">
          <Flex
            flexDir="column"
            gap={!valueOfRefundableAreEqualThanOriginal ? '8px' : '0px'}
          >
            {!valueOfRefundableAreEqualThanOriginal && (
              <Text
                color="#221C46"
                fontSize="20px"
                fontWeight="700"
                letterSpacing="-0.8px"
              >
                Estorno parcial aplicado anteriormente
              </Text>
            )}

            <Text
              color="#221C46"
              fontSize="16px"
              fontWeight="500"
              letterSpacing="-0.8px"
            >
              {!valueOfRefundableAreEqualThanOriginal
                ? translate('commons.provisionalCreditHasRefundableValue')
                : translate('commons.trustCreditTransaction')}
            </Text>
          </Flex>

          <WarningMessage />

          <Flex w="100%" flexDir="column" gap="4px">
            <RefundableValue
              value={Mask.moneyInput(
                String(refundableHistory?.refundableValue)
              )}
            />

            {Number(formattedValue) > Number(transaction.value) && (
              <Text color="#B30000" fontWeight="600" fontSize="12px">
                O valor do estorno não pode ser maior que o valor da própria
                transação
              </Text>
            )}
          </Flex>

          <UncontrolledInput
            id="occorence_code"
            height="40px"
            title={translate('commons.occurrenceCode')}
            isInvalid={!!errors.occorence_code}
            isRequired
            requiredMessage="Código da ocorrência é obrigatório"
            placeholder="Informe o código da ocorrência"
            control={control}
            onChange={event => {
              const inputValue = event.currentTarget.value
              if (inputValue.length >= 1) setIsValidOcorrenceCode(true)
              else setIsValidOcorrenceCode(false)
            }}
          />

          <TextArea
            id="reason"
            maxLenght={maxTextAreaLenght}
            title={translate('commons.reason')}
            isInvalid={!!errors.reason}
            placeholder="Informe o motivo do crédito provisório"
            isRequired
            control={control}
            onChange={event => {
              const value = event.target.value

              if (
                Number(value.length) <= maxTextAreaLenght &&
                Number(value.length) >= 1
              ) {
                setIsValidReason(true)
              } else {
                setIsValidReason(false)
              }
            }}
          />
        </VStack>
      )}
    </Modal>
  )
}

const WarningMessage = () => (
  <Flex
    bg="#FBF2EC"
    h="100%"
    p="16px 12px"
    gap="12px"
    alignItems="center"
    mb="24px"
  >
    <Icon name="warning" color="#221C46" size="xs" />
    <Text
      fontSize="16px"
      color="#874810"
      fontWeight="600"
      letterSpacing="-0.8px"
    >
      Atenção ao preencher as informações. Criar crédito provisório é uma ação
      que não pode ser desfeita.
    </Text>
  </Flex>
)

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      bg="transparent"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700">
        Cancelar
      </Text>
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      bg="#1B51DC"
      color="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles, border: 'none' }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}

const LoadingModal = () => (
  <Center p="20px">
    <Spinner />
  </Center>
)

const RefundableValue = ({ value }: { value: string }) => {
  return (
    <Flex flexDir="column" fontWeight="600" letterSpacing="-0.8px" gap="10px">
      <Text color="#463F5F" fontSize="14px">
        Valor do crédito provisório
      </Text>
      <Text color="#221C46" fontSize="16px">
        {value}
      </Text>
    </Flex>
  )
}
