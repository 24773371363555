import { Center, MenuButton as ChakraMenuButton, Image } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { PluxeeMenu } from 'components/ui'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import { useTransaction } from 'modules/transaction/presentation/contexts/TransactionContext'
import React from 'react'

export const AppetizerOptions = ({
  data,
  setWalletId,
  onOpenDetails,
  onOpenCreditReversal,
  isShowCreditReversal,
  setSelectedTransaction
}: {
  data: WalletTransaction
  setWalletId?: () => void
  isShowCreditReversal: boolean
  setSelectedTransaction: (data: WalletTransaction) => void
  onOpenDetails: () => void
  onOpenCreditReversal: () => void
}) => {
  const { fetchTransactionByUUID } = useTransaction()
  const {
    getRefundableHistory,
    setOriginalTransaction,
    setOriginalTransactionNSU,
    setOriginalTransactionUUID
  } = useWalletTransaction()

  const setAndFetchRequiredData = () => {
    setWalletId && setWalletId()
    setSelectedTransaction(data)

    if (data.status === 'E' && data.type === 'PTX') {
      getRefundableHistory({ transactionUUID: data.transactionUUID! })
    } else if (
      ['PRC', 'CTR', 'PCR'].includes(data.type!) &&
      data.status === 'E'
    ) {
      getRefundableHistory({ transactionUUID: data.originUUID! })

      fetchTransactionByUUID({ uuid: data.originUUID! }).then(
        originalTransaction => {
          setOriginalTransaction(originalTransaction)
          setOriginalTransactionNSU(originalTransaction.nsu)
          setOriginalTransactionUUID(originalTransaction.transactionUUID)
        }
      )
    }
  }

  const handleDetailsOnClick = () => {
    setAndFetchRequiredData()
    onOpenDetails()
  }

  const handleReverseCreditOnClick = () => {
    setAndFetchRequiredData()
    onOpenCreditReversal()
  }

  return (
    <PluxeeMenu
      menuButton={<CustomMenuButton />}
      items={[
        {
          title: translate('commons.seeDetails'),
          onClick: handleDetailsOnClick,
          isDisabled: false,
          icon: <Icon name="search" />
        },
        {
          title: translate('commons.creditReversal'),
          onClick: handleReverseCreditOnClick,
          isDisabled: !isShowCreditReversal,
          icon: (
            <Image
              w="24px"
              h="24px"
              src="/img/pluxee/provisional.svg"
              alt="Icon"
            />
          )
        }
      ]}
    />
  )
}

const CustomMenuButton = () => (
  <ChakraMenuButton
    w="40px"
    h="40px"
    borderRadius="0"
    background="transparent"
    color="#1B51DC"
    _active={{
      border: '2px solid #1B51DC'
    }}
    _focusVisible={{
      border: '3px solid #1B51DC',
      color: '#0F266D'
    }}
    _hover={{
      background: 'transparent',
      color: '#0F266D'
    }}
  >
    <Center>
      <Icon name="more_horiz" color="inherit" />
    </Center>
  </ChakraMenuButton>
)
