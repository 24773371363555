import { Center, Checkbox as ChakraCheckbox, Text } from '@chakra-ui/react'
import { ReactComponent as PluxeeLogo } from 'assets/checkmark.svg'
import React, { KeyboardEvent } from 'react'

export type CheckboxProps = {
  title: string
  checked: boolean
  onChange: () => void
  isRounded?: boolean
  defaultIcon?: boolean
}

const Checkbox = ({
  title,
  checked,
  onChange,
  isRounded = false,
  defaultIcon = false
}: CheckboxProps) => {
  const onKeyPress = (event: KeyboardEvent) => {
    if (event.code === 'Enter') onChange()
  }

  return (
    <ChakraCheckbox
      className="pluxeeCheckbox"
      onChange={onChange}
      onKeyPress={onKeyPress}
      defaultChecked={checked}
      icon={
        checked ? (
          <RenderIcon hasCustomIcon={isRounded ? true : defaultIcon} />
        ) : undefined
      }
      sx={{
        '& .chakra-checkbox__control': { borderRadius: isRounded ? '50%' : '0' }
      }}
    >
      <Text
        fontSize="xxs"
        lineHeight="20.3px"
        fontWeight="semibold"
        color="pluxee.text.primary"
        letterSpacing="-0.6px"
      >
        {title}
      </Text>
    </ChakraCheckbox>
  )
}

export default Checkbox

const RenderIcon = ({ hasCustomIcon }: { hasCustomIcon: boolean }) => {
  if (hasCustomIcon)
    return <Center bg="#1B51DC" borderRadius="50%" w="12px" h="12px" />

  return <PluxeeLogo />
}
