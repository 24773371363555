import { Button, Flex, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { useConsumer } from 'contexts/consumerContext'
import React from 'react'

export const BackToAllTransactionsButton = () => {
  const { setIsShowAllTransactions } = useConsumer()

  return (
    <Flex
      w="fit-content"
      h="32px"
      as={Button}
      border="1px solid red"
      p="8px 16px"
      borderColor="transparent"
      fontWeight="semibold"
      onClick={() => setIsShowAllTransactions(true)}
      gap="8px"
      bg="transparent"
      alignItems="center"
      cursor="pointer"
      borderRadius="0"
      color="pluxee.interactive.primary"
      _hover={{
        boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
        background: '#DEF3FB',
        color: '#221C46'
      }}
      _active={{
        boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
        color: 'standard.white',
        background: 'pluxee.cta.secondaryPressed'
      }}
      _focusVisible={{
        boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
        background: '#DEF3FB',
        color: '#221C46',
        border: '3px solid #1B51DC'
      }}
    >
      <Icon name="arrow_back" size="xs" />
      <Text fontSize="12px" fontWeight="700">
        Voltar para todas as transações da conta
      </Text>
    </Flex>
  )
}
