import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea as ChakraTextarea
} from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

export type TextAreaaProps = {
  id: string
  title: string
  placeholder: string
  maxLenght?: number
  isInvalid?: boolean
  isRequired?: boolean
  errorMessage?: string
  requiredMessage?: string
  control: Control<any>
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea = ({
  id,
  title,
  placeholder,
  maxLenght = 100,
  isInvalid = false,
  isRequired = false,
  errorMessage,
  requiredMessage,
  control,
  onChange
}: TextAreaaProps) => {
  const [textCount, setTextCount] = useState(0)
  const [showTextAreaMsg, setShowTextAreaMsg] = useState(false)
  const [labelColor, setLabelColor] = useState('pluxee.text.primary')
  const [isFocused, setIsFocused] = useState(false)

  const isInvalidInput = showTextAreaMsg || isInvalid

  const handleOnMouseEnter = () => setLabelColor('pluxee.text.link')
  const handleOnMouseLeave = () => {
    !isFocused && setLabelColor('pluxee.text.primary')
  }

  const handleOnBlur = () => {
    setIsFocused(false)
    setLabelColor('pluxee.text.primary')
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setLabelColor('pluxee.text.link')
  }

  return (
    <FormControl id={id} isInvalid={isInvalidInput}>
      <FormLabel
        fontSize="14px"
        fontWeight="600"
        color={isInvalidInput ? 'pluxee.text.primary' : labelColor}
        display="flex"
        gap="4px"
      >
        <Text letterSpacing="-0.8px">{title}</Text>
        {isRequired && <Text color="#CC1480">*</Text>}
      </FormLabel>

      <Flex flexDir="column" w="100%" gap="6px" alignItems="flex-end">
        <Flex w="100%" flexDir="column" gap="4px">
          <Controller
            name={id}
            rules={{
              required: {
                value: isRequired,
                message: requiredMessage ?? ''
              }
            }}
            control={control}
            render={({ field }) => (
              <ChakraTextarea
                placeholder={placeholder}
                {...field}
                onChange={event => {
                  setTextCount(event.target.value?.length)
                  const value = event.target.value

                  if (Number(value.length) > maxLenght) {
                    setShowTextAreaMsg(true)
                  } else setShowTextAreaMsg(false)

                  onChange(event)
                  field.onChange(event.target.value)
                }}
                required={isRequired}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                minH="164px"
                fontWeight="600"
                fontSize="16px"
                border="1px solid"
                borderRadius="none"
                borderColor={isInvalidInput ? '#B30000' : '#D1CFD7'}
                bg={isInvalidInput ? '#FCF1EE' : 'brand.primary-light'}
                color={isInvalidInput ? '#221C46' : 'pluxee.text.primary'}
                _placeholder={{
                  color: 'pluxee.icon.secondary'
                }}
                _focusVisible={{
                  bg: 'white',
                  border: '3px solid #1B51DC',
                  color: '#221C46',
                  _placeholder: {
                    color: 'pluxee.text.primary'
                  }
                }}
                _hover={{
                  color: !isInvalidInput && '#221C46',
                  border: !isInvalidInput && '1px solid',
                  borderColor: !isInvalidInput && 'pluxee.interactive.primary',
                  background: !isInvalidInput && '#DEF3FB',
                  _placeholder: {
                    color: !isInvalidInput && '#1B51DC',
                    letterSpacing: '-0.8px'
                  }
                }}
                letterSpacing="-0.8px"
              />
            )}
          />

          <Flex
            alignItems="center"
            justifyContent={showTextAreaMsg ? 'space-between' : 'flex-end'}
          >
            {showTextAreaMsg && (
              <Text color="#B30000" fontWeight="600" fontSize="12px">
                O motivo não pode ultrapassar {maxLenght} caracteres
              </Text>
            )}

            <Text color="#5A5469" fontSize="12px" fontWeight="600">
              {textCount}/{maxLenght}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {!!errorMessage && (
        <FormErrorMessage fontSize="smaller">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default TextArea
