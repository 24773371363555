const errorMessages = {
  'login already exist': 'Login de usuário já cadastrado no sistema.',
  'Card Not Unblock reason not allow':
    'Desbloqueio só pode ocorrer pelo Beneficiario via APP.',
  'This Product is not allowed to be manipulated by Backoffice':
    'Este produto não pode ser manipulado pelo BackOffice.'
} as const

export type ErrorMessage = keyof typeof errorMessages

export const formatErrorMessage = (errMessage: ErrorMessage) => {
  return errorMessages[errMessage] ?? errMessage
}
