import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  type InputProps
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { add } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { KeyboardEvent } from 'react'
import DatePicker from 'react-datepicker'

import { SubmitButtonProps } from './SubmitButton'

import './theme/react-datepicker.css'

export type DateRange = [Date | null, Date | null]

export type CalendarProps = {
  startDate: Date | null
  endDate: Date | null
  minDate?: Date
  maxDate?: Date
  setDateRange: (value: DateRange) => void
  onSelectDate?: (value: any) => void
  inputValue: string | undefined
  onChangeDate: () => void
  onClear: () => void
  closeOnClick?: boolean
  calendarWidth?: string
  oneDateStyle?: boolean
} & InputProps

const Calendar = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  setDateRange,
  onSelectDate,
  inputValue,
  onChangeDate,
  onClear,
  calendarWidth = '280px',
  oneDateStyle = false,
  ...rest
}: CalendarProps) => {
  const calendarDiv = document.querySelector(
    '.react-datepicker__tab-loop'
  ) as HTMLDivElement

  if (calendarDiv) calendarDiv.style.background = 'transparent'

  const inputDiv = document.querySelector(
    '.chakra-input__group'
  ) as HTMLDivElement

  const nextYearByStartDate = startDate
    ? add(startDate, { years: 1 })
    : add(new Date(), { years: 1 })

  const removeTodayDateClass = () => {
    const todayDate = document.querySelector(
      '.react-datepicker__day--today'
    ) as HTMLInputElement

    if (todayDate)
      todayDate.classList.remove('react-datepicker__day--keyboard-selected')
  }

  const handleKeyUpCapture = (event: KeyboardEvent<HTMLInputElement>) => {
    const isTabKey = event.code === 'Tab'
    const isEnterKey = event.code === 'Enter'

    const datepickerPopper = document.querySelector(
      '.react-datepicker-popper'
    ) as HTMLDivElement

    if (isTabKey && datepickerPopper) datepickerPopper.style.display = 'none'
    if (isEnterKey) datepickerPopper.style.display = 'block'
  }

  const handleOnChange = (newDates: [Date | null, Date | null]) =>
    setDateRange(newDates)

  const handleOnClick = () => {
    calendarDiv.style.display = 'none'
    onChangeDate()

    setTimeout(() => {
      inputDiv.classList.remove('react-datepicker-ignore-onclickoutside')
    }, 100)
  }

  return (
    <DatePicker
      locale={ptBR}
      maxDate={maxDate ?? nextYearByStartDate}
      minDate={minDate}
      wrapperClassName="datePicker"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={date => {
        handleOnChange(date)
        onSelectDate && onSelectDate(date)
      }}
      shouldCloseOnSelect={false}
      onCalendarOpen={removeTodayDateClass}
      customInput={
        <InputGroup
          position="relative"
          _before={
            endDate && !oneDateStyle
              ? {
                  content: '""',
                  position: 'absolute',
                  w: '16px',
                  h: '2px',
                  top: inputValue ? '47%' : '50%',
                  left: inputValue ? '155px' : '120px',
                  background: '#221C46'
                }
              : {}
          }
        >
          <InputLeftElement h="100%">{CalendarSvg}</InputLeftElement>

          <Input
            readOnly
            className="customInputCalendar"
            value={inputValue}
            placeholder={
              oneDateStyle
                ? '- -/- -/- - - -'
                : '- -/- -/- - - -                - -/- -/- - - -'
            }
            w={calendarWidth}
            h="32px"
            position="unset"
            fontWeight="600"
            fontSize="16px"
            border="1px solid"
            borderRadius="none"
            borderColor={'#D1CFD7'}
            bg={'brand.primary-light'}
            letterSpacing="-0.8px"
            color={'#221C46'}
            onKeyUp={handleKeyUpCapture}
            _placeholder={{
              color: 'pluxee.icon.secondary',
              fontSize: '16px'
            }}
            _focusVisible={{
              userSelect: 'none',
              border: '3px solid #1B51DC',
              _placeholder: {
                color: '#526CF8',
                userSelect: 'none'
              }
            }}
            _hover={{
              border: '1px solid',
              borderColor: '#526CF8',
              background: '#DEF3FB',
              _placeholder: {
                color: '#526CF8'
              }
            }}
            {...rest}
          />

          {inputValue && (
            <InputRightElement
              h="100%"
              cursor="pointer"
              onClick={onClear}
              data-testid="closeBtn"
            >
              <Icon name="close" color="#1B51DC" />
            </InputRightElement>
          )}
        </InputGroup>
      }
    >
      <Flex w="100%">
        <ApplyButton isDisabled={!startDate} onClick={handleOnClick} />
      </Flex>
    </DatePicker>
  )
}

export default Calendar

type ApplyButtonProps = Pick<SubmitButtonProps, 'isDisabled' | 'onClick'>

const ApplyButton = ({ onClick, isDisabled = false }: ApplyButtonProps) => (
  <Button
    w="75px"
    h="32px"
    gap="12px"
    border="3px solid"
    borderRadius="none"
    bg={'transparent'}
    borderColor={isDisabled ? '#B5B2BC' : '#1B51DC'}
    color={isDisabled ? '#B5B2BC' : '#1B51DC'}
    onClick={!isDisabled ? onClick : () => false}
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    _hover={{
      bg: !isDisabled && '#DEF3FB',
      color: !isDisabled && 'pluxee.interactive.primary',
      boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important'
    }}
    _active={{
      background: !isDisabled && 'pluxee.cta.secondaryPressed',
      color: !isDisabled && 'standard.white',
      boxShadow: !isDisabled && '2px 2px 0px 0px #A9A7B6 !important',
      borderColor: !isDisabled && 'pluxee.cta.secondaryPressed'
    }}
    _focusVisible={{
      bg: !isDisabled && '#DEF3FB',
      outline: !isDisabled && '3px solid #1B51DC'
    }}
  >
    <Text fontWeight="700" fontSize="12px" letterSpacing="-0.6px">
      Aplicar
    </Text>
  </Button>
)

const CalendarSvg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3335 1.33337V2.66671H4.66683V1.33337H3.3335V2.66671H2.66683H1.3335V4.00004V5.33337V6.66671V13.2381V14.5715H2.66683H13.3335H14.6668V13.2381V6.66671V5.33337V4.00004V2.66671H13.3335H12.6668V1.33337H11.3335ZM13.3335 5.33337H2.66683V4.00004H3.3335H4.66683H11.3335H12.6668H13.3335V5.33337ZM2.66683 6.66671H13.3335V13.2381H2.66683V6.66671Z"
      fill="#908C99"
    />
  </svg>
)
