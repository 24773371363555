export const AuthUtils = () => {
  const tokens = window.localStorage.getItem('okta-token-storage')
  const parsedTokens = JSON.parse(tokens!)

  return {
    idToken: parsedTokens?.idToken?.idToken,
    accessToken: parsedTokens?.accessToken?.accessToken,
    refreshToken: parsedTokens?.refreshToken?.refreshToken
  }
}
