import { Icon, IconButton, HStack } from 'components/atoms'
import React from 'react'

type PaginatorSize = 'sm' | 'md' | 'lg'

type PaginationProps = {
  currentPage: number
  lastPage: number
  offsetSize?: number
  size?: PaginatorSize
  onChangePage: (page: number) => void
  handlePrevPage: () => void
  handleNextPage: () => void
}

const Pagination = ({
  currentPage,
  lastPage,
  offsetSize = 3,
  size = 'md',
  onChangePage,
  handlePrevPage,
  handleNextPage
}: PaginationProps) => {
  const commonProps = {
    variant: 'default-outline',
    size
  }

  const elementsToRender: React.ReactNode[] = []

  const handleChangePage = (page: number) => {
    if (page < 0) {
      onChangePage(0)
      return
    }
    onChangePage(page)
  }

  const isDisabledPrevPage = !(currentPage - 1 >= 0)

  const prevPage = currentPage - 1
  elementsToRender.push(
    <IconButton
      key={`prev-page-${prevPage}`}
      isDisabled={isDisabledPrevPage}
      onClick={handlePrevPage}
      aria-label="Página anterior"
      {...commonProps}
      bg="white"
      borderRadius="none"
      borderColor="#908C99"
      color="#1B51DC"
      _hover={{ bg: '#DEF3FB' }}
      _disabled={{
        bg: '#FFFFFF',
        color: '#B5B2BC',
        borderColor: '#B5B2BC',
        cursor: 'not-allowed'
      }}
      _focusVisible={isDisabledPrevPage ? {} : {}}
      icon={<Icon name="chevron_left" color="inherit" />}
    />
  )

  const currentPageID = currentPage + 1
  const buttonsOnScreen = offsetSize + 2
  const hasButtonMore =
    lastPage > buttonsOnScreen + 1 && lastPage > 5 && offsetSize > 0

  let leftHideNumbers = [0]
  let rightHideNumbers = [0]

  for (let index = 0; index < lastPage; index++) {
    const indexID = index + 1
    const hasLeftMoreButton =
      currentPageID - offsetSize > 1 && currentPageID > 3
    const extraIndex = offsetSize % 2 === 0 ? 1 : 0
    const keyValue = Math.trunc(offsetSize / 2) + (offsetSize % 2)
    const lastOffsetRealIndex = currentPageID + keyValue
    const hasRightMoreButton = lastPage - lastOffsetRealIndex >= 2 + extraIndex

    const leftHideRange = hasRightMoreButton
      ? currentPageID - keyValue - 1
      : lastPage - (2 + offsetSize + (offsetSize === 1 ? offsetSize : 0))

    if (hasButtonMore && hasLeftMoreButton) {
      leftHideNumbers = Array.from({ length: leftHideRange }, (_, i) => i + 2)
    }

    const indexFrom = offsetSize === 1 ? 3 : 2
    const hideFrom = hasLeftMoreButton
      ? lastOffsetRealIndex + extraIndex
      : offsetSize + indexFrom

    const hiddenRight = hasLeftMoreButton
      ? lastOffsetRealIndex
      : indexFrom + offsetSize

    if (hasButtonMore && hasRightMoreButton) {
      rightHideNumbers = Array.from(
        { length: lastPage - hiddenRight },
        (_, i) => i + hideFrom
      )
    }

    if (hasButtonMore) {
      if (indexID === 2 && hasLeftMoreButton) {
        elementsToRender.push(
          <IconButton
            key={'show-befores-pages'}
            icon={<Icon name="more_horiz" color="#1B51DC" />}
            onClick={() => handleChangePage(currentPage - offsetSize)}
            aria-label="Mostrar páginas anteriores"
            {...commonProps}
            bg="transparent"
            borderRadius="none"
            borderColor="#908c99"
            _hover={{ bg: '#DEF3FB' }}
            _focusVisible={{ outline: '3px solid #1B51DC', bg: '#DEF3FB' }}
          />
        )
      }

      if (indexID === lastPage - 1 && hasRightMoreButton) {
        elementsToRender.push(
          <IconButton
            key={'show-after-pages'}
            icon={<Icon name="more_horiz" color="#1B51DC" size="sm" />}
            onClick={() => handleChangePage(currentPage + offsetSize)}
            aria-label="Mostrar proximas páginas"
            {...commonProps}
            bg="transparent"
            borderRadius="none"
            borderColor="#908c99"
            _hover={{ bg: '#DEF3FB' }}
            _focusVisible={{ outline: '3px solid #1B51DC', bg: '#DEF3FB' }}
          />
        )
      }
    }

    const hiddenNumbers = [...leftHideNumbers, ...rightHideNumbers]

    if (
      !hiddenNumbers.includes(indexID) ||
      [currentPageID, lastPage].includes(indexID)
    ) {
      const isCurrentPage = index === currentPage

      elementsToRender.push(
        <IconButton
          key={`page-${indexID}`}
          data-testid={`button-page-${indexID}`}
          onClick={() => handleChangePage(index)}
          icon={<>{indexID}</>}
          aria-label={`Página ${indexID}`}
          color={isCurrentPage ? 'standard.white' : '#221C46'}
          {...commonProps}
          bgColor={isCurrentPage ? '#0F266D' : undefined}
          borderRadius="none"
          borderColor={isCurrentPage ? '#0F266D' : '#908C99'}
          _hover={{
            bg: '#DEF3FB',
            color: '#221C46'
          }}
          _focusVisible={{
            outline: '3px solid #1B51DC',
            bg: '#DEF3FB',
            color: isCurrentPage ? '#221C46' : 'inherit'
          }}
        />
      )
    }
  }

  const nextPage = currentPage + 1
  elementsToRender.push(
    <IconButton
      key={`next-page-${nextPage}`}
      isDisabled={nextPage === lastPage}
      onClick={handleNextPage}
      aria-label="Proxima página"
      {...commonProps}
      bg="white"
      borderRadius="none"
      borderColor="#908C99"
      color="#1B51DC"
      icon={<Icon name="chevron_right" color="inherit" />}
      _hover={{ bg: '#DEF3FB' }}
      _focusVisible={{ outline: '3px solid #1B51DC', bg: '#DEF3FB' }}
    />
  )

  return <HStack spacing="nano">{elementsToRender}</HStack>
}

export default Pagination
