import {
  useQueryGetEvents,
  useQueryGetEventsByFilter
} from 'hooks/queries/accountQueries'
import { AccountEvents } from 'models/AccountEvents'
import { Pagination } from 'models/Pagination'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

export type ContextType = {
  events: AccountEvents[]

  isLoading: boolean
  isLoadingByFilters: boolean

  eventsPagination?: Pagination

  getEventsByFilter: ({
    dateStart,
    dateEnd
  }: {
    dateStart: string
    dateEnd: string
  }) => void
}

export const Context = createContext({} as ContextType)

type AccountEventsProviderProps = {
  accountId?: number
  children: ReactNode
}

export const AccountEventsProvider = ({
  children,
  accountId
}: AccountEventsProviderProps) => {
  const [events, setEvents] = useState<AccountEvents[]>([])
  const [eventsPagination, setEventsPagination] = useState<Pagination>()

  const queryAccount = useQueryGetEvents(accountId)
  const isLoading = queryAccount.isLoading

  const mutateGetWithFilters = useQueryGetEventsByFilter()
  const { isLoading: isLoadingByFilters, isSuccess: isSuccessByFilter } =
    mutateGetWithFilters

  async function getEventsByFilter({
    dateStart,
    dateEnd
  }: {
    dateStart: string
    dateEnd: string
  }) {
    return await mutateGetWithFilters.mutateAsync({
      accountId: String(accountId),
      dtStart: dateStart,
      dtEnd: dateEnd
    })
  }

  useEffect(() => {
    if (accountId && queryAccount.data?.events) {
      const pagination = queryAccount.data?.page

      setEvents(queryAccount.data?.events)
      setEventsPagination({
        currentPage: pagination?.page?.currentPage,
        totalPages: pagination?.page?.totalPages,
        totalResults: pagination?.page?.totalItems
      })
    }
  }, [accountId, queryAccount.data?.events])

  useEffect(() => {
    const data = mutateGetWithFilters?.data?.events
    const dataPagination = mutateGetWithFilters?.data?.page

    if (accountId && data) {
      setEvents(data)
      setEventsPagination({
        currentPage: dataPagination?.currentPage,
        totalPages: dataPagination?.totalPages,
        totalResults: dataPagination?.totalItems
      })
    }
  }, [accountId, mutateGetWithFilters?.data?.events])

  useEffect(() => {
    if (!mutateGetWithFilters.data && isSuccessByFilter) setEvents([])
  }, [mutateGetWithFilters.data, isSuccessByFilter])

  return (
    <Context.Provider
      value={{
        events,
        isLoadingByFilters,
        isLoading,
        eventsPagination,
        getEventsByFilter
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAccountEvents = () => {
  const context = useContext(Context)

  return context
}
