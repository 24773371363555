import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  Text
} from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'

export type ControlledInputProps = {
  id: string
  title: string
  height?: string
  isDisabled?: boolean
  placeholder: string
  value: string | number | undefined
  isInvalid?: boolean
  isRequired?: boolean
  errorMessage?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  register: UseFormRegister<any>
  bottomContent?: React.ReactNode
}

const ControlledInput = ({
  id,
  title,
  value,
  placeholder,
  isInvalid = false,
  isRequired = false,
  height = 'auto',
  isDisabled = false,
  errorMessage,
  onChange,
  register,
  bottomContent
}: ControlledInputProps) => {
  const [labelColor, setLabelColor] = useState('pluxee.text.primary')
  const [isFocused, setIsFocused] = useState(false)

  const handleOnMouseEnter = () => setLabelColor('pluxee.text.link')
  const handleOnMouseLeave = () => {
    !isFocused && setLabelColor('pluxee.text.primary')
  }

  const handleOnBlur = () => {
    setIsFocused(false)
    setLabelColor('pluxee.text.primary')
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setLabelColor('pluxee.text.link')
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return
    onChange(event)
  }

  const isNotAbleToHover = isInvalid || isDisabled

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      <FormLabel
        fontSize="14px"
        fontWeight="600"
        color={isInvalid ? 'pluxee.text.primary' : labelColor}
        display="flex"
        gap="4px"
      >
        <Text letterSpacing="-0.7px">{title}</Text>
        {isRequired && <Text color="#CC1480">*</Text>}
      </FormLabel>

      <ChakraInput
        value={value}
        placeholder={placeholder}
        {...register(id)}
        isDisabled={isDisabled}
        onChange={handleOnChange}
        required={isRequired}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        fontWeight="600"
        fontSize="16px"
        border="1px solid"
        borderRadius="none"
        letterSpacing="-0.8px"
        height={height}
        borderColor={isInvalid ? '#B30000' : '#D1CFD7'}
        bg={isInvalid ? '#FCF1EE' : 'brand.primary-light'}
        color={'pluxee.text.primary'}
        _placeholder={{
          color: 'pluxee.icon.secondary',
          letterSpacing: '-0.8px'
        }}
        _focusVisible={{
          border: '3px solid #1B51DC',
          _placeholder: {
            color: '#1B51DC'
          }
        }}
        _hover={{
          color: !isNotAbleToHover && '#221C46',
          border: !isNotAbleToHover && '1px solid',
          borderColor: !isNotAbleToHover && 'pluxee.interactive.primary',
          background: !isNotAbleToHover && '#DEF3FB',
          _placeholder: {
            color: !isNotAbleToHover && '#1B51DC'
          }
        }}
      />

      {bottomContent && bottomContent}

      {!!errorMessage && (
        <FormErrorMessage fontSize="smaller">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  )
}

type UncontrolledInputProps = {
  requiredMessage?: string
  control: Control<any>
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
} & Omit<ControlledInputProps, 'register' | 'value' | 'onChange'>

const UncontrolledInput = ({
  id,
  title,
  placeholder,
  isInvalid = false,
  isRequired = false,
  height = 'auto',
  isDisabled = false,
  errorMessage,
  requiredMessage,
  control,
  onChange
}: UncontrolledInputProps) => {
  const [labelColor, setLabelColor] = useState('pluxee.text.primary')
  const [isFocused, setIsFocused] = useState(false)

  const handleOnMouseEnter = () => setLabelColor('pluxee.text.link')
  const handleOnMouseLeave = () => {
    !isFocused && setLabelColor('pluxee.text.primary')
  }

  const handleOnBlur = () => {
    setIsFocused(false)
    setLabelColor('pluxee.text.primary')
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setLabelColor('pluxee.text.link')
  }

  const isNotAbleToHover = isInvalid || isDisabled

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      <FormLabel
        fontSize="14px"
        fontWeight="600"
        color={isInvalid ? 'pluxee.text.primary' : labelColor}
        display="flex"
        gap="4px"
      >
        <Text letterSpacing="-0.7px">{title}</Text>
        {isRequired && <Text color="#CC1480">*</Text>}
      </FormLabel>

      <Controller
        name={id}
        rules={{
          required: {
            value: isRequired,
            message: requiredMessage ?? ''
          }
        }}
        control={control}
        render={({ field }) => (
          <ChakraInput
            placeholder={placeholder}
            {...field}
            onChange={event => {
              onChange(event)
              field.onChange(event.currentTarget.value)
            }}
            isDisabled={isDisabled}
            required={isRequired}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            fontWeight="600"
            fontSize="16px"
            border="1px solid"
            borderRadius="none"
            letterSpacing="-0.8px"
            height={height}
            borderColor={isInvalid ? '#B30000' : '#D1CFD7'}
            bg={isInvalid ? '#FCF1EE' : 'brand.primary-light'}
            color={'pluxee.text.primary'}
            _placeholder={{
              letterSpacing: '-0.8px',
              color: 'pluxee.icon.secondary'
            }}
            _focusVisible={{
              border: '3px solid #1B51DC',
              _placeholder: {
                color: '#1B51DC'
              }
            }}
            _hover={{
              color: !isNotAbleToHover && '#221C46',
              border: !isNotAbleToHover && '1px solid',
              borderColor: !isNotAbleToHover && 'pluxee.interactive.primary',
              background: !isNotAbleToHover && '#DEF3FB',
              _placeholder: {
                color: !isNotAbleToHover && '#1B51DC'
              }
            }}
          />
        )}
      />

      {!!errorMessage && (
        <Flex
          mt="4px"
          color="#B30000"
          fontWeight="600"
          fontSize="12px"
          letterSpacing="-0.7px"
        >
          {errorMessage}
        </Flex>
      )}
    </FormControl>
  )
}

export { ControlledInput, UncontrolledInput }
