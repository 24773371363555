import { Flex, Text } from 'components/atoms'
import { useCustomer } from 'contexts/customerContext'
import { useAlert } from 'hooks/useAlert'
import React, { useEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { useNavigate } from 'react-router-dom'

import CustomerInfo from './customerInfo'

export const CustomerProfile = () => {
  const { selectedCustomer } = useCustomer()
  const { alert } = useAlert()
  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedCustomer?.id) {
      alert({
        title: 'Cliente não encontrado!',
        status: 'error',
        id: 'clientNotFoundToast'
      })

      navigate('/customer')
    }
  }, [selectedCustomer])

  return (
    <Flex flexDir="column">
      <Text
        fontSize="md"
        fontWeight="700"
        color="pluxee.text.primary"
        mb="32px"
        letterSpacing="-0.8px"
      >
        Cliente #{selectedCustomer?.id}
      </Text>

      {selectedCustomer ? (
        <CustomerInfo
          tradeName={selectedCustomer.tradeName}
          document={selectedCustomer.document}
          products={selectedCustomer.products}
          createDate={selectedCustomer.createDate}
          name={selectedCustomer.tradeName}
          id={selectedCustomer.id}
        />
      ) : (
        <SkeletonCustomerProfile />
      )}
    </Flex>
  )
}

const SkeletonCustomerProfile = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
    <rect x="0" y="25" rx="3" ry="3" width="27" height="27" />

    <rect x="35" y="25" rx="3" ry="3" width="60" height="8" />
    <rect x="35" y="38" rx="3" ry="3" width="60" height="8" />

    <rect x="110" y="25" rx="3" ry="3" width="60" height="8" />
    <rect x="110" y="38" rx="3" ry="3" width="60" height="8" />
    <rect x="110" y="51" rx="3" ry="3" width="60" height="8" />

    <rect x="185" y="25" rx="3" ry="3" width="60" height="8" />
    <rect x="185" y="38" rx="3" ry="3" width="60" height="8" />
  </ContentLoader>
)
