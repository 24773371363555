import { Text } from '@chakra-ui/react'
import React from 'react'

const AreaLabel = ({ title }: { title: string }) => (
  <Text
    w="50%"
    color="#221C46"
    fontSize="16px"
    fontWeight="700"
    letterSpacing="-0.8px"
  >
    {title}
  </Text>
)

export default AreaLabel
