import { Flex, Image } from '@chakra-ui/react'
import { TabList, TabPanel, TabPanels, Tabs } from 'components/atoms'
import { FilterTransactions, Tab } from 'components/ui'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { translate } from 'internationalization'
import React, { useEffect, useState } from 'react'

import { Appetizers } from './appetizers/appetizers'
import { Exits } from './exits/exits'
import { Transactions } from './transactions/transactions'

type TabIndexVariants = 0 | 1 | 2
type TabTitleVariants = 'transactions' | 'appetizer' | 'exits'

export const TabTransactions = () => {
  const { setWallet } = useWalletTransaction()
  const { selectedWallet } = useAccountWallet()
  const { removeStorageItem } = useLocalStorage()
  const [currentTab, setCurrentTab] = useState<TabTitleVariants>('transactions')

  const isHide = !selectedWallet

  useEffect(() => {
    setWallet(selectedWallet)
  }, [selectedWallet])

  const handleChangeTab = (tabIndex: TabIndexVariants) => {
    removeStorageItem('datesToFilter')

    const data = {
      0: 'transactions',
      1: 'appetizer',
      2: 'exits'
    }

    const selectedTab = data[tabIndex]
    setCurrentTab(selectedTab as TabTitleVariants)
  }

  if (isHide) return null

  return (
    <Flex maxW="100%">
      <Tabs
        w="100%"
        isLazy
        onChange={tabIndex => handleChangeTab(tabIndex as TabIndexVariants)}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <TabList borderBottom="1px solid" borderBottomColor="#D1CFD7">
            <Tab
              title={translate('commons.transactions')}
              icon={
                <Image
                  w="24px"
                  h="24px"
                  src="/img/pluxee/tab1.svg"
                  alt="Transactions Tab"
                />
              }
            />
            <Tab
              title={translate('commons.appetizer')}
              icon={
                <Image
                  w="24px"
                  h="24px"
                  src="/img/pluxee/tab2.svg"
                  alt="Appetizer Tab"
                />
              }
            />
            <Tab
              title="Saídas"
              icon={
                <Image
                  w="24px"
                  h="24px"
                  src="/img/pluxee/tab3.svg"
                  alt="Exits Tab"
                />
              }
            />
          </TabList>

          {currentTab === 'transactions' && (
            <FilterTransactions type="transactions" exportType="transactions" />
          )}

          {currentTab === 'appetizer' && (
            <FilterTransactions type="appetizer" exportType="appetizer" />
          )}

          {currentTab === 'exits' && (
            <FilterTransactions
              type="exits"
              exportType="exits"
              excludeType="PTX"
            />
          )}
        </Flex>

        <TabPanels>
          <TabPanel px="0">
            <Transactions />
          </TabPanel>

          <TabPanel px="0">
            <Appetizers />
          </TabPanel>

          <TabPanel px="0">
            <Exits />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
