import { useState } from 'react'

type UsePaginationProps = {
  initialPage: number
}

export const usePagination = ({ initialPage }: UsePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(initialPage)

  const handlePrevPage = () => setCurrentPage(prevState => prevState - 1)
  const handleNextPage = () => setCurrentPage(prevState => prevState + 1)
  const handleOnChangePage = (pageNumber: number) => setCurrentPage(pageNumber)
  const resetPage = (pageNumber: number) => setCurrentPage(pageNumber)

  return {
    currentPage,
    handleNextPage,
    handlePrevPage,
    handleOnChangePage,
    resetPage
  }
}
