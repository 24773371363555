import { Layout, Container, Loading } from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useTransaction } from 'modules/transaction/presentation/contexts/TransactionContext'
import React, { useCallback, useEffect, useState } from 'react'
import { sanitizeObject } from 'utils/sanitizeObject'

import SearchTransactionPresentation from '../presentational/SearchTransactionPresentational'

const SearchTransactionContainer = () => {
  const { user } = useProfile()
  if (!user) return <Loading />

  const { removeStorageItem } = useLocalStorage()
  const removeCachedTransactionUUID = () => removeStorageItem('transactionUUID')

  return (
    <Layout isShowBackButton onPageBack={removeCachedTransactionUUID}>
      <Container>
        <MainContainer
          removeCachedTransactionUUID={removeCachedTransactionUUID}
        />
      </Container>
    </Layout>
  )
}

export default SearchTransactionContainer

type MainContainerProps = {
  removeCachedTransactionUUID: () => void
}

const MainContainer = ({ removeCachedTransactionUUID }: MainContainerProps) => {
  const [isShowTable, setIsShowTable] = useState(false)
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [isFirstRequest, setIsFirstRequest] = useState(false)
  const [value, setValue] = useState('')

  const {
    isLoading,
    isError,
    transaction,
    isSuccess,
    setTransactionUUID,
    isEmptyTransaction
  } = useTransaction()

  const { getStorageItem, setStorageItem } = useLocalStorage()
  const cachedTransactionUUID = getStorageItem('transactionUUID')

  useEffect(() => {
    if (cachedTransactionUUID) {
      setValue(cachedTransactionUUID)
      handleSearch()
    }
  }, [cachedTransactionUUID, value])

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedTransactionUUID)
    return () => {
      window.removeEventListener('beforeunload', removeCachedTransactionUUID)
    }
  }, [removeCachedTransactionUUID])

  const minTransactionUUIDCharacters = 36
  const isEmptyTable =
    (isEmptyTransaction && isSuccess && isFirstRequest) || isError

  const handleSearch = useCallback(() => {
    if (value.length < minTransactionUUIDCharacters) return
    const sanitizedValue = sanitizeObject(value)

    setTransactionUUID(sanitizedValue)
    setStorageItem('transactionUUID', sanitizedValue)
    setIsFirstRequest(true)
  }, [setTransactionUUID, value])

  useEffect(() => {
    const hasValidValue = value.length === minTransactionUUIDCharacters
    setIsActiveButton(hasValidValue)
  }, [value])

  useEffect(() => {
    setIsShowTable(!isEmptyTransaction)
  }, [transaction])

  const handleInputChange = (inputValue: string) => {
    removeCachedTransactionUUID()
    setValue(inputValue)
  }

  return (
    <SearchTransactionPresentation
      value={value}
      isEmptyTable={isEmptyTable}
      isShowTable={isShowTable}
      isLoading={isLoading}
      isActiveButton={isActiveButton}
      onSearch={handleSearch}
      onInputChange={handleInputChange}
    />
  )
}
