import { IUserRepository } from 'modules/users/domain/repositories/IUserRepository'

import { UpdateProps } from '../queries/UpdateProps'

export class UpdateUser {
  constructor(private userRepository: IUserRepository) {}

  async execute({ user, id }: UpdateProps) {
    return await this.userRepository.update(id, user)
  }
}
