import { WalletTransaction } from 'models/WalletTransaction'
import { GetTransactionResponse } from 'modules/transaction/infra/dto/GetTransactionResponse'
import {
  useMutateGetOriginalTransaction,
  useQueryGetTransactionByUUID
} from 'modules/transaction/presentation/hooks/useTransactionQueries'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren
} from 'react'
import { objectIsEmpty } from 'utils/objectUtils'

export type ContextType = {
  transaction?: GetTransactionResponse
  setTransactionUUID: (value: string) => void

  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  isEmptyTransaction: boolean

  fetchTransactionByUUID: ({
    uuid
  }: {
    uuid: string
  }) => Promise<WalletTransaction>
}

export const TransactionContext = createContext<ContextType | undefined>(
  undefined
)

export const TransactionProvider = ({ children }: PropsWithChildren) => {
  const [transactionUUID, setTransactionUUID] = useState<string>()
  const [transaction, setTransaction] = useState<
    GetTransactionResponse | undefined
  >()

  const isEmptyTransaction = objectIsEmpty(transaction)

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isError
  } = useQueryGetTransactionByUUID(transactionUUID, {
    enabled: !!transactionUUID
  })

  useEffect(() => {
    if (transactionData) setTransaction(transactionData)
  }, [transactionData])

  useEffect(() => {
    if (isError) setTransaction(undefined)
  }, [isError])

  const mutateTransaction = useMutateGetOriginalTransaction()

  const fetchTransactionByUUID = async ({ uuid }: { uuid: string }) => {
    return await mutateTransaction.mutateAsync(uuid)
  }

  return (
    <TransactionContext.Provider
      value={{
        transaction,
        setTransactionUUID,
        isLoading,
        isSuccess,
        isError,
        isEmptyTransaction,
        fetchTransactionByUUID
      }}
    >
      {children}
    </TransactionContext.Provider>
  )
}

export const useTransaction = () => {
  const context = useContext(TransactionContext)
  if (!context) {
    throw new Error('useTransaction must be used within a TransactionProvider')
  }
  return context
}
