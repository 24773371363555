import { useUser } from 'modules/users/presentation/contexts/UserContext'
import React from 'react'

import { InputSearchPresentational } from '../presentational/InputPresentational'

export function InputSearchContainer() {
  const {
    users,
    setCurrentFilters,
    isLoadingGetAll,
    isSuccessGetAll,
    currentFilters,
    searchInputValue,
    setSearchInputValue,
    setCurrentRoleName,
    isSearchingByRole
  } = useUser()

  async function handleFilterByUserName(username: string) {
    setCurrentRoleName('')

    if (!username) {
      delete currentFilters.name
      setCurrentFilters({ ...currentFilters })
    } else {
      setCurrentFilters({ name: username, roleId: '' })
    }
  }

  const isError =
    isSuccessGetAll && Object.keys(users).length === 0 && !isSearchingByRole

  return (
    <InputSearchPresentational
      isError={isError}
      searchInputValue={searchInputValue}
      setSearchInputValue={setSearchInputValue}
      handleFilterByUserName={handleFilterByUserName}
      isLoadingGetAll={isLoadingGetAll}
    />
  )
}
