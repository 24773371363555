import { StyleProps, Collapse } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from 'components/atoms'
import React from 'react'
import { IconsName } from 'types'

import { Pagination, NoData } from '.'

export type CellProps = {
  title: string
  field?: string
  width?: string
  icon?: {
    name: IconsName
    ariaLabel: string
    isDisabled?: boolean
    action: (id: string) => void
  }
  format?: (value: string) => string
  render?: any
  isEmpty?: boolean
  isError?: boolean
  style?: StyleProps
  hidden?: boolean
}

type PluxeeTableProps = {
  textType?: 'capitalize' | 'uppercase' | 'initial'
  columns: CellProps[]
  list: Record<any, any>[]
  isPaginated?: boolean
  currentPage?: number
  totalPages?: number
  handlePrevPage?: () => void
  handleNextPage?: () => void
  handleOnChangePage?: (page: number) => void
  onClickRow?: (item: any) => void
  totalResults?: number
  minWidth?: string
  maxWidth?: string
  size?: 'lg' | 'md' | 'sm'
  testId?: string
  withCollapse?: boolean
  expandedRowIndex?: number | null
  onToggleRow?: (index: number) => void
  collapseContent?: (item: any) => React.ReactNode
}

const PluxeeTable = ({
  textType = 'capitalize',
  columns,
  list,
  currentPage = 0,
  totalPages = 1,
  handlePrevPage,
  handleNextPage,
  handleOnChangePage,
  onClickRow,
  maxWidth = '100%',
  isPaginated = false,
  totalResults,
  minWidth,
  size = 'md',
  testId,
  withCollapse = false,
  expandedRowIndex,
  onToggleRow,
  collapseContent
}: PluxeeTableProps) => {
  const isShowPagination = isPaginated && list?.length > 0

  const getOffsetSize = () => {
    if (totalPages === 2) return 2
    if (totalPages === 1) return 1
    if (totalPages === 0) return 0
    return 3
  }

  const handleOnClickRow = (item: any) => {
    if (onClickRow) onClickRow(item)
  }

  const handleToggleRow = (index: number) => {
    if (withCollapse && onToggleRow) {
      onToggleRow(index)
    }
  }

  return (
    <Box data-testid={testId} maxW={maxWidth} minWidth={minWidth}>
      <Box borderRadius="none" overflowX="auto">
        <ChakraTable size={size}>
          <Thead>
            <Tr height="52px">
              {columns.map((column, index) => (
                <Th
                  display={column.hidden ? 'none' : 'auto'}
                  key={index}
                  width={column.width ?? 'auto'}
                  fontSize="xxs"
                  {...column.style}
                  h="48px"
                  textTransform={textType}
                  fontWeight="700"
                  fontFamily="TTTravels"
                  bg="pluxee.text.primary"
                  color="standard.white"
                  lineHeight="20px"
                  letterSpacing="-0.7px"
                >
                  {column.title}
                </Th>
              ))}
            </Tr>
          </Thead>
          {list?.length === 0 ? (
            <NoDataElement />
          ) : (
            <Tbody>
              {list?.map((item, index) => (
                <React.Fragment key={index}>
                  <Tr
                    height="56px"
                    style={{ cursor: onClickRow ? 'pointer' : 'default' }}
                    background={index % 2 === 0 ? '#F3F3FC' : 'white'}
                  >
                    {columns.map((column, colIndex) => {
                      const { field, icon, format, width, render, title } =
                        column
                      const fieldValue = item[field as keyof typeof item]
                      const contentValue = format
                        ? format(fieldValue)
                        : fieldValue
                      const isEmptyTitle = title.length === 0
                      const isDenied = item?.status === 'F'
                      const isHidden = !!column.hidden

                      return (
                        <Td
                          key={colIndex}
                          display={isHidden ? 'none' : 'auto'}
                          fontSize="xxs"
                          fontWeight="500"
                          color="pluxee.text.secondary"
                          px="xxxs"
                          py="0"
                          {...column.style}
                          width={width ?? 'auto'}
                          bgColor={
                            expandedRowIndex === index
                              ? '#C3EBFB'
                              : isDenied
                              ? '#fcece7'
                              : 'inherit'
                          }
                          onClick={
                            !isEmptyTitle
                              ? () => handleOnClickRow(item)
                              : undefined
                          }
                        >
                          {contentValue}
                          {render ? (
                            <Box>
                              {render({
                                ...item,
                                onToggleRow: () => handleToggleRow(index),
                                isExpanded: expandedRowIndex === index
                              })}
                            </Box>
                          ) : (
                            icon && (
                              <IconButton
                                data-testid={`icon-button-${icon.name}`}
                                variant="ghost"
                                _hover={{ bgColor: 'none' }}
                                aria-label={icon.ariaLabel}
                                minW="0"
                                height="auto"
                                disabled={icon.isDisabled}
                                icon={
                                  <Icon
                                    name={icon.name}
                                    variant="outlined"
                                    color="brand.primary-default"
                                  />
                                }
                                onClick={() => icon.action(item.id)}
                              />
                            )
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                  {withCollapse && (
                    <Tr
                      key={`collapse-${index}`}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <Td
                        colSpan={columns.length}
                        p="0 !important"
                        m="0 !important"
                      >
                        <Collapse
                          in={expandedRowIndex === index}
                          animateOpacity
                        >
                          <Box
                            borderTop="1px solid #D1CFD7"
                            borderBottom="1px solid #D1CFD7"
                            p="4"
                            bg="#DEF3FB"
                          >
                            {collapseContent ? collapseContent(item) : null}
                          </Box>
                        </Collapse>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              ))}
            </Tbody>
          )}
        </ChakraTable>
      </Box>
      {isShowPagination ? (
        <Flex align="center" justify="center" mt="xxs">
          {totalResults && (
            <Text marginRight="xxxs" fontSize="xs">
              {totalResults > 1
                ? `${totalResults} resultados`
                : `${totalResults} resultado`}
            </Text>
          )}

          <Pagination
            size={size}
            handlePrevPage={handlePrevPage!}
            handleNextPage={handleNextPage!}
            onChangePage={handleOnChangePage!}
            currentPage={currentPage}
            lastPage={totalPages}
            offsetSize={getOffsetSize()}
          />
        </Flex>
      ) : null}
    </Box>
  )
}

export default PluxeeTable

const NoDataElement = () => {
  return (
    <Tbody>
      <Tr>
        <Td p="0 !important" border="0" colSpan={7}>
          <Flex alignItems="center" justifyContent="center" w="100%" pt="16px">
            <NoData />
          </Flex>
        </Td>
      </Tr>
    </Tbody>
  )
}
