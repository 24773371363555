import { Center, Image, MenuButton as ChakraMenuButton } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { PluxeeMenu } from 'components/ui'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import { useTransaction } from 'modules/transaction/presentation/contexts/TransactionContext'
import React from 'react'

export const TransactionOptions = ({
  data,
  setWalletId,
  isShowProvisionalCredit,
  isShowReverseTransaction,
  onOpenProvisionalCredit,
  onOpenDetails,
  onOpenReverseTransaction,
  setSelectedTransaction
}: {
  data: WalletTransaction
  setWalletId?: () => void
  isShowProvisionalCredit: boolean
  isShowReverseTransaction: boolean
  setSelectedTransaction: (data: WalletTransaction) => void
  onOpenDetails: () => void
  onOpenProvisionalCredit: () => void
  onOpenReverseTransaction: () => void
}) => {
  const { fetchTransactionByUUID } = useTransaction()
  const {
    getRefundableHistory,
    setOriginalTransaction,
    setOriginalTransactionNSU,
    setOriginalTransactionUUID
  } = useWalletTransaction()

  const setAndFetchRequiredData = () => {
    setWalletId && setWalletId()
    setSelectedTransaction(data)

    if (data.status === 'E' && data.type === 'PTX') {
      getRefundableHistory({ transactionUUID: data.transactionUUID! })
    } else if (
      ['PRC', 'CTR', 'PCR'].includes(data.type!) &&
      data.status === 'E'
    ) {
      getRefundableHistory({ transactionUUID: data.originUUID! })

      fetchTransactionByUUID({ uuid: data.originUUID! }).then(
        originalTransaction => {
          setOriginalTransaction(originalTransaction)
          setOriginalTransactionNSU(originalTransaction.nsu)
          setOriginalTransactionUUID(originalTransaction.transactionUUID)
        }
      )
    }
  }

  const handleDetailsOnClick = () => {
    setAndFetchRequiredData()
    onOpenDetails()
  }

  const handleProvisinalCreditOnClick = () => {
    setAndFetchRequiredData()
    onOpenProvisionalCredit()
  }

  const handleReverseTransactionOnClick = () => {
    setAndFetchRequiredData()
    onOpenReverseTransaction()
  }

  const menuItems = [
    {
      title: 'Ver detalhes',
      isDisabled: false,
      icon: <Icon name="search" />,
      onClick: handleDetailsOnClick
    },
    {
      title: 'Crédito provisório',
      isDisabled: !isShowProvisionalCredit,
      icon: (
        <Image w="24px" h="24px" src="/img/pluxee/provisional.svg" alt="Icon" />
      ),
      onClick: handleProvisinalCreditOnClick
    },
    {
      title: translate('commons.reverseTransaction'),
      isDisabled: !isShowReverseTransaction,
      onClick: handleReverseTransactionOnClick,
      icon: (
        <Image
          w="24px"
          h="24px"
          src="/img/pluxee/refundIcon.svg"
          alt="Refund Icon"
        />
      )
    }
  ]

  return <PluxeeMenu menuButton={<MenuButton />} items={menuItems} />
}

const MenuButton = () => (
  <ChakraMenuButton
    w="40px"
    h="40px"
    borderRadius="0"
    background="transparent"
    color="#1B51DC"
    _active={{
      border: '2px solid #1B51DC'
    }}
    _focusVisible={{
      border: '3px solid #1B51DC',
      color: '#0F266D'
    }}
    _hover={{
      background: 'transparent',
      color: '#0F266D'
    }}
  >
    <Center>
      <Icon name="more_horiz" color="inherit" />
    </Center>
  </ChakraMenuButton>
)
