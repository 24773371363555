import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export const BottomLabel = ({
  title,
  value
}: {
  title: string
  value: string
}) => {
  return (
    <Flex
      gap="4px"
      mt="4px"
      color="#5A5469"
      letterSpacing="-0.8px"
      fontWeight="600"
      fontSize="12px"
    >
      <Text>{title}</Text>
      <Text>{value}</Text>
    </Flex>
  )
}
