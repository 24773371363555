import { Button, Flex, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import React from 'react'

const CreateWalletButton = ({
  onClick,
  isDisabled = false
}: {
  onClick: () => void
  isDisabled?: boolean
}) => (
  <Flex height="40px">
    <Flex
      as={Button}
      gap="12px"
      alignItems="center"
      borderRadius="0"
      onClick={!isDisabled ? onClick : undefined}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      bg={!isDisabled ? '#1B51DC' : '#EFEFEF'}
      color={!isDisabled ? 'standard.white' : '#B5B2BC'}
      _hover={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && '#221C46'
      }}
      _active={{
        boxShadow: !isDisabled && '2px 2px 0px 0px #A9A7B6 !important',
        color: !isDisabled && 'standard.white',
        background: !isDisabled && 'pluxee.cta.secondaryPressed'
      }}
      _focusVisible={{
        boxShadow: !isDisabled && 'none !important',
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && '#221C46',
        border: !isDisabled && '3px solid #1B51DC'
      }}
    >
      <Text fontWeight="700" fontSize="14px" letterSpacing="-0.7px">
        Criar carteira
      </Text>
      <Icon name="add" />
    </Flex>
  </Flex>
)

export default CreateWalletButton
