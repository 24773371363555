import { Button, Center, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { WalletTransaction } from 'models/WalletTransaction'
import React, { PropsWithChildren, useEffect } from 'react'

import { TransactionDetailsModal } from './transactionDetails'

type BlockedActionProps = {
  onClose: () => void
  isOpen: boolean
  transaction: WalletTransaction
  description?: string
}

const defaultDescription =
  'Não será possível realizar um crédito provisório nesta transação pois a ação já foi realizada anteriormente. Para mais informações acesse os detalhes da transação.'

export const BlockedAction = ({
  onClose,
  isOpen,
  transaction,
  description = defaultDescription
}: BlockedActionProps) => {
  const {
    isOpen: isOpenDetails,
    onClose: onCloseDetails,
    onOpen: onOpenDetails
  } = useDisclosure()

  const onCloseModals = () => {
    onCloseDetails()
    onClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') onClose()
    })

    return () => {
      document.removeEventListener('keydown', onClose)
    }
  }, [])

  if (!isOpen) return <></>

  if (isOpenDetails)
    return (
      <TransactionDetailsModal
        transaction={transaction}
        isOpen={isOpenDetails}
        onClose={onCloseModals}
      />
    )

  return (
    <Wrapper onClickCloseButton={onCloseModals}>
      <Header />
      <Flex
        flexDir="column"
        alignItems="center"
        gap="40px"
        p="40px"
        textAlign="center"
      >
        <Flex flexDir="column" gap="8px">
          <Text
            fontWeight="800"
            fontSize="32px"
            letterSpacing="-1.6px"
            color="#221C46"
          >
            Ação bloqueada
          </Text>

          <Flex
            gap="4px"
            fontSize="20px"
            color="#5A5469"
            letterSpacing="-0.8px"
            fontWeight="500"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Flex>

        <Flex gap="16px">
          <CancelButton onClick={onClose} />
          <SubmitButton
            onClick={onOpenDetails}
            title="Ver detalhes da transação"
            isLoading={false}
          />
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Header = () => (
  <Center h="160px" w="100%" bg="#FBF2EC">
    <Flex
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: '128px',
        left: 'calc(50% - 24px)',
        width: '48px',
        height: '24px',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('/img/transactions/arrowDown.svg')",
        backgroundRepeat: 'no-repeat'
      }}
    >
      <WarningImage />
    </Flex>
  </Center>
)

interface WrapperProps extends PropsWithChildren {
  onClickCloseButton: () => void
}

export const Wrapper = ({ children, onClickCloseButton }: WrapperProps) => {
  return (
    <Flex
      className="blockedActionWrapper"
      position="fixed"
      left="-1%"
      right="0"
      bottom="0"
      top="0"
      bg="rgba(0, 0, 0, 25%)"
      zIndex={99}
      alignItems="flex-start"
      justifyContent="center"
      pt="90px"
    >
      <Flex
        position="relative"
        flexDir="column"
        w="100%"
        maxW="660px"
        bg="white"
      >
        <Flex
          position="absolute"
          top="0"
          right="0"
          m="24px"
          cursor="pointer"
          onClick={onClickCloseButton}
        >
          <CloseIcon />
        </Flex>
        {children}
      </Flex>
    </Flex>
  )
}

export const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      bg="transparent"
      w="97px"
      h="48px"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
        Fechar
      </Text>
    </Button>
  )
}

export const SubmitButton = ({
  isLoading,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      onClick={onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      _hover={{
        boxShadow: '4px 4px 0px 0px #A9A7B6!important',
        borderColor: '#283897',
        background: '#DEF3FB',
        color: '#221C46',
        border: 'none'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
        {title}
      </Text>
    </Button>
  )
}

const WarningImage = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6502_2172)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 87C69.5391 87 87 69.5391 87 48C87 26.4609 69.5391 9 48 9C26.4609 9 9 26.4609 9 48C9 69.5391 26.4609 87 48 87ZM48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
        fill="#874810"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5 54V30H52.5V54H43.5Z"
        fill="#874810"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5 66V57H52.5V66H43.5Z"
        fill="#874810"
      />
    </g>
    <defs>
      <clipPath id="clip0_6502_2172">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5859 12.0001L2.29297 3.70718L3.70718 2.29297L12.0001 10.5859L20.293 2.29297L21.7072 3.70718L13.4143 12.0001L21.7072 20.293L20.293 21.7072L12.0001 13.4143L3.70718 21.7072L2.29297 20.293L10.5859 12.0001Z"
      fill="#1B51DC"
    />
  </svg>
)
