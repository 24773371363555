import { Flex, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { Tooltip } from 'components/ui'
import { AccountWallet } from 'models/AccountWallet'
import React, { PropsWithChildren } from 'react'

type StatusInfoProps = Pick<
  AccountWallet,
  'walletStatus' | 'customerWalletStatus' | 'isAccountWalletPaused'
>

export const StatusInfo = ({
  walletStatus,
  customerWalletStatus,
  isAccountWalletPaused
}: StatusInfoProps) => {
  const isInactiveCustomerWallet = walletStatus === 'INACTIVE'
  const isActiveCustomerWallet = customerWalletStatus === 'ACTIVE'

  if (isInactiveCustomerWallet)
    return (
      <Wrapper>
        <Label
          title="Inativa pela Pluxee"
          tooltipLabel="A carteira foi inativada pela Pluxee. Ações do Cliente RH ou Colaborador não são possíveis."
        />
      </Wrapper>
    )

  return (
    <Wrapper>
      <Text>Ativa pela Pluxee</Text>

      {isActiveCustomerWallet ? (
        <Label title="Ligada pelo Cliente" />
      ) : (
        <Label
          title="Desligada pelo Cliente RH"
          tooltipLabel='Somente o Cliente RH poderá ligar esta carteira. Neste status a carteira permite transferências (somente envio) para outra carteira com status ”Ativa” ou ”Pausada". Transações de compra e saque podem ser realizadas até finalizar o saldo da carteira. A carteira com este status (sem saldo) não aparece para o colaborador no APP ou Portal.'
        />
      )}

      {!isAccountWalletPaused ? (
        <Label title="Não pausada pelo Colaborador" />
      ) : (
        <Label
          title="Pausada pelo Colaborador"
          tooltipLabel="A carteira não permite saída de valores (transacionar ou transferir ) enquanto o colaborador não alterar o status ”Pausada” via APP ou Portal."
        />
      )}
    </Wrapper>
  )
}

const Wrapper = ({ children }: PropsWithChildren) => (
  <Flex
    flexDir="column"
    alignItems="flex-end"
    fontSize="12px"
    fontWeight="500"
    color="#221C46"
    letterSpacing="-0.7px"
  >
    {children}
  </Flex>
)

const Label = ({
  title,
  tooltipLabel
}: {
  tooltipLabel?: string
  title: string
}) => (
  <Tooltip label={tooltipLabel ?? ''}>
    <Flex gap="4px" alignItems="center">
      {!!tooltipLabel && <Icon name="info_outline" color="#B30000" size="xs" />}
      <Text>{title}</Text>
    </Flex>
  </Tooltip>
)
