import React from 'react'

const round = () => {
  return (
    <>
      <path
        d="M4.14261 8.65039V15.3056C4.14104 15.5075 4.19703 15.7057 4.30404 15.877C4.42396 16.0603 4.59065 16.2082 4.78689 16.3055L11.3426 19.6839C11.3655 19.691 11.3883 19.6996 11.4197 19.7067V12.3544L4.14261 8.65039ZM9.69974 13.9986C9.65263 14.094 9.57979 14.1744 9.48943 14.2307C9.39907 14.287 9.29477 14.3169 9.18831 14.3171C9.09778 14.3166 9.00866 14.2946 8.92831 14.2528L6.11975 12.7958C5.98342 12.7245 5.88062 12.6024 5.83358 12.4559C5.78653 12.3095 5.79901 12.1504 5.86832 12.013C5.90198 11.9454 5.94871 11.8851 6.00581 11.8357C6.06291 11.7862 6.12925 11.7486 6.20099 11.725C6.27273 11.7013 6.34844 11.6921 6.42375 11.6979C6.49906 11.7037 6.57248 11.7244 6.63975 11.7587L9.44831 13.2086C9.51674 13.2439 9.57754 13.2924 9.62722 13.3512C9.6769 13.41 9.71449 13.4781 9.73784 13.5515C9.7612 13.6248 9.76985 13.7021 9.76331 13.7788C9.75678 13.8555 9.73517 13.9302 9.69974 13.9986Z"
        fill="currentColor"
      />
      <path
        d="M21 8.16952V15.3119C20.9981 15.7358 20.8758 16.1505 20.6471 16.5075C20.4174 16.8633 20.0982 17.1525 19.7214 17.346L13.1643 20.7129C12.8039 20.9015 12.4032 21 11.9964 21C11.5897 21 11.189 20.9015 10.8286 20.7129L4.27146 17.3417C3.89453 17.1479 3.5749 16.8588 3.34432 16.5032C3.11715 16.1454 2.99764 15.7299 3.00004 15.3061V8.16381C3.00469 7.96657 3.0489 7.77227 3.13004 7.59242C3.14575 7.55386 3.13861 7.52243 3.15289 7.491C3.17 7.46701 3.19073 7.44581 3.21432 7.42815V7.41244C3.2331 7.38767 3.25363 7.36428 3.27575 7.34244C3.27575 7.3353 3.29146 7.31959 3.29861 7.31244C3.43177 7.14068 3.59849 6.9978 3.78861 6.89248L10.8286 3.27703C11.1909 3.09487 11.5909 3 11.9964 3C12.402 3 12.802 3.09487 13.1643 3.27703L20.2029 6.89533C20.4356 7.01562 20.6326 7.19492 20.7743 7.41529C20.7814 7.42386 20.7814 7.43958 20.7886 7.44672C20.7957 7.45386 20.7971 7.46243 20.8043 7.46958V7.49386C20.9245 7.69939 20.9917 7.93157 21 8.16952ZM19.8571 15.3119V8.61235L12.5714 12.3549V19.7015L12.64 19.6787L19.1971 16.3004C19.3929 16.2023 19.5594 16.0545 19.68 15.8718C19.7902 15.7033 19.8516 15.5075 19.8571 15.3061V15.3119ZM16.2186 9.18374L19.16 7.66956L12.6429 4.31695C12.4439 4.21512 12.2236 4.16177 12 4.16125C11.7737 4.16176 11.5507 4.21508 11.3486 4.31695L9.05145 5.48972L16.2186 9.18374ZM12 11.3379L14.9486 9.83083L7.78717 6.14967L4.80003 7.67956L12 11.3379ZM11.4286 19.7015V12.3549L4.14289 8.65092V15.3061C4.14132 15.5081 4.19731 15.7063 4.30432 15.8775C4.42424 16.0608 4.59093 16.2088 4.78717 16.3061L11.3429 19.6844C11.3714 19.6858 11.3943 19.6944 11.4286 19.7015Z"
        fill="currentColor"
      />
      <path
        d="M16.7841 10.1893V12.8563C16.7841 13.0088 16.7236 13.155 16.6157 13.2628C16.5079 13.3707 16.3616 13.4312 16.2091 13.4312C16.0566 13.4312 15.9104 13.3707 15.8026 13.2628C15.6947 13.155 15.6341 13.0088 15.6341 12.8563V10.7807L12.5713 12.3549V19.7015L12.6399 19.6786L19.197 16.3003C19.3927 16.2022 19.5592 16.0544 19.6798 15.8718C19.7881 15.7007 19.8465 15.5028 19.8484 15.3004V8.6123L16.7841 10.1893Z"
        fill="currentColor"
      />
    </>
  )
}

export default round
