import { useDisclosure } from '@chakra-ui/react'
import { GetTransactionResponse } from 'modules/transaction/infra/dto/GetTransactionResponse'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { formatConsumerModel } from 'utils/productUtils'

import { TransactionAccordionPresentational } from '../presentational/TransactionAccordionPresentational'

export const TransactionAccordionContainer = ({
  account,
  transaction,
  consumer,
  customer,
  accountWalletDetails
}: GetTransactionResponse) => {
  const navigate = useNavigate()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })

  const { creationDate, productId, closureDate, id: accountId } = account
  const wallet = accountWalletDetails[0]

  const isUnlimitedDailyTx = wallet.dailyLimitTx >= 999999

  const formattedCreationDate = formatDateToBrazilianFormat(creationDate)

  const formattedClosureDate = closureDate
    ? formatDateToBrazilianFormat(closureDate)
    : '-'

  const formattedConsumerModel = customer.products
    ?.map(item => {
      const { formattedConsumerModel } = formatConsumerModel({
        isPatCustomer: item.patCustomer
      })

      return formattedConsumerModel
    })
    .join(' | ')

  const goToConsumerPage = () => {
    window.localStorage.setItem('cachedCPF', consumer.document)
    navigate(`/consumer`, {
      state: { isFromTransactionPage: true }
    })
  }

  return (
    <TransactionAccordionPresentational
      accountId={String(accountId)}
      productId={String(productId)}
      transaction={transaction}
      wallet={wallet}
      consumer={consumer}
      customer={customer}
      isUnlimitedDailyTx={isUnlimitedDailyTx}
      formattedCreationDate={formattedCreationDate}
      formattedClosureDate={formattedClosureDate}
      formattedConsumerModel={formattedConsumerModel}
      isOpen={isOpen}
      onToggle={onToggle}
      goToConsumerPage={goToConsumerPage}
    />
  )
}
