import { Flex } from '@chakra-ui/react'
import { useAuth } from 'contexts/authContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { WalletTransationTypes } from 'models/WalletTransaction'
import React, { useCallback, useEffect, useState } from 'react'
import {
  formatDateToBrazilianFormat,
  formatDateToFilter
} from 'utils/dateUtils'

import Calendar, { DateRange } from './Calendar'
import ExportButton from './ExportButton'

export type FilterTransactionsProps = {
  type: 'transactions' | 'exits' | 'appetizer'
  exportType: 'transactions' | 'exits' | 'appetizer'
  excludeType?: WalletTransationTypes
}

const FilterTransactions = ({
  exportType,
  type,
  excludeType
}: FilterTransactionsProps) => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null])
  const [startDate, endDate] = dateRange

  const [closeCalendarOnClick, setCloseCalendarOnClick] = useState(false)

  const { userLevel } = useAuth()

  const isAttendant3c = userLevel === 'Atendimento-3C'
  const isAttendantB2M = userLevel === 'Atendimento B2M'

  const { getTransactiosByFilter, currentFilters } = useWalletTransaction()

  const { getStorageItem, removeStorageItem, setStorageItem } =
    useLocalStorage()

  const calendarInputValue = getStorageItem('datesToFilter')

  const handleOnChangeDate = useCallback(() => {
    let dtStart
    let dtEnd

    let startDateBrazil
    let endDateBrazil

    if (!endDate) {
      dtStart = formatDateToFilter(startDate)!
      startDateBrazil = formatDateToBrazilianFormat(startDate?.toISOString())
    } else {
      dtStart = formatDateToFilter(startDate)!
      dtEnd = formatDateToFilter(endDate)!

      startDateBrazil = formatDateToBrazilianFormat(startDate?.toISOString())
      endDateBrazil = formatDateToBrazilianFormat(endDate?.toISOString())
    }

    setStorageItem(
      'datesToFilter',
      `${startDateBrazil}                  ${endDateBrazil ?? ''}`
    )

    if (type === 'exits') {
      delete currentFilters.type
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        operation: '-',
        excludeType
      })

      return
    }

    if (type === 'transactions') {
      setCloseCalendarOnClick(true)
      delete currentFilters.operation
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        type: 'PTX'
      })

      return
    }

    if (type === 'appetizer') {
      delete currentFilters.type
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        operation: '%2B'
      })

      return
    }
  }, [startDate, endDate])

  const handleRemoveFilter = () => {
    delete currentFilters.dtStart
    delete currentFilters.dtEnd

    removeStorageItem('datesToFilter')
    getTransactiosByFilter({ ...currentFilters })
  }

  const renderExportTransactionButton = useCallback(
    (dtStart: string, dtEnd: string) => {
      if (isAttendant3c || isAttendantB2M) return <></>

      if (exportType === 'transactions')
        return (
          <ExportButton
            transactionType="PTX"
            type="transacoes"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )

      if (exportType === 'exits')
        return (
          <ExportButton
            operationType="-"
            type="saidas"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )

      if (exportType === 'appetizer')
        return (
          <ExportButton
            operationType="%2B"
            type="entradas"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )
    },
    [startDate, endDate]
  )

  const removeCachedDate = () => removeStorageItem('datesToFilter')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDate)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDate)
    }
  }, [removeCachedDate])

  const renderCalendar = useCallback(() => {
    return (
      <Calendar
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        inputValue={calendarInputValue ?? ''}
        onChangeDate={handleOnChangeDate}
        onClear={handleRemoveFilter}
        closeOnClick={closeCalendarOnClick}
        h="40px"
        w="340px"
      />
    )
  }, [calendarInputValue, closeCalendarOnClick, startDate, endDate])

  const startDateToExport = formatDateToFilter(startDate)!
  const endDateToExport = formatDateToFilter(endDate)!

  return (
    <Flex alignItems="center" justifyContent="flex-end" maxW="507px" gap="16px">
      <Flex>{renderCalendar()}</Flex>
      {renderExportTransactionButton(startDateToExport, endDateToExport)}
    </Flex>
  )
}

export default FilterTransactions
