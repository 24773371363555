import { Box, Flex, Text } from '@chakra-ui/react'
import type { ResponseCode } from 'models/ResponseCode'
import React from 'react'
import { formatResponseCode, TransactionCases } from 'utils/transactionsUtils'

import Tooltip from './Tooltip'

export type TransactionStatusProps = {
  status: TransactionCases
  responseCode?: ResponseCode
  letterSpacing?: string
  maxW?: string
}

const TransactionStatus = ({
  status,
  responseCode,
  letterSpacing,
  maxW = '160px'
}: TransactionStatusProps) => {
  const formattedResponseCode = responseCode
    ? formatResponseCode({ code: responseCode })
    : '-'

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      maxW={maxW}
      w="100%"
    >
      <Flex
        alignItems="center"
        p="4px 8px"
        bg="white"
        border="1px solid"
        borderColor={getColorsByStatus({ status })}
        gap="8px"
      >
        <Box
          w="8px"
          h="8px"
          borderRadius="50%"
          bg={getColorsByStatus({ status })}
        />
        <Text
          fontWeight="500"
          fontSize="14px"
          color="#221C46"
          letterSpacing={letterSpacing}
        >
          {getFormattedStatus({ status })}
        </Text>
      </Flex>

      {responseCode && <Tooltip label={formattedResponseCode} />}
    </Flex>
  )
}

export default TransactionStatus

const getColorsByStatus = ({
  status
}: Pick<TransactionStatusProps, 'status'>) => {
  const formattedStatus = {
    P: '#F8A05B',
    E: '#01D253',
    F: '#B30000',
    U: '#908C99'
  }

  return formattedStatus[status] ?? formattedStatus.E
}

const getFormattedStatus = ({
  status
}: Pick<TransactionStatusProps, 'status'>) => {
  const formattedStatus = {
    P: 'Pendente',
    E: 'Aprovada',
    F: 'Negada',
    U: 'Desfeita'
  }

  return formattedStatus[status] ?? status
}
