import type { RestoreWalletOriginalValues } from 'models/request/RestoreWalletOriginalValues'
import type { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'
import { useQuery, useMutation } from 'react-query'
import { CustomerService } from 'services/customerService'

export function useQueryGetByDocument(document?: string) {
  return useQuery(['getByDocument', document], () => getByDocument(document), {
    retry: 0
  })
}

export function useMutateGetCustomizedClients() {
  return useMutation(['getCustomizedClients'], () => getCustomizedClients(), {
    retry: 0
  })
}

export function useQueryGetWalletsByCustomerId(customerId?: number) {
  return useQuery(
    ['getWalletsByCustomerId', customerId],
    () => getWalletsByCustomerId(customerId),
    { retry: 0 }
  )
}

export function useMutateUpdateWalletToCustomValues() {
  return useMutation((customValues: UpdateWalletToCustomValues) =>
    updateWalletToCustomValues({
      ...customValues
    })
  )
}

export function useMutateRestoreOriginalValues() {
  return useMutation(
    ({
      walletId,
      customValues,
      customerWalletId,
      customerId,
      allowTransfer,
      allowWithdraw,
      openWallet
    }: RestoreWalletOriginalValues) =>
      restoreWalletOriginalValues({
        customerId,
        walletId,
        customValues,
        customerWalletId,
        allowTransfer,
        allowWithdraw,
        openWallet
      })
  )
}

const getByDocument = async (document?: string) => {
  if (!document) return

  const response = await CustomerService.getByDocument(document)
  return response.data
}

const getWalletsByCustomerId = async (customerId?: number) => {
  if (!customerId) return

  const response = await CustomerService.getWalletsByCustomerId(customerId)
  return response.data
}

const getCustomizedClients = async () => {
  const response = await CustomerService.getCustomizedClients()
  return response.data
}

const updateWalletToCustomValues = async (
  props: UpdateWalletToCustomValues
) => {
  return await CustomerService.updateWalletToCustomValues({
    ...props
  })
}

const restoreWalletOriginalValues = async (
  props: RestoreWalletOriginalValues
) => {
  return await CustomerService.restoreWalletOriginalValues({
    ...props
  })
}
