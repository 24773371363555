import { GetRefundableHistory } from 'models/request/GetRefundableHistory'
import { GetWalletsByConsumer } from 'models/request/GetWalletByConsumer'
import { useMutation, useQuery } from 'react-query'
import {
  AccountWalletService,
  AllTransactions,
  CreditInTrustApiCall,
  CreditReversalApiCall,
  ReverseTransactionApiCall,
  TransactionsFilters
} from 'services/accountWalletService'

export function useQueryGetWalletsByAccount({
  accountId,
  customerId
}: GetWalletsByConsumer) {
  return useQuery(
    ['getWalletsByConsumer', { accountId, customerId }],
    () => getByAccount({ accountId, customerId }),
    { retry: 0 }
  )
}

export function useQueryGetTransactions({
  walletId,
  accountId,
  operation,
  page,
  type
}: TransactionsFilters) {
  return useQuery(
    ['getTransactions', walletId, accountId, operation, page, type],
    () => getTransactions({ walletId, accountId, operation, page, type })
  )
}

export function useQueryGetAllTransactions() {
  return useMutation(({ accountId, page, dtStart, dtEnd }: AllTransactions) =>
    getAllTransactions({
      accountId,
      page,
      dtStart,
      dtEnd
    })
  )
}

export function useMutationGetRefundableHistory() {
  return useMutation(({ transactionUUID }: GetRefundableHistory) =>
    getRefundableHistory({ transactionUUID })
  )
}

export function useQueryGetTransactionsByFilters() {
  return useMutation(
    ({
      walletId,
      accountId,
      operation,
      page,
      type,
      dtStart,
      dtEnd,
      excludeType
    }: TransactionsFilters) =>
      getTransactions({
        accountId,
        operation,
        page,
        type,
        walletId,
        dtStart,
        dtEnd,
        excludeType
      })
  )
}

export function useMutationReverseCredit() {
  return useMutation(
    ({
      accountId,
      walletId,
      occorence_code,
      reason,
      value,
      authorization_code,
      uuid
    }: CreditInTrustApiCall) =>
      creditInTrust({
        accountId,
        walletId,
        occorence_code,
        reason,
        value,
        authorization_code,
        uuid
      })
  )
}

export function useMutationProvisionalCredit() {
  return useMutation(
    ({
      accountId,
      walletId,
      occorence_code,
      reason,
      value,
      authorization_code,
      uuid
    }: CreditInTrustApiCall) =>
      provisionalCredit({
        accountId,
        walletId,
        occorence_code,
        reason,
        value,
        authorization_code,
        uuid
      })
  )
}

export function useMutationCreditInTrust() {
  return useMutation(
    ({
      accountId,
      walletId,
      occorence_code,
      reason,
      value,
      authorization_code,
      uuid
    }: CreditInTrustApiCall) =>
      creditInTrust({
        accountId,
        walletId,
        occorence_code,
        reason,
        value,
        authorization_code,
        uuid
      })
  )
}

export function useMutationCreditReversal() {
  return useMutation(
    ({
      accountId,
      walletId,
      occorence_code,
      reason,
      value,
      uuid
    }: CreditReversalApiCall) =>
      creditReversal({
        accountId,
        walletId,
        occorence_code,
        reason,
        value,
        uuid
      })
  )
}

export function useMutationReverseTransaction() {
  return useMutation(reverseTransaction)
}

const getByAccount = async ({
  accountId,
  customerId
}: GetWalletsByConsumer) => {
  if (!accountId || !customerId) return

  const response = await AccountWalletService.getByAccountId({
    accountId,
    customerId
  })

  return response.data
}

const getAllTransactions = async ({
  accountId,
  page,
  dtStart,
  dtEnd
}: AllTransactions) => {
  if (!accountId) return

  const response = await AccountWalletService.getAllTransactions({
    accountId: Number(accountId),
    page,
    dtStart,
    dtEnd
  })

  return response.data
}

const getTransactions = async ({
  walletId,
  accountId,
  operation,
  page,
  type,
  dtStart,
  dtEnd,
  excludeType
}: TransactionsFilters) => {
  if (!walletId || !accountId) return

  const response = await AccountWalletService.getTransactions({
    walletId: Number(walletId),
    accountId: Number(accountId),
    operation,
    page,
    type,
    dtStart,
    dtEnd,
    excludeType
  })

  return response.data
}

const getRefundableHistory = async ({
  transactionUUID
}: GetRefundableHistory) => {
  const response = await AccountWalletService.getRefundableHistory({
    transactionUUID
  })

  return response.data
}

const provisionalCredit = async (props: CreditInTrustApiCall) => {
  return await AccountWalletService.provisionalCredit({
    ...props
  })
}

const creditInTrust = async (props: CreditInTrustApiCall) => {
  return await AccountWalletService.creditInTrust({
    ...props
  })
}

const creditReversal = async (props: CreditReversalApiCall) => {
  return await AccountWalletService.creditReversal({
    ...props
  })
}

const reverseTransaction = async ({
  occurrence,
  reason,
  value,
  accountId,
  walletId,
  customerId
}: ReverseTransactionApiCall) => {
  return await AccountWalletService.reverseTransaction({
    occurrence,
    reason,
    value,
    accountId,
    walletId,
    customerId
  })
}
