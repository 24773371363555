import { Button as ChakraButton, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import React from 'react'

export type ButtonProps = {
  title: string
  onClick: () => void
  isLoading: boolean
  isDisabled?: boolean
}

const Button = ({ title, onClick, isLoading, isDisabled }: ButtonProps) => {
  return (
    <ChakraButton
      isLoading={isLoading}
      onClick={!isDisabled ? onClick : () => false}
      h="40px"
      gap="12px"
      bg={isDisabled ? '#EFEFEF' : 'pluxee.interactive.primary'}
      color={isDisabled ? '#B5B2BC' : 'white'}
      borderRadius="none"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{
        bg: !isDisabled && '#DEF3FB',
        color: !isDisabled && 'pluxee.interactive.primary',
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important'
      }}
      _active={{
        background: !isDisabled && 'pluxee.cta.secondaryPressed',
        color: !isDisabled && 'standard.white',
        boxShadow: !isDisabled && '2px 2px 0px 0px #A9A7B6 !important'
      }}
      _focusVisible={{
        bg: !isDisabled && '#DEF3FB',
        outline: !isDisabled && '3px solid #1B51DC',
        color: !isDisabled && 'pluxee.text.primary '
      }}
    >
      <Text fontWeight="700" fontSize="xxs">
        {title}
      </Text>
      <Icon name="add" size="md" />
    </ChakraButton>
  )
}

export default Button
