import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { ProductCard } from 'components/ui'
import { Product } from 'models/Product'
import React, { useEffect, useState } from 'react'
import { WalletService } from 'services/walletService'

export const Products = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [loading, setIsLoading] = useState(false)

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await WalletService.getProducts()
      setProducts(data.products)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) return <Spinner />

  return (
    <Box>
      <Text
        color="#221C46"
        fontSize="24px"
        fontWeight="700"
        letterSpacing="-1.2px"
        lineHeight="31.2px"
      >
        Gerenciar carteiras
      </Text>
      <Text
        color="#221C46"
        fontSize="14px"
        fontWeight="500"
        lineHeight="20.3px"
        letterSpacing="-0.7px"
        pt="24px"
      >
        Selecione abaixo o produto que deseja visualizar:
      </Text>

      <Flex pt="16px" gap="32px">
        {products.map(item => (
          <ProductCard key={item.productId} productInfo={item} />
        ))}
      </Flex>
    </Box>
  )
}
