import { Box, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { Container, Layout, StatusLabel } from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import { useWallet } from 'contexts/walletContext'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  renderWalletIcon,
  renderWalletBg,
  WalletIcon
} from 'templates/consumerDetails/consumerWallets/walletCard'
import { formatToBrl } from 'utils/currencyUtils'
import { objectIsEmpty } from 'utils/objectUtils'
import { formatProductId } from 'utils/productUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import { formatWalletModel } from 'utils/walletUtils'

import { RenderButtons } from './components'

const WalletDetailsTemplate = () => {
  return (
    <Layout isShowBackButton colorScheme="secondary" maxWidth="1126px">
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default WalletDetailsTemplate

const Main = () => {
  const { isPayments } = useProfile()
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    getWalletById,
    walletInfo: wallet,
    isLoadingWalletInfo: isLoading
  } = useWallet()

  useEffect(() => {
    if (!id) return
    getWalletById({ walletId: Number(id) })
  }, [id])

  const isAllowTransfer = wallet?.allowTransferAux || wallet?.allowTransferPat
  const isUnlimitedDailyWithdraw = wallet?.dailyLimitWithdrawTx === 999999
  const isUnlimitedMonthlyWithdraw = wallet?.monthlyLimitWithdrawTx === 999999
  const isUnlimitedDailyTransaction = wallet?.dailyLimitTx === 999999

  const maxChar = 23
  const isHealthWallet = wallet?.name === 'Saúde & Bem-estar'
  const capitalizedWalletName = isHealthWallet
    ? capitalizeSentence(String(wallet?.name))
    : capitalizeSentence(limitString(String(wallet?.name), maxChar))

  const isShowActButtons = wallet?.status && !isPayments

  if (!id) navigate('/wallet?wallet_id_not_found')
  if (objectIsEmpty(wallet) && isLoading) return <Spinner />

  return (
    <Flex p="16px 40px" mt="16px">
      <Flex
        flexDir="column"
        maxW="1046px"
        bg="white"
        width="100%"
        p="40px"
        gap="24px"
        margin="0 auto"
        boxShadow="1px 1px 0px 0px rgba(34, 28, 70, 0.20)"
      >
        <Flex justifyContent="space-between">
          <Flex gap="24px" alignItems="center" pl="16px" pb="16px">
            <Center
              w="40px"
              h="40px"
              borderRadius="4px"
              bg={renderWalletBg(capitalizedWalletName as WalletIcon)}
            >
              {renderWalletIcon(capitalizedWalletName as WalletIcon)}
            </Center>

            <Box>
              <Text
                color="#221C46"
                fontSize="24px"
                fontWeight="700"
                letterSpacing="-1.2px"
              >
                {wallet?.name ? capitalizeSentence(wallet.name) : '-'}
              </Text>

              {wallet?.status && (
                <Flex gap="8px">
                  <Text
                    color="#463F5F"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="24px"
                    letterSpacing="-0.8px"
                  >
                    Status:
                  </Text>

                  <StatusLabel statusType={wallet?.status} />
                </Flex>
              )}
            </Box>
          </Flex>

          {isShowActButtons && (
            <RenderButtons
              id={Number(wallet.id)}
              status={wallet.status!}
              name={wallet?.name ? capitalizeSentence(wallet.name) : '-'}
            />
          )}
        </Flex>

        <Flex flexDir="column">
          <Text
            color="#221C46"
            fontSize="20px"
            fontWeight="700"
            lineHeight="32px"
            letterSpacing="-1px"
            pb="24px"
          >
            Detalhes da carteira
          </Text>
          <Flex
            w="100%"
            pt="24px"
            pb="48px"
            borderTop="1px solid #F0EEF5"
            borderBottom="1px solid #F0EEF5"
          >
            <Flex flexDir="column" w="50%" gap="8px">
              <Text
                color="#221C46"
                fontSize="16px"
                fontWeight="700"
                lineHeight="24px"
                letterSpacing="-0.8px"
              >
                Carteira
              </Text>
              <Flex>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                  w="280px"
                >
                  Prioridade:
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {wallet?.priority ?? '-'}
                </Text>
              </Flex>

              {wallet?.productId && (
                <Flex>
                  <Text
                    color="#221C46"
                    fontSize="14px"
                    fontWeight="700"
                    lineHeight="20.3px"
                    letterSpacing="-0.7px"
                    w="280px"
                  >
                    Produto:
                  </Text>
                  <Text
                    color="#221C46"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="20.3px"
                    letterSpacing="-0.7px"
                  >
                    {formatProductId(String(wallet.productId))}
                  </Text>
                </Flex>
              )}

              {wallet?.model && (
                <Flex>
                  <Text
                    color="#221C46"
                    fontSize="14px"
                    fontWeight="700"
                    lineHeight="20.3px"
                    letterSpacing="-0.7px"
                    w="280px"
                  >
                    Modelo:
                  </Text>
                  <Text
                    color="#221C46"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="20.3px"
                    letterSpacing="-0.7px"
                  >
                    {formatWalletModel(wallet.model)}
                  </Text>
                </Flex>
              )}
            </Flex>

            <Flex flexDir="column" w="50%" gap="8px">
              <Text
                color="#221C46"
                fontSize="16px"
                fontWeight="700"
                lineHeight="24px"
                letterSpacing="-0.8px"
              >
                Permissões
              </Text>
              <Flex>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                  w="280px"
                >
                  Carteira aberta?
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {wallet?.openWallet ? 'Sim' : 'Não'}
                </Text>
              </Flex>
              <Flex>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                  w="280px"
                >
                  Permite transferência?
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {isAllowTransfer ? 'Sim' : 'Não'}
                </Text>
              </Flex>
              <Flex>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                  w="280px"
                >
                  Permite saque?
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {wallet?.allowWithdraw ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          gap="8px"
          borderBottom={wallet?.mccs?.length ? '1px solid #F0EEF5' : 'none'}
          pb="48px"
        >
          <Text
            color="#221C46"
            fontSize="16px"
            fontWeight="700"
            lineHeight="24px"
            letterSpacing="-0.8px"
          >
            Limites
          </Text>
          <Box bg="#FAF8FF" padding="16px">
            <Flex gap="8px" alignItems="center">
              <Center bg="#C7FCCA" w="32px" h="32px" p="4px" borderRadius="6px">
                <Image src="/img/walletDetails/plx-icon-payment.png" />
              </Center>
              <Text
                color="#221C46"
                fontSize="14px"
                fontWeight="700"
                lineHeight="20.3px"
                letterSpacing="-0.7px"
              >
                Transações diárias
              </Text>
            </Flex>
            <Flex pt="16px" justifyContent="space-between">
              <Text
                color="#221C46"
                fontSize="14px"
                fontWeight="700"
                lineHeight="20.3px"
                letterSpacing="-0.7px"
              >
                Limite diário total:
              </Text>
              <Text
                color="#221C46"
                fontSize="14px"
                fontWeight="500"
                lineHeight="20.3px"
                letterSpacing="-0.7px"
              >
                {wallet?.dailyLimitValue
                  ? formatToBrl(wallet.dailyLimitValue)
                  : '-'}
              </Text>
            </Flex>
            <Flex pt="8px" justifyContent="space-between">
              <Text
                color="#221C46"
                fontSize="14px"
                fontWeight="700"
                lineHeight="20.3px"
                letterSpacing="-0.7px"
              >
                Quantidade de transações diárias:
              </Text>
              <Text
                color="#221C46"
                fontSize="14px"
                fontWeight="500"
                lineHeight="20.3px"
                letterSpacing="-0.7px"
              >
                {isUnlimitedDailyTransaction
                  ? 'Ilimitado'
                  : `${wallet?.dailyLimitTx} transações`}
              </Text>
            </Flex>
          </Box>

          {wallet?.allowWithdraw && (
            <Box bg="#FAF8FF" padding="16px">
              <Flex gap="8px" alignItems="center">
                <Center
                  bg="#C7FCCA"
                  w="32px"
                  h="32px"
                  p="4px"
                  borderRadius="6px"
                >
                  <Image src="/img/walletDetails/plx-icon-money.png" />
                </Center>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  Saques
                </Text>
              </Flex>
              <Flex pt="16px" justifyContent="space-between">
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  Limite diário total:
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {wallet?.dailyLimitWithdrawValue
                    ? formatToBrl(wallet.dailyLimitWithdrawValue)
                    : '-'}
                </Text>
              </Flex>
              <Flex pt="8px" justifyContent="space-between">
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  Quantidade de transações diárias:
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {isUnlimitedDailyWithdraw
                    ? 'Ilimitado'
                    : `${wallet.dailyLimitWithdrawTx} transações`}
                </Text>
              </Flex>
              <Flex pt="8px" justifyContent="space-between">
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  Taxa de saque:
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {wallet?.withdrawFee ? formatToBrl(wallet.withdrawFee) : '-'}
                </Text>
              </Flex>
              <Flex pt="8px" justifyContent="space-between">
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  Quantidade de saques mensais:
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {isUnlimitedMonthlyWithdraw
                    ? 'Ilimitado'
                    : `${wallet.monthlyLimitWithdrawTx} transações`}
                </Text>
              </Flex>
              <Flex pt="8px" justifyContent="space-between">
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  Limite mensal total:
                </Text>
                <Text
                  color="#221C46"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20.3px"
                  letterSpacing="-0.7px"
                >
                  {wallet.monthlyLimitWithdrawValue
                    ? formatToBrl(wallet.monthlyLimitWithdrawValue)
                    : '-'}
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>

        {!wallet?.openWallet && (
          <Flex flexDir="column" gap="8px" pb="48px">
            <Text
              color="#221C46"
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              letterSpacing="-0.8px"
            >
              MCCs aceitas:
            </Text>
            <Box bg="#FAF8FF" padding="16px 34px">
              <Flex gap="8px" alignItems="center" flexWrap="wrap">
                {wallet?.mccs?.map((item, index) => (
                  <Center
                    key={`MCC - ${index}`}
                    bg="#E3E2FA"
                    w="60px"
                    h="32px"
                    p="4px 8px"
                  >
                    <Text
                      color="#221C46"
                      fontSize="14px"
                      fontWeight="700"
                      letterSpacing="-0.7px"
                      lineHeight="20.3px"
                    >
                      {item}
                    </Text>
                  </Center>
                ))}
              </Flex>
            </Box>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
