import { Flex, HStack, VStack } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import React from 'react'
import ContentLoader from 'react-content-loader'

import { BackToAllTransactionsButton } from '../backToAllTransactionsButton'

import { TransactionsTable } from './tableTransactions'

export const Transactions = () => {
  const { isLoading, isRefetching, isError } = useWalletTransaction()

  if (isLoading || isRefetching) return <Skeleton />
  if (isError) return <NoDataFound />

  return (
    <VStack align="start" w="100%">
      <Flex w="100%" justify="start" mb="10px" gap="xxs">
        <BackToAllTransactionsButton />
      </Flex>

      <HStack w="100%">
        <TransactionsTable />
      </HStack>
    </VStack>
  )
}

const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)
