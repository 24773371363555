import { useMutation, useQuery } from 'react-query'
import { ConsumerService, GetByCpfFilters } from 'services/consumerService'

export function useQueryGetAll() {
  return useQuery('getAll', () => getAll())
}

export function useQueryGetById(id: string) {
  return useQuery(['getById', id], () => getById(id))
}

export function useQueryGetByCPF(cpf?: string) {
  return useQuery(['getByCPF', cpf], () => getByCPF(cpf), { retry: 0 })
}

export function useMutateGetByCPFWithFilters() {
  return useMutation(getByCPFWithFilters, { retry: 0 })
}

const getAll = async () => {
  const response = await ConsumerService.getAll()

  return response.data
}

const getById = async (id: string) => {
  const response = await ConsumerService.getById(id)
  return response.data
}

const getByCPF = async (cpf?: string) => {
  if (!cpf) return

  const response = await ConsumerService.getByCPF(cpf)
  return response.data
}

const getByCPFWithFilters = async ({
  cpf,
  filters
}: {
  cpf: string
  filters: GetByCpfFilters
}) => {
  if (!cpf) return

  const response = await ConsumerService.getByCpfWithFilters(cpf, filters)
  return response.data
}
