import { Box, Flex, Text } from '@chakra-ui/react'
import { Loading } from 'components/ui'
import { Container, Layout, SearchInput, SubmitButton } from 'components/ui'
import { useCustomer } from 'contexts/customerContext'
import { useProfile } from 'contexts/profileContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { sanitizeObject } from 'utils/sanitizeObject'

import { CustomersTable } from './table/table'

const SearchCustomerPluxee = () => {
  const { user } = useProfile()
  if (!user) return <Loading />

  const { removeStorageItem } = useLocalStorage()
  const removeCachedDocument = () => removeStorageItem('cachedDocument')

  return (
    <Layout isShowBackButton onPageBack={removeCachedDocument}>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default SearchCustomerPluxee

const Main = () => {
  const [isShowTable, setIsShowTable] = useState(false)
  const [isFirstRequest, setIsFirstRequest] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(true)

  const [value, setValue] = useState('')
  const { alert } = useAlert()
  const { getStorageItem, removeStorageItem, setStorageItem } =
    useLocalStorage()
  const { setDocument, isLoading, isError, customers, isSuccess } =
    useCustomer()

  const cachedDocument = getStorageItem('cachedDocument')
  const minDocumentCharacters = 11
  const isEmptyTable =
    (!customers.length && isSuccess && isFirstRequest) || isError

  const handleSearch = useCallback(() => {
    if (value.length < minDocumentCharacters) {
      return alert({
        id: 'customerToast',
        status: 'warning',
        title: 'Preencha o CPF/CNPJ corretamente!'
      })
    }

    const sanitizedValue = sanitizeObject(value)

    setDocument(sanitizedValue.slice(0, 14))
    setStorageItem('cachedDocument', sanitizedValue.slice(0, 14))
    setIsFirstRequest(true)
  }, [setDocument, value])

  const handleInputChange = (inputValue: string) => {
    removeCachedDocument()

    inputValue.length > 11
      ? setValue(Mask.removeMaskCnpj(inputValue))
      : setValue(Mask.removeMaskCpf(inputValue))
  }

  const removeCachedDocument = () => removeStorageItem('cachedDocument')
  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDocument)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDocument)
    }
  }, [removeCachedDocument])

  useEffect(() => {
    if (cachedDocument) {
      setValue(cachedDocument)
      handleSearch()
    }
  }, [cachedDocument, value])

  useLayoutEffect(() => {
    if (customers.length) setIsShowTable(true)
    else setIsShowTable(false)
  }, [customers])

  useLayoutEffect(() => {
    const isValidCpf = value.length === 11
    const isValidCnpj = value.length >= 14

    const hasValidValue = isValidCpf || isValidCnpj

    if (hasValidValue) setIsDisabledButton(false)
    else setIsDisabledButton(true)
  }, [value])

  return (
    <Flex flexDir="column" bg="standard.white" w="100%" p="40px">
      <Text
        color="pluxee.text.primary"
        fontWeight="700"
        fontSize="md"
        mb="24px"
      >
        Consultar cliente
      </Text>

      <Flex gap="32px" alignItems="flex-end" mb="32px">
        <Box width="100%">
          <SearchInput
            size="1.5rem"
            customIcon="search"
            isError={isEmptyTable}
            onChange={handleInputChange}
            label="CNPJ ou CPF do cliente"
            placeholder="Informe o CNPJ ou CPF do cliente"
            value={value.length > 11 ? Mask.CNPJ(value) : Mask.CPF(value)}
          />

          {isEmptyTable && (
            <Text color="#B30000" fontSize="xxxs" fontWeight="600" mt="4px">
              Cliente não encontrado, verifique e tente novamente.
            </Text>
          )}
        </Box>

        <SubmitButton
          onClick={handleSearch}
          isLoading={isLoading}
          isDisabled={isDisabledButton}
          decreaseMarginBottom={isEmptyTable}
        />
      </Flex>

      {isShowTable && <CustomersTable />}
    </Flex>
  )
}
