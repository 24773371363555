import { Flex, Text, Button } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { CustomerWallet } from 'models/CustomerWallet'
import React from 'react'

export const WithdrawInfo = ({
  customerWallet,
  onClick
}: {
  customerWallet: CustomerWallet
  onClick: () => void
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="4px">
      <Flex
        flexDir="column"
        gap="4px"
        color="#221C46"
        letterSpacing="-0.8px"
        fontWeight="700"
      >
        <Text fontSize="24px">Informações de saque</Text>
        <Text fontSize="14px" fontWeight="500">
          Customize as informações de saque conforme o contrato do cliente.
        </Text>
      </Flex>

      {customerWallet.customValues && (
        <Flex
          as={Button}
          onClick={onClick}
          gap="10px"
          h="32px"
          p="0px 12px 0px 8px"
          alignItems="center"
          justifyContent="space-between"
          bg="white"
          borderRadius="0"
          border="3px solid #1B51DC"
          color="#1B51DC"
          _hover={{
            boxShadow: '4px 4px 0px 0px #CBCAD2 !important',
            background: '#DEF3FB',
            color: '#1B51DC'
          }}
          _active={{
            boxShadow: '2px 2px 0px 0px #CBCAD2 !important',
            color: '#fff',
            background: '#0F266D',
            borderColor: '#0F266D'
          }}
          _focusVisible={{
            boxShadow: 'none !important',
            background: '#DEF3FB',
            color: '#1B51DC',
            outline: '3px solid #1B51DC'
          }}
        >
          <Icon name="refresh" size="sm" />
          <Text
            mt="2px"
            fontWeight="700"
            fontSize="12px"
            letterSpacing="-0.8px"
          >
            Retornar aos valores padrão
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
