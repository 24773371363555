import { Divider } from '@chakra-ui/react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  CloseButton
} from 'components/atoms'
import React from 'react'

type ModalSizes = '6xl' | '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'md' | 'lg'

export type ModalProps = {
  heading: string
  children: React.ReactNode
  footer: React.ReactNode
  isOpen: boolean
  hideLine?: boolean
  titleSpacingToBottom?: string
  onClose: () => void
  size?: ModalSizes
  decreaseMarginTop?: boolean
  onCloseComplete?: () => void
  closeOnOverlayClick?: boolean
}

const Modal = ({
  size = '2xl',
  heading,
  children,
  footer,
  isOpen,
  onClose,
  decreaseMarginTop,
  onCloseComplete,
  hideLine = false,
  titleSpacingToBottom = '24px',
  closeOnOverlayClick = true
}: ModalProps) => {
  return (
    <ChakraModal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" backdropFilter="blur(1px)" />
      <ModalContent
        fontFamily="TTTravels"
        bg="standard.white"
        boxShadow="4px 4px 0px 0px rgba(34, 28, 70, 0.20) !important"
        mt={decreaseMarginTop ? '60px' : '150px'}
        borderRadius="none"
      >
        <ModalHeader bg="standard.white">
          <Text
            fontSize="md"
            fontWeight="700"
            color="pluxee.text.primary"
            lineHeight="31.2px"
            paddingTop="16px"
            letterSpacing="-1.2px"
            mb={titleSpacingToBottom}
          >
            {heading}
          </Text>

          {!hideLine && <Divider />}
        </ModalHeader>

        <ModalCloseButton
          w="40px"
          h="40px"
          marginTop="10px"
          marginRight="3px"
          borderRadius="none"
          _focusVisible={{
            border: 'none'
          }}
          _active={{
            background: 'transparent'
          }}
        >
          <CloseButton
            data-testid="closeButton"
            border="none"
            outline="none"
            fontSize="20px"
            color="pluxee.interactive.primary"
            _active={{
              color: 'pluxee.cta.secondaryPressed',
              background: 'transparent'
            }}
            _hover={{
              background: 'transparent',
              color: 'pluxee.cta.secondaryPressed'
            }}
            _focus={{
              background: 'transparent',
              border: 'none',
              outline: 'none'
            }}
            _focusVisible={{
              border: '3px solid',
              borderColor: 'pluxee.text.link',
              color: 'pluxee.cta.secondaryPressed',
              padding: '17px',
              borderRadius: 'none'
            }}
          />
        </ModalCloseButton>

        <ModalBody>{children}</ModalBody>

        <ModalFooter
          py="none"
          paddingBottom="16px"
          borderRadius="none"
          border="none"
        >
          {footer}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
