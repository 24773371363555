import { GetAllProps } from 'modules/users/application/queries/GetAllProps'
import { UpdateProps } from 'modules/users/application/queries/UpdateProps'
import { CreateUser } from 'modules/users/application/useCases/CreateUser'
import { DeleteUser } from 'modules/users/application/useCases/DeleteUser'
import { GetAllUsers } from 'modules/users/application/useCases/GetAllUsers'
import { GetRoles } from 'modules/users/application/useCases/GetRoles'
import { UpdateUser } from 'modules/users/application/useCases/UpdateUser'
import { UserRepository } from 'modules/users/infra/repositories/UserRepository'
import { CreateAndUpdateUserProps } from 'modules/users/presentation/contexts/UserContext'
import { useQuery, useMutation } from 'react-query'

const userRepository = new UserRepository()

const getAllUsers = new GetAllUsers(userRepository)
const createUser = new CreateUser(userRepository)
const updateUser = new UpdateUser(userRepository)
const deleteUser = new DeleteUser(userRepository)
const getRoles = new GetRoles(userRepository)

export const useQueryGetAllUsers = (filters: GetAllProps) => {
  return useQuery(['getAllUsers', filters], () => getAllUsers.execute(filters))
}

export const useMutationCreateUser = () => {
  return useMutation((user: CreateAndUpdateUserProps) =>
    createUser.execute(user)
  )
}

export const useMutationUpdateUser = () => {
  return useMutation(({ id, user }: UpdateProps) =>
    updateUser.execute({ id, user })
  )
}

export const useMutationDeleteUser = () => {
  return useMutation((id: number) => deleteUser.execute(id))
}

export const useQueryGetRoles = () => {
  return useQuery(['getUsersRole'], () => getRoles.execute())
}

export const useQueryFetchUsers = (filters?: GetAllProps) => {
  return useQuery(['getUsers', filters], () => userRepository.getAll(filters))
}
