import { Box, Flex } from '@chakra-ui/react'
import { Checkbox, SearchInput, SubmitButton } from 'components/ui'
import { useSchedules } from 'contexts/scheduleContext'
import { useAlert } from 'hooks/useAlert'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useCallback, useEffect, useState } from 'react'
import { sanitizeObject } from 'utils/sanitizeObject'

import { ScheduleTable } from './scheduleTable/table'

export const SearchArea = () => {
  const [showTable, setShowTable] = useState(false)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [orderCode, setOrderCode] = useState('')
  const [clientCode, setClientCode] = useState('')
  const [listAllOrders, setListAllOrders] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [cacheLoaded, setCacheLoaded] = useState(false)

  const [isOrderCodeError, setIsOrderCodeError] = useState(false)
  const [isClientCodeError, setIsClientCodeError] = useState(false)

  const { alert } = useAlert()
  const { getStorageItem, setStorageItem, removeStorageItem } =
    useLocalStorage()

  const {
    getSchedulesByOrderId,
    getSchedulesByCustomerId,
    schedules,
    schedulesPagination,
    isLoadingSchedulesByOrder,
    isLoadingSchedulesByCustomer
  } = useSchedules()

  const isLoading = isLoadingSchedulesByOrder || isLoadingSchedulesByCustomer
  const isError = isOrderCodeError || isClientCodeError
  const hasOrderCode = orderCode.length > 0
  const hasClientCode = clientCode.length > 0

  const resetState = () => {
    setShowTable(false)
    setShowCheckbox(false)
    setIsOrderCodeError(false)
    setIsClientCodeError(false)
  }

  const fetchSchedules = useCallback(
    async (pageNumber = 0, filterByScheduleds = listAllOrders) => {
      resetState()

      if (hasOrderCode) {
        getSchedulesByOrderId(orderCode)
          .then(() => {
            setShowTable(true)
          })
          .catch(() => {
            setIsOrderCodeError(true)
          })
      } else if (hasClientCode) {
        try {
          await getSchedulesByCustomerId({
            customerId: clientCode,
            filterByScheduleds,
            pageNumber: String(pageNumber)
          })

          setShowCheckbox(true)
          setShowTable(true)
        } catch (error) {
          if (filterByScheduleds) {
            alert({
              id: 'noScheduledOrders',
              status: 'warning',
              title:
                'Nenhum pedido agendado encontrado. Mostrando todos os pedidos.'
            })

            setListAllOrders(false)
            fetchSchedules(pageNumber, false)
          } else {
            setIsClientCodeError(true)
          }
        }
      }
    },
    [orderCode, clientCode, listAllOrders, currentPage]
  )

  const handleGetSchedules = useCallback(() => {
    resetState()
    setStorageItem('cachedOrderCode', orderCode)
    setStorageItem('cachedClientCode', clientCode)
    fetchSchedules(0)
  }, [orderCode, clientCode, listAllOrders])

  useEffect(() => {
    setIsActiveButton(hasOrderCode || hasClientCode)
  }, [orderCode, clientCode])

  useEffect(() => {
    const cachedOrder = getStorageItem('cachedOrderCode')
    const cachedClient = getStorageItem('cachedClientCode')
    if (cachedOrder) setOrderCode(cachedOrder)
    if (cachedClient) setClientCode(cachedClient)
    setCacheLoaded(true)

    if (cachedOrder || cachedClient) fetchSchedules(0)
  }, [])

  useEffect(() => {
    if (cacheLoaded) fetchSchedules(0)
  }, [cacheLoaded])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeStorageItem('cachedOrderCode')
      removeStorageItem('cachedClientCode')
    })
    return () => {
      window.removeEventListener('beforeunload', () => {
        removeStorageItem('cachedOrderCode')
        removeStorageItem('cachedClientCode')
      })
    }
  }, [])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    fetchSchedules(page)
  }

  const handleCheckboxChange = () => {
    setListAllOrders(prev => {
      const newListAllOrders = !prev
      fetchSchedules(0, newListAllOrders)
      return newListAllOrders
    })
  }

  const defaultPagination = {
    currentPage: currentPage,
    totalPages: schedulesPagination?.totalPages || 0,
    totalResults: schedulesPagination?.totalResults || 0
  }

  return (
    <>
      <Flex
        gap="29px"
        alignItems={isError ? 'flex-start' : 'flex-end'}
        minH="80px"
      >
        <SearchInput
          size="1.5rem"
          isOptionalLabel
          value={orderCode}
          customFontSize="16px"
          label="Código do pedido"
          imageIcon="/img/schedules/cart.svg"
          placeholder="Informe o código do pedido"
          onChange={inputValue => setOrderCode(sanitizeObject(inputValue))}
          errorMessage="Pedido não encontrado, verifique e tente novamente"
          isError={isOrderCodeError}
        />

        <SearchInput
          size="1.5rem"
          isOptionalLabel
          value={clientCode}
          customFontSize="16px"
          label="Código do cliente"
          imageIcon="/img/schedules/user.svg"
          placeholder="Informe o código do cliente"
          onChange={inputValue => setClientCode(sanitizeObject(inputValue))}
          errorMessage="Cliente não encontrado ou sem pedidos realizados."
          isError={isClientCodeError}
        />

        <Flex minW="199px" mt={isError ? '28px' : '0px'}>
          <SubmitButton
            onClick={handleGetSchedules}
            isLoading={isLoading}
            isDisabled={!isActiveButton}
          />
        </Flex>
      </Flex>

      {showCheckbox && (
        <Box mt="20px">
          <Checkbox
            title="Mostrar apenas pedidos agendados"
            checked={listAllOrders}
            onChange={handleCheckboxChange}
          />
        </Box>
      )}

      {showTable && (
        <Box mt="20px">
          <ScheduleTable
            schedules={schedules}
            currentPage={currentPage}
            handleNextPage={() => handlePageChange(currentPage + 1)}
            handlePrevPage={() => handlePageChange(currentPage - 1)}
            handleOnChangePage={handlePageChange}
            isLoading={isLoading}
            schedulesPagination={schedulesPagination ?? defaultPagination}
          />
        </Box>
      )}
    </>
  )
}
