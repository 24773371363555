import { useConsumer } from 'contexts/consumerContext'
import React from 'react'

import { AccountTransactions } from './accountTransactions/accountTransactions'
import { TabTransactions } from './tabTransactions/tabTransactions'

export const Transactions = () => {
  const { isShowAllTransactions } = useConsumer()
  if (isShowAllTransactions) return <AccountTransactions />

  return <TabTransactions />
}
