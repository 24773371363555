import React, { ReactNode, ElementType, ReactElement } from 'react'

type ProviderWithProps = {
  Provider: ElementType
  props?: Record<string, any>
}

type ComposeProvidersProps = {
  providers: ProviderWithProps[]
  children: ReactNode
}

const ComposeProviders = ({
  providers,
  children
}: ComposeProvidersProps): ReactElement => {
  return (
    <>
      {providers.reduceRight((acc: ReactNode, { Provider, props }) => {
        return <Provider {...props}>{acc}</Provider>
      }, children)}
    </>
  )
}

export default ComposeProviders
