import { GetAllProps } from 'modules/users/application/queries/GetAllProps'
import { UserRole } from 'modules/users/domain/entities/UserRole'
import { IUserRepository } from 'modules/users/domain/repositories/IUserRepository'
import { usersApi } from 'modules/users/infra/api/UsersApi'
import { CreateAndUpdateUserProps } from 'modules/users/presentation/contexts/UserContext'

import { GetUsersResponse } from '../dto/GetUsersResponse'

export class UserRepository implements IUserRepository {
  async getAll(filters?: GetAllProps): Promise<GetUsersResponse> {
    const response = await usersApi.get('/users', {
      params: filters,
      data: null
    })

    return response.data
  }

  async create(user: CreateAndUpdateUserProps): Promise<void> {
    await usersApi.post('/users', user, { data: null })
  }

  async update(id: number, user: CreateAndUpdateUserProps): Promise<void> {
    await usersApi.put(`/users/${id}`, user, { data: null })
  }

  async remove(id: number): Promise<void> {
    await usersApi.delete(`/users/${id}`, { data: null })
  }

  async getRoles(): Promise<UserRole[]> {
    const response = await usersApi.get('/roles', { data: null })
    return response.data
  }
}
