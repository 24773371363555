import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { Toggle } from 'components/ui'
import { ControlledInput } from 'components/ui/Input'
import React, { useCallback, useEffect, useState } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { formatMcc } from 'utils/mccUtils'

import { WalletAddInputs } from '../validations/walletAddSchema'

import AreaLabel from './areaLabel'

type MccAreaProps = {
  existingMccs?: string[]
  isInvalid?: boolean
  isDisabled?: boolean
  checkedOpenWallet?: boolean
  setCheckedOpenWallet: () => void
  register: UseFormRegister<WalletAddInputs>
  setValue: UseFormSetValue<WalletAddInputs>
}

const MccArea = ({
  existingMccs,
  register,
  setValue,
  isInvalid,
  isDisabled,
  checkedOpenWallet,
  setCheckedOpenWallet
}: MccAreaProps) => {
  const [mccs, setMccs] = useState<string[]>([])
  const [mccErrorMsg, setMccErrorMsg] = useState(false)
  const [mccInputValue, setMCCInputValue] = useState('')

  const removeMCC = (index: number) => {
    const newMccs = mccs.filter((_, i) => i !== index)
    setMccs(newMccs)
  }

  const addMCC = useCallback(() => {
    if (!mccInputValue) return

    if (mccInputValue.length > 4) {
      const newMccsArray = mccInputValue.split(',')
      const newMccs = [...mccs, ...newMccsArray]

      setMccs(newMccs)
      setMCCInputValue('')
      return
    }

    if (mccs.includes(mccInputValue)) return setMccErrorMsg(true)

    const newMccs = [...mccs, mccInputValue]
    setMccs(newMccs)
    setMCCInputValue('')
    setMccErrorMsg(false)
  }, [mccInputValue, mccs])

  useEffect(() => {
    if (existingMccs) setMccs(existingMccs)
  }, [existingMccs])

  useEffect(() => {
    if (mccs.length < 1) setMccErrorMsg(false)
    if (mccs) setValue('mccs', mccs)
  }, [mccs])

  return (
    <Flex w="100%" flexDir="column" gap="8px">
      <Flex w="100%">
        <AreaLabel title="MCC" />

        <Toggle
          isReverseLayout
          title="Esta é uma carteira aberta?"
          tooltip="Uma carteira aberta não possui Limitação de MCCs e será aceita em qualquer tipo de estabelecimento"
          justifyContent="flex-end"
          register={register}
          id={'wallet.openWallet'}
          isChecked={checkedOpenWallet}
          onChange={setCheckedOpenWallet}
        />
      </Flex>

      <Flex gap="16px" alignItems="center">
        <Flex w="100%" flexDir="column" gap="4px">
          <ControlledInput
            height="48px"
            id="mccs"
            title="MCCs aceitas por esta carteira:"
            isRequired
            placeholder="Informe o código MCC"
            register={register}
            isInvalid={isInvalid || mccErrorMsg}
            isDisabled={isDisabled}
            value={formatMcc(mccInputValue)}
            onChange={event => setMCCInputValue(event.currentTarget.value)}
          />

          {mccErrorMsg && (
            <Text
              letterSpacing="-0.6px"
              fontSize="12px"
              fontWeight="600"
              color="#B30000"
            >
              MCC já adicionado
            </Text>
          )}
        </Flex>

        <Button
          mt={mccErrorMsg ? '5px' : '26px'}
          h="48px"
          p="12px 16px 12px 24px"
          gap="12px"
          bg="transparent"
          border="3px solid"
          borderRadius="0"
          borderColor={isDisabled ? '#B5B2BC' : '#221C46'}
          color={isDisabled ? '#B5B2BC' : '#221C46'}
          onClick={addMCC}
          _hover={
            !isDisabled
              ? {
                  background: '#C7FCCA',
                  boxShadow: '4px 4px 0px 0px #CBCAD2 !important'
                }
              : {}
          }
          _active={
            !isDisabled
              ? {
                  background: '#00330E',
                  boxShadow: '2px 2px 0px 0px #CBCAD2 !important',
                  color: 'white',
                  borderColor: '#00330E'
                }
              : {}
          }
          _focusVisible={
            !isDisabled
              ? {
                  background: '#C7FCCA',
                  outline: '3px solid #1B51DC'
                }
              : {}
          }
        >
          <Text
            letterSpacing="-0.8px"
            color="inherit"
            fontWeight="700"
            fontSize="16px"
          >
            Adicionar MCC
          </Text>
          <Icon name="add" color="inherit" />
        </Button>
      </Flex>

      <Flex flexWrap="wrap" gap="8px">
        {!checkedOpenWallet &&
          mccs?.map((item, index) => (
            <Center
              key={`MCC - ${index}`}
              gap="4px"
              h="40px"
              p="8px 12px 8px 12px"
              bg="#E3E2FA"
            >
              <Text
                letterSpacing="-0.7px"
                fontSize="14px"
                fontWeight="700"
                color="#221C46"
              >
                {item}
              </Text>

              <Center onClick={() => removeMCC(index)}>
                <Icon size="md" cursor="pointer" name="close" color="#1B51DC" />
              </Center>
            </Center>
          ))}
      </Flex>
    </Flex>
  )
}

export default MccArea
