import { useAlert } from 'hooks/useAlert'
import { User } from 'modules/users/domain/entities/User'
import { useUser } from 'modules/users/presentation/contexts/UserContext'
import { UserFormInputs } from 'modules/users/presentation/validations/UserFormValidation'
import React, { useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formatErrorMessage, ErrorMessage } from 'utils/errorUtils'
import { sanitizeValue } from 'utils/sanitizeObject'
import { hasPluxeeGroupDomain } from 'utils/stringUtils'

import { UserFormPresentation } from '../presentational/UserFormPresentation'

interface UserFormContainerProps {
  isOpen: boolean
  onClose: () => void
  user?: User
}

export const UserFormContainer = ({
  isOpen,
  onClose,
  user
}: UserFormContainerProps) => {
  const isEditing = !!user
  const { alert } = useAlert()

  const {
    roles,
    isLoadingRoles,
    createUser,
    updateUser,
    isLoadingCreate,
    isLoadingUpdate,
    updateUsersList
  } = useUser()

  const {
    watch,
    reset,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm<UserFormInputs>()

  const [isEmailValid, setIsEmailValid] = useState(isEditing)

  const username = watch('username')?.trim().length >= 1
  const login = watch('login')?.trim().length >= 1
  const isValidFields = isEditing ? username : username && login && isEmailValid

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'username' || name === 'login') {
        const sanitizedValue = sanitizeValue(value[name])
        if (sanitizedValue !== value[name]) {
          setValue(name as keyof UserFormInputs, sanitizedValue)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value

    if (!hasPluxeeGroupDomain(email)) {
      setError('login', {
        type: 'manual',
        message: 'O email deve conter o domínio @pluxeegroup.com'
      })
      setIsEmailValid(false)
    } else {
      clearErrors('login')
      setIsEmailValid(true)
    }
  }

  const onSubmit: SubmitHandler<UserFormInputs> = async formData => {
    try {
      const userData = {
        name: formData.username,
        login: isEditing ? user.login : formData.login,
        id_role: Number(formData.role?.value)
      }

      if (!isEditing) {
        await createUser(userData)
        alert({
          id: 'successCreateUser',
          status: 'success',
          title: 'Usuário criado com sucesso'
        })
      } else {
        await updateUser(user.id, userData)
        alert({
          id: 'successUpdateUser',
          status: 'success',
          title: 'Usuário atualizado com sucesso'
        })
      }

      reset()
      onClose()
      updateUsersList()
    } catch (err: any) {
      const errorData = err?.response?.data

      if (Array.isArray(errorData)) {
        const errMsg = errorData.map((e: any) => e.message).join(', ')
        alert({
          id: 'errorValidation',
          status: 'error',
          title: formatErrorMessage(errMsg as ErrorMessage) ?? errMsg
        })
      } else if (errorData?.message === 'login already exist') {
        const msg = formatErrorMessage(errorData.message as ErrorMessage)
        setError('login', { message: msg })
      } else {
        alert({
          id: 'errorUserAction',
          status: 'error',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
        })
      }
    }
  }

  useEffect(() => {
    if (isEditing) {
      reset({
        username: user.name,
        role: { label: user.role?.name, value: user.role?.id }
      })
    }
  }, [isEditing, user])

  return (
    <UserFormPresentation
      isOpen={isOpen}
      onClose={onClose}
      isEditing={isEditing}
      control={control}
      errors={errors}
      isLoadingCreate={isLoadingCreate}
      isLoadingUpdate={isLoadingUpdate}
      isValidFields={isValidFields}
      roles={roles?.map(({ id, name }) => ({ value: id, label: name }))}
      isLoadingRoles={isLoadingRoles}
      handleSubmit={handleSubmit(onSubmit)}
      handleChangeEmail={handleChangeEmail}
      resetForm={() => {
        reset({
          username: user?.name,
          role: { label: user?.role?.name, value: user?.role?.id }
        })
      }}
    />
  )
}
