import { useAccountWallet } from 'contexts/accountWalletContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useAlert } from 'hooks/useAlert'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ConsumerInfo from './consumerInfo'

export const AccountDetails = () => {
  const params = useParams()
  const { alert } = useAlert()

  const navigate = useNavigate()
  const { setAccountId } = useAccountWallet()
  const { setAccountId: setWalletTransactionAccountId } = useWalletTransaction()

  useEffect(() => {
    const accountId = Number(params.id)

    setAccountId(accountId)
    setWalletTransactionAccountId(accountId)
  }, [])

  useEffect(() => {
    if (!params.id) {
      alert({
        status: 'error',
        id: 'errorConsumerDetails',
        title: 'Ocorreu um erro, tente novamente mais tarde!'
      })

      navigate('/consumer')
    }
  }, [params])

  return <ConsumerInfo />
}
