import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

type HeaderProps = {
  buttons: React.ReactNode
}

const Header = ({ buttons }: HeaderProps) => {
  return (
    <Flex alignItems="center" flexWrap="wrap" gap="16px">
      <Text
        color="#221C46"
        fontSize="24px"
        fontWeight="700"
        letterSpacing="-1.2px"
      >
        Carteiras
      </Text>

      {buttons}
    </Flex>
  )
}

export default Header
