import {
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  VStack
} from 'components/atoms'
import { CloseButton, Modal } from 'components/ui'
import React from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { capitalizeSentence } from 'utils/stringUtils'

import { StatusInfo, StatusInfoProps } from './StatusInfo'
import { StatusEnum, WalletStatus } from './walletStatus'

type Wallet = {
  dailyTxUnlimited: boolean
  dailyLimitTx: number
  dailyLimitValue: number
  dailyLimitWithdrawTx?: number
  dailyLimitWithdrawValue?: number
  monthlyLimitWithdrawTx?: number
  monthlyLimitWithdrawValue?: number
  withdrawFee?: number
  status: string
}

type WalletDetailsProps = {
  isOpen: boolean
  onClose: () => void
  name: string
  status: string
  allowTransfer: boolean
  allowWithdraw: boolean
  lastModified?: string
  wallet: Wallet
  dailyLimitWithdrawTx: number
  dailyLimitWithdrawValue: number
  monthlyLimitWithdrawTx: number
  monthlyLimitWithdrawValue: number
  withdrawFee?: number
}

export const WalletDetails = ({
  isOpen,
  onClose,
  name,
  allowTransfer,
  allowWithdraw,
  status,
  wallet,
  dailyLimitWithdrawTx,
  dailyLimitWithdrawValue,
  monthlyLimitWithdrawTx,
  monthlyLimitWithdrawValue,
  withdrawFee
}: WalletDetailsProps) => {
  const isUnlimitedValueTransaction = wallet.dailyLimitValue >= 999999
  const isUnlimitedValueWithdraw = dailyLimitWithdrawValue! >= 999999

  const isUnlimitedDailyTx = wallet.dailyLimitTx >= 999999
  const isUnlimitedDailyTxWithdraw = dailyLimitWithdrawTx! >= 999999
  const isUnlimitedMonthlyTxWithdraw = monthlyLimitWithdrawTx! >= 999999

  const modalFooter = <CloseButton onClick={onClose} />

  return (
    <Modal
      heading="Limites em carteira"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      decreaseMarginTop
      hideLine
      titleSpacingToBottom="0"
    >
      <VStack spacing="xxs" align="start" letterSpacing="-0.8px">
        <HStack justify="space-between" w="100%">
          <Text fontWeight="700" color="pluxee.text.link" fontSize="md">
            {capitalizeSentence(name)}
          </Text>
        </HStack>

        <Divider />

        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Flex flexDir="column" gap="6px" alignItems="flex-end">
            <Flex gap="8px" alignItems="center">
              <Text fontWeight="700" fontSize="xs" color="pluxee.text.primary">
                Status:
              </Text>
              <WalletStatus
                fontSize="14px"
                padding="4px 8px"
                hideTooltip
                status={
                  getFinalWalletStatus({
                    customerWalletStatus: status,
                    walletCatalogStatus: wallet.status
                  }) as StatusEnum
                }
              />
            </Flex>
          </Flex>

          <StatusInfo
            walletCatalogStatus={wallet.status}
            customerWalletStatus={status}
          />
        </Flex>

        <Divider />

        <Flex w="100%" gap="24px">
          <Flex flex="1" flexDir="column" gap="24px">
            <Flex
              gap="8px"
              flexDir="column"
              fontWeight="700"
              color="pluxee.text.primary"
              fontSize="xs"
            >
              <Text>Permissões</Text>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Permite transferência?
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {allowTransfer ? 'Sim' : 'Não'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Permite saque?
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {allowWithdraw ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Flex>

            <Divider />
          </Flex>
        </Flex>

        <Flex w="100%" gap="16px" flexDir="column">
          <Flex flex="1" flexDir="column" gap="16px">
            <Text
              color="pluxee.text.primary"
              fontWeight="700"
              lineHeight="24px"
              fontSize="xs"
            >
              Limites
            </Text>
          </Flex>

          <ContextBox icon="payment" title="Transações diárias">
            <Details
              title="Limite diário total:"
              description={
                isUnlimitedValueTransaction
                  ? 'Ilimitado'
                  : formatToBrl(wallet.dailyLimitValue)
              }
            />

            <Details
              title="Quantidade de transações diárias:"
              description={
                isUnlimitedDailyTx ? 'Ilimitado' : wallet.dailyLimitTx
              }
            />
          </ContextBox>

          {allowWithdraw && (
            <ContextBox icon="dinner" title={'Saques'}>
              <Details
                title="Limite diário total:"
                description={
                  isUnlimitedValueWithdraw
                    ? 'Ilimitado'
                    : formatToBrl(dailyLimitWithdrawValue ?? 0)
                }
              />

              <Details
                title="Quantidade de saques diários:"
                description={
                  isUnlimitedDailyTxWithdraw
                    ? 'Ilimitado'
                    : dailyLimitWithdrawTx
                }
              />

              <Details
                title="Taxa de saque:"
                description={
                  withdrawFee ? formatToBrl(withdrawFee) : formatToBrl(0)
                }
              />

              <Details
                title="Quantidade de saques mensais:"
                description={
                  isUnlimitedMonthlyTxWithdraw
                    ? 'Ilimitado'
                    : monthlyLimitWithdrawTx
                }
              />

              <Details
                title="Limite mensal total:"
                description={
                  monthlyLimitWithdrawValue
                    ? formatToBrl(monthlyLimitWithdrawValue)
                    : ''
                }
              />
            </ContextBox>
          )}
        </Flex>
      </VStack>
    </Modal>
  )
}

const ContextBox = ({
  children,
  icon,
  title
}: {
  children: React.ReactNode
  icon: React.ReactNode
  title: string
}) => (
  <Flex flexDir="column" p="16px" gap="8px" bg="pluxee.background.primary">
    <Flex alignItems="center" gap="8px" mb="8px">
      <Center
        w="32px"
        h="32px"
        borderRadius="6px"
        bg="pluxee.brand.secondary-dimmed"
      >
        <Image src={`/icons/pluxee/${icon}.svg`} />
      </Center>

      <Text
        color="pluxee.text.primary"
        fontWeight="700"
        fontSize="xxs"
        lineHeight="20.3px"
      >
        {title}
      </Text>
    </Flex>

    {children}
  </Flex>
)

const Details = ({
  title,
  description
}: {
  title: string
  description?: string | number
}) => (
  <Flex
    alignItems="center"
    color="pluxee.text.primary"
    fontWeight="700"
    fontSize="xxs"
    lineHeight="20.3px"
  >
    <Text minW="300px">{title}</Text>
    <Text fontWeight="500">{description}</Text>
  </Flex>
)

export const getFinalWalletStatus = ({
  customerWalletStatus,
  walletCatalogStatus
}: StatusInfoProps) => {
  let status

  const isActive =
    walletCatalogStatus === 'ACTIVE' && customerWalletStatus === 'ACTIVE'
  const isInactive = walletCatalogStatus === 'INACTIVE'
  const isOff =
    walletCatalogStatus === 'ACTIVE' && customerWalletStatus === 'BLOCKED'

  if (isActive) status = 'ACTIVE'
  if (isInactive) status = 'INACTIVE'
  if (isOff) status = 'BLOCKED'

  return status
}
