import { Box, Flex, Image, Text, Button } from '@chakra-ui/react'
import { useOkta } from 'contexts/oktaContext'
import React from 'react'

const UserNotFound = () => {
  const { logout } = useOkta()

  return (
    <Flex
      fontFamily="TTTravels"
      bg="#FAF8FF"
      w="100%"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src="/img/plx-icon-not-found.png" />

      <Box pt="94px">
        <Text
          color="#221C46"
          fontSize="24px"
          lineHeight="31.2px"
          letterSpacing="-1.2px"
          fontWeight="700"
          textAlign="center"
        >
          Ops...
        </Text>
        <Text
          color="#221C46"
          fontSize="24px"
          lineHeight="31.2px"
          letterSpacing="-1.2px"
          fontWeight="700"
          textAlign="center"
        >
          Não encontramos o seu e-mail cadastrado na nossa base.
        </Text>
        <Text
          color="#221C46"
          fontSize="20px"
          fontWeight="500"
          letterSpacing="-1px"
          lineHeight="25px"
          textAlign="center"
          pt="8px"
        >
          Entre em contato com o gestor responsável e solicite seu acesso.
        </Text>
        <Flex align="center" justifyContent="center">
          <Button
            border="3px solid #221C46"
            borderRadius="0"
            bg="transparent"
            color="#221C46"
            fontSize="16px"
            fontWeight="700"
            letterSpacing="-0.8px"
            lineHeight="24px"
            p="12px 24px"
            mt="24px"
            h="48px"
            _hover={{
              bg: '#C7FCCA',
              boxShadow: '4px 4px 0px 0px rgba(34, 28, 70, 0.20)'
            }}
            _active={{
              boxShadow: '2px 2px 0px 0px rgba(34, 28, 70, 0.20)',
              bg: '#00330E',
              color: 'white',
              borderColor: '#00330E'
            }}
            _focusVisible={{
              outline: '3px solid #1B51DC',
              bg: '#C7FCCA',
              color: '#221C46'
            }}
            onClick={logout}
          >
            Voltar para o login
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

export default UserNotFound
