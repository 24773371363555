import { Center, Flex } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import React from 'react'
import { toast } from 'react-toastify'

type useAlertProps = {
  id: string
  title: string
  status: 'error' | 'info' | 'success' | 'warning'
}

export const useAlert = () => {
  const alert = ({ id, status, title }: useAlertProps) => {
    if (status === 'success') Variants.success({ id, title })
    if (status === 'error') Variants.error({ id, title })
    if (status === 'warning') Variants.warning({ id, title })
    if (status === 'info') Variants.info({ id, title })
  }

  const hideAllAlerts = (id?: string) => toast.dismiss(id)

  return { alert, hideAllAlerts }
}

type VariantProps = {
  title: string
  id: string
}

const timeToExpire = 5000

const Variants = {
  success: ({ id, title }: VariantProps) =>
    toast(title, {
      style: {
        width: '320px',
        minHeight: '48px',
        fontFamily: 'TTTravels',
        fontWeight: 600,
        fontSize: '14px',
        color: '#042E20',
        background: '#D7FCEA',
        border: '1px solid #01D253',
        borderRadius: '0px',
        boxShadow: '4px 4px 0px 0px #221C4633',
        letterSpacing: '-0.8px'
      },
      icon: (
        <Center bg="#006245" borderRadius="50%" p="2px">
          <Icon name="check" color="#D7FCEA" size="xs" />
        </Center>
      ),
      closeButton: (
        <Flex alignItems="center" pr="8px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5859 12.0001L2.29297 3.70718L3.70718 2.29297L12.0001 10.5859L20.293 2.29297L21.7072 3.70718L13.4143 12.0001L21.7072 20.293L20.293 21.7072L12.0001 13.4143L3.70718 21.7072L2.29297 20.293L10.5859 12.0001Z"
              fill="#006245"
            />
          </svg>
        </Flex>
      ),
      progressStyle: { opacity: 0, background: 'transparent' },
      autoClose: timeToExpire,
      toastId: id
    }),
  error: ({ id, title }: VariantProps) =>
    toast(title, {
      style: {
        width: '320px',
        minHeight: '48px',
        fontFamily: 'TTTravels',
        fontWeight: 600,
        fontSize: '14px',
        color: '#B30000',
        background: '#FCF1EE',
        border: '1px solid #B30000',
        borderRadius: '0px',
        boxShadow: '4px 4px 0px 0px #221C4633',
        letterSpacing: '-0.8px'
      },
      icon: (
        <Center bg="#B30000" borderRadius="50%" p="2px">
          <Icon name="priority_high" color="#FCF1EE" size="xs" />
        </Center>
      ),
      closeButton: (
        <Flex alignItems="center" pr="8px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5859 12.0001L2.29297 3.70718L3.70718 2.29297L12.0001 10.5859L20.293 2.29297L21.7072 3.70718L13.4143 12.0001L21.7072 20.293L20.293 21.7072L12.0001 13.4143L3.70718 21.7072L2.29297 20.293L10.5859 12.0001Z"
              fill="#B30000"
            />
          </svg>
        </Flex>
      ),
      progressStyle: { opacity: 0, background: 'transparent' },
      autoClose: timeToExpire,
      toastId: id
    }),
  warning: ({ id, title }: VariantProps) =>
    toast(title, {
      style: {
        width: '320px',
        minHeight: '48px',
        fontFamily: 'TTTravels',
        fontWeight: 600,
        fontSize: '14px',
        color: '#874810',
        background: '#FBF2EC',
        border: '1px solid #F8A05B',
        borderRadius: '0px',
        boxShadow: '4px 4px 0px 0px #221C4633',
        letterSpacing: '-0.8px'
      },
      icon: <Icon name="warning" color="#B26019" size="md" p="2px" />,
      progressStyle: { opacity: 0, background: 'transparent' },
      autoClose: timeToExpire,
      toastId: id,
      closeButton: (
        <Flex alignItems="center" pr="8px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5859 12.0001L2.29297 3.70718L3.70718 2.29297L12.0001 10.5859L20.293 2.29297L21.7072 3.70718L13.4143 12.0001L21.7072 20.293L20.293 21.7072L12.0001 13.4143L3.70718 21.7072L2.29297 20.293L10.5859 12.0001Z"
              fill="#B26019"
            />
          </svg>
        </Flex>
      )
    }),
  info: ({ id, title }: VariantProps) =>
    toast(title, {
      style: {
        width: '320px',
        minHeight: '48px',
        fontFamily: 'TTTravels',
        fontWeight: 600,
        fontSize: '14px',
        color: '#0F2365',
        background: '#F3F3FC',
        border: '1px solid #878DFA',
        borderRadius: '0px',
        boxShadow: '4px 4px 0px 0px #221C4633',
        letterSpacing: '-0.8px'
      },
      icon: <Icon name="info" color="#1B51DC" size="md" p="2px" />,
      progressStyle: { opacity: 0, background: 'transparent' },
      autoClose: timeToExpire,
      toastId: id,
      closeButton: (
        <Flex alignItems="center" pr="8px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5859 12.0001L2.29297 3.70718L3.70718 2.29297L12.0001 10.5859L20.293 2.29297L21.7072 3.70718L13.4143 12.0001L21.7072 20.293L20.293 21.7072L12.0001 13.4143L3.70718 21.7072L2.29297 20.293L10.5859 12.0001Z"
              fill="#1B51DC"
            />
          </svg>
        </Flex>
      )
    })
}
