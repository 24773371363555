import { Center, Flex, Image, Text } from '@chakra-ui/react'
import { useWallet } from 'contexts/walletContext'
import { useAlert } from 'hooks/useAlert'
import React, { useEffect } from 'react'

import { CancelButton, SubmitButton, Wrapper } from './wrapper'

type DeleteWalletProps = {
  walletId: number
  walletName: string
  onClose: () => void
  onComplete: () => void
  isOpen: boolean
}

export const DeleteWallet = ({
  walletId,
  walletName,
  onClose,
  onComplete,
  isOpen
}: DeleteWalletProps) => {
  const { alert } = useAlert()
  const { removeWallet, isLoadingRemove } = useWallet()

  const handleDeleteWallet = () => {
    removeWallet(walletId)
      .then(() => {
        onClose()
        onComplete()

        alert({
          id: 'deleteWalletToast',
          title: 'Carteira excluída com sucesso',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)

        return alert({
          id: 'errorDeleteWalletToast',

          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente',
          status: 'error'
        })
      })
  }

  useEffect(() => {
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') onClose()
    })

    return () => {
      document.removeEventListener('keydown', onClose)
    }
  }, [])

  if (!isOpen) return <></>

  return (
    <Wrapper>
      <Header />
      <Flex
        flexDir="column"
        alignItems="center"
        gap="40px"
        p="40px"
        textAlign="center"
      >
        <Flex flexDir="column" gap="8px">
          <Text
            fontWeight="800"
            fontSize="32px"
            letterSpacing="-1.6px"
            color="#221C46"
          >
            Excluir carteira
          </Text>

          <Flex
            flexDir="column"
            fontSize="20px"
            color="#5A5469"
            letterSpacing="-1px"
          >
            <Flex gap="4px">
              <Text fontWeight="500">Deseja inativar a carteira</Text>
              <Text fontWeight="700">{walletName}?</Text>
            </Flex>

            <Text fontWeight="700">Essa ação não poderá ser desfeita.</Text>
          </Flex>
        </Flex>

        <Flex gap="16px">
          <CancelButton onClick={onClose} />
          <SubmitButton
            onClick={handleDeleteWallet}
            title="Excluir carteira"
            isLoading={isLoadingRemove}
          />
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Header = () => (
  <Center h="160px" w="100%" bg="#FCF1EE">
    <Flex
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: '128px',
        left: 'calc(50% - 24px)',
        width: '48px',
        height: '24px',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('/img/wallet/inactiveArrowDown.svg')",
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Image
        w="96px"
        h="96px"
        alt="Inactive Wallet Icon"
        src="/img/wallet/inactiveWalletIcon.svg"
      />
    </Flex>
  </Center>
)
