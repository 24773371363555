import { Image } from '@chakra-ui/react'
import { Flex, Tabs, TabList, TabPanels, TabPanel, Box } from 'components/atoms'
import { SearchInput, SubmitButton, Tab } from 'components/ui'
import { useSchedules } from 'contexts/scheduleContext'
import Mask from 'hooks/Masks'
import { removeCPFMask } from 'internationalization'
import React, { useLayoutEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useParams } from 'react-router-dom'

import { ScheduleHistory } from './history'
import { ResultsTable } from './table'

export const ConsumersList = () => {
  const [cpf, setCpf] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { id: orderId } = useParams()

  const isShowClearButton = cpf.length >= 14
  const handleOnClear = () => {
    setCpf('')
    setShowErrorMessage(false)
  }

  const {
    schedulesDetails,
    isLoadingDetails,
    isLoadingConsumers,
    getConsumersByCpf,
    setHideConsumersList,
    isErrorConsumers
  } = useSchedules()

  useLayoutEffect(() => {
    if (isErrorConsumers) setShowErrorMessage(true)
  }, [isErrorConsumers])

  useLayoutEffect(() => {
    const isEmptyCPF = cpf.length === 0
    if (isEmptyCPF) {
      setHideConsumersList(true)
      setShowErrorMessage(false)
    }
  }, [cpf])

  async function handleGetConsumersByCpf() {
    if (!cpf || cpf.length < 14) return setHideConsumersList(true)

    const params = {
      cpf: String(removeCPFMask(cpf)),
      orderId: String(orderId)
    }

    setHideConsumersList(false)
    getConsumersByCpf(params)
  }

  const isInvalidInput = isErrorConsumers && showErrorMessage
  if (isLoadingDetails || !schedulesDetails || isLoadingConsumers)
    return (
      <Box width="100%">
        <SkeletonConsumersList />
      </Box>
    )

  return (
    <Flex w="100%" id="tabsSection">
      <Tabs w="100%">
        <TabList borderBottom="1px solid #D1CFD7" maxW="max-content">
          <Tab
            title="Colaboradores"
            icon={
              <Image
                src="/img/schedules/users.svg"
                alt="Users Icon"
                w="24px"
                h="24px"
              />
            }
          />
          <Tab
            width="fit-content"
            title="Histórico do agendamento"
            icon={
              <Image
                src="/img/schedules/history.svg"
                alt="History Icon"
                w="24px"
                h="24px"
              />
            }
          />
        </TabList>

        <TabPanels>
          <TabPanel padding="0px">
            <Flex gap="24px" mt="25px" mb="35px">
              <Box
                w="600px"
                color="primaryColors.blue.80"
                fontSize="xxs"
                bg="transparent"
                display="flex"
                alignItems="flex-end"
                gap="31.5px"
              >
                <SearchInput
                  value={cpf}
                  size="1.5rem"
                  isOptionalLabel
                  customIcon="search"
                  onClear={handleOnClear}
                  isError={isInvalidInput}
                  label={'CPF do colaborador'}
                  hiddenErrorIcon={isInvalidInput}
                  showClearButton={isShowClearButton}
                  placeholder="Informe o CPF do colaborador"
                  onChange={inputValue => setCpf(Mask.CPF(inputValue))}
                  errorMessage="CPF não encontrado, verifique e tente novamente"
                />

                <Flex mb={isInvalidInput ? '23px' : '0px'} minW="199px">
                  <SubmitButton
                    onClick={handleGetConsumersByCpf}
                    isLoading={false}
                    isDisabled={cpf.length !== 14}
                  />
                </Flex>
              </Box>
            </Flex>

            {schedulesDetails && (
              <Flex w="100%">
                <ResultsTable isInvalid={isInvalidInput} />
              </Flex>
            )}
          </TabPanel>

          <TabPanel padding="0px" pt="24px">
            <ScheduleHistory />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

const SkeletonConsumersList = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="70" height="10" />
    <rect x="0" y="30" rx="3" ry="3" width="200" height="10" />
    <rect x="0" y="50" rx="3" ry="3" width="100%" height="300" />
  </ContentLoader>
)

export default ConsumersList
