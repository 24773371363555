export const space = {
  quarck: '0.25rem', // 4px
  nano: '0.5rem', // 8px
  xxxs: '1rem', // 16px
  xxs: '1.5rem', // 24px
  xs: '2rem', // 32px
  sm: '2.5rem', // 40px
  md: '3rem', // 48px
  lg: '3.5rem', // 56px
  xl: '4rem', // 64px
  xxl: '5rem', // 80px
  xxxl: '7.5rem', // 120px
  huge: '10rem', // 160px
  giant: '12.5rem' // 200px
}

export const spaceInset = {
  quarck: '0.25rem 0.25rem', // 4px
  nano: '0.5rem 0.5rem', // 8px
  xs: '1rem 1rem', // 16px
  sm: '1.5rem 1.5rem', // 24px
  md: '2rem 2rem', // 32px
  lg: '2.5rem 2.5rem' // 40px
}
