import { Box, MenuButton as ChakraMenuButton } from '@chakra-ui/react'
import { Center, Flex, Icon, Image, Text, Button } from 'components/atoms'
import { useOkta } from 'contexts/oktaContext'
import { useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { User } from 'models/User'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { limitString } from 'utils/stringUtils'
import { getFormattedUserName } from 'utils/userUtils'

import { Container, PluxeeMenu, Tooltip } from '.'
import type { LayoutProps } from './Layout'

export type HeaderProps = Pick<
  LayoutProps,
  'isShowBackButton' | 'onPageBack' | 'pageBackTitle' | 'maxWidth'
>

const Header = ({
  isShowBackButton,
  onPageBack,
  pageBackTitle,
  maxWidth
}: HeaderProps) => {
  const [rotate, setRotate] = useState('rotate(0deg)')
  const [color, setColor] = useState('pluxee.interactive.primary')
  const [isExpanded, setIsExpanded] = useState(false)

  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const ariaExpanded = menuButtonRef.current?.getAttribute('aria-expanded')

  useEffect(() => {
    if (
      ariaExpanded === undefined ||
      ariaExpanded === null ||
      ariaExpanded === 'false'
    ) {
      setIsExpanded(false)
      setRotate('rotate(0deg)')
      setColor('pluxee.interactive.primary')
    } else {
      setIsExpanded(true)
      setRotate('rotate(180deg)')
      setColor('#1B51DC')
    }
  }, [ariaExpanded])

  const { logout } = useOkta()
  const navigate = useNavigate()
  const { user } = useProfile()
  const { removeStorageItem } = useLocalStorage()

  function goToHome() {
    navigate('/')
    const keysToRemoveOnLocalStorage = [
      'cachedCPF',
      'cachedDocument',
      'customer',
      'selectedProduct',
      'cachedOrderCode',
      'cachedClientCode'
    ]

    keysToRemoveOnLocalStorage.forEach(key => removeStorageItem(key))
  }

  return (
    <Flex flexDir="column" w="100%">
      <Flex
        h="80px"
        bg="standard.white"
        color="standard.white"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="pluxee.brand.secondary-light"
      >
        <Flex gap="85px" alignItems="center">
          <Box
            cursor="pointer"
            onClick={goToHome}
            bgImage="url('/img/plx-header.png')"
            bgRepeat="no-repeat"
            bgSize="cover"
            bgPosition="center"
            position="relative"
            w="400px"
            h="80px"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            pl="84px"
            _after={{
              content: '""',
              position: 'absolute',
              bgImage: "url('/img/plx-slice.png')",
              bgRepeat: 'no-repeat',
              bgSize: 'cover',
              width: '80.5px',
              height: '80px',
              right: '0',
              transform: 'translate(80px, 0px)'
            }}
          >
            <Image src="/img/plx-logo.png" w="96px" />
          </Box>

          <Text
            color="pluxee.text.primary"
            fontWeight="600"
            fontSize="xs"
            letterSpacing="-0.8px"
          >
            MBP - Multi Benefits Platform
          </Text>
        </Flex>

        <Flex
          as={Button}
          borderRadius="none"
          bg={'transparent'}
          h="100%"
          gap="8px"
          cursor="pointer"
          borderLeft={isExpanded ? 'none' : '1px solid'}
          borderColor="pluxee.border.secondary"
          alignItems="center"
          justifyContent="center"
          p="24px 16px 24px 16px"
          borderBottom={
            isExpanded ? '4px solid #1B51DC' : '4px solid transparent'
          }
          _hover={{
            bg: '#DEF3FB',
            borderBottom: '4px solid',
            borderColor: '#1B51DC',
            borderLeft: 'none'
          }}
          _active={{ bg: '#0F266D' }}
          _focusVisible={{
            bg: '#DEF3FB',
            border: '3px solid #1B51DC'
          }}
          onClick={() => {
            menuButtonRef.current?.click()
          }}
          onMouseDown={() => {
            setColor('white')
          }}
          onMouseOver={() => {
            setColor('#221C46')
          }}
          onMouseLeave={() => {
            setColor('pluxee.interactive.primary')
            setRotate('rotate(0deg)')
          }}
          onFocusCapture={() => {
            setColor('#221C46')
            setRotate('rotate(180deg)')
          }}
          onKeyDownCapture={() => {
            menuButtonRef?.current?.setAttribute('aria-expanded', 'false')
            setColor('#1B51DC')
            setRotate('rotate(0deg)')
          }}
        >
          <Center
            w="32px"
            h="32px"
            bg="pluxee.brand.secondary"
            color="pluxee.text.primary"
            borderRadius="2px"
          >
            <Text fontWeight="700" fontSize="xs" letterSpacing="-0.8px">
              {user && getFormattedUserName(user.name)}
            </Text>
          </Center>

          <PluxeeMenu
            customMarginTop="19px"
            customTransform="translateX(16px) !important"
            customizedMenuItem={<UsernameMenuItem {...user} />}
            menuButton={
              <CustomizedMenuButton
                menuButtonRef={menuButtonRef}
                color={color}
                rotate={rotate}
              />
            }
            items={[
              {
                title: 'Sair',
                onClick: logout,
                isDisabled: false,
                width: '360px',
                height: '72px',
                color: '#221C46',
                fontSize: '16px',
                padding: '12px 20px',
                icon: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.0094 19.7175C8.149 19.7175 5.01953 16.5881 5.01953 12.7277C5.01953 10.2408 6.31819 8.05734 8.27433 6.81834V4.54803C5.17377 5.96614 3.01953 9.09533 3.01953 12.7277C3.01953 17.6926 7.04443 21.7175 12.0094 21.7175C16.9744 21.7175 20.9993 17.6926 20.9993 12.7277C20.9993 9.10126 18.8521 5.97636 15.7597 4.55499V6.82798C17.7073 8.06864 18.9993 10.2473 18.9993 12.7277C18.9993 16.5881 15.8698 19.7175 12.0094 19.7175Z"
                      fill="#221C46"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.9993 13L10.9993 2L12.9993 2V13H10.9993Z"
                      fill="#221C46"
                    />
                  </svg>
                )
              }
            ]}
          />
        </Flex>
      </Flex>

      <Flex
        id="rightSide"
        h="60px"
        bg="standard.white"
        justifyContent="center"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="pluxee.border.secondary"
      >
        <Text
          fontWeight="600"
          fontSize="xs"
          color="pluxee.text.primary"
          letterSpacing="-0.8px"
        >
          {user && user.role?.name}
        </Text>
      </Flex>

      {isShowBackButton && (
        <Container>
          <Flex maxW={maxWidth} w="100%" h="40px" m="0 auto">
            <Flex id="backButton" mt="16px" paddingLeft="41px" height="40px">
              <Flex
                as={Button}
                pr="24px"
                gap="12px"
                bg="transparent"
                alignItems="center"
                cursor="pointer"
                borderRadius="0"
                color="pluxee.interactive.primary"
                onClick={() => {
                  navigate(-1)
                  onPageBack && onPageBack()
                }}
                _hover={{
                  boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
                  background: '#DEF3FB',
                  color: 'pluxee.interactive.primary'
                }}
                _active={{
                  boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
                  color: 'standard.white',
                  background: 'pluxee.cta.secondaryPressed'
                }}
                _focusVisible={{
                  boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
                  background: '#DEF3FB',
                  color: 'pluxee.interactive.primary',
                  border: '3px solid #1B51DC'
                }}
              >
                <Icon name="arrow_back" size="sm" />
                <Text fontWeight="700" fontSize="12px" letterSpacing="-0.6px">
                  {pageBackTitle}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      )}
    </Flex>
  )
}

export default Header

const UsernameMenuItem = (user: Partial<User>) => {
  const { name } = user

  const usernameIsGreatherThan20 = name!.length >= 25
  const formattedUsername = usernameIsGreatherThan20
    ? limitString(name!, 25)
    : name!

  return (
    <Flex
      gap="16px"
      bg="#221C46"
      w="360px"
      h="72px"
      alignItems="center"
      pl="20px"
      boxShadow={'4px 4px 0px 0px #CBCAD2'}
    >
      <Center
        w="32px"
        h="32px"
        bg="pluxee.brand.secondary"
        color="pluxee.text.primary"
        borderRadius="2px"
      >
        <Text fontWeight="700" fontSize="xs">
          {user && getFormattedUserName(user.name ?? '')}
        </Text>
      </Center>

      <Tooltip label={usernameIsGreatherThan20 ? name! : ''}>
        <Text
          letterSpacing="-0.8px"
          fontWeight="700"
          fontSize="16px"
          color="#fff"
        >
          {formattedUsername}
        </Text>
      </Tooltip>
    </Flex>
  )
}

const CustomizedMenuButton = ({
  color,
  rotate,
  menuButtonRef
}: {
  color: string
  rotate: string
  menuButtonRef: RefObject<HTMLButtonElement>
}) => {
  return (
    <ChakraMenuButton
      ref={menuButtonRef}
      w="40px"
      h="40px"
      as={Button}
      background="transparent"
      borderRadius="none"
      color="pluxee.interactive.primary"
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
      _expanded={{ bg: 'transparent' }}
      tabIndex={-1}
    >
      <Center>
        <Icon
          name="keyboard_arrow_down"
          color={color}
          size="lg"
          cursor="pointer"
          transform={rotate}
          transition="0.2s ease-in"
        />
      </Center>
    </ChakraMenuButton>
  )
}
