import type { CreateAndEditWallet } from 'models/Wallet'
import { WalletPriority } from 'models/WalletPriority'

import { walletApi } from './instances/walletApi'

export const WalletService = {
  getProducts() {
    return walletApi.get('/products', { data: null })
  },
  getWalletsByProductId(productId: number) {
    return walletApi.get(`/wallets/by-product/${productId}`, { data: null })
  },
  getWalletById(walletId: number) {
    return walletApi.get(`wallets/${walletId}`, { data: null })
  },
  create(wallet: CreateAndEditWallet) {
    return walletApi.post('/wallets', wallet)
  },
  update(props: CreateAndEditWallet) {
    return walletApi.put(`/wallets/${props.wallet?.id}`, props)
  },
  delete: (id: number) => {
    return walletApi.delete(`/wallets/${id}`)
  },
  active: (id: number) => {
    return walletApi.post(`/wallets/${id}/activate`)
  },
  inactive: (id: number) => {
    return walletApi.post(`/wallets/${id}/deactivate`, { data: null })
  },
  updatePriorities: (newPriorities: WalletPriority[]) => {
    return walletApi.post(`/wallets/priority`, newPriorities)
  }
}
