import { usersApi } from 'services/instances/usersApi'

export type LoginProps = {
  code: string
  client_request_id?: string
}

const APIM_KEY = process.env.REACT_APP_APIM_KEY as string
const redirectLogoutUrl = process.env.REACT_APP_REDIRECT_LOGOUT_URL as string

export const AuthService = {
  login: async ({ code, client_request_id }: LoginProps) => {
    return usersApi.post(
      '/token/oauth2',
      { code, client_request_id },
      { data: null }
    )
  },
  logout: async ({ id_token_hint }: { id_token_hint: string }) => {
    return usersApi.post(
      `/token/oauth2/logout`,
      {
        id_token_hint,
        post_logout_redirect_uri: redirectLogoutUrl
      },
      { data: null }
    )
  },
  verifyProfile: async ({ token }: { token: string }) => {
    return usersApi.get('/token/profile', {
      data: null,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': APIM_KEY
      }
    })
  }
}
