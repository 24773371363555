import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

type FormWrapperProps = {
  children: React.ReactNode
  heading?: string
  subHeading?: string
  headingSpaceToBottom?: string
  gap?: string
  WalletInfo?: React.ReactNode
}

const FormWrapper = ({
  children,
  heading,
  subHeading,
  gap = '28px',
  headingSpaceToBottom = '24px',
  WalletInfo
}: FormWrapperProps) => {
  return (
    <Flex flexDir="column" gap={headingSpaceToBottom} w="100%">
      <Flex flexDir="column" gap="24px" color="#221C46">
        {!!heading && (
          <Text fontSize="32px" fontWeight="800" letterSpacing="-1.6px">
            {heading}
          </Text>
        )}

        {WalletInfo ? WalletInfo : null}

        <Text fontSize="24px" fontWeight="700" letterSpacing="-1.2px">
          {subHeading ?? ''}
        </Text>
      </Flex>

      <Flex flexDir="column" w="100%" gap={gap}>
        {children}
      </Flex>
    </Flex>
  )
}

export default FormWrapper
