import { ITransactionRepository } from 'modules/transaction/domain/repositories/ITransactionRepository'

export class GetOriginalTransaction {
  constructor(private transactionRepository: ITransactionRepository) {}

  async execute(transactionUUID?: string) {
    return await this.transactionRepository.getOriginalTransaction(
      transactionUUID
    )
  }
}
