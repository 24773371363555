import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

const NoData = () => {
  return (
    <Flex w="100%" flexDir="column" alignItems="center" justifyContent="center">
      <Image src="/img/plx-icon-no-data.png" />
      <Text
        fontFamily="TTTravels"
        fontSize="16px"
        fontWeight="700"
        lineHeight="24px"
        letterSpacing="-0.8px"
        fontStyle="normal"
        color="#221C46"
      >
        Sem dados a serem exibidos
      </Text>
      <Text
        fontFamily="TTTravels"
        fontSize="14px"
        fontWeight="500"
        fontStyle="normal"
        lineHeight="20.3px"
        letterSpacing="-0.7px"
        color="#221C46"
      >
        Informações cadastradas aparecerão aqui
      </Text>
    </Flex>
  )
}

export default NoData
