import { extendTheme } from '@chakra-ui/react'
import { checkboxTheme } from 'components/ui/theme/CheckboxTheme'
import { inputThemePlx } from 'components/ui/theme/InputTheme'
import { toggleThemePlx } from 'components/ui/theme/ToggleTheme'

import * as border from './borders'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import components from './components'
import { shadows } from './shadows'
import { space } from './spacing'
import { fonts, fontSizes, fontWeights, lineHeights } from './typography'

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  },
  styles: {
    global: {
      '*:focus': {
        boxShadow: 'none !important'
      },
      '#semibold': {
        fontWeight: 'semibold'
      }
    }
  },
  fonts,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  breakpoints,
  shadows,
  space,
  borders: border.width,
  radii: border.radius,
  components: {
    ...components,
    Checkbox: checkboxTheme,
    Switch: toggleThemePlx,
    Input: inputThemePlx
  }
})

export default theme
