import { Flex, Text } from '@chakra-ui/react'
import { Checkbox } from 'components/ui'
import React, { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { WalletAddInputs } from '../validations/walletAddSchema'

import AreaLabel from './areaLabel'

type InnerText = 'PAT' | 'MULTI'

const ModelArea = ({
  currentModel,
  setValue
}: {
  currentModel: 'A' | 'M' | undefined
  setValue: UseFormSetValue<WalletAddInputs>
}) => {
  const formatInnerText = (value: InnerText) => {
    const data = {
      PAT: 'A',
      MULTI: 'M'
    }

    return data[value]
  }

  useEffect(() => {
    if (currentModel) setValue('wallet.model', currentModel)
  }, [currentModel])

  useEffect(() => {
    const checkboxGroupDiv =
      document.querySelectorAll<HTMLInputElement>('.pluxeeCheckbox')
    checkboxGroupDiv?.forEach(item => {
      const formattedInnerText = formatInnerText(item.innerText as InnerText)
      const checkbox = item?.children[1] as HTMLInputElement

      if (formattedInnerText !== currentModel) {
        checkbox.style.borderColor = '#908C99'
      } else {
        checkbox.style.borderColor = '#1B51DC'
        checkbox.removeAttribute('data-focus')
        checkbox.removeAttribute('data-focus-visible')
      }
    })
  }, [currentModel])

  return (
    <Flex flexDir="column" w="100%">
      <Flex mb="8px">
        <AreaLabel title="Modelo" />
      </Flex>

      <Flex
        fontSize="14px"
        fontWeight="600"
        color={'pluxee.text.primary'}
        display="flex"
        gap="4px"
        mb="8px"
      >
        <Text letterSpacing="-0.7px">Tipo de modelo</Text>
        <Text color="#CC1480">*</Text>
      </Flex>

      <Flex alignItems="center" gap="24px">
        <Checkbox
          title="PAT"
          checked={currentModel === 'A'}
          onChange={() => setValue('wallet.model', 'A')}
          isRounded
          defaultIcon
        />

        <Checkbox
          title={'MULTI'}
          checked={currentModel === 'M'}
          onChange={() => setValue('wallet.model', 'M')}
          isRounded
          defaultIcon
        />
      </Flex>
    </Flex>
  )
}

export default ModelArea
