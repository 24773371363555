import { useAlert } from 'hooks/useAlert'
import { useUser } from 'modules/users/presentation/contexts/UserContext'
import React, { useEffect, useState } from 'react'

import { FilterByRolePresentation } from '../presentational/FilterByRolePresentation'

interface FilterByRoleContainerProps {
  isOpen: boolean
  onClose: () => void
}

export const FilterByRoleContainer = ({
  isOpen,
  onClose
}: FilterByRoleContainerProps) => {
  const { alert } = useAlert()
  const [tempCurrentRoleName, setTempCurrentRoleName] = useState('')

  const {
    roles,
    isLoadingRoles,
    currentFilters,
    selectedRoleId,
    isSuccessGetAll,
    setSelectedRoleId,
    setCurrentFilters,
    setSearchInputValue,
    setCurrentRoleName,
    setIsSearchingByRole
  } = useUser()

  const handleFilterUsersByRole = () => {
    if (!selectedRoleId) {
      return alert({
        id: 'filterToast',
        status: 'warning',
        title: 'Necessário selecionar um tipo para filtro'
      })
    }

    delete currentFilters.name
    setSearchInputValue('')
    setIsSearchingByRole(true)

    setCurrentFilters({
      ...currentFilters,
      name: '',
      roleId: selectedRoleId === 'ALL' ? '' : selectedRoleId,
      page: '0'
    })

    setCurrentRoleName(tempCurrentRoleName)
  }

  useEffect(() => {
    if (isSuccessGetAll) onClose()
  }, [isSuccessGetAll])

  useEffect(() => {
    const checkboxGroupDiv =
      document.querySelectorAll<HTMLInputElement>('.pluxeeCheckbox')
    checkboxGroupDiv?.forEach(item => {
      const checkbox = item?.children[1] as HTMLInputElement

      if (item.innerText !== tempCurrentRoleName) {
        checkbox.style.borderColor = '#908C99'
      } else {
        checkbox.style.borderColor = '#1B51DC'
        checkbox.removeAttribute('data-focus')
        checkbox.removeAttribute('data-focus-visible')
      }
    })
  }, [tempCurrentRoleName])

  return (
    <FilterByRolePresentation
      isOpen={isOpen}
      onClose={onClose}
      isLoadingRoles={isLoadingRoles}
      roles={roles}
      selectedRoleId={selectedRoleId!}
      tempCurrentRoleName={tempCurrentRoleName}
      handleFilterUsersByRole={handleFilterUsersByRole}
      setSelectedRoleId={setSelectedRoleId}
      setTempCurrentRoleName={setTempCurrentRoleName}
    />
  )
}
