import { Flex } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

const FormSpacing = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      flexDir="column"
      bg="white"
      p="32px 48px"
      gap="24px"
      boxShadow="1px 1px 0px 0px rgba(34, 28, 70, 0.20)"
    >
      {children}
    </Flex>
  )
}

export default FormSpacing
