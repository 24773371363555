import { Button, Flex, Link as ChakraLink, Text } from 'components/atoms'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Tooltip from './Tooltip'

type Item = {
  label: string
  path: string
  tooltipLabel?: string
}

export type HomeCardProps = {
  icon: React.ReactNode
  title: string
  items: Item[]
}

const HomeCard = ({ title, icon, items }: HomeCardProps) => {
  return (
    <Flex
      w="317px"
      minH="234px"
      h="auto"
      p="16px 24px 24px"
      gap="24px"
      borderRadius="4px"
      bg="pluxee.background.primary"
      color="pluxee.text.primary"
      flexDir="column"
      letterSpacing="-0.8px"
    >
      <Flex gap="12px" alignItems="center" h="24px">
        {icon}
        <Text fontWeight="700" pt={1} fontSize="xs">
          {title}
        </Text>
      </Flex>

      <Flex flexDir="column" gap="24px">
        {items.map(({ label, path, tooltipLabel }) => (
          <Link
            key={`Link: ${label}`}
            label={label}
            path={path}
            tooltipLabel={tooltipLabel}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default HomeCard

const Link = ({ label, path, tooltipLabel }: Item) => {
  const navigate = useNavigate()

  return (
    <Flex gap="8px">
      <ChakraLink
        mt="2px"
        p="0"
        as={Button}
        fontSize="xs"
        w="fit-content"
        h="fit-content"
        fontWeight="500"
        borderRadius="0"
        background="transparent"
        color="pluxee.text.primary"
        key={`Ir para - ${path}`}
        onClick={() => {
          navigate(path)
          window.localStorage.removeItem('transactionUUID')
        }}
        _hover={{ background: 'transparent' }}
        letterSpacing="-0.8px"
      >
        {label}
      </ChakraLink>

      {!!tooltipLabel && (
        <Tooltip
          label={tooltipLabel}
          iconColor="#1B51DC"
          minW="16px"
          minH="16px"
        />
      )}
    </Flex>
  )
}
