import { Image } from '@chakra-ui/react'
import { Box, Divider, Flex, Text } from 'components/atoms'
import { translate } from 'internationalization'
import { ResponseCode } from 'models/ResponseCode'
import type {
  TransactionWorkflow,
  WalletTransaction
} from 'models/WalletTransaction'
import React, { useCallback } from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import { TransactionCases } from 'utils/transactionsUtils'

import { TransactionStatus as Status } from '.'
import Tooltip from './Tooltip'

export type TransactionHistoryProps = {
  transaction: WalletTransaction
}

const TransactionHistory = ({
  transaction: { walletValidationHistory, type }
}: TransactionHistoryProps) => (
  <Flex flexDir="column" justifyContent="flex-start" w="100%">
    {walletValidationHistory && (
      <>
        <Divider mb={5} />
        <Workflow
          transactionHistory={walletValidationHistory}
          transactionType={type!}
        />
      </>
    )}
  </Flex>
)

const Header = () => (
  <Flex gap="8px" flexDir="column" mb="8px">
    <Text fontSize="16px" fontWeight="700" color="#221C46">
      {translate('commons.account.history')}
    </Text>
    <Text fontSize="14px" fontWeight="500" color="#221C46">
      {translate('commons.account.historyDescription')}
    </Text>
  </Flex>
)

const Workflow = ({
  transactionHistory,
  transactionType
}: {
  transactionHistory: TransactionWorkflow[]
  transactionType: string
}) => {
  return (
    <>
      <Header />

      <WorkflowHeader transactionType={transactionType} />
      {transactionHistory?.map((transaction, index) => (
        <React.Fragment key={`Transaction - ${index}`}>
          <TransactionRow
            index={index}
            transaction={{
              ...transaction
            }}
            transactionType={transactionType}
          />
          <Divider />
        </React.Fragment>
      ))}
    </>
  )
}

const WorkflowHeader = ({ transactionType }: { transactionType: string }) => {
  const isWithdraw = transactionType === 'WTX'

  const defaultColumns = [
    {
      label: 'Caminho',
      tooltip:
        'A coluna “Caminho” apresenta o fluxo de validação que o sistema faz para que a transação ocorra...'
    },
    { label: 'Carteira' },
    { label: 'Saldo disponível' },
    { label: 'Qtd diária' },
    { label: isWithdraw ? 'Limite diário' : 'Limite disponível' }
  ]

  const columns = !isWithdraw
    ? [...defaultColumns, { label: 'Status' }]
    : [
        ...defaultColumns,
        { label: 'Qtd mensal' },
        { label: 'Limite mensal' },
        { label: 'Status' }
      ]

  return (
    <Flex
      p="14px 16px"
      fontSize="14px"
      fontWeight="500"
      color="white"
      bg="#221C46"
      justifyContent="flex-start"
      wrap="wrap"
    >
      {columns.map((column, idx) => (
        <Flex key={idx} gap="8px" alignItems="center" minW="130px" flex="1">
          <Text>{column.label}</Text>
          {column.tooltip && (
            <Tooltip iconColor="white" label={column.tooltip} />
          )}
        </Flex>
      ))}
    </Flex>
  )
}

const TransactionRow = ({
  transaction,
  transactionType,
  index
}: {
  transaction: TransactionWorkflow
  transactionType: string
  index: number
}) => {
  const isWalletUnavailable =
    transaction?.stepValidation === 'accountWalletUnavailable'

  const capitalizedName = capitalizeSentence(transaction.walletName ?? '-')
  const formattedWalletName = isWalletUnavailable
    ? `${capitalizedName}: Carteira pausada`
    : capitalizedName

  const formattedDailyLimitTx =
    transaction.dailyLimitTx === 999999
      ? 'Ilimitado'
      : `${transaction.dailyLimitTx} ${getLimitedLabel(
          Number(transaction.dailyLimitTx)
        )}`

  const isBalanceError = transaction?.stepValidation === 'balance'
  const isDailyLimitTxError = transaction?.stepValidation === 'dailyLimitTx'
  const isDailyLimitValueError =
    transaction?.stepValidation === 'dailyLimitValue'
  const isDailyLimitWithdrawTxError =
    transaction?.stepValidation === 'dailyLimitWithdrawTx'
  const isDailyLimitWithdrawValueError =
    transaction?.stepValidation === 'dailyLimitWithdrawValue'
  const isMonthlyLimitWithdrawTxError =
    transaction?.stepValidation === 'monthlyLimitWithdrawTx'
  const isMonthlyLimitWithdrawValueError =
    transaction?.stepValidation === 'monthlyLimitWithdrawValue'

  const isWithdraw = transactionType === 'WTX'
  const validationNumber = index + 1

  let hideNextColumns = false

  return (
    <Flex
      alignItems="center"
      h="60px"
      paddingLeft="16px"
      fontSize="12px"
      fontWeight="500"
      color="primaryColors.blue.60"
      bg="transparent"
      _odd={{ background: '#F3F3FC' }}
      wrap="wrap"
    >
      <TextColumn width="123px" label={`${validationNumber}ª Validação`} />

      <TransactionColumn
        label={formattedWalletName}
        isError={false}
        noLimitString={isWalletUnavailable}
        hideText={hideNextColumns}
      />

      <TransactionColumn
        label={formatToBrl(transaction.balance ?? 0)}
        isError={isBalanceError || isWalletUnavailable}
        hideText={hideNextColumns || isWalletUnavailable}
      />

      {(hideNextColumns = hideNextColumns || isBalanceError)}

      {isWithdraw ? (
        <>
          <TransactionColumn
            label={
              transaction.dailyLimitWithdrawTx === 999999
                ? 'Ilimitado'
                : `${transaction.dailyLimitWithdrawTx} transações`
            }
            isError={
              isDailyLimitWithdrawTxError ||
              isBalanceError ||
              isWalletUnavailable
            }
            hideText={hideNextColumns || isWalletUnavailable}
          />

          {(hideNextColumns = hideNextColumns || isDailyLimitWithdrawTxError)}

          <TransactionColumn
            label={formatToBrl(transaction.dailyLimitWithdrawValue ?? 0)}
            isError={
              isDailyLimitWithdrawValueError ||
              isWalletUnavailable ||
              isDailyLimitWithdrawTxError ||
              isBalanceError
            }
            hideText={hideNextColumns || isWalletUnavailable}
          />

          {
            (hideNextColumns =
              hideNextColumns || isDailyLimitWithdrawValueError)
          }

          <TransactionColumn
            label={
              transaction.monthlyLimitWithdrawTx === 999999
                ? 'Ilimitado'
                : `${transaction.monthlyLimitWithdrawTx} transações`
            }
            isError={
              isMonthlyLimitWithdrawTxError ||
              isDailyLimitWithdrawValueError ||
              isWalletUnavailable ||
              isDailyLimitWithdrawTxError ||
              isBalanceError
            }
            hideText={hideNextColumns || isWalletUnavailable}
          />

          {(hideNextColumns = hideNextColumns || isMonthlyLimitWithdrawTxError)}

          <TransactionColumn
            hideLine
            label={formatToBrl(transaction.monthlyLimitWithdrawValue ?? 0)}
            isError={
              isMonthlyLimitWithdrawValueError ||
              isDailyLimitWithdrawValueError ||
              isMonthlyLimitWithdrawTxError ||
              isWalletUnavailable ||
              isDailyLimitWithdrawTxError ||
              isBalanceError
            }
            hideText={hideNextColumns || isWalletUnavailable}
          />

          {
            (hideNextColumns =
              hideNextColumns || isMonthlyLimitWithdrawValueError)
          }
        </>
      ) : (
        <>
          <TransactionColumn
            hideText={hideNextColumns || isWalletUnavailable}
            label={formattedDailyLimitTx}
            isError={
              isDailyLimitTxError || isBalanceError || isWalletUnavailable
            }
          />

          {(hideNextColumns = hideNextColumns || isDailyLimitTxError)}

          <TransactionColumn
            hideLine
            label={formatToBrl(transaction.dailyLimitValue ?? 0)}
            hideText={hideNextColumns || isWalletUnavailable}
            isError={
              isDailyLimitValueError ||
              isDailyLimitTxError ||
              isBalanceError ||
              isWalletUnavailable
            }
          />

          {(hideNextColumns = hideNextColumns || isDailyLimitValueError)}
        </>
      )}

      <Flex flex="1" h="100%" display="flex" alignItems="center">
        {transaction.responseCode && (
          <TransactionStatus
            status={transaction.status as TransactionCases}
            responseDesc={transaction.responseCode}
          />
        )}
      </Flex>
    </Flex>
  )
}

const TextColumn = ({ label, width }: { label: string; width: string }) => (
  <Text color="#221C46" fontWeight="500" fontSize="14px" w={width}>
    {label}
  </Text>
)

type TransactionColumnProps = {
  label: string
  isError: boolean
  hideText?: boolean
  hideLine?: boolean
  noLimitString?: boolean
}

const TransactionColumn = ({
  label,
  isError,
  hideText = false,
  hideLine = false,
  noLimitString = false
}: TransactionColumnProps) => {
  const hideIcon = isError && hideText

  const renderIcon = useCallback(() => {
    if (hideIcon) return <></>
    if (noLimitString) return <FailedIcon />

    if (isError) return <FailedIcon />
    else return <CheckedIcon />
  }, [])

  return (
    <Flex gap="8px" flexDir="column" minW="130px" flex="1">
      <Flex gap="4px" alignItems="center">
        {renderIcon()}
        <Text
          color="#221C46"
          letterSpacing="-0.6px"
          visibility={hideText ? 'hidden' : 'visible'}
          whiteSpace={noLimitString ? 'nowrap' : 'normal'}
        >
          {!noLimitString ? limitString(label, 15) : label}
        </Text>
      </Flex>
      <ProgressLine isError={isError} hideLine={hideLine} />
    </Flex>
  )
}

type ProgressLineProps = { isError: boolean } & Pick<
  TransactionColumnProps,
  'hideLine'
>

const ProgressLine = ({ isError, hideLine }: ProgressLineProps) => (
  <Flex w="100%" alignItems="center">
    <Box
      bg={isError ? 'white' : '#1B51DC'}
      w="16px"
      h="16px"
      border="1px solid #1B51DC"
    />

    {!hideLine && <Box bg={'#1B51DC'} w="100%" h="1px" />}
  </Flex>
)

const TransactionStatus = ({
  status,
  responseDesc
}: {
  status: TransactionCases
  responseDesc: ResponseCode
}) => {
  const getBgColor = (status: TransactionCases) =>
    ({
      P: 'transparent',
      E: 'transparent',
      F: '#FCECE7',
      U: 'transparent'
    }[status])

  return (
    <Flex
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="flex-start"
      bg={getBgColor(status)}
      p={2}
    >
      <Status status={status} responseCode={responseDesc} />
    </Flex>
  )
}

const CheckedIcon = () => <Image src="/img/pluxee/check.svg" alt="Check Icon" />
const FailedIcon = () => <Image src="/img/pluxee/failed.svg" alt="Fail Icon" />

const getLimitedLabel = (limit: number) =>
  String(limit).length >= 6 ? 'transaç..' : 'transações'

export default TransactionHistory
