import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

export type ScheduleStatusProps = {
  status: 'SCHEDULED' | 'PROCESSED' | 'CANCELED' | 'CANCELLED'
}

const ScheduleStatus = ({ status }: ScheduleStatusProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="32px"
      maxW="160px"
      w="100%"
    >
      <Flex
        alignItems="center"
        p="4px 8px"
        bg="white"
        border="1px solid"
        borderColor={getColorsByStatus({ status })}
        gap="8px"
      >
        <Box
          w="8px"
          h="8px"
          borderRadius="50%"
          bg={getColorsByStatus({ status })}
        />
        <Text
          fontWeight="500"
          fontSize="14px"
          color="#221C46"
          letterSpacing="-0.7px;"
        >
          {getFormattedStatus({ status })}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ScheduleStatus

const getColorsByStatus = ({ status }: Pick<ScheduleStatusProps, 'status'>) => {
  const formattedStatus = {
    PROCESSED: '#01D253',
    ACCEPTED: '#01D253',
    SCHEDULED: '#878DFA',
    CANCELED: '#B30000',
    CANCELLED: '#B30000',
    REJECTED: '#B30000'
  }

  return formattedStatus[status] ?? 'white'
}

const getFormattedStatus = ({
  status
}: Pick<ScheduleStatusProps, 'status'>) => {
  const formattedStatus = {
    PROCESSED: 'Disponibilizado',
    SCHEDULED: 'Agendado',
    CANCELED: 'Cancelado',
    CANCELLED: 'Cancelado',
    REJECTED: 'Rejeitado',
    ACCEPTED: 'Disponibilizado'
  }

  return formattedStatus[status] ?? status
}
