import { ProfileProvider } from 'contexts/profileContext'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'theme/styles/index.css'
import 'react-day-picker/dist/style.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'theme/providers/ThemeProvider'

import App from './App'
import 'react-toastify/dist/ReactToastify.css'

import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    },
    mutations: {
      retry: 0
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ProfileProvider>
          <App />
          <ToastContainer />
        </ProfileProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </BrowserRouter>
)
