import axios from 'axios'
import { AuthUtils } from 'utils/authUtils'

const usersApiEndpoint = process.env.REACT_APP_USER_API_ENDPOINT as string
const APIM_KEY = process.env.REACT_APP_APIM_KEY as string

export const usersApi = axios.create({
  baseURL: usersApiEndpoint,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': APIM_KEY,
    Authorization: `Bearer ${AuthUtils().idToken}`
  }
})
