import { Button as ChakraButton, Text } from 'components/atoms'
import React from 'react'

interface ButtonPresentationProps {
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  title: string
}

export const ButtonPresentation = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: ButtonPresentationProps) => {
  const defaultStyles = {
    boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
    background: !isDisabled && '#DEF3FB',
    color: !isDisabled && 'pluxee.interactive.primary'
  }

  return (
    <ChakraButton
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: !isDisabled && 'none !important'
      }}
      _hover={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        background: !isDisabled && '#DEF3FB',
        color: !isDisabled && '#221C46',
        borderColor: !isDisabled && '#DEF3FB'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </ChakraButton>
  )
}
