import { Center, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'

import { CancelButton, SubmitButton, Wrapper } from './wrapper'

type ConfirmOperationProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onClick: () => void
  title: string
  description: string
  buttonTitle: string
}

export const ConfirmOperation = ({
  isOpen,
  isLoading,
  onClose,
  onClick,
  title,
  description,
  buttonTitle
}: ConfirmOperationProps) => {
  useEffect(() => {
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') onClose()
    })

    return () => {
      document.removeEventListener('keydown', onClose)
    }
  }, [])

  if (!isOpen) return <></>

  return (
    <Wrapper>
      <Header />
      <Flex
        flexDir="column"
        alignItems="center"
        gap="40px"
        p="40px"
        textAlign="center"
      >
        <Flex flexDir="column" gap="8px">
          <Text
            fontWeight="800"
            fontSize="32px"
            letterSpacing="-1.6px"
            color="#221C46"
          >
            {title}
          </Text>

          <Flex
            justifyContent="center"
            gap="4px"
            fontSize="20px"
            color="#5A5469"
            letterSpacing="-0.4px"
          >
            <Text fontWeight="500">{description}</Text>
          </Flex>
        </Flex>

        <Flex gap="16px">
          <CancelButton onClick={onClose} />
          <SubmitButton
            onClick={onClick}
            title={buttonTitle}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Header = () => (
  <Center h="160px" w="100%" bg="#FBF2EC">
    <Flex
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: '128px',
        left: 'calc(50% - 24px)',
        width: '48px',
        height: '24px',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage:
          "url('/img/wallet/confirmOriginalValuesArrowDown.png')",
        backgroundRepeat: 'no-repeat'
      }}
    >
      <HeaderImage />
    </Flex>
  </Center>
)

const HeaderImage = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.466 23.5812C17.1351 25.2012 18.0903 27.4481 18.0903 29.7408H21.4672C21.4672 27.4435 22.4223 25.2012 24.0915 23.5812C25.7751 21.9475 28.0038 21.0505 30.3773 21.0505H30.633V17.8472H30.3773C28.0038 17.8472 25.7703 16.9457 24.0915 15.3119C22.3982 13.6691 21.4672 11.4816 21.4672 9.15234H18.0903C18.0903 11.4816 17.1592 13.6691 15.466 15.3119C13.7823 16.9457 11.5536 17.8472 9.18013 17.8472H8.92445V21.0505H9.18013C11.5536 21.0505 13.7872 21.952 15.466 23.5812ZM17.8828 17.5497C18.6258 16.8267 19.2577 16.0304 19.7787 15.1747C20.2997 16.0304 20.9317 16.8267 21.6746 17.5497C22.4416 18.2911 23.3052 18.9272 24.2362 19.4443C23.3052 19.9614 22.4368 20.5975 21.6746 21.3388C20.9317 22.0619 20.2997 22.8581 19.7787 23.7139C19.2577 22.8581 18.6258 22.0619 17.8828 21.3388C17.1158 20.5975 16.2523 19.9614 15.3212 19.4443C16.2523 18.9272 17.1206 18.2911 17.8828 17.5497Z"
      fill="#B26019"
    />
    <path
      d="M39.5582 29.7409H36.1813V36.6052H39.5582V29.7409Z"
      fill="#B26019"
    />
    <path
      d="M46.9089 32.6347L41.7912 37.4876L44.1786 39.7531L49.2963 34.9002L46.9089 32.6347Z"
      fill="#B26019"
    />
    <path d="M52.342 41.8678H45.1058V45.0712H52.342V41.8678Z" fill="#B26019" />
    <path
      d="M94.3116 0H1.68844C0.757387 0 0 0.718467 0 1.60168V70.245C0 71.1282 0.757387 71.8467 1.68844 71.8467H27.43V92.7967H10.2078V96H85.9079L81.9763 71.8467H94.3116C95.2426 71.8467 96 71.1282 96 70.245V1.60168C96 0.718467 95.2426 0 94.3116 0ZM48.4149 71.8467L44.2758 87.1908L36.8322 92.7967H30.802V71.8467H48.4149ZM66.3944 57.093V61.1749H50.9138V62.5753L49.2784 68.6433H30.8068V57.093H66.3944ZM43.2241 53.8896L44.4253 51.6793H48.082V53.8896H43.2241ZM58.7192 68.7211L57.875 88.0145H54.2907V64.3783H67.5715L74.441 68.7257H58.7144L58.7192 68.7211ZM50.9138 87.5843L47.836 86.9116L50.9138 75.4939V87.5843ZM78.3292 67.33L69.7713 61.9163V49.8625L73.8573 40.8657C74.0503 40.4401 74.1853 39.9962 74.2673 39.5523C74.4362 39.9275 74.5664 40.3211 74.6485 40.733L78.3244 59.5001V67.3254L78.3292 67.33ZM68.2179 30.5875C65.2945 30.5875 62.9114 28.3314 62.9114 25.5536C62.9114 22.7759 65.2896 20.5198 68.2179 20.5198C71.1461 20.5198 73.5244 22.7759 73.5244 25.5536C73.5244 28.3314 71.1461 30.5875 68.2179 30.5875ZM69.1297 35.9279C69.6893 36.1339 70.1765 36.5 70.519 36.9896C71.0593 37.7676 71.151 38.7469 70.7602 39.6026L64.2669 53.8896H51.4541V51.6793H62.2167L69.1297 35.9279ZM42.2641 92.7967L46.1813 89.845L49.4231 90.5543L47.2764 92.7967H42.2593H42.2641ZM51.84 92.7967L53.3499 91.2179H57.7351L57.6675 92.7967H51.84ZM61.0444 92.7967L61.9562 71.9291H78.5705L81.9715 92.7967H61.0444ZM92.6231 68.6433H81.7061V59.6282L90.9154 34.674L87.7266 33.6169L80.5194 53.1528L77.9723 40.1564C77.3403 36.9255 74.8173 34.2851 71.59 33.1502C74.7063 31.9009 76.9013 28.9675 76.9013 25.5628C76.9013 21.0232 73.0082 17.3256 68.2179 17.3256C63.4275 17.3256 59.5345 21.0186 59.5345 25.5628C59.5345 29.5395 62.5206 32.8664 66.4812 33.6307L59.9638 48.4805H42.3654L39.4275 53.8942H38.5399L30.6621 36.4954L27.5602 37.7584L34.8687 53.8942H24.7719L25.2543 44.9111H15.5867L16.0691 53.8942H10.2175V57.0975H27.4396V68.6479H3.37688V3.20336H92.6231V68.6433ZM19.1325 48.1098H21.6941L21.3853 53.8896H19.446L19.1373 48.1098H19.1325Z"
      fill="#B26019"
    />
  </svg>
)
