import { useOktaAuth } from '@okta/okta-react'
import ComposeProviders from 'components/ui/ComposeProviders'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserProvider } from 'modules/users/presentation/contexts/UserContext'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

import UsersTemplate from './UsersTemplate'

const User = () => {
  const navigate = useNavigate()
  const { authState, oktaAuth } = useOktaAuth()
  const { getStorageItem } = useLocalStorage()
  const { user, isLoadingProfile, isSuccessProfile, isProfileAdmin } =
    useProfile()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission = isProfileAdmin

      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isProfileAdmin,
    navigate,
    user,
    oktaAuth
  ])

  const providers = [{ Provider: ProfileProvider }, { Provider: UserProvider }]

  return (
    <ComposeProviders providers={providers}>
      <UsersTemplate />
    </ComposeProviders>
  )
}

export default User
