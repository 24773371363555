import { useMutation } from 'react-query'
import { AuthService, LoginProps } from 'services/authService'

export function useMutationLogin() {
  return useMutation(
    ({ code, client_request_id }: LoginProps) =>
      login({ code, client_request_id }),
    { retry: 0 }
  )
}

export function useMutationVerifyProfile() {
  return useMutation(({ token }: { token: string }) => verifyLogin({ token }), {
    retry: 0
  })
}

export function useMutationLogoutUser() {
  return useMutation(({ id_token_hint }: { id_token_hint: string }) =>
    logout({ id_token_hint })
  )
}

const login = async ({ code, client_request_id }: LoginProps) => {
  if (!code) return

  const response = await AuthService.login({ code, client_request_id })
  return response.data
}

const logout = async ({ id_token_hint }: { id_token_hint: string }) => {
  if (!id_token_hint) return

  const response = await AuthService.logout({ id_token_hint })
  return response.data
}

const verifyLogin = async ({ token }: { token: string }) => {
  if (!token) return

  const response = await AuthService.verifyProfile({ token })
  return response.data
}
