import {
  Button,
  Center,
  Menu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  MenuList,
  useDisclosure
} from '@chakra-ui/react'
import { Flex, Icon, Image, Text } from 'components/atoms'
import { StatusLabel } from 'components/ui'
import { MenuItemProps, PluxeeMenuProps } from 'components/ui/PluxeeMenu'
import { AcceptedStatus } from 'components/ui/StatusLabel'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useAlert } from 'hooks/useAlert'
import { formatCNPJ, formatCPF } from 'internationalization'
import { type Consumer } from 'models/Consumer'
import { type ConsumerAccount } from 'models/ConsumerAccount'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { formatConsumerModel, formatProductId } from 'utils/productUtils'
import { capitalizeSentence } from 'utils/stringUtils'

import { HistoryModal } from './accountHistory/accountHistory'
import { CardsModal } from './consumerCards/consumerCards'

const Wrapper = () => {
  const { alert } = useAlert()
  const { setCustomerId } = useAccountWallet()

  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (!state || typeof state === 'undefined') {
      alert({
        status: 'error',
        id: 'warningAccountsDifferents',
        title: 'Ocorreu um erro, tente novamente mais tarde!'
      })

      navigate('/consumer')
    }
  }, [state])

  state && setCustomerId(state?.customer?.id)

  const {
    isOpen: isHistoryOpen,
    onOpen: onHistoryOpen,
    onClose: onHistoryClose
  } = useDisclosure()

  const {
    isOpen: isCardsOpen,
    onOpen: isCardsModalOpen,
    onClose: onCardsModalClose
  } = useDisclosure()

  const menuItems = [
    {
      title: 'Cartões vinculados',
      onClick: () => isCardsModalOpen()
    },
    {
      title: 'Histórico da conta',
      onClick: () => onHistoryOpen()
    }
  ]

  return (
    <Flex flexDir="column">
      <Flex alignItems="center" justifyContent="space-between" mb="32px">
        <Flex gap="16px" alignItems="center">
          <Text
            fontSize="md"
            fontWeight="700"
            color="pluxee.text.primary"
            letterSpacing="-0.8px"
          >
            Conta #{state?.account?.id}
          </Text>

          <StatusLabel statusType={state?.account.status as AcceptedStatus} />
        </Flex>

        <ActMenu items={menuItems} />
      </Flex>

      {state && (
        <ConsumerDetails
          account={state.account}
          consumer={state.consumer}
          customer={state.customer}
          isHistoryModalOpen={isHistoryOpen}
          onHistoryModalClose={onHistoryClose}
          isCardsModalOpen={isCardsOpen}
          onCardsModalClose={onCardsModalClose}
        />
      )}
    </Flex>
  )
}

type ConsumerDetailsProps = {
  account: Pick<ConsumerAccount, 'account'>['account']
  consumer: Consumer
  customer: Pick<ConsumerAccount, 'customer'>['customer']
  isHistoryModalOpen: boolean
  onHistoryModalClose: () => void
  isCardsModalOpen: boolean
  onCardsModalClose: () => void
}

const ConsumerDetails = ({
  account,
  consumer,
  customer,
  isHistoryModalOpen,
  onHistoryModalClose,
  isCardsModalOpen,
  onCardsModalClose
}: ConsumerDetailsProps) => {
  const { creationDate, productId, closureDate } = account

  const formattedClosureDate = closureDate
    ? formatDateToBrazilianFormat(closureDate)
    : '—'

  const formattedCreationDate = creationDate
    ? formatDateToBrazilianFormat(creationDate)
    : '—'

  const { formattedConsumerModel } = formatConsumerModel({
    isPatCustomer: customer.product!.patCustomer
  })

  return (
    <>
      <Flex flexDir="column" gap="16px">
        <Text
          color="pluxee.text.primary"
          fontSize="md"
          fontWeight="700"
          lineHeight="31.2px"
          letterSpacing="-0.8px"
        >
          Detalhes da conta:
        </Text>

        <Flex gap="32px" alignItems="flex-start" letterSpacing="-0.8px">
          <Flex id="consumer-avatar" w="84px" h="84px">
            <Image
              aria-label="Default Avatar"
              src="/img/pluxee/defaultAvatar.png"
              alt="Icone de uma pessoa"
              w="100%"
              h="100%"
            />
          </Flex>
          <Flex gap="74px">
            <Flex id="consumer-info" flexDir="column" gap="8px">
              <Flex gap="10px">
                <Details
                  minW="62px"
                  title="Nome:"
                  description={capitalizeSentence(consumer.name)}
                />
              </Flex>

              {consumer.document && (
                <Flex gap="10px">
                  <Details
                    minW="62px"
                    title="CPF:"
                    description={formatCPF(consumer.document)}
                  />
                </Flex>
              )}
            </Flex>

            <Flex
              id="consumer-info"
              flexDir="column"
              justifyContent="center"
              gap="8px"
              pt={1}
            >
              <Flex gap="10px">
                <Details
                  title="Empresa:"
                  description={capitalizeSentence(customer.name)}
                />
              </Flex>

              {customer.document && (
                <Flex gap="10px">
                  <Details
                    title="CNPJ:"
                    description={formatCNPJ(customer.document)}
                  />
                </Flex>
              )}

              <Flex gap="10px">
                <Details
                  title="Produto:"
                  description={formatProductId(String(productId))}
                />
              </Flex>
            </Flex>

            <Flex id="consumer-info" flexDir="column" gap="8px">
              <Flex gap="10px">
                <Details
                  title="Criado em:"
                  description={formattedCreationDate}
                />
              </Flex>

              <Flex gap="10px">
                <Details
                  title="Inativado em:"
                  description={formattedClosureDate}
                />
              </Flex>

              <Flex gap="10px">
                <Details
                  title="Modelo:"
                  description={formattedConsumerModel ?? '-'}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <HistoryModal
        account={account}
        consumer={consumer}
        customer={customer}
        isOpen={isHistoryModalOpen}
        onClose={onHistoryModalClose}
      />

      <CardsModal
        account={account}
        isOpen={isCardsModalOpen}
        onClose={onCardsModalClose}
      />
    </>
  )
}

export default Wrapper

const Details = ({
  title,
  description,
  minW = '100px'
}: {
  title: string
  description: string | number
  minW?: string
}) => {
  return (
    <Flex alignItems="center" gap="16px">
      <Text
        fontWeight="700"
        color="pluxee.text.primary"
        minW={minW}
        fontSize="xxs"
      >
        {title}
      </Text>
      <Text color="pluxee.text.primary" fontWeight="500" fontSize="xxs">
        {description}
      </Text>
    </Flex>
  )
}

const ActMenu = ({ items }: PluxeeMenuProps) => {
  const [boxShadow, setBoxShadow] = useState('')

  const handleOnClick = () => {
    setBoxShadow('4px 4px 0px 0px #A9A7B6')
  }

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Menu isLazy gutter={2}>
      <ChakraMenuButton
        w="fit-content"
        h="40px"
        as={Button}
        onClick={handleOnClick}
        borderRadius="none"
        background="#1B51DC"
        color="white"
        _hover={{ ...defaultStyles }}
        _focusVisible={{ ...defaultStyles, border: '3px solid #1B51DC' }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white'
        }}
        _expanded={{
          ...defaultStyles,
          background: '#1B51DC',
          color: 'white',
          boxShadow: 'none !important'
        }}
      >
        <Center>
          <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
            Ações
          </Text>
          <Icon
            name="keyboard_arrow_down"
            color="inherit"
            size="lg"
            cursor="pointer"
          />
        </Center>
      </ChakraMenuButton>

      <MenuList
        p="0"
        borderRadius="0"
        border="1px solid"
        borderColor="pluxee.border.secondary"
        boxShadow={boxShadow}
        _hover={{
          background: 'transparent',
          boxShadow: 'none'
        }}
      >
        {items.map(({ title, isDisabled, onClick, icon }) => {
          return (
            <MenuItem
              key={`Menu Option - ${title}`}
              title={title}
              isDisabled={isDisabled}
              onClick={onClick}
              icon={icon}
            />
          )
        })}
      </MenuList>
    </Menu>
  )
}

const MenuItem = ({
  title,
  onClick,
  icon,
  isDisabled = false
}: MenuItemProps) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.text.primary'
  }

  return (
    <ChakraMenuItem
      gap="8px"
      onClick={onClick}
      isDisabled={isDisabled}
      w="188px"
      h="44px"
      background="standard.white"
      borderColor="pluxee.border.secondary"
      fontWeight="600"
      fontSize="xxs"
      color="pluxee.text.primary"
      boxShadow={'4px 4px 0px 0px #A9A7B6'}
      outline="none"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        color: 'standard.white',
        background: 'pluxee.cta.secondaryPressed'
      }}
      _focusVisible={{ ...defaultStyles, border: '3px solid #1B51DC' }}
      p="12px 8px"
    >
      {icon}
      <Text whiteSpace="nowrap" letterSpacing="-0.8px">
        {title}
      </Text>
    </ChakraMenuItem>
  )
}
