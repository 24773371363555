import { Button, Center, Image, Tooltip, useDisclosure } from '@chakra-ui/react'
import {
  Flex,
  Icon,
  MenuButton as ChakraMenuButton,
  Text
} from 'components/atoms'
import { PluxeeMenu, StatusLabel } from 'components/ui'
import { AcceptedStatus } from 'components/ui/StatusLabel'
import { useConsumer } from 'contexts/consumerContext'
import { useProfile } from 'contexts/profileContext'
import { useAlert } from 'hooks/useAlert'
import { BlockReason } from 'models/BlockReason'
import { Card, CardReason } from 'models/Card'
import React, { PropsWithChildren } from 'react'
import { ConsumerService } from 'services/consumerService'
import { formatToDateAndTime, getMonthAndYear } from 'utils/dateUtils'
import { capitalizeSentence } from 'utils/stringUtils'

import { BlockAndUnblockCard } from './blockAndUnblockCard'

type MultiBenefitCardProps = {
  card: Card
}

export const MultiBenefitCard = ({ card }: MultiBenefitCardProps) => {
  const { alert } = useAlert()
  const { setBlockReasons, setIsErrorBlockReasons } = useConsumer()

  const {
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    isOperation3c,
    isBackoffice4c,
    isManageTransactions,
    isFraudAndPrevention,
    isPayments,
    userLevel
  } = useProfile()

  const isProduct3c = card.productId === 34
  const isFraudLevel = !isProduct3c && isFraudAndPrevention

  const cardIsBlocked = ['BLOCKED', 'PASSWORD_BLOCKED'].includes(card.status)
  const cardIsBlockedByPass = ['PASSWORD_BLOCKED'].includes(card.status)
  const cardIsCancelled = ['WAITING_CANCELLATION', 'CANCELLED'].includes(
    card.status
  )

  const isDisableForBackoffice4C = cardIsBlocked && isBackoffice4c
  const isDisableForAttendant4C =
    (cardIsBlocked && isAttendant) || (cardIsBlocked && isSupervisorB2b)

  const isDisableMenu =
    ((isProduct3c || cardIsCancelled) && !cardIsBlocked) ||
    isOperation3c ||
    isAttendant3c ||
    isAttendantB2M ||
    isManageTransactions ||
    isDisableForBackoffice4C ||
    isDisableForAttendant4C ||
    isPayments

  const {
    isOpen: isLockUnlockOpen,
    onOpen: onLockUnlockOpen,
    onClose: onLockUnlockClose
  } = useDisclosure()

  const handleOnOpenModal = async () => {
    setIsErrorBlockReasons(false)

    await ConsumerService.getBlockReasons({
      profileName: userLevel!
    })
      .then(results => {
        const blockReasons: BlockReason[] = results.data
        setBlockReasons(blockReasons)
      })
      .catch(err => {
        setIsErrorBlockReasons(true)

        alert({
          id: 'alertBlockReasons',
          title:
            err.response.data.message ?? 'Ocorreu um erro tente novamente!',
          status: 'error'
        })
      })

    onLockUnlockOpen()
  }

  return (
    <>
      <CardWrapper>
        <CardContainer>
          <TopArea
            onLockUnlockOpen={handleOnOpenModal}
            isDisabled={isDisableMenu}
          />
          <CardStatus
            cardStatus={card.status}
            cardIsBlockedByPassword={cardIsBlockedByPass}
            alterationDate={card.alterationDate ?? ''}
            cancellationDate={card.cancellationDate ?? ''}
            cardReason={card.cardReason!}
          />
          <CardInfo card={card} />
        </CardContainer>
      </CardWrapper>

      <BlockAndUnblockCard
        card={card}
        isOpen={isLockUnlockOpen}
        onClose={onLockUnlockClose}
        isFraudLevel={isFraudLevel}
      />
    </>
  )
}

const CardWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      p="16px"
      w="320px"
      h="fit-content"
      minH="206px"
      align="start"
      fontSize="xxs"
      borderRadius="8px"
      bgColor="#1ceb5e"
      backgroundSize="initial"
      border="1px solid #E9E8EF"
      backgroundRepeat="no-repeat"
      backgroundPosition="bottom 16px left 16px"
      backgroundImage="url('/img/pluxee/X-Pluxee.svg')"
    >
      {children}
    </Flex>
  )
}

const CardContainer = ({ children }: PropsWithChildren) => {
  return (
    <Flex id="container" flexDir="column" w="100%">
      {children}
    </Flex>
  )
}

const TopArea = ({
  onLockUnlockOpen,
  isDisabled = false
}: {
  onLockUnlockOpen: () => void
  isDisabled?: boolean
}) => (
  <Flex id="topArea" alignItems="flex-start" justifyContent="space-between">
    <Flex alignItems="center" gap="16px">
      <Flex gap="8px">
        <Image src="/img/pluxee/crown.svg" />
        <Text fontSize="16px" fontWeight="700" letterSpacing="-0.8px">
          Multi-benefícios
        </Text>
      </Flex>

      <Flex>
        <Image src="/img/pluxee/type-plx-logo.svg" w="72px" h="22px" />
      </Flex>
    </Flex>

    <Flex>
      <PluxeeMenu
        menuButton={<CustomMenuButton isDisabled={isDisabled} />}
        items={[
          {
            title: 'Ações de segurança',
            onClick: () => onLockUnlockOpen(),
            isDisabled
          }
        ]}
      />
    </Flex>
  </Flex>
)

const CardStatus = ({
  cardStatus,
  cardIsBlockedByPassword,
  alterationDate,
  cancellationDate,
  cardReason
}: {
  cardStatus: string
  cardIsBlockedByPassword: boolean
  alterationDate: string
  cancellationDate: string
  cardReason: CardReason
}) => {
  if (cardIsBlockedByPassword) {
    return (
      <Flex flexDir="column" lineHeight="130%">
        <Text fontWeight="600" color="#2A295C" letterSpacing="-0.8px">
          Bloqueado
        </Text>
        <Text fontWeight="400" color="#7F7F9D" letterSpacing="-0.8px">
          Senha incorreta
        </Text>
      </Flex>
    )
  }

  if (cardStatus === 'PRE_BLOCKED') {
    return (
      <Flex id="cardStatus" mt="6px" w="fit-content">
        <StatusLabel statusType={cardStatus as AcceptedStatus} />
      </Flex>
    )
  }

  let defaultMessage = alterationDate
    ? `Alterado em: ${formatToDateAndTime(alterationDate)}`
    : ''
  if (cardStatus === 'CANCELLED')
    defaultMessage = cancellationDate
      ? `Cancelado em: ${formatToDateAndTime(cancellationDate)}`
      : ''

  const formattedCardReason = cardReason
    ? `Motivo: ${cardReason.code} - ${capitalizeSentence(
        cardReason?.description
      )}`
    : ''

  return (
    <CustomTooltip label={`${defaultMessage} ${formattedCardReason}`}>
      <Flex id="cardStatus" mt="6px" w="fit-content">
        <StatusLabel statusType={cardStatus as AcceptedStatus} />
      </Flex>
    </CustomTooltip>
  )
}

const CardInfo = ({ card }: Pick<MultiBenefitCardProps, 'card'>) => {
  const cardType = card.virtual ? 'virtual' : 'físico'
  const cardFirst4Digits = card.cardNumber?.substring(0, 4)
  const cardLast4Digits = card.cardNumber?.substring(
    card.cardNumber?.length,
    12
  )

  const capitalizeCardName = capitalizeSentence(card.cardName ?? '-')

  return (
    <Flex
      flexDir="column"
      id="cardInfo"
      gap="6px"
      mt="12px"
      fontWeight="700"
      px="24px"
    >
      <Text fontSize="14px" letterSpacing="-0.8px">
        Cartão {cardType}
      </Text>
      <Flex id="cardNumber" gap="16px" fontSize="16px">
        <Text letterSpacing="-0.8px">{cardFirst4Digits}</Text>
        <Text letterSpacing="-0.8px">****</Text>
        <Text letterSpacing="-0.8px">****</Text>
        <Text letterSpacing="-0.8px">{cardLast4Digits}</Text>
      </Flex>

      <Flex
        id="consumerInfo"
        marginTop="10px"
        justifyContent="space-between"
        maxW="203px"
      >
        <Flex flexDir="column">
          <Text
            fontWeight="500"
            fontSize="10px"
            color="#55547D"
            letterSpacing="-0.8px"
          >
            Nome
          </Text>
          <Text fontSize="16px">{capitalizeCardName ?? ''}</Text>
        </Flex>

        <Flex flexDir="column">
          <Text
            fontWeight="500"
            fontSize="10px"
            color="#55547D"
            letterSpacing="-0.8px"
          >
            Validade
          </Text>
          <Text fontSize="16px" letterSpacing="-0.8px">
            {card.expirationDate
              ? getMonthAndYear(new Date(card.expirationDate))
              : '-'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const CustomMenuButton = ({ isDisabled = false }: { isDisabled?: boolean }) => (
  <ChakraMenuButton disabled={isDisabled}>
    <Center
      as={Button}
      w="40px"
      h="40px"
      border="none"
      outline="none"
      p="0"
      color={isDisabled ? '#B5B2BC' : '#1B51DC'}
      borderRadius="0"
      background="transparent"
      _hover={{
        background: 'transparent'
      }}
      _focusVisible={{
        border: '3px solid #1B51DC',
        color: '#0F266D'
      }}
    >
      <Icon
        name="more_vert"
        variant="outlined"
        _hover={{ color: '#0F266D' }}
        _active={{ color: '#0F266D' }}
      />
    </Center>
  </ChakraMenuButton>
)

const CustomTooltip = ({
  children,
  label
}: {
  children: React.ReactNode
  label: string
}) => (
  <Tooltip
    aria-label={label}
    label={label}
    hasArrow
    arrowSize={16}
    arrowShadowColor="#CBCAD2"
    minW="256px"
    minH="48px"
    textAlign="center"
    bg="#221C46"
    placement="auto"
    border="0"
    p="16px"
    borderRadius="none"
    fontFamily="TTTravels"
    fontWeight="500"
    fontSize="13px"
    color="white"
    boxShadow="4px 4px 0px 0px #CBCAD2"
  >
    {children}
  </Tooltip>
)
