import { Security } from '@okta/okta-react'
import {
  LoginCallback,
  NotAuthorized,
  UserNotFound,
  PageNotFound,
  LogoutRedirect,
  VpnOff
} from 'components/ui'
import { OktaProvider } from 'contexts/oktaContext'
import Transaction from 'modules/transaction/presentation/pages/Transaction'
import User from 'modules/users/presentation/pages/Users'
import {
  Customer,
  Consumer,
  ConsumerDetails,
  CustomerDetails,
  SchedulesDetails,
  CustomizedClients,
  CustomizedWalletUpdate,
  Home,
  Schedules,
  Wallet,
  WalletDetails,
  WalletManager
} from 'pages'
import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { oktaAuth } from './oktaConfig'

export default function MainRoutes() {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    const navigate = useNavigate()
    navigate(originalUri || '/', { replace: true })
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <OktaProvider>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/not-allowed" element={<NotAuthorized />} />
          <Route path="/login-not-found" element={<UserNotFound />} />
          <Route path="/redirect-logout" element={<LogoutRedirect />} />
          <Route path="/vpn-off" element={<VpnOff />} />

          <Route path="/user" element={<User />} />

          <Route path="/schedules" element={<Schedules />} />
          <Route path="/schedules/:id" element={<SchedulesDetails />} />

          <Route path="/wallet" element={<Wallet />} />
          <Route path="/wallet/details/:id" element={<WalletDetails />} />

          <Route path="/wallet/add" element={<WalletManager />} />
          <Route path="/wallet/edit/:id" element={<WalletManager />} />

          <Route path="/consumer" element={<Consumer />} />
          <Route path="/consumer/details/:id" element={<ConsumerDetails />} />

          <Route path="/customer" element={<Customer />} />
          <Route path="/customer/details" element={<CustomerDetails />} />

          <Route path="/transactions" element={<Transaction />} />

          <Route path="/customized-clients" element={<CustomizedClients />} />
          <Route
            path="/customized-wallet-update"
            element={<CustomizedWalletUpdate />}
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </OktaProvider>
    </Security>
  )
}
