import { CancelOrderProps } from 'contexts/scheduleContext'
import { AntecipateOrderProps } from 'models/request/AntecipateOrder'

import { scheduleApi } from './instances/schedulesApi'

export const SchedulesService = {
  getSchedulesByOrderId(orderId: string) {
    return scheduleApi.get(`/schedules/order/${orderId}`, { data: null })
  },
  getDetails(orderId: string) {
    return scheduleApi.get(`/schedules/${orderId}`, { data: null })
  },
  getHistory(orderId: string) {
    return scheduleApi.get(`schedules/${orderId}/topup-events`, { data: null })
  },
  getConsumersByCpf({ orderId, cpf }: { orderId: string; cpf: string }) {
    return scheduleApi.get(`schedules/${orderId}/by-cpf/${cpf}`)
  },
  cancelOrder({ orderId, occurencyId, userName, sourceIp }: CancelOrderProps) {
    return scheduleApi.post(
      `schedules/${orderId}/cancel`,
      {
        occurrencyId: occurencyId,
        userInfo: {
          updatedBy: {
            user: userName,
            sourceIp
          }
        }
      },
      { data: null }
    )
  },
  antecipateOrder({
    orderId,
    scheduledDate,
    sourceIp,
    userName
  }: AntecipateOrderProps) {
    return scheduleApi.post(
      `schedules/${orderId}/anticipate`,
      {
        scheduledDate,
        userInfo: {
          updatedBy: {
            user: userName,
            sourceIp
          }
        }
      },
      { data: null }
    )
  },
  getSchedulesByCustomerId({
    customerId,
    filterByScheduleds = false,
    pageNumber
  }: {
    customerId: string
    filterByScheduleds?: boolean
    pageNumber?: string
  }) {
    const queryParams = new URLSearchParams()

    if (filterByScheduleds) queryParams.append('status', 'SCHEDULED')
    if (pageNumber) queryParams.append('page', pageNumber)

    const queryString = queryParams.toString()
    const url = `/schedules/customer/${customerId}${
      queryString ? `?${queryString}` : ''
    }`

    return scheduleApi.get(url, {
      data: null
    })
  }
}
