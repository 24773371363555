import { Flex } from '@chakra-ui/react'
import { Container, Layout } from 'components/ui'
import { useWallet } from 'contexts/walletContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { WalletPriorityToLayout } from 'models/WalletPriority'
import React, { useEffect, useState } from 'react'

import { ActionButtons, Header } from './components'
import EditWalletsPriority from './components/editWalletsPriority'
import { Products } from './products'
import { WalletsList } from './walletList'

const WalletTemplate = () => {
  const { removeStorageItem } = useLocalStorage()
  const { setEditPriority } = useWallet()

  function onPageBack() {
    removeStorageItem('selectedProduct')
    setEditPriority(false)
  }

  return (
    <Layout isShowBackButton onPageBack={onPageBack}>
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default WalletTemplate

const Main = () => {
  const [showingWallets, setShowingWallets] = useState(false)
  const { selectedProduct, editPriority, wallets } = useWallet()

  const showWalletList =
    selectedProduct?.productId && !editPriority && showingWallets
  const showEditPriority =
    selectedProduct?.productId && !showingWallets && editPriority

  const createWalletPriority = () => {
    if (!wallets) return []

    const orderedWallets = wallets.sort((a, b) => a.priority! - b.priority!)
    return orderedWallets.map(wallet => {
      const item: WalletPriorityToLayout = {
        id: wallet.id!,
        name: wallet.name!,
        priority: wallet.priority!
      }

      return item
    })
  }

  useEffect(() => {
    if (selectedProduct?.productId) setShowingWallets(true)

    if (editPriority) {
      setShowingWallets(false)
    } else {
      setShowingWallets(true)
    }
  }, [selectedProduct, editPriority])

  return (
    <Flex flexDir="column" bg="white" width="100%" p="40px" gap="40px">
      <Products />
      {showWalletList && (
        <WalletsList header={<Header buttons={<ActionButtons />} />} />
      )}

      {showEditPriority && (
        <EditWalletsPriority walletList={createWalletPriority()} />
      )}
    </Flex>
  )
}
