import { useOktaAuth } from '@okta/okta-react'
import ComposeProviders from 'components/ui/ComposeProviders'
import { CustomerProvider } from 'contexts/customerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomizedClientsTemplate from 'templates/customizedClients/customizedClients'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const CustomizedClients = () => {
  const navigate = useNavigate()
  const { getStorageItem } = useLocalStorage()
  const { authState, oktaAuth } = useOktaAuth()
  const {
    user,
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isSupervisorB2b,
    isFraudAndPrevention,
    isBackoffice4c,
    isPayments
  } = useProfile()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission =
        isAttendant ||
        isFraudAndPrevention ||
        isBackoffice4c ||
        isPayments ||
        isSupervisorB2b

      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isSupervisorB2b,
    isPayments,
    navigate,
    user,
    authState
  ])

  const providers = [
    { Provider: ProfileProvider },
    { Provider: CustomerProvider }
  ]

  return (
    <ComposeProviders providers={providers}>
      <CustomizedClientsTemplate />
    </ComposeProviders>
  )
}

export default CustomizedClients
