import {
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableColumnHeaderProps,
  Button,
  Spinner
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { Pagination, ScheduleStatus } from 'components/ui'
import { useSchedules } from 'contexts/scheduleContext'
import { usePagination } from 'hooks/usePagination'
import { formatCPF, translate } from 'internationalization'
import { SchedulesDetails } from 'models/Schedules'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { customerApi } from 'services/instances/customerApi'
import { formatToBrl } from 'utils/currencyUtils'
import { goToSection } from 'utils/windowUtils'

import { SchedulesStatusEnum } from '../orderDetails'
const CONSUMERS_PER_PAGE = 20

export const ResultsTable = ({ isInvalid }: { isInvalid: boolean }) => {
  const { id: scheduleId } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [lastPage, setLastPage] = useState<number>()
  const [schedulesDetails, setSchedulesDetails] = useState<SchedulesDetails>()

  const { currentPage, handleNextPage, handleOnChangePage, handlePrevPage } =
    usePagination({
      initialPage: schedulesDetails?.beneficiaryDetails?.page?.currentPage ?? 0
    })

  async function getConsumersList({ page }: { page: number }) {
    try {
      const { data: scheduleData } = await customerApi.get(
        `/schedules/${scheduleId}`,
        { params: { page, size: CONSUMERS_PER_PAGE } }
      )

      setSchedulesDetails(scheduleData)
      setLastPage(scheduleData.beneficiaryDetails?.page?.totalPages)
    } catch (err) {
      console.log('error: ', err)
    } finally {
      setIsLoading(false)
      goToSection('tabsSection')
    }
  }

  useEffect(() => {
    getConsumersList({ page: currentPage })
  }, [currentPage])

  const { hideConsumersList, consumersList } = useSchedules()
  const beneficiaryList = schedulesDetails?.beneficiaryDetails?.items

  const renderList = useCallback(() => {
    if (hideConsumersList || isInvalid)
      return beneficiaryList?.map(
        ({ name, document, id, wallets, accountNumber }) => (
          <CustomTableCell
            key={id}
            id={id}
            consumerName={name ?? 'Pendente'}
            accountNumber={accountNumber ?? 'Pendente'}
            consumerCpf={formatCPF(document)}
            items={wallets}
          />
        )
      )

    return consumersList?.beneficiaryDetails?.map(
      ({ document, name, wallets, accountNumber, id }) => (
        <CustomTableCell
          key={id}
          id={id}
          consumerName={name ?? 'Pendente'}
          consumerCpf={formatCPF(document)}
          items={wallets}
          accountNumber={String(accountNumber)}
        />
      )
    )
  }, [hideConsumersList, isInvalid, schedulesDetails])

  const itensPerPage = 20
  const isPaginated =
    (hideConsumersList &&
      !isLoading &&
      beneficiaryList!.length > itensPerPage) ||
    (hideConsumersList &&
      !isLoading &&
      consumersList?.beneficiaryDetails?.length > itensPerPage)

  return (
    <Flex w="100%" flexDir="column">
      {!isLoading ? (
        <TableContainer w="100%" bgColor="#221C46">
          <Table variant="simple" borderBottom="1px solid white">
            <Thead bg="#221C46">
              <Tr>
                <CustomTh w="435px" title="Colaboradores" isBolder />
                <CustomTh title="CPF" isBolder />
              </Tr>
            </Thead>
            <Tbody>{renderList()}</Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Spinner />
      )}

      {isPaginated && (
        <Center mt={8}>
          <Pagination
            size="sm"
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangePage={pageNumber => handleOnChangePage(pageNumber)}
            currentPage={currentPage}
            lastPage={lastPage ?? 0}
          />
        </Center>
      )}
    </Flex>
  )
}

type CustomThProps = {
  title: string
  isBolder?: boolean
  color?: string
} & TableColumnHeaderProps

export const CustomTh = ({
  title,
  isBolder = false,
  color = 'white',
  ...rest
}: CustomThProps) => (
  <Th
    color={color}
    fontWeight={isBolder ? '700' : '500'}
    textTransform="capitalize"
    letterSpacing="-0.7px"
    fontFamily="TTTravels"
    fontSize="14px !important"
    {...rest}
    py="10px"
    borderBottom="1px solid #D1CFD7"
  >
    {title}
  </Th>
)

type Item = {
  id: number
  walletId: string
  walletType: string
  value: number
  status: string
  statusDate: string
  transactionUUID?: string
}

type CustomTableCellProps = {
  consumerName: string
  consumerCpf: string
  accountNumber: string
  items: Item[]
  id: number
}

const CustomTableCell = ({
  consumerName,
  consumerCpf,
  accountNumber,
  items,
  id
}: CustomTableCellProps) => {
  const [openDetails, setOpenDetails] = useState<number>()
  const isShowDetails = openDetails === id

  return (
    <>
      <Tr
        bg="red"
        w="100%"
        color="brand.primary-dark"
        bgColor={isShowDetails ? '#C3EBFB' : '#FFFFFF'}
        borderTop={isShowDetails ? '2px solid #1B51DC' : 'none'}
        _odd={{ bg: isShowDetails ? '#C3EBFB' : '#F3F3FC' }}
      >
        <Td
          w="230px"
          color="#221C46"
          fontSize="14px"
          fontWeight="500"
          letterSpacing="-0.7px"
          textTransform="capitalize"
        >
          {consumerName.toLowerCase()}
        </Td>
        <Td
          color="#221C46"
          fontSize="14px"
          fontWeight="500"
          letterSpacing="-0.7px"
          textTransform="capitalize"
        >
          {consumerCpf}
        </Td>

        <Td></Td>
        <Td></Td>

        <Td
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          maxH="56px"
        >
          <Center
            as={Button}
            color="#1B51DC"
            borderRadius="0"
            background="transparent"
            onClick={() => setOpenDetails(!isShowDetails ? id : 0)}
            _hover={{
              background: 'transparent'
            }}
            _focusVisible={{
              border: '3px solid #1B51DC',
              color: '#0F266D'
            }}
            w="40px"
            h="40px"
          >
            <Icon
              name={isShowDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              variant="outlined"
              _hover={{ color: '#0F266D' }}
              _active={{ color: '#0F266D' }}
            />
          </Center>
        </Td>
      </Tr>

      {isShowDetails && (
        <>
          <Tr bgColor="#C3EBFB" w="100%">
            <CustomTh
              pl="72px"
              bg="#DEF3FB"
              h="40px"
              color="#221C46"
              isBolder
              title={translate('commons.wallet')}
            />
            <CustomTh
              color="#221C46"
              bg="#DEF3FB"
              h="40px"
              isBolder
              title={translate('commons.value')}
            />
            <CustomTh
              color="#221C46"
              bg="#DEF3FB"
              h="40px"
              isBolder
              title={translate('commons.account.account')}
            />
            <CustomTh
              color="#221C46"
              bg="#DEF3FB"
              h="40px"
              isBolder
              title={translate('commons.status')}
            />
            <CustomTh bg="#DEF3FB" h="40px" color="#221C46" isBolder title="" />
          </Tr>

          {items.map(({ id, value, status, walletType }, index) => {
            const isLastRow = index === items.length - 1
            return (
              <Tr key={id} bgColor="#DEF3FB" color="#221C46" h="40px">
                <Td
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '72px'
                  }}
                  borderTop="1px solid #D1CFD7"
                  borderBottom={isLastRow ? '2px solid #D1CFD7' : 'none'}
                  fontSize="14px"
                  fontWeight="500"
                  letterSpacing="-0.7px"
                >
                  {walletType}
                </Td>
                <Td
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '10px'
                  }}
                  borderTop="1px solid #D1CFD7"
                  borderBottom={isLastRow ? '2px solid #D1CFD7' : 'none'}
                  fontSize="14px"
                  fontWeight="500"
                  letterSpacing="-0.7px"
                >
                  {formatToBrl(value)}
                </Td>
                <Td
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '10px'
                  }}
                  borderTop="1px solid #D1CFD7"
                  borderBottom={isLastRow ? '2px solid #D1CFD7' : 'none'}
                  fontSize="14px"
                  fontWeight="500"
                  letterSpacing="-0.7px"
                >
                  {accountNumber}
                </Td>
                <Td
                  style={{
                    paddingTop: '0px',
                    paddingBottom: '0px'
                  }}
                  borderTop="1px solid #D1CFD7"
                  borderBottom={isLastRow ? '2px solid #D1CFD7' : 'none'}
                  fontSize="14px"
                  fontWeight="500"
                  letterSpacing="-0.7px"
                >
                  <ScheduleStatus status={status as SchedulesStatusEnum} />
                </Td>

                <Td
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '10px'
                  }}
                  borderTop="1px solid #D1CFD7"
                  borderBottom={isLastRow ? '2px solid #D1CFD7' : 'none'}
                ></Td>
              </Tr>
            )
          })}
        </>
      )}
    </>
  )
}
