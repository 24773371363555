import { Button, Center, Flex } from '@chakra-ui/react'
import { Text, Icon } from 'components/atoms'
import { Table } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useCustomer } from 'contexts/customerContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { usePagination } from 'hooks/usePagination'
import { formatCNPJ, formatCPF } from 'internationalization'
import { CustomizedClient } from 'models/CustomizedClient'
import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useNavigate } from 'react-router-dom'
import { capitalizeSentence, limitString } from 'utils/stringUtils'

export const CustomizedClientsTable = () => {
  const navigate = useNavigate()

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null)

  const {
    customizedClients,
    customizedClientsPag,
    isLoadingCustomizedClients
  } = useCustomer()

  const { setStorageItem } = useLocalStorage()

  const { currentPage, handlePrevPage, handleNextPage, handleOnChangePage } =
    usePagination({
      initialPage: customizedClientsPag?.currentPage ?? 0
    })

  const columns: CellProps[] = [
    {
      title: 'Cliente',
      width: '25%',
      render: (data: Record<keyof CustomizedClient, any>) => {
        const isGreaterThan40 = data.name?.length >= 40
        const formattedName = isGreaterThan40
          ? limitString(data.name, 40)
          : data.name

        return (
          <>
            <Text
              fontSize="xxs"
              lineHeight="150%"
              color="pluxee.text.secondary"
              letterSpacing="-0.8px"
            >
              {formattedName}
            </Text>
          </>
        )
      }
    },
    {
      title: 'CNPJ/CPF',
      width: '230px',
      render: (data: Record<keyof CustomizedClient, any>) => {
        const isCpf = data.document?.length === 11
        const formattedDocument = isCpf
          ? formatCPF(data.document)
          : formatCNPJ(data.document)

        return (
          <Text
            color="pluxee.text.secondary"
            fontSize="xxs"
            letterSpacing="-0.8px"
          >
            {formattedDocument}
          </Text>
        )
      }
    },
    {
      title: 'Código do cliente',
      render: (data: Record<keyof CustomizedClient, any>) => (
        <Text
          color="pluxee.text.secondary"
          fontSize="xxs"
          letterSpacing="-0.8px"
        >
          {data.id}
        </Text>
      )
    },
    {
      title: ' ',
      width: '5%',
      render: (
        data: Record<keyof CustomizedClient, any> & {
          onToggleRow: () => void
          isExpanded: boolean
        }
      ) => (
        <Flex alignItems="center" gap="25px">
          <Text
            color="#1B51DC"
            letterSpacing="-0.8px"
            fontSize="12px"
            fontWeight="600"
            textDecor="underline"
            textUnderlineOffset={2}
            onClick={() => handleOnClickRow(data)}
            cursor="pointer"
            whiteSpace="nowrap"
          >
            Acessar cliente
          </Text>

          <Center
            w="40px"
            h="40px"
            as={Button}
            color="#1B51DC"
            borderRadius="0"
            background="transparent"
            _hover={{
              background: 'transparent'
            }}
            _focusVisible={{
              border: '3px solid #1B51DC',
              color: '#0F266D'
            }}
            onClick={data.onToggleRow}
          >
            <Icon
              name={
                data.isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }
              variant="outlined"
              _hover={{ color: '#0F266D' }}
              _active={{ color: '#0F266D' }}
            />
          </Center>
        </Flex>
      )
    }
  ]

  const renderCollapseContent = (item: CustomizedClient) => {
    const formattedWalletName = item.customerWallets
      .map(item => capitalizeSentence(item.name))
      .join(' - ')

    return (
      <Flex
        gap="16px"
        color="#221C46"
        letterSpacing="-0.8px"
        fontSize="14px"
        fontWeight="700"
      >
        <Text>Carteira(s) parametrizada(s):</Text>
        <Text fontWeight="500">{formattedWalletName}</Text>
      </Flex>
    )
  }

  const handleOnClickRow = (customer: CustomizedClient) => {
    setStorageItem('customer', JSON.stringify(customer))
    navigate(`/customer/details`)
  }

  const handleToggleRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index)
  }

  const minimumCustomersForPagination = 20

  if (isLoadingCustomizedClients) return <Skeleton />

  return (
    <Table
      textType="initial"
      minWidth="100%"
      size="sm"
      columns={columns}
      list={customizedClients}
      isPaginated={customizedClients.length >= minimumCustomersForPagination}
      currentPage={currentPage}
      totalPages={customizedClientsPag?.totalPages}
      totalResults={customizedClientsPag?.totalResults}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
      withCollapse={true}
      expandedRowIndex={expandedRowIndex}
      onToggleRow={handleToggleRow}
      collapseContent={renderCollapseContent}
    />
  )
}

const Skeleton = () => {
  return (
    <ContentLoader viewBox="0 0 380 200">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="30" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="60" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="90" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="120" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="150" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="180" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="240" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="270" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="300" rx="3" ry="3" width="100%" height="20" />
    </ContentLoader>
  )
}
