export const generateCSVFilename = (
  date: Date,
  type: 'transacoes' | 'entradas' | 'saidas'
) => {
  const currentDate = date.getFullYear()
  const currentDay = date.getDate()
  const currentMonth = date.getMonth() + 1

  const todayDate = `${insertZero(currentDay)}${insertZero(
    currentMonth
  )}${currentDate}`

  return `backoffice-${type}-${todayDate}`
}

export const insertZero = (value: number): string =>
  value < 10 ? `0${value}` : `${value}`
