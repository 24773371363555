import { useDisclosure } from '@chakra-ui/react'
import { usePagination } from 'hooks/usePagination'
import { User } from 'modules/users/domain/entities/User'
import { DeleteUser, UserForm } from 'modules/users/presentation/components'
import { useUser } from 'modules/users/presentation/contexts/UserContext'
import React, { useEffect } from 'react'
import { backToTop } from 'utils/windowUtils'

import UsersTablePresentational from '../presentational/UserTablePresentational'

export const UsersTableContainer = () => {
  const {
    isOpen: isOpenEditUser,
    onClose: onCloseEditUser,
    onOpen: onOpenEditUser
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteUser,
    onClose: onCloseDeleteUser,
    onOpen: onOpenDeleteUser
  } = useDisclosure()

  const {
    users,
    selectedUser,
    setSelectedUser,
    setCurrentFilters,
    isLoading,
    isLoadingGetAll,
    usersPagination,
    currentFilters
  } = useUser()

  const { currentPage, handlePrevPage, handleNextPage, handleOnChangePage } =
    usePagination({
      initialPage: usersPagination?.currentPage ?? 0
    })

  useEffect(() => {
    setCurrentFilters({ ...currentFilters, page: String(currentPage) ?? '0' })
  }, [currentPage])

  const handleEditUser = (user: User) => {
    setSelectedUser(user)
    onOpenEditUser()
  }

  const handleDeleteUser = (user: User) => {
    setSelectedUser(user)
    backToTop()
    onOpenDeleteUser()
  }

  const minimumUsersForPagination = 20

  if (isLoading || isLoadingGetAll) {
    return (
      <UsersTablePresentational
        users={[]}
        currentPage={0}
        handlePrevPage={() => {}}
        handleNextPage={() => {}}
        isLoading={true}
        totalPages={0}
        totalResults={0}
        handleOnChangePage={() => {}}
        handleEditUser={() => {}}
        handleDeleteUser={() => {}}
        minimumUsersForPagination={minimumUsersForPagination}
      />
    )
  }

  return (
    <>
      <UsersTablePresentational
        users={users}
        currentPage={currentPage}
        totalPages={usersPagination?.totalPages}
        totalResults={usersPagination?.totalResults}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleOnChangePage={handleOnChangePage}
        handleEditUser={handleEditUser}
        handleDeleteUser={handleDeleteUser}
        minimumUsersForPagination={minimumUsersForPagination}
      />

      {selectedUser && (
        <>
          <UserForm
            user={selectedUser}
            isOpen={isOpenEditUser}
            onClose={onCloseEditUser}
          />

          <DeleteUser
            userId={selectedUser.id}
            isOpen={isOpenDeleteUser}
            onClose={onCloseDeleteUser}
            userName={selectedUser.name}
            onComplete={() => {
              backToTop()
              setCurrentFilters({
                ...currentFilters,
                page: String(currentPage) ?? '0'
              })
            }}
          />
        </>
      )}
    </>
  )
}
