import {
  useQueryGetByDocument,
  useMutateGetCustomizedClients,
  useQueryGetWalletsByCustomerId,
  useMutateRestoreOriginalValues,
  useMutateUpdateWalletToCustomValues
} from 'hooks/queries/customerQueries'
import { Customer } from 'models/Customer'
import { CustomerWallet } from 'models/CustomerWallet'
import { CustomizedClient } from 'models/CustomizedClient'
import { Pagination } from 'models/Pagination'
import { RestoreWalletOriginalValues } from 'models/request/RestoreWalletOriginalValues'
import { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

export type ContextType = {
  customers: Customer[]
  customersPagination?: Pagination

  customizedClients: CustomizedClient[]
  customizedClientsPag?: Pagination

  wallets: CustomerWallet[]

  selectedCustomer?: Customer
  document: string

  isLoading: boolean
  isError: boolean
  isSuccess: boolean

  isLoadingWallets: boolean
  isErrorWallets: boolean
  isSuccessWallets: boolean

  isLoadingCustomizedClients: boolean
  isErrorCustomizedClients: boolean

  setDocument: (value: string) => void
  getCustomizedClients: () => void

  isErrorRestoreOriginalValues: boolean
  isLoadingRestoreOriginalValues: boolean
  restoreWalletOriginalValues: (
    walletInfo: RestoreWalletOriginalValues
  ) => Promise<void>

  isErrorUpdateWalletToCustomValues: boolean
  isLoadingUpdateWalletToCustomValues: boolean
  updateWalletToCustomValues: (
    customValues: UpdateWalletToCustomValues
  ) => Promise<void>
}

export const Context = createContext({} as ContextType)

type CustomerProviderProps = {
  children: ReactNode
}

export const CustomerProvider = ({ children }: CustomerProviderProps) => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [customersPagination, setCustomersPagination] = useState<Pagination>()

  const [customizedClients, setCustomizedClients] = useState<
    CustomizedClient[]
  >([])
  const [customizedClientsPag, setCustomizedClientsPag] = useState<Pagination>()

  const [wallets, setWallets] = useState<CustomerWallet[]>([])

  const [document, setDocument] = useState('')
  const selectedCustomer = JSON.parse(localStorage.getItem('customer') ?? '{}')

  const queryCustomer = useQueryGetByDocument(document)
  const { isLoading, isError, isSuccess } = queryCustomer

  const mutateCustomizedClients = useMutateGetCustomizedClients()
  const {
    isLoading: isLoadingCustomizedClients,
    isError: isErrorCustomizedClients,
    data: customizedClientsData
  } = mutateCustomizedClients

  const mutateRestoreOriginalValues = useMutateRestoreOriginalValues()
  const {
    isError: isErrorRestoreOriginalValues,
    isLoading: isLoadingRestoreOriginalValues
  } = mutateRestoreOriginalValues

  const mutateUpdateWalletToCustomValues = useMutateUpdateWalletToCustomValues()
  const {
    isError: isErrorUpdateWalletToCustomValues,
    isLoading: isLoadingUpdateWalletToCustomValues
  } = mutateUpdateWalletToCustomValues

  const queryWallets = useQueryGetWalletsByCustomerId(selectedCustomer?.id)
  const {
    isLoading: isLoadingWallets,
    isError: isErrorWallets,
    isSuccess: isSuccessWallets
  } = queryWallets

  useEffect(() => {
    const data = queryCustomer.data

    if (document && data) {
      setCustomers(data.customers)
      setCustomersPagination({
        currentPage: data.page?.currentPage - 1,
        totalPages: data.page?.totalPages,
        totalResults: data.page?.totalItems
      })
    }
  }, [document, queryCustomer.data])

  useEffect(() => {
    if (isError) {
      setCustomers([])
      setCustomersPagination(undefined)
    }
  }, [isError])

  useEffect(() => {
    const results = customizedClientsData

    if (results) {
      setCustomizedClients(results.customers)
      setCustomizedClientsPag({
        currentPage: results.page?.currentPage - 1,
        totalPages: results.page?.totalPages,
        totalResults: results.page?.totalItems
      })
    }
  }, [customizedClientsData])

  useEffect(() => {
    if (isErrorCustomizedClients) {
      setCustomizedClients([])
      setCustomizedClientsPag(undefined)
    }
  }, [isErrorCustomizedClients])

  useEffect(() => {
    const data = queryWallets.data

    if (selectedCustomer?.id && data) setWallets(data.wallets)
  }, [document, queryWallets.data])

  useEffect(() => {
    if (isErrorWallets) setWallets([])
  }, [isErrorWallets])

  const getCustomizedClients = async () => {
    return await mutateCustomizedClients.mutateAsync()
  }

  const updateWalletToCustomValues = async (
    customValues: UpdateWalletToCustomValues
  ) => {
    await mutateUpdateWalletToCustomValues.mutateAsync({
      ...customValues
    })
  }

  const restoreWalletOriginalValues = async ({
    customValues,
    customerId,
    customerWalletId,
    walletId,
    allowTransfer,
    allowWithdraw,
    openWallet
  }: RestoreWalletOriginalValues) => {
    await mutateRestoreOriginalValues.mutateAsync({
      customerId,
      customerWalletId,
      customValues,
      walletId,
      allowTransfer,
      allowWithdraw,
      openWallet
    })
  }

  return (
    <Context.Provider
      value={{
        customers,
        customersPagination,
        customizedClients,
        customizedClientsPag,
        selectedCustomer,
        wallets,
        isLoading,
        isSuccess,
        isError,
        isLoadingWallets,
        isErrorWallets,
        isSuccessWallets,
        isErrorCustomizedClients,
        isLoadingCustomizedClients,
        document,
        setDocument,
        getCustomizedClients,
        isErrorRestoreOriginalValues,
        isLoadingRestoreOriginalValues,
        restoreWalletOriginalValues,
        isErrorUpdateWalletToCustomValues,
        isLoadingUpdateWalletToCustomValues,
        updateWalletToCustomValues
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useCustomer = () => useContext(Context)
