import { Flex, Text, Box } from '@chakra-ui/react'
import { SubmitButton, SearchInput } from 'components/ui'
import React from 'react'

import { TransactionTableContainer } from '../../TransactionsTable/container/TransactionsTableContainer'

type SearchTransactionPresentationProps = {
  value: string
  isEmptyTable: boolean
  isShowTable: boolean
  isLoading: boolean
  isActiveButton: boolean
  onSearch: () => void
  onInputChange: (value: string) => void
}

const SearchTransactionPresentation = ({
  value,
  isEmptyTable,
  isShowTable,
  isLoading,
  isActiveButton,
  onSearch,
  onInputChange
}: SearchTransactionPresentationProps) => (
  <Flex flexDir="column" bg="white" width="100%" p="40px">
    <Text
      mb="24px"
      fontSize="24px"
      fontWeight="700"
      letterSpacing="-1.2px"
      color="pluxee.text.primary"
    >
      Consultar transação
    </Text>

    <Flex alignItems="flex-end" mb="64px" gap="32px">
      <Box width="100%">
        <SearchInput
          size="1.5rem"
          customIcon="search"
          isError={isEmptyTable}
          onChange={inputValue => onInputChange(inputValue)}
          label="ID da transação"
          placeholder="Informe o ID da transação"
          value={value}
        />

        {isEmptyTable && (
          <Text color="#B30000" fontSize="xxxs" fontWeight="600" mt="4px">
            Transação não encontrada, verifique e tente novamente
          </Text>
        )}
      </Box>
      <SubmitButton
        onClick={onSearch}
        isLoading={isLoading}
        isDisabled={!isActiveButton}
        decreaseMarginBottom
        customMarginBottom={!isEmptyTable ? '4px' : '25px'}
      />
    </Flex>

    {isShowTable && <TransactionTableContainer />}
  </Flex>
)

export default SearchTransactionPresentation
