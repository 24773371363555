import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/ui'
import React from 'react'

import { UserForm } from '.'

export const Heading = () => {
  const {
    isOpen: isOpenCreateUser,
    onClose: onCloseCreateUser,
    onOpen: onOpenCreateUser
  } = useDisclosure()

  return (
    <>
      <Flex alignItems="center" gap="24px">
        <Text
          color="#221C46"
          fontSize="24px"
          fontWeight="700"
          letterSpacing="-1.2px"
          lineHeight="31.2px"
        >
          Gerenciamento de usuários
        </Text>

        <Button
          title="Criar usuário"
          isLoading={false}
          onClick={onOpenCreateUser}
        />
      </Flex>

      <UserForm isOpen={isOpenCreateUser} onClose={onCloseCreateUser} />
    </>
  )
}
