import { Divider, Flex, useDisclosure } from '@chakra-ui/react'
import { Container, Layout, Toggle } from 'components/ui'
import { ControlledInput } from 'components/ui/Input'
import { useCustomer } from 'contexts/customerContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AreaLabel,
  FormSpacing,
  FormWrapper,
  CancelButton,
  SubmitButton
} from 'templates/walletManager/components'
import { sanitizeObject } from 'utils/sanitizeObject'
import { acceptedOnlyNumbers } from 'utils/stringUtils'
import { backToTop } from 'utils/windowUtils'

import { BottomLabel } from './components/bottomLabel'
import { ConfirmOperation } from './components/restoreValuesModal/confirmOperation'
import { WalletInfo } from './components/walletInfo'
import { WithdrawInfo } from './components/withdrawInfo'
import { CustomizedWalletFields } from './validation/customizedWalletSchema'

const CustomizedWalletUpdateTemplate = () => {
  return (
    <Layout maxWidth="1126px" isShowBackButton colorScheme="secondary">
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default CustomizedWalletUpdateTemplate

const Main = () => {
  const customerId = JSON.parse(window.localStorage.getItem('customer')!).id

  const { alert } = useAlert()
  const navigate = useNavigate()
  const { isOpen, onClose, onOpen: onOpenConfirmModal } = useDisclosure()

  const {
    state: { customerWallet }
  } = useLocation()

  const {
    updateWalletToCustomValues,
    restoreWalletOriginalValues,
    isLoadingRestoreOriginalValues,
    isLoadingUpdateWalletToCustomValues
  } = useCustomer()

  const [maskedDailyWithdraw, setMaskedDailyWithdraw] = useState('0')
  const [maskedMonthlyWithdraw, setMaskedMonthlyWithdraw] = useState('0')
  const [maskedWithdrawFee, setMaskedWithdrawFee] = useState('0')
  const [disabledAllFields, setDisabledAllFields] = useState(false)

  const {
    watch,
    reset,
    setValue,
    setError,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CustomizedWalletFields>()

  const dailyWithdrawalTxUnlimited = watch('wallet.dailyWithdrawalTxUnlimited')
  const monthlyWithdrawalTxUnlimited = watch(
    'wallet.monthlyWithdrawalTxUnlimited'
  )

  const dailyLimitWithdrawTx = watch('wallet.dailyLimitWithdrawTx')
  const monthlyLimitWithdrawTx = watch('wallet.monthlyLimitWithdrawTx')
  const unlimitedTx = 999999

  const valuesEqualsOriginalValues =
    Number(maskedDailyWithdraw) === customerWallet?.dailyLimitWithdrawValue &&
    Number(maskedMonthlyWithdraw) ===
      customerWallet?.monthlyLimitWithdrawValue &&
    Number(maskedWithdrawFee) === customerWallet?.withdrawFee &&
    customerWallet?.monthlyLimitWithdrawValue &&
    dailyLimitWithdrawTx === customerWallet?.dailyLimitWithdrawTx &&
    monthlyLimitWithdrawTx === customerWallet?.monthlyLimitWithdrawTx

  const onSubmit: SubmitHandler<CustomizedWalletFields> = async formData => {
    const { wallet: walletFormData } = formData

    const isEmptyDailyWithdrawTx =
      walletFormData.dailyLimitWithdrawTx?.toString() === '0'
    const isEmptyMonthlyLimitWithdrawTx =
      walletFormData.monthlyLimitWithdrawTx?.toString() === '0'

    if (isEmptyDailyWithdrawTx || isEmptyMonthlyLimitWithdrawTx) {
      setError('wallet.dailyLimitTx', { message: 'invalid' })
      isEmptyDailyWithdrawTx &&
        setError('wallet.dailyLimitWithdrawTx', { message: 'invalid' })
      isEmptyMonthlyLimitWithdrawTx &&
        setError('wallet.monthlyLimitWithdrawTx', { message: 'invalid' })

      alert({
        id: 'minValues',
        title: 'Para poder prosseguir por favor insira valores acima de 0',
        status: 'error'
      })

      return
    }

    if (Number(maskedDailyWithdraw) === 0) {
      return alert({
        id: 'errorDailyLimitWiyhdrawValue',
        status: 'warning',
        title: 'Informe o valor de limite de saques diários!'
      })
    }

    if (Number(maskedMonthlyWithdraw) === 0) {
      return alert({
        id: 'errorMonthlyWithdraw',
        status: 'warning',
        title: 'Informe o valor de limite de saques mensais!'
      })
    }

    if (disabledAllFields) {
      restoreWalletOriginalValues({
        customerWalletId: customerWallet.id,
        walletId: customerWallet.walletId,
        customerId: Number(customerId),
        customValues: false,
        allowTransfer: customerWallet.allowTransfer,
        allowWithdraw: customerWallet.allowWithdraw,
        openWallet: customerWallet.openWallet
      })
        .then(() => {
          onClose()
          navigate(-1)

          alert({
            id: 'successRestoreOriginalValues',
            status: 'success',
            title: 'A carteira retornou aos valores padrão'
          })
        })
        .catch(err => {
          console.error('Ops... something went wrong!', err)
          const errMessage =
            err?.response?.data?.message ??
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'

          alert({
            id: 'errorRestoreOriginalValues',
            status: 'error',
            title: errMessage
          })
        })

      return
    }

    const walletWithCustomValues = {
      allowTransfer: customerWallet.allowTransfer,
      allowWithdraw: customerWallet.allowWithdraw,
      openWallet: customerWallet.openWallet,
      customerId: Number(customerId),
      customerWalletId: customerWallet.id,
      walletId: customerWallet.walletId,
      customValues: true,
      dailyLimitTx: Number(walletFormData.dailyLimitTx || 0),
      dailyLimitValue: customerWallet.dailyLimitValue,
      dailyLimitWithdrawValue: Number(maskedDailyWithdraw),
      monthlyLimitWithdrawValue: Number(maskedMonthlyWithdraw),
      withdrawFee: Number(maskedWithdrawFee),
      dailyLimitWithdrawTx: dailyWithdrawalTxUnlimited
        ? unlimitedTx
        : Number(walletFormData.dailyLimitWithdrawTx || 0),
      monthlyLimitWithdrawTx: monthlyWithdrawalTxUnlimited
        ? unlimitedTx
        : Number(walletFormData.monthlyLimitWithdrawTx || 0)
    }

    const sanitizedFields = sanitizeObject(
      walletWithCustomValues
    ) as UpdateWalletToCustomValues

    updateWalletToCustomValues({
      ...sanitizedFields
    })
      .then(() => {
        onClose()
        navigate(-1)

        alert({
          id: 'successCustomizedClient',
          status: 'success',
          title: 'Cliente customizado com sucesso!'
        })
      })
      .catch(err => {
        console.error('Ops... something went wrong!', err)
        const errMessage =
          err?.response?.data?.message ??
          'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'

        alert({
          id: 'errorCustomizedClient',
          status: 'error',
          title: errMessage
        })
      })

    return
  }

  useEffect(() => {
    if (!customerWallet) {
      navigate('/customer/details')
      alert({
        id: 'NoWalletData',
        status: 'warning',
        title: 'Selecione um carteira antes!'
      })
    }
  }, [customerWallet])

  useEffect(() => {
    if (customerWallet) {
      setMaskedWithdrawFee(String(customerWallet.withdrawFee))
      setMaskedDailyWithdraw(
        customerWallet.dailyLimitWithdrawValue
          ? String(customerWallet.dailyLimitWithdrawValue)
          : '0'
      )

      setMaskedMonthlyWithdraw(
        customerWallet.monthlyLimitWithdrawValue
          ? String(customerWallet.monthlyLimitWithdrawValue)
          : '0'
      )

      reset({
        wallet: {
          id: customerWallet.id,
          name: customerWallet.name,
          model: customerWallet.model,
          withdrawFee: customerWallet.withdrawFee,
          productId: customerWallet.productId,
          allowWithdraw: customerWallet.allowWithdraw,
          dailyLimitTx: customerWallet.dailyLimitTx,
          dailyLimitWithdrawTx: customerWallet.dailyLimitWithdrawTx,
          dailyTxUnlimited: customerWallet.dailyLimitTx >= 999999,
          monthlyLimitWithdrawTx: customerWallet.monthlyLimitWithdrawTx,
          openWallet: customerWallet.openWallet,
          monthlyWithdrawalTxUnlimited:
            customerWallet.monthlyLimitWithdrawTx! >= 999999,
          dailyWithdrawalTxUnlimited:
            customerWallet.dailyLimitWithdrawTx! >= 999999,
          allowTransfer:
            customerWallet.allowTransferAux || customerWallet.allowTransferPat
        }
      })
    }
  }, [customerWallet])

  const isUnlimitedDailyTxWithdraw =
    customerWallet?.wallet.dailyLimitWithdrawTx >= 999999
  const isUnlimitedMonthlyTxWithdraw =
    customerWallet?.wallet.monthlyLimitWithdrawTx >= 999999

  const restoreOriginalValues = () => {
    reset({
      wallet: {
        dailyLimitWithdrawTx: customerWallet?.wallet.dailyLimitWithdrawTx,
        monthlyLimitWithdrawTx: customerWallet?.wallet.monthlyLimitWithdrawTx,
        dailyWithdrawalTxUnlimited:
          customerWallet?.wallet.dailyLimitWithdrawTx >= 999999,
        monthlyWithdrawalTxUnlimited:
          customerWallet?.wallet.monthlyLimitWithdrawTx >= 999999
      }
    })

    setMaskedDailyWithdraw(
      customerWallet?.wallet.dailyLimitWithdrawValue
        ? String(customerWallet?.wallet.dailyLimitWithdrawValue)
        : '0'
    )

    setMaskedMonthlyWithdraw(
      customerWallet?.wallet.monthlyLimitWithdrawValue
        ? String(customerWallet?.wallet.monthlyLimitWithdrawValue)
        : '0'
    )

    setMaskedWithdrawFee(
      customerWallet?.wallet.withdrawFee
        ? String(customerWallet?.wallet.withdrawFee)
        : '0'
    )

    setDisabledAllFields(true)
  }

  const confirmOperationFunc = () => {
    backToTop()
    onOpenConfirmModal()
  }

  return (
    <>
      <Flex
        flexDir="column"
        gap="40px"
        maxW="1126px"
        width="100%"
        p="40px"
        m="0 auto"
      >
        <FormSpacing>
          <FormWrapper
            heading="Customizar carteira"
            headingSpaceToBottom="0px"
            gap="16px"
          >
            <WalletInfo {...customerWallet} />
            <Divider />

            <WithdrawInfo
              customerWallet={customerWallet}
              onClick={restoreOriginalValues}
            />

            <Flex w="100%" flexDir="column" gap="8px">
              <AreaLabel title="Limites diários" />

              <Flex gap="16px">
                <Flex w="100%" gap="8px" flexDir="column">
                  <ControlledInput
                    height="48px"
                    register={register}
                    value={dailyLimitWithdrawTx}
                    id="wallet.dailyLimitWithdrawTx"
                    title="Limite de saques diários"
                    placeholder="Informe a quantidade de saques"
                    isDisabled={dailyWithdrawalTxUnlimited || disabledAllFields}
                    isRequired={!dailyWithdrawalTxUnlimited}
                    isInvalid={!!errors.wallet?.dailyLimitWithdrawTx}
                    onChange={event =>
                      setValue(
                        'wallet.dailyLimitWithdrawTx',
                        Number(acceptedOnlyNumbers(event.target.value, 30))
                      )
                    }
                    bottomContent={
                      <BottomLabel
                        title="Quantidade padrão:"
                        value={
                          isUnlimitedDailyTxWithdraw
                            ? 'Ilimitado'
                            : customerWallet?.wallet.dailyLimitWithdrawTx
                        }
                      />
                    }
                  />

                  <Toggle
                    titleSize="14px"
                    id="wallet.dailyWithdrawalTxUnlimited"
                    register={register}
                    title="Quantidade de saques diários ilimitados"
                    isDisabled={disabledAllFields}
                    isChecked={dailyWithdrawalTxUnlimited}
                    onChange={() => {
                      setValue(
                        'wallet.dailyWithdrawalTxUnlimited',
                        !dailyWithdrawalTxUnlimited
                      )

                      setValue(
                        'wallet.dailyLimitWithdrawTx',
                        !dailyWithdrawalTxUnlimited
                          ? unlimitedTx
                          : customerWallet.dailyLimitWithdrawTx
                      )
                    }}
                  />
                </Flex>

                <ControlledInput
                  height="48px"
                  register={register}
                  id="wallet.dailyLimitWithdrawValue"
                  title="Limite diário em R$"
                  isInvalid={!!errors.wallet?.dailyLimitWithdrawValue}
                  isDisabled={disabledAllFields}
                  placeholder="R$ 0,00"
                  value={Mask.moneyInput(String(maskedDailyWithdraw))}
                  onChange={event => {
                    setMaskedDailyWithdraw(
                      Mask.removeMoneyInput(event.currentTarget.value)
                    )
                  }}
                  bottomContent={
                    <BottomLabel
                      title="Valor padrão:"
                      value={
                        customerWallet?.wallet.dailyLimitWithdrawValue
                          ? Mask.money(
                              Number(
                                customerWallet?.wallet.dailyLimitWithdrawValue
                              )
                            )
                          : 'Não definido'
                      }
                    />
                  }
                />
              </Flex>
            </Flex>

            <Flex w="100%" flexDir="column" gap="8px">
              <AreaLabel title="Limites mensais" />

              <Flex gap="16px">
                <Flex w="100%" gap="8px" flexDir="column">
                  <ControlledInput
                    height="48px"
                    register={register}
                    value={monthlyLimitWithdrawTx}
                    isDisabled={
                      monthlyWithdrawalTxUnlimited || disabledAllFields
                    }
                    isRequired={!monthlyWithdrawalTxUnlimited}
                    id="wallet.monthlyLimitWithdrawTx"
                    title="Limite de saques mensais"
                    isInvalid={!!errors.wallet?.monthlyLimitWithdrawTx}
                    placeholder="Informe a quantidade de saques"
                    onChange={event =>
                      setValue(
                        'wallet.monthlyLimitWithdrawTx',
                        Number(acceptedOnlyNumbers(event.target.value, 30))
                      )
                    }
                    bottomContent={
                      <BottomLabel
                        title="Quantidade padrão:"
                        value={
                          isUnlimitedMonthlyTxWithdraw
                            ? 'Ilimitado'
                            : customerWallet?.wallet.monthlyLimitWithdrawTx
                        }
                      />
                    }
                  />

                  <Toggle
                    titleSize="14px"
                    id="wallet.monthlyWithdrawalTxUnlimited"
                    register={register}
                    title="Quantidade de saques mensais ilimitados"
                    isDisabled={disabledAllFields}
                    isChecked={monthlyWithdrawalTxUnlimited}
                    onChange={() => {
                      setValue(
                        'wallet.monthlyWithdrawalTxUnlimited',
                        !monthlyWithdrawalTxUnlimited
                      )

                      setValue(
                        'wallet.monthlyLimitWithdrawTx',
                        !monthlyWithdrawalTxUnlimited
                          ? unlimitedTx
                          : customerWallet.monthlyLimitWithdrawTx
                      )
                    }}
                  />
                </Flex>

                <ControlledInput
                  height="48px"
                  register={register}
                  id="wallet.monthlyLimitWithdrawValue"
                  title="Limite mensal em R$"
                  isInvalid={!!errors.wallet?.monthlyLimitWithdrawValue}
                  isDisabled={disabledAllFields}
                  placeholder="R$ 0,00"
                  value={Mask.moneyInput(String(maskedMonthlyWithdraw))}
                  onChange={event => {
                    setMaskedMonthlyWithdraw(
                      Mask.removeMoneyInput(event.currentTarget.value)
                    )
                  }}
                  bottomContent={
                    <BottomLabel
                      title="Valor padrão:"
                      value={
                        customerWallet?.wallet.monthlyLimitWithdrawValue
                          ? Mask.money(
                              Number(
                                customerWallet?.wallet.monthlyLimitWithdrawValue
                              )
                            )
                          : 'Não definido'
                      }
                    />
                  }
                />
              </Flex>
            </Flex>

            <Flex w="100%" flexDir="column" gap="8px">
              <AreaLabel title="Taxas" />

              <Flex gap="16px">
                <ControlledInput
                  height="48px"
                  register={register}
                  value={Mask.moneyInput(String(maskedWithdrawFee))}
                  id="wallet.withdrawFee"
                  title="Taxa de saque"
                  isInvalid={!!errors.wallet?.withdrawFee}
                  isDisabled={disabledAllFields}
                  errorMessage="Taxa de saque é obrigatório"
                  placeholder="R$ 0,00"
                  onChange={event => {
                    const maxLenght = 4

                    setMaskedWithdrawFee(
                      Mask.removeMoneyInput(
                        event.currentTarget.value,
                        maxLenght
                      )
                    )
                  }}
                  bottomContent={
                    <BottomLabel
                      title="Valor padrão:"
                      value={
                        customerWallet?.wallet.withdrawFee
                          ? Mask.money(customerWallet?.wallet.withdrawFee)
                          : 'Não definido'
                      }
                    />
                  }
                />

                <Flex w="100%" />
              </Flex>
            </Flex>
          </FormWrapper>
        </FormSpacing>

        <Flex alignSelf="end" gap="16px">
          <CancelButton onClick={() => navigate(-1)} />
          <SubmitButton
            title={'Salvar alterações'}
            onClick={confirmOperationFunc}
            isLoading={
              isLoadingRestoreOriginalValues ||
              isLoadingUpdateWalletToCustomValues
            }
            isDisabled={
              isLoadingRestoreOriginalValues ||
              isLoadingUpdateWalletToCustomValues ||
              (valuesEqualsOriginalValues && !disabledAllFields)
            }
          />
        </Flex>
      </Flex>

      <ConfirmOperation
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleSubmit(onSubmit)}
        isLoading={
          isLoadingRestoreOriginalValues || isLoadingUpdateWalletToCustomValues
        }
        buttonTitle={
          disabledAllFields ? 'Remover customização' : 'Customizar carteira'
        }
        title={
          disabledAllFields
            ? 'Retorno ao valores padrão'
            : 'Modificações no cliente'
        }
        description={
          disabledAllFields
            ? 'Ao acionar o botão ”Retornar aos valores padrão” os valores customizados serão removidos desta carteira. Deseja prosseguir?'
            : 'Alguns parâmetros do cliente estão sendo customizados. Caso ocorram alterações nos valores padrões, elas não refletirão nos dados que estão sendo alterados. Deseja prosseguir?'
        }
      />
    </>
  )
}
