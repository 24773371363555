export type AcceptedCodes =
  | '002'
  | '026'
  | '012'
  | '013'
  | '014'
  | '015'
  | '020'
  | '021'
  | '070'

export const getTextByBlockCode = (code: AcceptedCodes) => {
  const data = {
    '002': messages.firstMessage,
    '026': messages.secondMessage,
    '012': messages.secondMessage,
    '013': messages.thirdMessage,
    '014': messages.thirdMessage,
    '015': messages.secondMessage,
    '020': messages.secondMessage,
    '021': messages.secondMessage,
    '070': messages.fourtyMessage
  }

  return data[code] ?? data['002']
}

export const getButtonTitleByBlockCode = (code: AcceptedCodes) => {
  const data = {
    '002': buttonTitles.block,
    '026': buttonTitles.cancel,
    '012': buttonTitles.cancel,
    '013': buttonTitles.cancel,
    '014': buttonTitles.cancel,
    '015': buttonTitles.cancel,
    '020': buttonTitles.cancel,
    '021': buttonTitles.cancel,
    '070': buttonTitles.block
  }

  return data[code] ?? data['002']
}

const messages = {
  firstMessage: `A tratativa de segurança bloqueia o cartão preventivamente, apenas o analista de prevenção a fraude poderá desbloquear via Backoffice. Lembre-se que esta tratativa não emite nova via automaticamente.`,
  secondMessage: `A tratativa de segurança cancela o cartão, não permitindo a ação ser desfeita. Lembre-se que esta tratativa emite nova via automaticamente.`,
  thirdMessage: `A tratativa de segurança cancela o cartão, não permitindo a ação ser desfeita. Lembre-se que esta tratativa não emite nova via automaticamente.`,
  fourtyMessage: `A tratativa de segurança bloqueia o cartão preventivamente, apenas o colaborador poderá desbloquear via APP. Lembre-se que esta tratativa não emite nova via automaticamente.`
}

const buttonTitles = {
  block: `Bloquear`,
  cancel: `Cancelar cartão`
}
