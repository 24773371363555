const maskCPF = (cpf: string) => {
  const cleanCPF = (cpf || '').replace(/\D/g, '')
  const cpfFormat = cleanCPF.length > 11 ? cleanCPF.slice(0, 11) : cleanCPF

  return cpfFormat
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const maskMoney = (money: number) => {
  const moneyFormat = (money || 0).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
  return moneyFormat
}

const maskCNPJ = (cnpj: string) => {
  const cleanCNPJ = (cnpj || '').replace(/\D/g, '')
  const cnpjFormat = cleanCNPJ.length > 14 ? cleanCNPJ.slice(0, 14) : cleanCNPJ

  return cnpjFormat
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const dateMask = (value: string) => {
  return (value || '')
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

const maskZipCode = (zipCode: string) => {
  const cleanZipCode = (zipCode || '').replace(/\D/g, '')
  const zipCodeFormat =
    cleanZipCode.length > 8 ? cleanZipCode.slice(0, 8) : cleanZipCode

  return zipCodeFormat.replace(/(\d{5})(\d{1,4})$/, '$1-$2')
}

const maskBillet = (billet: string) => {
  const cleanBillet = (billet || '').replace(/\D/g, '')
  const billetFormat =
    cleanBillet.length > 47 ? cleanBillet.slice(0, 47) : cleanBillet

  return billetFormat
    .replace(/(\d{5})(\d)/, '$1.$2')
    .replace(/(\d{5})(\d)/, '$1 $2')
    .replace(/(\d{5})(\d)/, '$1.$2')
    .replace(/(\d{6})(\d)/, '$1 $2')
    .replace(/(\d{6}) (\d{5})(\d)/, '$1 $2.$3')
    .replace(/(\d{6})(\d)/, '$1 $2')
    .replace(/(\d)(\d{7,13})/, '$1 $2')
}

const maskBankAccount = (account: string) => {
  const cleanBankAccount = (account || '').replace(/\D/g, '')
  const bankAccount =
    cleanBankAccount.length > 9
      ? cleanBankAccount.slice(0, 9)
      : cleanBankAccount

  return bankAccount.replace(/(\d)(\d{1}$)/, '$1-$2')
}

const maskBankAgency = (agency: string) => {
  const cleanBankAgency = (agency || '').replace(/\D/g, '')
  const bankAgency =
    cleanBankAgency.length > 4 ? cleanBankAgency.slice(0, 4) : cleanBankAgency

  return bankAgency
}

const maskMoneyInput = (input: string) => {
  const aux = (input || '').replace(/[^-&&^\d&&^.]/g, '')
  let value = parseFloat(aux)

  let base = 'R$ '
  if (value < 0) {
    value = value * -1
    base += '- '
  }
  const valueWithMask = value.toFixed(2).split('.')
  valueWithMask[0] = base + valueWithMask[0].split(/(?=(?:...)*$)/).join('.')
  return valueWithMask.join(',')
}

const removeMaskMoneyInput = (value: string, maxLength?: number) => {
  if (!value) return '0'

  let returnValue = value.replace(/[\D]+/g, '')

  if (maxLength && returnValue.length > maxLength) {
    returnValue = returnValue.slice(0, maxLength)
  }

  switch (returnValue.length) {
    case 1:
      return `0.0${returnValue}`
    case 2:
      return `0.${returnValue}`
    default:
      return `${returnValue.slice(
        0,
        returnValue.length - 2
      )}.${returnValue.slice(returnValue.length - 2)}`
  }
}

const removeMaskCpf = (cpf: string) => {
  const cleanCPF = (cpf || '').replace(/\D/g, '')
  return cleanCPF
}

const removeMaskCnpj = (cnpj: string) => {
  return (cnpj || '').replace(/\D/g, '')
}

const phoneMask = (phoneNumber: string) => {
  return (phoneNumber || '')
    .replace('+55', '')
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')
}
const maskOnlyNumbers = (value: string) => {
  return value.replace(/[\D]+/g, '')
}

const Mask = {
  CPF: maskCPF,
  CNPJ: maskCNPJ,
  money: maskMoney,
  date: dateMask,
  zipCode: maskZipCode,
  billet: maskBillet,
  bankAccount: maskBankAccount,
  bankAgency: maskBankAgency,
  moneyInput: maskMoneyInput,
  removeMoneyInput: removeMaskMoneyInput,
  removeMaskCpf,
  removeMaskCnpj,
  phone: phoneMask,
  onlyNumbers: maskOnlyNumbers
}

export default Mask

export type MaskType = keyof typeof Mask
